import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StudentScheduledLesson } from '../../../components/common/StudentScheduledLesson';
import Klass from '../../../models/Klass';
import LessonProgress from '../../../models/LessonProgress';
import ScheduledLesson from '../../../models/ScheduledLesson';

interface StudentCalendarListProps {
  klass: null | Klass;
  scheduledLessons: null | ScheduledLesson[];
  lessonsProgress: null | LessonProgress[];
}

export const StudentCalendarList = ({
  scheduledLessons,
  lessonsProgress,
  klass,
}: StudentCalendarListProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPage',
  });

  const [listPreview, setListPreview] = useState<ScheduledLesson[]>();

  const isTheLimitOfList = listPreview?.length !== scheduledLessons?.length;

  const seeMoreScheduledLessons = (currentList?: ScheduledLesson[]) => {
    if (currentList && scheduledLessons) {
      let findLastIndex = currentList.lastIndexOf(
        currentList[currentList.length - 1],
      );

      if (findLastIndex) {
        findLastIndex++;
        const newLastIndex = findLastIndex + 4;
        const findMoreLessons = scheduledLessons.slice(
          findLastIndex,
          newLastIndex,
        );
        setListPreview(listPreview?.concat(findMoreLessons));
      }
    }
  };

  useEffect(() => {
    if (scheduledLessons && !listPreview) {
      setListPreview(scheduledLessons.slice(0, 4));
    }
  }, [listPreview, scheduledLessons]);

  return (
    <div className="flex flex-col gap-4">
      {listPreview &&
        listPreview.map((scheduledLesson, index) => {
          const findLessonProgress = lessonsProgress?.find(
            lesson => lesson.lesson.id === scheduledLesson.lesson.id,
          );

          return (
            <StudentScheduledLesson
              key={index}
              scheduledLesson={scheduledLesson}
              lessonProgress={findLessonProgress || null}
              klass={klass || null}
            />
          );
        })}

      {isTheLimitOfList && (
        <button
          className="w-fit flex self-end px-2.5 bg-accent-content rounded-full text-accent font-500"
          onClick={() => seeMoreScheduledLessons(listPreview)}
        >
          {t('seeMore')}
        </button>
      )}
    </div>
  );
};
