import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectInput from '@/components/common/dataInput/SelectInput';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Klass, {
  DaysOfWeekTypeEnum,
  KlassPayload,
  KlassStatusEnum,
} from '@/models/Klass';
import { isEmpty } from 'lodash';
import { buildChangedObject } from '@/utils/buildChangedObject';
import TimeInput from '@/components/common/dataInput/TimeInput';
import { CourseDurationMinuteEnum } from '@/models/Course';
import { courseDurationNumberForString } from '@/utils/CourseDuration';
import {
  createKlass,
  updateKlass,
  updateKlassStatus,
} from '@/data/services/klassServices';
import DateInput from '@/components/common/dataInput/DateInput';
import { handleUserFullName } from '@/functions/handleUserFullName';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import useAuth from '@/data/hook/useAuth';
import { isAdmin } from '@/functions/auth';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { UserTypeEnum } from '@/models/User';
import { DayOfWeek } from 'react-day-picker';
import TooltipHandler from '@/components/common/TooltipHandler';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import {
  courseBasesQueryKeys,
  klassesQueryKeys,
  unitsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';

export interface KlassFormProps {
  klass?: Klass;
  disabled?: boolean;
  className?: string;
  formMode?: 'edit' | 'create';
  onUpdate?: (klass?: Klass) => void;
  onCancel?: () => void;
}

export default function KlassForm(props: KlassFormProps) {
  const { user } = useAuth();
  const {
    klass,
    disabled,
    className,
    onUpdate,
    onCancel,
    formMode = 'edit',
  } = props;

  const [hasAlter, setHasAlter] = useState<boolean>(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.addKlass',
  });

  const { t: tStatus } = useTranslation('translation', {
    keyPrefix: 'manageKlasses',
  });

  const { t: tCategory } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });

  const defaultDayTimes = [{}];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm<KlassPayload>({
    defaultValues: {
      dayTimes: klass?.dayTimes ?? defaultDayTimes,
      duration: klass?.duration,
      status: klass?.status,
      unitId: klass?.unitId ?? 0,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'dayTimes',
  });

  const queryClient = useQueryClient();

  const blockChanges = !isEmpty(errors);

  const onEdit = async ({ status, duration, dayTimes }: KlassPayload) => {
    if (!klass) throw new Error('Klass not found');

    const previousDate = moment(klass.klassStartDate).format('YYYY-MM-DD');
    const dayTimesFormated = dayTimes?.map(({ time, day }) => ({
      day,
      time: moment(`${previousDate} ${time}`).format('HH:mm:ss'),
    }));

    const parcialKlass = buildChangedObject<Klass>(klass, {
      duration: Number(duration),
      dayTimes: dayTimesFormated,
    });

    let klassUpdated: Klass | undefined;
    if (status && klass.status !== status) {
      klassUpdated = await updateKlassStatus(klass.id, { status });
    }
    if (Object.keys(parcialKlass).length)
      klassUpdated = await updateKlass(klass.id, parcialKlass);
    return klassUpdated;
  };

  const onCreate = async ({
    unitId,
    courseId,
    dayTimes = [],
    duration,
    teacherId,
    klassStartDate,
  }: KlassPayload) => {
    const firstTime = dayTimes[0]?.time;

    const previousDate = moment(klassStartDate).format('YYYY-MM-DD');

    const timeFormat =
      firstTime && moment(`${previousDate} ${firstTime}`).format('HH:mm:ss');

    klassStartDate = moment(`${previousDate} ${timeFormat}`).format(
      'YYYY-MM-DDTHH:mm:ss',
    );

    const newklass: KlassPayload = {
      unitId,
      courseId,
      teacherId,
      duration: Number(duration),
      dayTimes,
      klassStartDate,
    };

    await createKlass(newklass);
  };

  const klassesQueryKey = klassesQueryKeys.list._def;

  const { mutate: update, isLoading: isUpdating } = useMutation(onEdit, {
    onSuccess: data => {
      setHasAlter(false);
      alert.success(t('saveSuccess'));
      queryClient.invalidateQueries(klassesQueryKey);
      onCancel?.();
      onUpdate?.(data);
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const { mutate: create, isLoading: isCreating } = useMutation(onCreate, {
    onSuccess: () => {
      setHasAlter(false);
      alert.success(t('addSuccess'));
      queryClient.invalidateQueries(klassesQueryKey);
      onCancel?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = async (data: KlassPayload) => {
    formMode === 'create' ? create(data) : update(data);
    setHasAlter(false);
  };

  const onReset = () => {
    reset({
      dayTimes: klass?.dayTimes,
      duration: klass?.duration,
      status: klass?.status,
    });
    onCancel?.();
    setHasAlter(false);
  };

  useEffect(() => {
    const subscription = watch(() => setHasAlter(true));
    return () => subscription.unsubscribe();
  }, [watch, setHasAlter]);

  const convertMomentDaysToDaysOfWeek = (day: number) => {
    const daysOfWeek = Object.keys(DaysOfWeekTypeEnum);
    return daysOfWeek[day];
  };

  const klassStatus = Object.keys(KlassStatusEnum).filter(
    key => key !== KlassStatusEnum.CANCELED,
  ) as (keyof typeof KlassStatusEnum)[];
  const disableStatusInput =
    disabled || klass?.status === KlassStatusEnum.CANCELED;

  const unitId = watch('unitId');

  const enabledRecessRequest = isAdmin(user?.userType);

  const { data: unit, isFetching: isFetchingCalendar } = useQuery({
    ...unitsQueryKeys.get(unitId ?? 0),
    enabled: !!unitId && enabledRecessRequest && formMode === 'create',
  });

  const recessCalendar = unit?.recess;

  const weekDaysValues = {
    [DaysOfWeekTypeEnum.SUNDAY]: 0,
    [DaysOfWeekTypeEnum.MONDAY]: 1,
    [DaysOfWeekTypeEnum.TUESDAY]: 2,
    [DaysOfWeekTypeEnum.WEDNESDAY]: 3,
    [DaysOfWeekTypeEnum.THURSDAY]: 4,
    [DaysOfWeekTypeEnum.FRIDAY]: 5,
    [DaysOfWeekTypeEnum.SATURDAY]: 6,
  };

  const weekDays = Object.values(weekDaysValues);

  const recessDates = recessCalendar?.map(date => moment(date).toDate()) ?? [];

  const dayTimes = watch('dayTimes');

  const hasSelectedKlassDayTimes =
    dayTimes?.reduce((prev, { day, time }) => !!day && !!time, false) ?? false;

  const klassWeekDays =
    dayTimes?.filter(value => !!value)?.map(({ day }) => weekDaysValues[day]) ??
    [];

  const weekDayBlocked: DayOfWeek = {
    dayOfWeek: weekDays.filter(value => !klassWeekDays.includes(value)),
  };

  const disableDatesMacther = [weekDayBlocked, ...recessDates];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4 ${className || ''}`}
      data-testid="klassForm"
    >
      <ConditionalRenderer condition={formMode === 'create'}>
        <Controller
          name="unitId"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              displayName={unit => unit.name}
              onSelect={({ id }) => onChange(id)}
              className="w-full rounded-md placeholder:font-400 max-w-2xl"
              input={{
                label: t('unit'),
                errorLabelText: error?.message,
                labelPosition: 'left',
                disabled,
                testId: 'selectUnit',
              }}
              onDeselect={() => onChange(undefined)}
            />
          )}
          rules={{
            required: formMode === 'create' ? t('error.unit') : undefined,
          }}
        />
      </ConditionalRenderer>
      <div
        className={`${
          !!unitId || isFetchingCalendar ? '' : 'disabled'
        } flex flex-col gap-4 transition ease-in-out duration-300`}
      >
        <ConditionalRenderer condition={formMode === 'create'}>
          <Controller
            name="courseId"
            control={control}
            render={({ field: { onChange } }) => (
              <InfiniteSearchInput
                service={courseBasesQueryKeys.list}
                displayName={course =>
                  `${course.name} - ${tCategory(course.category)} `
                }
                filters={{ coursePathStatus: VersioningStatusEnum.PUBLISHED }}
                onSelect={({ id }) => onChange(id)}
                className="w-full rounded-md placeholder:font-400 max-w-2xl"
                input={{
                  label: t('course'),
                  errorLabelText: errors?.courseId?.message,
                  labelPosition: 'left',
                  disabled,
                  testId: 'selectCourse',
                }}
                onDeselect={() => onChange(undefined)}
              />
            )}
            rules={{
              required: formMode === 'create' ? t('error.course') : undefined,
            }}
          />
        </ConditionalRenderer>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <SelectInput
              testId="selectDay"
              label={t('day')}
              labelPosition="left"
              className="w-full rounded-md placeholder:font-400"
              hiddenSelect={disabled}
              defaultValue={
                klass
                  ? t(`weekdays.${klass.dayTimes[0].day.toLowerCase()}`)
                  : ''
              }
              register={register(`dayTimes.${index}.day`, {
                required: t('error.dayWeek'),
              })}
              errorLabelText={errors.dayTimes?.[index]?.day?.message}
            >
              <option value="" disabled>
                {t('select.day')}
              </option>
              {moment.weekdays().map((day, index) => {
                return (
                  <option
                    key={day}
                    value={convertMomentDaysToDaysOfWeek(index)}
                    className="dayOption"
                  >
                    {t(
                      `weekdays.${convertMomentDaysToDaysOfWeek(
                        index,
                      ).toLowerCase()}`,
                    )}
                  </option>
                );
              })}
            </SelectInput>
            <Controller
              name={`dayTimes.${index}.time`}
              control={control}
              rules={{
                required: t('error.klassTime'),
                validate: value => {
                  if (value) {
                    const time = moment(value, 'HH:mm');
                    if (!time.isValid() || value.includes('-'))
                      return t('error.invalidTime');
                  }
                  return true;
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TimeInput
                  label={t('time')}
                  testId="timeInput"
                  errorLabelText={errors.dayTimes?.[index]?.time?.message}
                  labelPosition="left"
                  value={value}
                  disabled={disabled}
                  onTimeChange={time => {
                    onChange(time);
                  }}
                />
              )}
            />
          </Fragment>
        ))}

        <ConditionalRenderer condition={formMode === 'create'}>
          <TooltipHandler
            renderTooltip={!hasSelectedKlassDayTimes}
            tooltipMessage={t('selectDayTimes')}
            classNameContainer="w-full"
          >
            <Controller
              control={control}
              rules={{
                required: t('error.klassDate'),
              }}
              name={'klassStartDate'}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  disabledDates={disableDatesMacther}
                  label={t('startDate')}
                  labelPosition="left"
                  testId="startDate"
                  value={value}
                  className="rounded-md w-full placeholder:font-400 disabled:border-primary disabled:opacity-40"
                  onDateChange={date => {
                    onChange(date);
                  }}
                  errorLabelText={errors.klassStartDate?.message}
                  disabled={disabled || !hasSelectedKlassDayTimes}
                />
              )}
            />
          </TooltipHandler>
        </ConditionalRenderer>
        <SelectInput
          testId="selectDuration"
          label={t('duration')}
          labelPosition="left"
          className="w-full rounded-md placeholder:font-400"
          hiddenSelect={disabled}
          defaultValue={
            klass ? courseDurationNumberForString(klass.duration) : ''
          }
          register={register('duration', {
            required: t('error.duration'),
          })}
          errorLabelText={errors.duration?.message}
        >
          <option value="" disabled>
            {t('select.duration')}
          </option>
          {Object.values(CourseDurationMinuteEnum)
            .filter(value => typeof value === 'number')
            .map(minuteNumber => {
              const minuteString = courseDurationNumberForString(
                Number(minuteNumber),
              );
              return (
                <option
                  key={minuteNumber}
                  value={minuteNumber}
                  className="durationOption"
                >
                  {minuteString}
                </option>
              );
            })}
        </SelectInput>
        <ConditionalRenderer condition={formMode === 'edit'}>
          <SelectInput
            testId="klassStatus"
            label={t('status')}
            labelPosition="left"
            disabled={disableStatusInput}
            className="w-full rounded-md placeholder:font-400"
            defaultValue={tStatus(`status.${klass?.status}`)}
            hiddenSelect={disableStatusInput}
            register={register('status')}
            errorLabelText={errors.unitId?.message}
          >
            {klassStatus.map(status => {
              return (
                <option
                  key={status}
                  value={status}
                  className="flex items-center gap-1.5"
                >
                  {tStatus(`status.${status}`)}
                </option>
              );
            })}
          </SelectInput>
        </ConditionalRenderer>
        <ConditionalRenderer condition={formMode === 'create'}>
          <Controller
            name="teacherId"
            control={control}
            render={({ field: { onChange } }) => (
              <InfiniteSearchInput
                service={usersQueryKeys.list}
                options={{ enabled: !!unitId }}
                filters={{
                  unit: unitId ? [unitId] : undefined,
                  userType: [UserTypeEnum.TEACHER],
                }}
                displayName={teacher => handleUserFullName(teacher)}
                onSelect={({ id }) => onChange(id)}
                className="w-full rounded-md placeholder:font-400 max-w-2xl"
                input={{
                  label: t('teacher'),
                  errorLabelText: errors?.teacherId?.message,
                  labelPosition: 'left',
                  disabled,
                  testId: 'selectTeacher',
                }}
                onDeselect={() => onChange(undefined)}
              />
            )}
            rules={{
              required: formMode === 'create' ? t('error.teacher') : undefined,
            }}
          />
        </ConditionalRenderer>
      </div>

      <ConditionalRenderer condition={!disabled}>
        <SaveCancelGroup
          loading={isUpdating || isCreating}
          save={{
            disabled: blockChanges || !hasAlter,
            testId: 'saveButton',
          }}
          cancel={{
            onClick: onReset,
            testId: 'cancelButton',
            disabled: isUpdating || !hasAlter,
          }}
        />
      </ConditionalRenderer>
    </form>
  );
}
