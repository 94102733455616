import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { StudentReportEditProps } from '../teacher/StudentReportEdit';
import ScheduledLessonReport, {
  GradeEnum,
} from '@/models/ScheduledLessonReport';
import { StudentRatingReport } from '../teacher/StudentRatingReport';
import { AccordionTextarea } from '@/components/common/dataInput/AccordionTextarea';
import {
  AssignmentType,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import useStudentReport from '@/data/hook/useStudentReport';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { homeworkProgressQueryKeys } from '@/data/services/querykeys';
import { updateMgActivityProgress } from '@/data/services/mgActivityProgressServices';
import { Reward } from '@/models/Rewards';

type PickProps = Pick<StudentReportEditProps, 'enrollment' | 'canEditCash'>;

type StudentReportFormProps = Required<PickProps> & {
  homeworkProgress?: HomeworkActivityProgress;
  report: ScheduledLessonReport;
  invalidate?: () => Promise<void>;
  reward: Reward;
};

export type ReportFields = ScheduledLessonReport & {
  notDone: boolean;
  homeworkGrade: GradeEnum | null;
  notes: string;
  allValues?: number;
};

export function StudentReportForm({
  reward,
  enrollment,
  canEditCash,
  homeworkProgress,
  report,
  invalidate,
}: StudentReportFormProps) {
  const [changed, setChanged] = useState(false);

  const { update } = useStudentReport();

  const queryClient = useQueryClient();

  const checkHomework = () => {
    const hasAssignment =
      homeworkProgress?.homework.assignmentType === AssignmentType.TEXT;

    return hasAssignment
      ? !!homeworkProgress.answer
      : homeworkProgress?.activityProgress.hasDone;
  };

  const isHomeworkDone = checkHomework();

  const defaultValues = useMemo(
    () => ({
      ...report,
      presenceReward: report.presenceReward ?? reward.amount,
      homeworkGrade: homeworkProgress?.activityProgress.grade,
      notDone: !isHomeworkDone,
    }),
    [
      homeworkProgress?.activityProgress.grade,
      isHomeworkDone,
      report,
      reward.amount,
    ],
  );

  const { control, handleSubmit, register, reset, watch } =
    useForm<ReportFields>({
      defaultValues,
    });

  useEffect(() => {
    reset(defaultValues);
    setChanged(false);
  }, [defaultValues, reset]);

  useEffect(() => {
    const subscription = watch(() => setChanged(true));
    return () => subscription.unsubscribe();
  }, [watch, setChanged]);

  const onSubmit = async ({
    homeworkGrade,
    notDone,
    ...changes
  }: ReportFields) => {
    if (homeworkProgress) {
      const mgActivityProgressId = homeworkProgress.activityProgress.id;
      await updateMgActivityProgress(mgActivityProgressId, {
        grade: notDone ? 0 : homeworkGrade,
        hasDone: !notDone,
      });
    }
    return await update({
      id: report.id,
      changes,
    });
  };

  const { mutate, isLoading } = useMutation(onSubmit, {
    onError(error: any) {
      alert.error(getErrorMessage(error));
    },
    async onSuccess() {
      await invalidate?.();
      if (homeworkProgress)
        await queryClient.invalidateQueries(
          homeworkProgressQueryKeys.list(homeworkProgress.id, {
            studentId: enrollment.student.id,
          }),
        );
      setChanged(false);
    },
  });

  const resetFields = () => {
    reset(defaultValues);
    setChanged(false);
  };

  return (
    <form
      data-testid="studentReportEdit"
      className="flex flex-col gap-y-4"
      onSubmit={handleSubmit(data => mutate(data))}
    >
      <StudentRatingReport
        control={control}
        homeworkProgress={homeworkProgress}
        rewardAmount={reward.amount}
        locked={!canEditCash}
      />

      <AccordionTextarea
        register={register('notes')}
        testId="accordionTextarea"
        studentName={enrollment.student.firstName}
      />

      <SaveCancelGroup
        loading={isLoading}
        cancel={{
          onClick: resetFields,
          testId: 'cancelButton',
        }}
        save={{
          disabled: !changed,
          testId: 'saveButton',
        }}
      />
    </form>
  );
}
