import ErrorComponent from '../../components/common/ErrorComponent';
import Layout from '../../components/template/Layout';

interface ErrorPageProps {
  statusCode?: number;
  title?: string;
  subtitle?: string;
}

export default function ErrorPage(props: ErrorPageProps) {
  const { statusCode, title, subtitle } = props;
  return (
    <Layout>
      <ErrorComponent
        statusCode={statusCode}
        errorTextSubTitle={subtitle}
        errorTextTitle={title}
      />
    </Layout>
  );
}
