import { useState } from 'react';
import Text, { TextProps } from './Text';
import { Tooltip } from './Tooltip';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';
import IconButton from '../../common/buttons/IconButton';

import alert from '../../../utils/UseAlert';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../ConditionalRenderer';

type TextWithCopyProps = TextProps & {
  copyText?: string;
  copiedText?: string;
  text: string;
};

export default function TextWithCopy({
  copyText,
  copiedText,
  text,
  ...textProps
}: TextWithCopyProps) {
  const [isCopied, setIsCopied] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const startTimeout = () => {
    const timer = setTimeout(() => {
      setIsCopied(false);
      setTimerId(null);
    }, 2000);
    setTimerId(timer);
  };

  const handleCopy = (text: string) => {
    if (isCopied && timerId) {
      clearTimeout(timerId);
      startTimeout();
      return;
    }
    navigator.clipboard.writeText(text);
    alert.success(tCommon('copied'));
    setIsCopied(true);
    startTimeout();
  };

  return (
    <ConditionalRenderer condition={!!text}>
      <div className="flex items-center gap-1">
        <Text text={text} {...textProps} />
        <Tooltip
          text={
            !isCopied
              ? copyText || tCommon('copy')
              : copiedText || tCommon('copied')
          }
          placement="right"
        >
          <IconButton
            icon={
              isCopied ? (
                <CheckCircleIcon className="w-4 h-4 text-success" />
              ) : (
                <DocumentDuplicateIcon className="w-4 h-4  text-primary" />
              )
            }
            onClick={() => handleCopy(text || '')}
            className="opacity-75 hover:opacity-100 border-none transition ease-in-out duration-150"
          />
        </Tooltip>
      </div>
    </ConditionalRenderer>
  );
}
