import { ICourse } from '../../models/Course';
import { SimplifiedCourseProgress } from '../../models/StudentCourseProgress';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Skeleton from '../common/Skeleton';
import CourseItem from './CourseItem';
import ProgressBarCourse from './ProgressBarCourse';

interface CourseProgressListProps {
  courseProgress?: SimplifiedCourseProgress;
  courses: ICourse[];
  hideProgress?: boolean;
  loading?: boolean;
}

export default function CourseProgressList({
  courses,
  hideProgress,
  courseProgress,
  loading,
}: CourseProgressListProps) {
  if (!loading) {
    <Skeleton className="flex w-full h-40 bg-primary-content" />;
  }

  return (
    <ConditionalRenderer condition={courses}>
      <div className="flex flex-col gap-3">
        <ConditionalRenderer condition={courseProgress}>
          <ProgressBarCourse
            isStudent={!hideProgress}
            className={hideProgress ? 'hidden' : ''}
            courseName={courseProgress?.coursePath.course.name}
            courseType={courseProgress?.coursePath.course.courseType}
            progress={courseProgress?.progress}
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={courses}>
          <div className="grid sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-3">
            {courses.map((course: ICourse, index) => {
              return (
                <CourseItem
                  key={index}
                  course={course}
                  isActive={courseProgress?.coursePath.course.id === course.id}
                />
              );
            })}
          </div>
        </ConditionalRenderer>
      </div>
    </ConditionalRenderer>
  );
}
