import { Fragment, useEffect } from 'react';
import { PencilIcon } from '@heroicons/react/outline';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import HeadTitle from '@/components/common/HeadTitle';
import Overlay from '@/components/common/Overlay';
import { ReportHeader } from '@/components/teacher/ReportHeader';
import { ReportPagination } from '@/components/teacher/ReportPagination';
import Layout from '@/components/template/Layout';
import { listScheduledLessons } from '@/data/services/scheduledLessonsServices';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import ScheduledLessonReportView from './ScheduledLessonReportView';
import { ErrorCapturer } from '@/adapters/ErrorCapturer';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { ScheduledLessonByKlassNotFoundDispatcher } from '@/errors/scheduledLesson';
import Skeleton from '@/components/common/Skeleton';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  klassesQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';

export default function ManageScheduledLessonReport() {
  const navigate = useNavigate();
  const controls = useAnimation();
  const { klassId: klassIdParam, scheduledLessonId: scheduledLessonIdParam } =
    useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  const queryClient = useQueryClient();
  const klassId = Number(klassIdParam);

  const hasKlassId = !isNaN(klassId);

  const {
    data: klass,
    isInitialLoading: loadingKlass,
    error: klassError,
  } = useQuery({
    ...klassesQueryKeys.get(klassId),
    enabled: hasKlassId,
  });

  const klassErrorDetail = getErrorMessage(klassError);

  const scheduledLessonId = Number(scheduledLessonIdParam);

  const hasScheduledLessonId = !isNaN(scheduledLessonId);
  const { queryFn: scheduledLessonQueryFn, queryKey: scheduledLessonQueryKey } =
    scheduledLessonsQueryKeys.get(scheduledLessonId);

  const {
    data: scheduledLesson,
    isInitialLoading: loadingScheduledLesson,
    error: scheduledLessonError,
  } = useQuery({
    queryFn: scheduledLessonQueryFn,
    queryKey: scheduledLessonQueryKey,
    enabled: hasScheduledLessonId,
  });
  const invalidateScheduledLesson = async () =>
    await queryClient.invalidateQueries({ queryKey: scheduledLessonQueryKey });

  const scheduledLessonErrorDetail = getErrorMessage(scheduledLessonError);

  const onSelectKlass = async (klassId: number) => {
    try {
      const { results } = await listScheduledLessons({
        klassId,
        pageSize: 1,
        isActive: true,
      });

      if (results.length)
        navigate(`/class/${klassId}/lesson-report/${results[0].id}`);
    } catch (error: any) {
      const errorDispatcher = new ScheduledLessonByKlassNotFoundDispatcher(
        klassId,
      );
      const errorCapturer = new ErrorCapturer(errorDispatcher);
      errorCapturer.dispatchError();
    }
  };

  const pageTitle = t('title');

  useEffect(() => {
    const animate = async () => {
      await controls.start({
        scale: 0.8,
        opacity: 0,
        transition: {
          duration: 0.1,
        },
      });

      await controls.start({
        scale: [0.8, 1],
        opacity: [0, 1],
        transition: {
          duration: 0.2,
        },
      });
    };
    animate();
  }, [scheduledLessonId, controls]);

  const loading = loadingKlass || loadingScheduledLesson;

  const errorDetail = klassErrorDetail || scheduledLessonErrorDetail;

  if (loading) {
    return (
      <Layout className="flex flex-col gap-4">
        <Loading />
      </Layout>
    );
  }

  if (errorDetail) {
    return (
      <Layout className="flex flex-col gap-4">
        <ErrorComponent errorTextTitle={errorDetail} />
      </Layout>
    );
  }

  if (klass && scheduledLesson) {
    const { id: klassId } = klass;
    const {
      order,
      lesson: { status },
      type: lessonType,
    } = scheduledLesson;

    const lessonName = makeLessonHeadTitle({
      lessonOrder: order,
      klass,
      lessonType,
    });

    const title = `${pageTitle} - ${lessonName}`;

    return (
      <Layout className="flex flex-col gap-4">
        <HeadTitle routeInfo={title} />

        <ReportHeader
          onSelect={onSelectKlass}
          klass={klass}
          key={'klass' + klass.id}
        />

        <ReportPagination
          key={'scheduledLesson' + scheduledLesson.id}
          klass={klass}
          loading={loadingKlass}
          scheduledLesson={scheduledLesson}
        />

        <motion.div animate={controls}>
          <NoPublishedOverlay klassId={klassId} status={status} />

          <ScheduledLessonReportView
            key={klass.id}
            klass={klass}
            scheduledLesson={scheduledLesson}
            isLoading={loadingScheduledLesson}
            invalidateScheduledLesson={invalidateScheduledLesson}
          />
        </motion.div>
      </Layout>
    );
  }

  return <Fragment />;
}

function Loading() {
  const defaultClassNameGrid =
    'flex flex-wrap [&>*]:w-[48%] [&>*]:min-w-[350px] sm:[&>*]:min-w-[600px] lg:[&>*]:min-w-[400px] gap-4 w-full justify-center my-4';
  return (
    <div>
      <Skeleton className="flex shadow-default rounded-xl bg-primary-content h-20" />
      <div className={defaultClassNameGrid}>
        <Skeleton className="flex shadow-default rounded-xl bg-primary-content h-80" />
        <Skeleton className="flex shadow-default rounded-xl bg-primary-content h-80" />
        <Skeleton className="flex shadow-default rounded-xl bg-primary-content h-80" />
        <Skeleton className="flex shadow-default rounded-xl bg-primary-content h-80" />
      </div>
    </div>
  );
}

type NoPublishedOverlayProps = {
  klassId: number;
  status: VersioningStatusEnum;
};

function NoPublishedOverlay({ klassId, status }: NoPublishedOverlayProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const published = status === VersioningStatusEnum.PUBLISHED;

  if (published) return <Fragment />;

  return (
    <Overlay color="base">
      <ErrorComponent
        actionButtons={[
          {
            icon: <PencilIcon />,
            text: t('goEditPage'),
            href: `/class/${klassId}/lessons`,
          },
        ]}
        errorTextTitle={t('cantEditReport')}
        errorTextSubTitle={t('publisInstructions')}
        hideStatusCode
        animation={{
          className: 'w-full md:w-6/12 max-w-[1000px]',
          src: '/images/ongoing-construction.json',
        }}
      />
    </Overlay>
  );
}
