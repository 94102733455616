import requester from '@/axios';
import { AbsenceNotification } from '@/models/AbsenceNotification';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

type Ordering = 'created_at' | '-created_at';

const BASE_URL = 'analytics/teachers/absences-notifications';

export type ListAbsenceNotificationParams = Omit<BaseFilters, 'search'> & {
  hasRead?: boolean;
  studentId?: number;
  teacherId?: number;
  klassId?: number;
  ordering?: Ordering;
  latestOnly?: boolean;
};

export const listAbsenceNotification = async (
  params: ListAbsenceNotificationParams = {},
) => {
  const { data } = await requester().get<JsonApiResponse<AbsenceNotification>>(
    `${BASE_URL}/`,
    {
      params,
    },
  );

  return data;
};
