import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Controls from '@/components/common/Controls';
import ErrorComponent from '@/components/common/ErrorComponent';
import HeadTitle from '@/components/common/HeadTitle';
import Steps from '@/components/common/Steps/Steps';
import LottieAnimation from '@/components/common/dataDisplay/LottieAnimation';
import { LoadingIcon } from '@/components/icons';
import LessonViewContainer from '@/components/lessons/LessonViewContainer';
import {
  GRADE_CONDITION_FOR_TROPHY,
  REQUEST_STALE_TIME_IN_MS,
} from '@/constants';
import useLessonContext from '@/data/hook/lesson';
import useStudentContext from '@/data/hook/student';
import useActivityView from '@/data/hook/useActivityView';
import { createActivityProgressAttempt } from '@/data/services/activityProgressAttemptServices';
import {
  activityProgressAttemptsQueryKeys,
  activityProgressesQueryKeys,
  correctAnswersQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { formatLessonNamePrefix } from '@/functions/lessonsName';
import ActivityProgress, {
  ActivityProgressAttempt,
} from '@/models/ActivityProgress';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';
import ActivityResult from '../ActivityResult';
import ElementPreviewFactory from '../elements/preview/ElementPreviewFactory';

export default function StepperContainer() {
  const { slugCourseName = '', lessonId, klassId, activityId } = useParams();

  const { courseProgress, klass } = useStudentContext();

  const { lesson, scheduledLesson, updateLessonProgress } = useLessonContext();

  const { activeStep, createSteps, mode, blockNavigate } = useActivityView();

  const hasActivityId = !!activityId && !isNaN(Number(activityId));

  const [attemptProgress, setAttemptProgress] =
    useState<ActivityProgressAttempt>();

  const {
    data: progress,
    isInitialLoading: loadingProgress,
    error: progressError,
    isFetchedAfterMount: fetchedAfterMount,
  } = useQuery({
    ...activityProgressesQueryKeys.get(Number(activityId)),
    enabled: hasActivityId,
  });

  const errorDetail = getErrorMessage(progressError);

  const navigate = useNavigate();

  const close = () => {
    navigate(
      getKlassViewBaseUrl({
        slugCourseName,
        lessonId: Number(lessonId),
        klassId: Number(klassId),
      }) + '/activities',
    );
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const { data: bestAttempt, isInitialLoading: loadingBestAttempt } = useQuery({
    enabled:
      !!progress && !!progress.bestAttempt?.id && progress.answersRevealed,
    ...activityProgressAttemptsQueryKeys.get({
      activityProgressId: progress?.id ?? 0,
      attemptId: progress?.bestAttempt?.id ?? 0,
    }),
  });

  useEffect(() => {
    if (bestAttempt) setAttemptProgress(bestAttempt);

    return () => setAttemptProgress(undefined);
  }, [bestAttempt]);

  const loading = loadingProgress || loadingBestAttempt || !fetchedAfterMount;

  if (loading) {
    return (
      <div className="flex w-full justify-center h-72">
        <LoadingIcon className="w-40 text-primary/40" />
      </div>
    );
  }

  if (errorDetail) {
    return <ErrorComponent errorTextTitle={errorDetail} />;
  }

  if (progress) {
    const courseAbbreviation =
      courseProgress?.coursePath.course.abbreviation ?? '';

    const activityName = progress.activity.name ?? '';

    const title = `${activityName} - ${formatLessonNamePrefix({
      lessonOrder: lesson?.order || 0,
      scheduledLessonType: scheduledLesson?.type,
    })} - ${courseAbbreviation}`;

    const questionsProgress = progress.questionsProgress ?? [];

    const steps = () =>
      createSteps({
        attemptProgress: mode !== 'activity' ? attemptProgress : undefined,
        questionsProgress,
      });

    return (
      <Fragment>
        <HeadTitle routeInfo={title} />
        <LessonViewContainer className="flex flex-col gap-4" close={close}>
          <Steps
            activeStep={activeStep}
            steps={steps()}
            loading={loading}
            hideIndicator={mode === 'result'}
            disabled={blockNavigate}
          />
          <Content
            activityProgress={progress}
            loading={loading}
            klass={klass}
            courseProgress={courseProgress}
            scheduledLesson={scheduledLesson}
            updateLessonProgress={updateLessonProgress}
            attemptProgress={attemptProgress}
            setAttemptProgress={setAttemptProgress}
          />
        </LessonViewContainer>
      </Fragment>
    );
  }

  return <Fragment />;
}

type ContainerProps = {
  activityProgress: ActivityProgress;
  loading?: boolean;
  klass?: Klass;
  scheduledLesson?: ScheduledLesson;
  courseProgress?: SimplifiedCourseProgress;
  updateLessonProgress: (lessonId: number) => Promise<void>;
  attemptProgress?: ActivityProgressAttempt;
  setAttemptProgress: (attemptProgress?: ActivityProgressAttempt) => void;
};

function Content({
  activityProgress,
  loading,
  klass,
  scheduledLesson,
  courseProgress,
  updateLessonProgress,
  attemptProgress,
  setAttemptProgress,
}: ContainerProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'lesson.activity' });

  const { questionsProgress, answersRevealed } = activityProgress;

  const { state } = useLocation();

  const navigate = useNavigate();

  const {
    blockNavigate,
    setActiveStep,
    activeStep,
    setMode,
    mode,
    status,
    setStatus,
  } = useActivityView();

  const queryClient = useQueryClient();

  const settingCurrentQuestion =
    questionsProgress && mode === 'activity' && status === 'starting';

  useEffect(() => {
    if (answersRevealed) {
      setMode('answerkey');
      setStatus('inProgress');
    }
  }, [answersRevealed, setMode, setStatus]);

  useEffect(() => {
    if (settingCurrentQuestion) {
      const findCurrentIndex = questionsProgress.findIndex(
        question => question.isCurrentQuestion,
      );

      setActiveStep(findCurrentIndex > -1 ? findCurrentIndex : 0);
      setStatus('inProgress');
    }
  }, [questionsProgress, setActiveStep, setStatus, settingCurrentQuestion]);

  const updateActivityProgress = async () =>
    await queryClient.invalidateQueries(
      activityProgressesQueryKeys.get(activityProgress.id),
    );

  const onExit = () => {
    if (!courseProgress || !scheduledLesson || !klass)
      throw new Error('progress not found');

    navigate(
      getKlassViewBaseUrl({
        slugCourseName: courseProgress.coursePath.slug,
        lessonId: scheduledLesson.lesson.id,
        klassId: klass.id,
      }) + '/activities',
    );
  };

  const [hasDoneOnce, setHasDoneOnce] = useState(!!state?.hasDoneOnce);

  const [trophyAnimation, setTrophyAnimation] = useState(false);

  const { data: teacher } = useQuery({
    ...usersQueryKeys.get(klass?.teacherId ?? 0),
    enabled: !!klass && !!klass.teacherId,
    keepPreviousData: true,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  type LoadingControl = 'none' | 'next' | 'prev';

  const [loadingControl, setLoadingControl] = useState<LoadingControl>('none');

  const onNavigation = async (direction: 1 | -1) => {
    try {
      if (activityProgress.questionsProgress?.length) {
        setLoadingControl(direction === 1 ? 'next' : 'prev');
        setActiveStep(activeStep + direction);
        window.scroll({ top: 0, behavior: 'smooth' });
      }
    } finally {
      setLoadingControl('none');
    }
  };

  const onResult = async (activityAttempt?: ActivityProgressAttempt) => {
    if (activityAttempt) {
      const event: EventTrack = {
        category: courseProgress?.coursePath.slug ?? 'Curso',
        action: 'Finish Activity',
        label: handleUserFullName(teacher) ?? 'Teacher',
        value: scheduledLesson?.lesson?.order ?? 0,
      };

      eventTrack(event);

      setMode('result');

      setHasDoneOnce(activityAttempt.grade >= GRADE_CONDITION_FOR_TROPHY);
      setTrophyAnimation(activityAttempt.grade >= GRADE_CONDITION_FOR_TROPHY);
      setAttemptProgress(activityAttempt);

      await updateActivityProgress();

      if (scheduledLesson?.lesson.id)
        await updateLessonProgress(scheduledLesson.lesson.id);
    }
  };

  const { mutate: createAttempt, isLoading: loadingCreateAttempt } =
    useMutation(createActivityProgressAttempt, {
      async onSuccess(data) {
        await onResult(data);
      },
      onError(error: any) {
        alert.error(getErrorMessage(error));
      },
    });

  const onFinish = () => {
    switch (mode) {
      case 'activity': {
        createAttempt(activityProgress.id);
        break;
      }
      case 'review': {
        setMode('result');
        break;
      }
      default:
        onExit();
    }
  };

  const { data: answers, isInitialLoading: loadingAnswers } = useQuery({
    ...correctAnswersQueryKeys.get(activityProgress.activity.id),
    enabled: activityProgress.answersRevealed && !!activityProgress.activity.id,
  });

  const onReview = () => {
    if (mode !== 'answerkey') {
      setMode('review');
      setActiveStep(0);
    }
  };

  const onRedo = () => {
    setHasDoneOnce(false);
    setActiveStep(0);
    setAttemptProgress(undefined);
    setMode('activity');
  };

  const loadingContainer = loading || loadingAnswers;

  if (loadingContainer)
    return (
      <div className="flex w-full justify-center h-72">
        <LoadingIcon className="w-40 text-primary/40" />
      </div>
    );

  if (activityProgress.questionsProgress?.length) {
    const questionProgress = activityProgress.questionsProgress[activeStep];

    const answer = answers?.questions?.find(
      answer => answer.order === questionProgress.order,
    );

    const questionAttempt = attemptProgress?.content?.[activeStep];

    const firstStep = activeStep === 0;

    const lastStep =
      activeStep === activityProgress.questionsProgress.length - 1;

    return (
      <ConditionalRenderer
        condition={mode === 'result'}
        fallback={
          <Fragment>
            <ElementPreviewFactory
              {...questionProgress}
              activityMode={mode}
              answer={answer}
              attemptElement={mode === 'activity' ? undefined : questionAttempt}
            />
            <Controls
              disabled={blockNavigate}
              isOnLast={lastStep}
              hidePrev={firstStep}
              nextText={t('activityNext')}
              prevText={t('activityPrev')}
              buttonColor="text-primary"
              goNext={() => onNavigation(1)}
              goBack={() => onNavigation(-1)}
              onFinish={onFinish}
              isNextRequest={loadingCreateAttempt || loadingControl === 'next'}
              isPrevRequest={loadingControl === 'prev'}
            />
          </Fragment>
        }
      >
        <div className="relative">
          <ConditionalRenderer condition={trophyAnimation}>
            <LottieAnimation
              className="bg-opacity-60 bg-base-100 absolute w-full h-full left-0 flex justify-center items-center"
              src="/images/trophy-animation.json"
              setIsVisible={setTrophyAnimation}
            />
          </ConditionalRenderer>
          <ActivityResult
            setHasDoneOnce={setHasDoneOnce}
            hasDoneOnce={hasDoneOnce}
            onExit={onExit}
            activityProgress={activityProgress}
            onReview={onReview}
            onRedo={onRedo}
            activityMode={mode}
            attemptId={attemptProgress?.id}
          />
        </div>
      </ConditionalRenderer>
    );
  }

  return <Fragment />;
}
