import { SaveIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import requester from '@/axios';
import Activity from '@/models/Activity';
import {
  QuizActivityEditElement,
  QuizActivityElement,
  QuizAnswersType,
} from '@/models/ActivityElement';
import { ImageFolderEnum } from '@/models/CkEditor';
import MultipleChoiceAnswer from '@/models/MultipleChoiceAnswer';
import MainButton from '@/components/common/buttons/MainButton';
import TextInput from '@/components/common/dataInput/TextInput';
import MyCkeditor from '@/components/editor/MyCkeditor';
import AnswerList from './AnswerList';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import { AxiosRequestHeaders } from 'axios';
import alert from '@/utils/UseAlert';
import TooltipHandler from '@/components/common/TooltipHandler';
import ContainerActivityEdit from '../commom/ContainerActivityEdit';
import { getErrorMessage } from '@/utils/getErrorMessage';

export interface AnswersInputError {
  answerVerifier: string;
  errorMessage: string;
}

export interface QuizElementEditProps
  extends Omit<QuizActivityEditElement, 'activity'> {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: Omit<QuizActivityEditElement, 'activity'>) => void;
}

export default function QuizElementEdit(props: QuizElementEditProps) {
  const {
    activity,
    identifier,
    onEdit,
    onSave,
    question,
    quizAnswers,
    reason,
    content,
    type,
    id,
    changeStatus,
    answerType,
    order,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityQuiz',
  });
  interface RequestConfig {
    route: Record<QuizAnswersType, string>;
    headers: AxiosRequestHeaders;
  }
  const requestConfig: RequestConfig = {
    route: {
      IMAGE: 'image-quiz-answer',
      TEXT: 'text-quiz-answer',
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    },
  };

  const [currentType, setCurrentType] = useState(answerType);
  const isImageType = currentType === QuizAnswersType.IMAGE;

  const [errors, setErrors] = useState<any[]>([]);
  const [isButtonActivated, setIsButtonActivated] = useState<boolean>(false);
  const [answersErrors, setAnswersErrors] = useState<AnswersInputError[]>([]);

  const defaultAnswer = new MultipleChoiceAnswer('', false);

  const handleQuestionChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onEdit({ ...props, question: event.currentTarget.value });
    },
    [onEdit, props],
  );

  const handleAnswerChange = (quizAnswers: MultipleChoiceAnswer[]) => {
    onEdit({ ...props, quizAnswers });
  };
  const [isRequesting, setIsRequesting] = useState(false);

  const handleJustificativeChange = (editorValue: string | undefined) => {
    onEdit({ ...props, reason: editorValue || '' });
  };

  const handleContentChange = (editorValue: string | undefined) => {
    onEdit({ ...props, content: editorValue || '' });
  };

  useEffect(() => {
    if (changeStatus === 'new') {
      const keysActivityQuiz = ['question', 'reason', 'quizAnswers'];
      const hasKey = keysActivityQuiz.find(key => Object.hasOwn(props, key));
      if (hasKey) return setIsButtonActivated(true);

      return setIsButtonActivated(false);
    }

    if (changeStatus === 'changed') return setIsButtonActivated(true);
    setIsButtonActivated(false);
  }, [props, changeStatus]);

  useEffect(() => {
    if (isRequesting) {
      window.onbeforeunload = function () {
        return 'Tem certeza que deseja sair? Suas informações serão perdidas';
      };
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isRequesting]);

  function checkQuiz(): string[] | null {
    const errors: string[] = [];
    if (question === undefined || question === '') {
      errors.push('question');
    }

    if (reason === undefined || reason === '') {
      errors.push('justificative');
    }

    if (quizAnswers === undefined) {
      errors.push('answers');
    }
    const emptyAnswers = quizAnswers.filter(
      ({ answer, editType }) =>
        editType !== 'remove' && (answer === '' || !answer),
    );
    if (emptyAnswers.length > 0) {
      errors.push('answerWithoutValue');
      const answersErrors = emptyAnswers.map<AnswersInputError>(
        ({ answerVerifier }) => ({
          answerVerifier,
          errorMessage: t('answers.noValue'),
        }),
      );
      setAnswersErrors(answersErrors);
    } else if (
      quizAnswers.filter(item => item.isCorrect && item.editType !== 'remove')
        .length !== 1
    ) {
      errors.push('anyTrue');
    } else if (isImageType) {
      const answersWithoutImages = quizAnswers.filter(({ answer }) => !answer);
      if (answersWithoutImages.length) {
        errors.push('missingImages');
        alert.error(`${t('missingImages')}`);
      } else {
        const imagesWithoutAlt: number[] = [];
        quizAnswers.forEach(({ altText }, index) => {
          if (!altText) {
            imagesWithoutAlt.push(index + 1);
          }
        });
        if (imagesWithoutAlt.length) {
          errors.push('missingAlt');
          const imageList = imagesWithoutAlt.reduce(
            (prev, current, index, array) =>
              prev.concat(
                index === array.length - 1 ? `${current}.` : `${current}, `,
              ),
            '',
          );
          alert.error(`${t('missingAlt')}: ${imageList}`);
        }
      }
    }

    if (errors.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  const handleQuestionsInOrder = async () => {
    const updatedQuestions = quizAnswers.filter(
      ({ editType }) => editType !== 'remove',
    );
    const removedQuestions = quizAnswers.filter(
      ({ editType }) => editType === 'remove',
    );
    const isFalseQuestion = (question: MultipleChoiceAnswer) =>
      question.editType === 'isCorrectEdit' && !question.isCorrect;
    //Respostas que foram setadas como false
    const falseQuestions = updatedQuestions.filter(isFalseQuestion);
    //Resto das outras respostas
    const otherQuestions = updatedQuestions.filter(
      question => !isFalseQuestion(question),
    );

    try {
      await handleQuizQuestions(removedQuestions);
      const updatedFalseQuestions = await handleQuizQuestions(falseQuestions);
      const updatedOtherQuestions = await handleQuizQuestions(otherQuestions);
      const modified = updatedQuestions.map(item => {
        const cleanAnswer = item;
        if (!cleanAnswer.id) {
          if (isFalseQuestion(cleanAnswer)) {
            const matchQuestion = updatedFalseQuestions?.find(
              question =>
                question?.answerVerifier === cleanAnswer.answerVerifier,
            );
            cleanAnswer.id = matchQuestion?.id;
          } else {
            const matchQuestion = updatedOtherQuestions?.find(
              question => question?.answer === cleanAnswer.answer,
            );
            cleanAnswer.id = matchQuestion?.id;
          }
        }
        delete cleanAnswer.editType;
        return cleanAnswer;
      });
      onSave({ ...props, quizAnswers: modified });
      alert.success(t('saveSuccess'));
    } catch (error) {
      alert.error(getErrorMessage(error));
    } finally {
      setIsRequesting(false);
    }
  };

  const handleQuizQuestions = async (questions: MultipleChoiceAnswer[]) => {
    try {
      const quizQuestions = await Promise.all<MultipleChoiceAnswer | undefined>(
        questions.map(quizAnswer => {
          return handleQuizQuestion(quizAnswer);
        }),
      );
      return quizQuestions;
    } catch (error: any) {
      alert.error(getErrorMessage(error));
    }
  };

  const handleQuizQuestion = async (quizAnswer: MultipleChoiceAnswer) => {
    const { editType, answer, isCorrect, id: answerId, altText } = quizAnswer;
    const formData = new FormData();
    formData.set('quiz', id.toString());
    formData.set('answer', answer);

    const answerIsString = typeof answer === 'string';
    if (isImageType) {
      altText && formData.set('altText', altText);
      if (answerIsString) {
        formData.delete('answer');
      }
    }

    if (editType === 'new') {
      formData.set('isCorrect', String(isCorrect));
      const { data: quizQuestion } =
        await requester().post<MultipleChoiceAnswer>(
          `${requestConfig.route[currentType]}/`,
          formData,
          { headers: requestConfig.headers },
        );
      return quizQuestion;
    } else if (editType === 'edit') {
      const { data: quizQuestion } =
        await requester().patch<MultipleChoiceAnswer>(
          `${requestConfig.route[currentType]}/${answerId}/`,
          formData,
          { headers: requestConfig.headers },
        );
      return quizQuestion;
    } else if (editType === 'isCorrectEdit') {
      formData.set('isCorrect', String(isCorrect));

      const { data: quizQuestion } =
        await requester().patch<MultipleChoiceAnswer>(
          `${requestConfig.route[currentType]}/${answerId}/`,
          formData,
          { headers: requestConfig.headers },
        );
      return quizQuestion;
    } else if (editType === 'remove') {
      await requester().delete(
        `${requestConfig.route[currentType]}/${answerId}/`,
      );
    } else {
      return quizAnswer;
    }
  };

  const handleNewQuizQuestionsPost = async (
    questions: MultipleChoiceAnswer[],
    quiz: QuizActivityElement,
  ) => {
    const newQuizQuestions = await Promise.all<MultipleChoiceAnswer>(
      questions.map(quizAnswer => handleNewQuizQuestionPost(quizAnswer, quiz)),
    );
    return newQuizQuestions;
  };

  const handleNewQuizQuestionPost = async (
    { answer, isCorrect, altText }: MultipleChoiceAnswer,
    { id: quiz }: QuizActivityElement,
  ) => {
    const formData = new FormData();
    formData.set('quiz', quiz.toString());
    formData.set('answer', answer);
    formData.set('isCorrect', String(isCorrect));
    !!altText && formData.set('altText', altText);

    const { data: quizAnswer } = await requester().post(
      `${requestConfig.route[currentType]}/`,
      formData,
      { headers: requestConfig.headers },
    );
    return quizAnswer;
  };

  const handleClickSave = async () => {
    const ErrorID = checkQuiz();
    if (ErrorID !== null) {
      setErrors(ErrorID);
    } else {
      setIsRequesting(true);
      setAnswersErrors([]);
      setErrors([]);

      if (changeStatus === 'new') {
        try {
          const { data: quiz } =
            await requester().post<QuizActivityEditElement>(`quiz-activity/`, {
              activity: activity.id,
              question,
              reason,
              type,
              identifier,
              order,
              content,
              answerType: currentType,
            });
          const answers = await handleNewQuizQuestionsPost(quizAnswers, quiz);
          onSave({
            ...props,
            id: quiz.id,
            quizAnswers: answers,
          });

          alert.success(t('saveSuccess'));
        } catch (err: any) {
          alert.error(getErrorMessage(err));
        } finally {
          setIsRequesting(false);
        }
      } else if (changeStatus === 'changed') {
        try {
          const { data } = await requester().patch(`quiz-activity/${id}/`, {
            question,
            reason,
            type,
            identifier,
            content,
            answerType: currentType,
          } as QuizElementEditProps);
          handleQuestionsInOrder();
          onSave({ ...props, id: data.id });
        } catch (err: any) {
          alert.error(getErrorMessage(err));
        } finally {
          setIsRequesting(false);
        }
      } else {
        setIsRequesting(false);
      }
    }
  };

  return (
    <ContainerActivityEdit id="QuizElementEdit">
      <div>
        <label className="font-rubik text-base-content">{t('title')}</label>
        <br />
        {errors && errors.some(errorID => errorID === 'question') ? (
          <label className="font-rubik text-error error-label">
            {t('titleError')}
          </label>
        ) : (
          ''
        )}

        <TextInput
          key={identifier + 'title'}
          fontWeight="font-400"
          className="rounded-lg border-primary h-9 font-600 font-rubik"
          type="text"
          testId="quiz-title"
          name="question"
          required
          minLength={3}
          maxLength={100}
          onChange={handleQuestionChange}
          defaultValue={question}
        />
      </div>

      <div>
        <label className="font-rubik text-base-content">{t('content')}</label>
        <MyCkeditor
          key={identifier + 'content'}
          content={content || ''}
          folder={ImageFolderEnum.ACTIVITY}
          handleContentChange={handleContentChange}
          testId="ckEditorContent"
        />
      </div>

      <AnswerTypesContainer
        disable={changeStatus !== 'new'}
        currentType={currentType}
        onSelect={type => setCurrentType(type)}
      />

      <div>
        <label className="font-rubik text-base-content">
          {t('answers.answers')}
        </label>
        <br />
        {errors && errors.some(errorID => errorID === 'answers') ? (
          <label className="font-rubik text-error error-label">
            {t('answersError')}
          </label>
        ) : (
          ''
        )}
        {errors && errors.some(errorID => errorID === 'anyTrue') ? (
          <label className="font-rubik text-error error-label">
            {t('atLeastOneTrueAnswerError')}
          </label>
        ) : (
          ''
        )}
        <AnswerList
          answerType={currentType}
          isRequesting={isRequesting}
          quizQuestions={quizAnswers ? [...quizAnswers] : [defaultAnswer]}
          onChange={handleAnswerChange}
          answersErrors={answersErrors}
        />
      </div>
      <div>
        <label className="font-rubik text-base-content">
          {t('justificative')}
        </label>

        <br />

        {errors && errors.some(errorID => errorID === 'justificative') ? (
          <label className="font-rubik text-error error-label">
            {t('justificativeError')}
          </label>
        ) : (
          ''
        )}
        <MyCkeditor
          key={identifier + 'reason'}
          content={reason || ''}
          folder={ImageFolderEnum.ACTIVITY}
          handleContentChange={handleJustificativeChange}
          testId={'true_or_false-explanation'}
        />
      </div>

      <MainButton
        disabled={!isButtonActivated}
        loading={isRequesting}
        icon={<SaveIcon />}
        id="quiz-save"
        text={t('save')}
        onClick={handleClickSave}
      />
    </ContainerActivityEdit>
  );
}

interface AnswerTypesContainerProps {
  onSelect?: (type: QuizAnswersType) => void;
  currentType: QuizAnswersType;
  disable?: boolean;
}

const AnswerTypesContainer: React.FC<AnswerTypesContainerProps> = ({
  onSelect,
  currentType,
  disable = true,
}) => {
  return (
    <div className="flex gap-4">
      <AnswerTypeSelector
        onSelect={onSelect}
        currentType={currentType}
        disable={disable}
        type={QuizAnswersType.TEXT}
      />
      <AnswerTypeSelector
        onSelect={onSelect}
        disable={disable}
        currentType={currentType}
        type={QuizAnswersType.IMAGE}
      />
    </div>
  );
};

interface AnswerTypeSelectorProps extends AnswerTypesContainerProps {
  type: QuizAnswersType;
  disable: boolean;
  testId?: string;
}

const AnswerTypeSelector: React.FC<AnswerTypeSelectorProps> = ({
  type,
  onSelect,
  currentType,
  disable = true,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityQuiz',
  });
  const answerTypes: Record<QuizAnswersType, string> = {
    IMAGE: t('image'),
    TEXT: t('text'),
  };
  const isCurrentType = currentType === type;
  const isTypeDisabled = disable && !isCurrentType;
  return (
    <TooltipHandler
      renderTooltip={isTypeDisabled}
      tooltipMessage={t('cantChangeType')}
    >
      <PillComponent
        testId={`selector${type}`}
        color={isTypeDisabled ? 'neutral' : 'primary'}
        isFilled={isCurrentType}
        onClick={() => onSelect && onSelect(type)}
        className={`${disable ? 'pointer-events-none' : ''} ${
          isTypeDisabled ? 'opacity-[0.35]' : ''
        } cursor-pointer transition-all duration-150 ease-in-out hover:scale-[1.01] hover:opacity-90 active:scale-[0.98]`}
      >
        {answerTypes[type]}
      </PillComponent>
    </TooltipHandler>
  );
};
