import Text from '../common/dataDisplay/Text';
import TeacherComment from './TeacherComment';
import { LessonComment } from '@/models/Comment';
import CommentBox from './CommentBox';
import { useTranslation } from 'react-i18next';
import Skeleton from '../common/Skeleton';
import InfinityList from '../common/InfinityList';
import { useState } from 'react';
import { createLessonComment } from '@/data/services/lessonCommentServices';
import alert from '@/utils/UseAlert';
import { useQueryClient } from '@tanstack/react-query';
import { lessonCommentsQueryKeys } from '@/data/services/querykeys';

export default function LessonCommentSection({
  comments,
  lessonId,
  hasNextPage,
  isFetchingNextPage,
  isUpdatingList,
  fetchNextPage,
  isLoading: isFetching,
}: {
  comments: LessonComment[];
  lessonId: number;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  isUpdatingList: boolean;
  isLoading: boolean;
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleSubmit = async (content: string) => {
    setIsUpdating(true);
    try {
      await createLessonComment(content, lessonId);
      alert.success(t('teacherComments.successAdd'), { duration: 2000 });
      queryClient.invalidateQueries(
        lessonCommentsQueryKeys.list(lessonId)._ctx.infinity.queryKey,
      );
    } catch (error) {
      alert.error(t('teacherComments.errorAdd'), { duration: 2000 });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="flex lg:max-w-[600px] xl:max-w-[720px] mt-6 flex-col gap-4">
      {isFetching ? (
        <Skeleton className="bg-accent/40 w-44 rounded-full h-7" />
      ) : (
        <Text
          format="rubik-500"
          className="text-18 text-accent"
          text={`${comments.length} ${
            comments.length === 1
              ? t('teacherComments.comment')
              : t('teacherComments.comments')
          }`}
        />
      )}
      <hr className="h-px w-full border-primary" />
      <CommentBox
        testId="Section"
        isSubmitting={isUpdating}
        onSubmit={handleSubmit}
      />
      <InfinityList
        hasNextPage={hasNextPage}
        onReachEnd={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        className="flex flex-col w-full gap-4"
      >
        {comments
          .sort((a, b) => (b.upVotes?.length ?? 0) - (a.upVotes?.length ?? 0))
          .map(comment => (
            <TeacherComment
              isUpdatingList={isUpdatingList}
              key={comment.id}
              lessonId={lessonId}
              comment={comment}
            />
          ))}
      </InfinityList>
    </div>
  );
}
