import { Form, Input } from 'antd';
import { RegexValidator } from '../../validators/regex';

interface PasswordConfirmProps {
  regexRules: RegexValidator[];
  passwordValue: string;
  onPasswordChange: (newPassword: string) => void;
  className?: string;
}

export default function PasswordConfirm(props: PasswordConfirmProps) {
  return (
    <>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          ...props.regexRules,
        ]}
        hasFeedback
      >
        <Input.Password
          value={props.passwordValue}
          onChange={e => props.onPasswordChange?.(e.target.value)}
          className={props.className}
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error('The two passwords that you entered do not match!'),
              );
            },
          }),
        ]}
      >
        <Input.Password className={props.className} />
      </Form.Item>
    </>
  );
}
