import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import requester from '../../axios';
import { Button, message } from 'antd';
import config from '../../config';
import { AxiosError } from 'axios';
import { Form } from 'antd';
import { useParams } from 'react-router';
import PasswordConfirm from '../../components/login/PasswordConfirm';
import LoadingView from '../../pages/courses/LoadingView';

import {
  min8Chars,
  min1EspecialChar,
  min1LowerChar,
  min1NumberChar,
  min1UpperChar,
} from '../../validators/regex';

export default function PasswordReset() {
  const [password, setPassword] = useState('');

  const [token, setToken] = useState<string>();
  const { urltoken } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [validToken, setValidToken] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setToken(urltoken);
  }, [urltoken]);

  useEffect(() => {
    const onStart = async () => {
      try {
        await requester().post(`password_reset/validate_token/`, {
          token: token,
        });
        setValidToken(true);
        setIsLoading(false);
      } catch (err) {
        console.error('Token invalido', (err as AxiosError).message);
        setValidToken(false);
        setIsLoading(false);
      }
    };

    if (token !== undefined) {
      onStart();
    }
  }, [token]);

  const onFail = async () => {
    message.error('Senha Inválida', config.TOAST_DURATION);
  };

  const onFinish = async () => {
    try {
      await requester().post(`password_reset/confirm/`, {
        password: password,
        token: token,
      });
      navigate('/login/password-changed');
      message.success('Senha Atualizada', config.TOAST_DURATION);
    } catch (err) {
      message.error('Error changing password', config.TOAST_DURATION);

      message.error(
        (err as AxiosError<{ password: [] }>).response?.data.password,
        config.TOAST_DURATION,
      );

      console.error(
        'Error changing password',
        (err as AxiosError<{ password: [] }>).response?.data.password,
      );
    }
  };

  if (isLoading) {
    return <LoadingView />;
  } else if (validToken) {
    return (
      <>
        <h1 className=" mx-auto text-center font-700 text-20 ">
          Resete a Senha
        </h1>
        <Form
          form={form}
          name="register"
          className="container border border-black p-4 "
          onFinish={onFinish}
          onFinishFailed={onFail}
          scrollToFirstError
          size="large"
        >
          <PasswordConfirm
            regexRules={[
              min8Chars,
              min1EspecialChar,
              min1LowerChar,
              min1NumberChar,
              min1UpperChar,
            ]}
            passwordValue={password}
            onPasswordChange={setPassword}
          />

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  } else {
    return (
      <>
        <h1 className=" mx-auto text-center font-700 text-20 ">
          token invalido
        </h1>
      </>
    );
  }
}
