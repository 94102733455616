import { InformationCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import AntdTooltip from '../../../common/dataDisplay/AntdTooltip';
import PillComponent from '../../../common/dataDisplay/PillComponent';
import Text from '../../../common/dataDisplay/Text';

interface AttendancePillProps {
  presences: number;
  absences: number;
  studentsCount: number;
}

export const AttendancePill = ({
  presences,
  absences,
  studentsCount,
}: AttendancePillProps) => {
  const Frequency = () => {
    const attendance = [
      {
        title: t('manageAttendance.attendances'),
        total: presences,
      },
      {
        title: t('manageAttendance.absences'),
        total: absences,
      },
    ];
    return (
      <div className="flex flex-col gap-1">
        {attendance.map(({ title, total }) => (
          <div className="flex gap-3" key={title}>
            <Text text={title} format="rubik-500" className="text-primary" />
            <Text text={total.toString()} format="rubik-400" />
          </div>
        ))}
      </div>
    );
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });
  return (
    <PillComponent className="gap-2">
      <div className="flex content-center items-center align-middle gap-1 h-5">
        <Text text={t('attendance')} format="rubik-500" />
        <Text
          text={presences + ' / ' + studentsCount}
          format="rubik-400"
          className="text-base-content self-center"
        />
      </div>
      <AntdTooltip
        children={<InformationCircleIcon className="w-4 h-4 text-primary" />}
        content={<Frequency />}
      />
    </PillComponent>
  );
};
