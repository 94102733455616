import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import React, { Fragment } from 'react';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useAuth from '@/data/hook/useAuth';
import ActivityViewItem from './ActivityViewItem';
import HeadTitle from '@/components/common/HeadTitle';
import useStudentContext from '@/data/hook/student';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import useLessonContext from '@/data/hook/lesson';
import { isStudent } from '@/functions/auth';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

export default function ActivitiesView() {
  const { t } = useTranslation('translation', { keyPrefix: 'lesson' });
  const { slugCourseName } = useParams();
  const { lesson, scheduledLesson } = useLessonContext();
  const { order } = lesson || {};
  const divClassName = 'flex flex-col w-full';
  const ulClassName = 'list-none p-0 m-0';
  const { courseProgress } = useStudentContext();

  const { data: course } = useQuery({
    enabled: !!slugCourseName && !courseProgress,
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });
  const coursePath = course ?? courseProgress?.coursePath;
  const title = `${t('activity.titleHeader')} - ${makeLessonHeadTitle({
    lessonOrder: order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  })}`;

  return (
    <div className={divClassName}>
      <HeadTitle routeInfo={title} />
      <ul className={ulClassName}>
        <HandleActivitiesList />
      </ul>
    </div>
  );
}

const HandleActivitiesList: React.FC = () => {
  const { user } = useAuth();

  const { scheduledLesson, lessonProgress, lesson } = useLessonContext();
  const { activities } = lesson || {};

  const { homeworkProgress, activitiesProgress } = lessonProgress || {};
  const hrClassName = 'h-px my-4 border-secondary-content';

  const studentView = isStudent(user?.userType);

  if (scheduledLesson) {
    const { homeworkActivity } = scheduledLesson;

    return (
      <Fragment>
        <ConditionalRenderer
          condition={studentView}
          fallback={<Activities activityIds={activities} />}
        >
          <Activities
            activityProgressIds={activitiesProgress?.map(({ id }) => id)}
          />
        </ConditionalRenderer>

        <ConditionalRenderer
          condition={studentView}
          fallback={
            <Homework
              divider={!!activities?.length}
              homeworkId={homeworkActivity}
            />
          }
        >
          <Homework
            homeworkProgressId={homeworkProgress?.id}
            homeworkId={homeworkProgress?.homework.id}
            divider={!!activitiesProgress?.length}
          />
        </ConditionalRenderer>
      </Fragment>
    );
  }

  if (activities) {
    return (
      <Fragment>
        {activities.map((id, i, array) => (
          <li key={id}>
            <ActivityViewItem activityId={id} />
            <ConditionalRenderer condition={i < array.length - 1}>
              <hr className={hrClassName} />
            </ConditionalRenderer>
          </li>
        ))}
      </Fragment>
    );
  }
  return <Fragment />;
};

type ActivitiesProps = {
  activityIds?: number[];
  activityProgressIds?: number[];
};

function Activities({ activityIds, activityProgressIds }: ActivitiesProps) {
  const hrClassName = 'h-px my-4 border-secondary-content';

  const ids = activityProgressIds ?? activityIds ?? [];

  return (
    <Fragment>
      {ids.map((id, i, array) => (
        <li key={id}>
          <ActivityViewItem
            key={id}
            activityId={activityIds ? id : undefined}
            activityProgressId={activityProgressIds ? id : undefined}
          />
          <ConditionalRenderer condition={i < array.length - 1}>
            <hr className={hrClassName} />
          </ConditionalRenderer>
        </li>
      ))}
    </Fragment>
  );
}

type HomeworkProps = {
  homeworkId?: number | null;
  homeworkProgressId?: number | null;
  divider?: boolean;
};

function Homework({ homeworkId, homeworkProgressId, divider }: HomeworkProps) {
  const hasHomework = !!homeworkId || !!homeworkProgressId;

  const hrClassName = 'h-px my-4 border-secondary-content';

  return (
    <ConditionalRenderer condition={!!hasHomework}>
      <li>
        <ConditionalRenderer condition={divider}>
          <hr className={hrClassName} />
        </ConditionalRenderer>

        <ActivityViewItem
          homeworkId={homeworkId ?? undefined}
          homeworkProgressId={homeworkProgressId ?? undefined}
        />
      </li>
    </ConditionalRenderer>
  );
}
