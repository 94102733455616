import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import useStudentContext from '@/data/hook/student';
import useAuth from '@/data/hook/useAuth';
import { simplifiedCourseProgressesQueryKeys } from '@/data/services/querykeys';
import { CourseProgressFilters } from '@/data/services/studentCourseProgressServices';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';

export default function CourseProgressSelect() {
  const { user } = useAuth();
  const {
    courseProgress,
    loadingCourseProgress,
    changeKlassByCourseSlug,
    courseProgressList,
  } = useStudentContext();

  const onSelect = async (option: SimplifiedCourseProgress) =>
    await changeKlassByCourseSlug(option.coursePath.slug);

  const filters: CourseProgressFilters = {
    isActive: true,
  };

  const renderSelect = courseProgressList && courseProgressList.length > 1;

  return (
    <ConditionalRenderer condition={renderSelect}>
      <InfiniteSearchInput
        loading={loadingCourseProgress}
        service={filters =>
          simplifiedCourseProgressesQueryKeys.list(user?.id ?? 0, filters)
        }
        filters={filters}
        displayName={option => option.coursePath.course.name}
        onSelect={onSelect}
        selectedItem={courseProgress}
        blockDeselect
      />
    </ConditionalRenderer>
  );
}
