import requester from '@/axios';
import BetaModal from '@/components/admin/courses/BetaModal';
import PublishEditModal from '@/components/admin/courses/PublishEditModal';
import { ListLessons } from '@/components/admin/lessons/ListLessons';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import CourseTitle from '@/components/common/CourseTitle';
import Skeleton from '@/components/common/Skeleton';
import MainButton from '@/components/common/buttons/MainButton';
import ModalDisable from '@/components/common/modals/ModalDisable';
import ModalEmptyActivity from '@/components/common/modals/ModalEmptyActivity';
import CourseStatus from '@/components/courses/CourseStatus';
import LegalInformationCourseCollapse from '@/components/courses/LegalInformationCourseCollapse';
import Layout from '@/components/template/Layout';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { deleteActivity } from '@/data/services/activityServices';
import {
  createCourseLesson,
  deleteLesson,
  pushLesson,
} from '@/data/services/lessonServices';
import { EmptyActivity, EmptyActivityWithLesson } from '@/models/Activity';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import RouteParams from '@/models/RouteParams';
import alert from '@/utils/UseAlert';
import {
  EyeIcon,
  PlusIcon,
  TrashIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import CancelChangesModal from './lessons/CancelChangesModal';
import VersioningHeader from './lessons/VersioningHeader';
import ModalBank, {
  ModalBankButton,
} from '@/components/common/modals/ModalBank/ModalBank';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import {
  coursePathsQueryKeys,
  lessonsQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';

interface ManageCourseEditingProps {
  page: 'edit' | 'version' | 'draft';
}

function ManageCourseEditing({ page }: ManageCourseEditingProps) {
  type modalOpenType =
    | 'cancel'
    | 'exclude'
    | 'publish'
    | 'beta'
    | 'none'
    | 'activity'
    | 'bank';

  const { slugCourseName = '' } = useParams<RouteParams>();
  const [selectedLesson, setSelectedLesson] = useState<Lesson>();
  const [modalOpen, setModalOpen] = useState<modalOpenType>('none');
  const [isRequestCreate, setIsRequestCreate] = useState(false);
  const [isRequestingCancel, setIsRequestingCancel] = useState(false);
  const navigate = useNavigate();
  const { setCourseStatus, handleLessonTab } = useCourseEditing();
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  const { t: tErrors } = useTranslation('translation', { keyPrefix: 'erros' });
  const queryClient = useQueryClient();

  const [emptyActivities, setEmptyActivities] = useState<
    EmptyActivityWithLesson[]
  >([]);

  const [isLoadingDeleteActivities, setIsLoadingDeleteActivities] =
    useState<boolean>(false);

  const { data: course, isLoading: isLoadingCourse } = useQuery({
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });

  useEffect(() => {
    if (course) {
      setCourseStatus(course.status);
    }
  }, [course, setCourseStatus]);

  const { queryKey, queryFn } = lessonsQueryKeys.nestedList(slugCourseName, {
    pageSize: 50,
  })._ctx.infinity;
  const {
    results: lessons,
    isInitialLoading: isLoadingLessons,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    queryKey,
    queryFn,
    keepPreviousData: true,
    enabled: !!slugCourseName,
  });

  const versioningLessons = lessons?.filter(
    lesson => lesson.status === 'VERSIONING',
  );

  const checkEmptyActivities = async (versioningLessons?: Lesson[]) => {
    const { data: emptyActivities } = await requester().get<EmptyActivity[]>(
      `courses/${slugCourseName}/check-activities/`,
    );
    const hasEmptyActivities = !!emptyActivities.length;

    if (hasEmptyActivities) {
      const emptyActivitiesAux: EmptyActivityWithLesson[] = [];
      versioningLessons?.forEach(versioningLesson => {
        const filterEmptyActivity = emptyActivities.filter(({ lesson }) =>
          lesson.includes(versioningLesson.id),
        );
        if (filterEmptyActivity && filterEmptyActivity.length) {
          filterEmptyActivity.map(activity =>
            emptyActivitiesAux.push({
              ...activity,
              lessonInfo: versioningLesson,
            }),
          );
        }
      });
      setEmptyActivities(emptyActivitiesAux);
    }
    setModalOpen(hasEmptyActivities ? 'activity' : 'beta');
  };

  const deleteActivities = async (emptyActivities: EmptyActivity[]) => {
    setIsLoadingDeleteActivities(true);
    const [request] = await Promise.allSettled(
      emptyActivities.map(emptyActivity =>
        deleteActivity({
          activityId: emptyActivity.id,
          lessonId: emptyActivity.lesson[0],
        }),
      ),
    );
    if (request.status === 'rejected') {
      alert.error(tErrors('serverError'));
    } else {
      setIsLoadingDeleteActivities(false);
      setModalOpen('none');
    }
  };

  async function courseToBeta() {
    try {
      await requester().patch(`courses/${slugCourseName}/`, {
        status: VersioningStatusEnum.BETA,
      });
      alert.success(t('alert.saved'));
      await queryClient.invalidateQueries();
      navigate(`/courses/${slugCourseName}`);
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    }
  }

  function formatCourseName(coursePath?: CoursePath) {
    return coursePath
      ? `${coursePath.course.name} v${coursePath.version.toFixed(1)}`
      : '';
  }

  const { mutate: create } = useMutation(createCourseLesson, {
    async onSuccess() {
      alert.success(t('successCreateMessage'));
      await updateLessons();
    },
    onSettled() {
      setIsRequestCreate(false);
    },
    onError(error) {
      const apiError = new ApiError(error as any);
      if (apiError.fields.includes('abbreviation')) {
        const fieldMessage = apiError.getMessageByField('abbreviation');
        fieldMessage && alert.warning(fieldMessage);
      } else {
        alert.error(apiError.getErrorMessage());
      }
    },
  });

  const createNewLesson = async () => {
    if (!course) throw new Error('course path not found');

    const body: Partial<Lesson> = {
      name: 'name',
      description: 'description',
      motivation: { message: 'Boa!' },
    };

    setIsRequestCreate(true);

    create({ slugCourseName: course.slug, body });
  };
  const closeModal = () => setModalOpen('none');

  async function cancelVersion() {
    try {
      setIsRequestingCancel(true);
      await requester().delete(`courses/${course?.slug}/`);
      alert.success(t('modal.cancel.success'));
      navigate('../courses');
    } catch (error: any) {
      const { getErrorMessage } = new ApiError(error);
      alert.error(getErrorMessage());
    } finally {
      setIsRequestingCancel(false);
    }
  }

  function renderModal(selectedLesson: Lesson) {
    setSelectedLesson(selectedLesson);
    setModalOpen('exclude');
  }

  const updateLessons = () => queryClient.invalidateQueries(queryKey);

  async function deleteCurrentLesson(lesson: Lesson) {
    slugCourseName && (await deleteLesson(lesson.id, slugCourseName));
  }

  const { isLoading: isRequestingDelete, mutate: onDeleteLesson } = useMutation(
    deleteCurrentLesson,
    {
      async onSettled() {
        await updateLessons();
      },
      onSuccess() {
        alert.success(t('successDeleteMessage'));
        setModalOpen('none');
        handleLessonTab({
          activeLessonIndex: null,
          activeTab: 'details',
          previousActiveLessonIndex: null,
        });
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onUseLesson = async (lesson: Lesson) => {
    if (!slugCourseName) throw new Error('slugCourseName is undefined');
    try {
      await pushLesson(slugCourseName, {
        ...lesson,
        order: undefined,
      });
      closeModal();
      updateLessons();
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    }
  };

  const isLoadingLessonList = isLoadingCourse || isLoadingLessons;

  return (
    <Layout>
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>

      <div className="hidden md:block">
        <VersioningHeader course={course} page={page} />

        <div className="flex justify-end items-center gap-2.5 mb-6">
          {course && !isLoadingCourse && (
            <CourseStatus status={course.status} version={course.version} />
          )}
          <MainButton
            disabled={isLoadingLessons}
            onClick={() => setModalOpen('cancel')}
            dataTestId={'dicardButton'}
            text={t('discard')}
            color="neutral"
            icon={<TrashIcon />}
          />
          {page === 'draft' || page === 'version' ? (
            <MainButton
              disabled={isLoadingLessons}
              text={t('finish')}
              dataTestId={'finishButton'}
              color="secondary"
              onClick={() => checkEmptyActivities(versioningLessons)}
              icon={<UploadIcon />}
            />
          ) : (
            <MainButton
              text={t('publish')}
              dataTestId={'publishButton'}
              color="secondary"
              onClick={() => setModalOpen('publish')}
              icon={<UploadIcon />}
            />
          )}
          <MainButton
            disabled={isLoadingLessons}
            text={t('see')}
            dataTestId={'seeButton'}
            href={`/courses/${course?.slug}`}
            color="primary"
            icon={<EyeIcon />}
          />
        </div>
        <div className="flex justify-between flex-col gap-5">
          <ConditionalRenderer
            condition={!isLoadingCourse}
            fallback={
              <Skeleton className="bg-neutral/50 w-full h-[60px] rounded-lg" />
            }
          >
            <CourseTitle
              title={formatCourseName(course)}
              backUrl="/courses"
              hasBackButton
            />
          </ConditionalRenderer>

          <ConditionalRenderer condition={course}>
            {course && (
              <ListLessons
                lessons={lessons}
                course={course}
                updateLessons={updateLessons}
                deleteLesson={renderModal}
                isLoadingLessonList={isLoadingLessonList}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onReachEnd={fetchNextPage}
              />
            )}
          </ConditionalRenderer>

          <LegalInformationCourseCollapse course={course} />

          <ConditionalRenderer condition={emptyActivities.length}>
            <ModalEmptyActivity
              isRequesting={isLoadingDeleteActivities}
              isVisible={modalOpen === 'activity'}
              emptyActivities={emptyActivities}
              onClickConfirm={async () => {
                setIsLoadingDeleteActivities(true);
                await deleteActivities(emptyActivities);
                await courseToBeta();
              }}
              onClickCancel={closeModal}
            />
          </ConditionalRenderer>

          <ConditionalRenderer condition={selectedLesson}>
            {selectedLesson && (
              <ModalDisable
                isRequesting={isRequestingDelete}
                modalType="delete"
                visible={modalOpen === 'exclude'}
                selectedObjectName={selectedLesson.name}
                translationString={'modalDisableLesson'}
                onClickConfirm={() => onDeleteLesson(selectedLesson)}
                onClickCancel={closeModal}
              />
            )}
          </ConditionalRenderer>

          <ConditionalRenderer condition={course && !isLoadingCourse}>
            {course && !isLoadingCourse && (
              <React.Fragment>
                <CancelChangesModal
                  isLoading={isRequestingCancel}
                  closeModal={closeModal}
                  itemName={formatCourseName(course)}
                  onConfirm={cancelVersion}
                  visible={modalOpen === 'cancel'}
                />
                <BetaModal
                  onClose={closeModal}
                  slug={course.slug}
                  visible={modalOpen === 'beta'}
                />
                <PublishEditModal
                  onClose={closeModal}
                  slug={course.slug}
                  visible={modalOpen === 'publish'}
                />
                <ModalBank
                  visible={modalOpen === 'bank'}
                  onClose={closeModal}
                  initial="lessons"
                  onUseLesson={onUseLesson}
                />
              </React.Fragment>
            )}
          </ConditionalRenderer>
          <ConditionalRenderer condition={page !== 'edit'}>
            <div className="flex gap-2">
              <MainButton
                dataTestId="newLesson"
                onClick={createNewLesson}
                loading={isRequestCreate}
                icon={<PlusIcon className="w-4 h-4" />}
                text={t('newLesson')}
                className="w-fit"
              />
              <ModalBankButton
                onClick={() => setModalOpen('bank')}
                mode="lessons"
              />
            </div>
          </ConditionalRenderer>
        </div>
      </div>
    </Layout>
  );
}

export default ManageCourseEditing;
