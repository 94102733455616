import { useForm, SubmitHandler } from 'react-hook-form';
import { TagType } from '../common/dataInput/TagInput/TagInput';
import TextInput from '../common/dataInput/TextInput';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { some, isEqual } from 'lodash';
import ConditionalRenderer from '../common/ConditionalRenderer';
import ImageInput from '../common/dataInput/ImageInput';
import { validateUpload } from '../../utils/VerifyImageFile';
import { addTool, editTool } from '../../data/services/toolServices';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from '../../models/Errors';
import alert from '../../utils/UseAlert';
import ColorInput from '../common/dataInput/ColorInput/ColorInput';
import { validateLink } from '../../validators/regex';
import Tool from '../../models/Tool';
import { LoadingIcon } from '../icons';
import { buildChangedObject } from '@/utils/buildChangedObject';
import ITag from '@/models/Tag';
import Tag from '../common/dataDisplay/Tag';
import RoundedButton from '../common/buttons/RoundedButton';

export interface TagFormProps {
  tagType: TagType;
  tag?: ITag;
  updateTags(tagType: TagType): void;
}

export default function TagForm(props: TagFormProps) {
  const { tagType, tag, updateTags } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'tagForm',
  });

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const [hasChanges, setHasChanges] = useState(false);

  const { deafultValues, componentValues } = useMemo(() => {
    const keys = {
      tool: [tag as Tool, { name: '', site: '', color: '' }],
      none: [tag as any, { name: '' }],
    };

    return {
      deafultValues: keys[tagType][0],
      componentValues: keys[tagType][1],
    };
  }, [tag, tagType]);

  const [tagImage, setTagImage] = useState<null | File | string>(null);

  useEffect(() => {
    setTagImage(deafultValues?.image ?? null);
  }, [deafultValues?.image, tagType]);

  const {
    watch,
    reset,
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    values: deafultValues ?? componentValues,
  });

  const id = getValues('id');
  const name = watch('name');
  const color = watch('color');

  const resetImageValue =
    (deafultValues?.image as string) ??
    (componentValues?.image as string) ??
    null;

  const { isLoading: isLoadingEditTool, mutate: editTagTool } = useMutation(
    editTool,
    {
      onSuccess() {
        alert.success(t('editSaved'));
        updateTags(tagType);
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const { isLoading: isLoadingAddTool, mutate: addTagTool } = useMutation(
    addTool,
    {
      onSuccess() {
        alert.success(t('addSaved'));
        updateTags(tagType);
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitTool: SubmitHandler<Omit<ITag, string>> = async data => {
    const image = tagImage ?? undefined;

    if (id) {
      const changedFields = buildChangedObject(tag as {}, data);
      const body = { ...changedFields, image };
      if (tagType === 'tool') editTagTool({ params: { toolId: id }, body });
    } else {
      const body = { ...data, image };
      if (tagType === 'tool') addTagTool({ params: {}, body });
    }
  };

  const resetFields = () => {
    reset();
    setHasChanges(false);
    setTagImage(resetImageValue);
  };

  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files && e.target.files.length) {
      const value = e.target.files.item(0);
      if (value && !validateUpload(value)) {
        setTagImage(value);
        setHasChanges(true);
      }
    }
  };

  const onImageRemove = () => {
    setTagImage(null);
    setHasChanges(true);
  };

  useEffect(() => {
    const changes = watch(value => {
      setHasChanges(!isEqual(value, tag));
    });

    return () => changes.unsubscribe();
  }, [watch, tag]);

  const blockChanges = some(errors, error => !!error?.message);

  if (tagType === 'none') {
    return (
      <div className="flex justify-center items-center h-36">
        <LoadingIcon className="w-1/6 text-primary/40" />
      </div>
    );
  }

  const onSubmitTag = {
    tool: onSubmitTool,
  };

  const isLoading = isLoadingAddTool || isLoadingEditTool;

  return (
    <form
      data-testid="tagForm"
      className="flex flex-col gap-3.5"
      onSubmit={handleSubmit(onSubmitTag[tagType])}
    >
      <TextInput
        color={errors.name?.message ? 'warning' : 'primary'}
        testId="nameInput"
        label={t('name')}
        register={register('name', {
          required: t('warnings.required'),
        })}
        fontWeight="font-400"
        errorLabelText={errors?.name?.message as string}
      />

      <ConditionalRenderer condition={tagType === 'tool'}>
        <TextInput
          color={errors.site?.message ? 'warning' : 'primary'}
          testId="linkInput"
          label={t('link')}
          register={register('site', {
            pattern: {
              value: validateLink,
              message: t('warnings.validateLink'),
            },
          })}
          fontWeight="font-400"
          errorLabelText={errors?.site?.message as string}
        />

        <div className="flex flex-col gap-4">
          <ColorInput
            control={control}
            name="color"
            errorLabelText={errors?.color?.message as string}
          />
          <Tag
            text={name}
            color="custom"
            style={{ backgroundColor: color }}
            className="text-base-100"
          />
        </div>

        <ImageInput
          testId="tagImageInput"
          className="w-fit"
          image={tagImage}
          onChange={onImageChange}
          onRemove={onImageRemove}
        />
      </ConditionalRenderer>

      <div className="flex w-full justify-around gap-6">
        <RoundedButton
          disabled={!hasChanges || isLoading}
          text={tCommon('cancel')}
          color="neutral"
          className="w-full"
          onClick={resetFields}
          testId="cancelButton"
        />
        <RoundedButton
          disabled={!hasChanges || isLoading || blockChanges}
          type="submit"
          text={tCommon('save')}
          className="w-full"
          loading={isLoading}
          testId="saveButton"
        />
      </div>
    </form>
  );
}
