import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchInput, { SearchOption } from './SearchInput';
import { debounce } from 'lodash';
import { BaseInputProps } from './BaseInput';
import { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import JsonApiResponse, { Meta } from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import useInfiniteService from '@/data/hook/useInfiniteService';

export type CreateOptionFn<T> = (item: T, number: number) => SearchOption<T>;

type ServiceReturn<Model> = {
  _ctx: QueryCtx<Model>;
};

type QueryCtx<Model> = {
  infinity: {
    queryFn(ctx: any): Promise<JsonApiResponse<Model>> | JsonApiResponse<Model>;
    queryKey: QueryKey;
  };
};
interface InfiniteSearchInputProps<Filter extends BaseFilters, Model> {
  selectedItem?: Model;
  service: (filters?: Filter) => ServiceReturn<Model>;
  filters?: Filter;
  displayName: (option: Model) => string;
  onSelect(item: Model): void;
  onDeselect?(): void;
  blockDeselect?: boolean;
  input?: Omit<BaseInputProps, 'onChange' | 'value'>;
  className?: string;
  options?: Omit<UseInfiniteQueryOptions<JsonApiResponse<Model>>, 'queryKey'>;
  inputIcon?: JSX.Element;
  loading?: boolean;
  onGetMeta?: (meta: Meta) => void;
  createOption?: CreateOptionFn<Model>;
}

export default function InfiniteSearchInput<Filter extends BaseFilters, Model>({
  selectedItem,
  service,
  displayName,
  blockDeselect,
  onSelect,
  onDeselect,
  className,
  input,
  filters,
  options: queryOptions,
  inputIcon,
  loading,
  onGetMeta,
  createOption = createDefaultOption,
}: InfiniteSearchInputProps<Filter, Model>) {
  const [searchValue, setSearchValue] = useState<string>();
  const [search, setSearch] = useState<string>();

  const {
    meta,
    results: items,
    isInitialLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    ...queryOptions,
    keepPreviousData: true,
    ...service({
      ...filters,
      search,
    } as Filter)._ctx.infinity,
  });

  useEffect(() => {
    if (!!selectedItem) {
      setSearchValue(displayName(selectedItem));
    }
  }, [displayName, selectedItem]);

  useEffect(() => {
    if (meta) {
      onGetMeta?.(meta);
    }
  }, [meta, onGetMeta]);

  const options = items.map<SearchOption<Model>>(createOption);

  const memoedDebounce = useMemo(
    () => debounce(search => setSearch(search), 500),
    [setSearch],
  );

  const debouncedSearch = useCallback(
    (search?: string) => {
      memoedDebounce(search);
    },
    [memoedDebounce],
  );

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value;

    setSearchValue(value ?? undefined);
    debouncedSearch(value ?? undefined);
  };

  const handleSelectItem = (item: Model) => {
    onSelect(item);
    setSearchValue(displayName(item));
    setSearch(undefined);
  };

  const handleDeselect = () => {
    setSearch(undefined);
    setSearchValue(undefined);
    onDeselect?.();
  };

  return (
    <SearchInput
      hasNextPage={hasNextPage}
      onReachEnd={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      options={options}
      displayName={displayName}
      onSearch={handleSearch}
      onSelect={handleSelectItem}
      value={searchValue ?? ''}
      isLoading={loading || isInitialLoading}
      isSearching={isInitialLoading}
      hasItemSelected={!!selectedItem || !!searchValue}
      onDeselect={blockDeselect ? undefined : handleDeselect}
      className={className}
      input={input}
      inputIcon={inputIcon}
    />
  );
}

const createDefaultOption = <T,>(item: T, index: number): SearchOption<T> => ({
  value: item,
  key: index,
});
