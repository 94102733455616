import UserGroupIcon from '@heroicons/react/outline/UserGroupIcon';
import Klass from '../../models/Klass';
import { useTranslation } from 'react-i18next';
import CollapsableCard from './CollapsableCard';
import TeacherKlassesList from './TeacherKlassesList';

interface TeacherKlassesProps {
  klasses: Klass[];
  isLoading: boolean;
  fetchNextPage?(): unknown | Promise<unknown>;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
}

export const TeacherKlasses = ({
  klasses,
  isLoading,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
}: TeacherKlassesProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherKlasses',
  });

  const hasData = !!klasses.length;

  return (
    <CollapsableCard
      title={t('yourClasses').toLocaleUpperCase()}
      headerIcon={<UserGroupIcon className="h-6 stroke-[2]" />}
      isCollapsable={hasData}
      testId={'teacherKlasses'}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      onReachEnd={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
    >
      <TeacherKlassesList klasses={klasses} />
    </CollapsableCard>
  );
};
