import MainButton from './buttons/MainButton';
import Feedback from './CannyFeedback';
import { ArrowRightIcon, RefreshIcon } from '@heroicons/react/outline';
import Text from './dataDisplay/Text';
import { useTranslation } from 'react-i18next';

export default function SuggestionsCard() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'suggestionsPage',
  });

  return (
    <div className="flex flex-col w-full md:max-w-sm ">
      <div className="flex justify-between items-center">
        <Text
          className="text-primary shrink text-20"
          format="rubik-500"
          text={t('suggestions') + ' Portal'}
        />
        <div className="flex shrink-0 items-center gap-3">
          <MainButton
            href="/sugestoes"
            className="shrink-0"
            icon={<ArrowRightIcon />}
            text={t('seeAll')}
          />
          <MainButton
            icon={<RefreshIcon />}
            data-canny-changelog
            text={t('updates')}
          />
        </div>
      </div>

      <Feedback path="/" />
    </div>
  );
}
