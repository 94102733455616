import moment from 'moment';
import { Link } from 'react-router-dom';
import ScheduledLesson from '@/models/ScheduledLesson';
import { CheckCircleIcon } from '@heroicons/react/solid';
import BodyTextSmall from './BodyTextSmall';
import ButtonText from './ButtonText';
import PillComponent from './dataDisplay/PillComponent';
import LessonIconContainer from './LessonIconContainer';
import { formatLessonName } from '@/functions/lessonsName';
import LessonProgress from '@/models/LessonProgress';
import Klass from '@/models/Klass';
import AvatarName from './dataDisplay/AvatarName';
import { useQuery } from '@tanstack/react-query';
import ConditionalRenderer from './ConditionalRenderer';
import { usersQueryKeys } from '@/data/services/querykeys';

interface StudentScheduledLessonProps {
  scheduledLesson: ScheduledLesson;
  lessonProgress: null | LessonProgress;
  klass: null | Klass;
}

export function StudentScheduledLesson({
  scheduledLesson,
  lessonProgress,
  klass,
}: StudentScheduledLessonProps) {
  const { data: lessonTeacher } = useQuery({
    enabled: !!scheduledLesson.teacher,
    ...usersQueryKeys.get(scheduledLesson.teacher),
  });

  const klassId = scheduledLesson.klass;

  const hasHomework = scheduledLesson.homeworkActivity !== null;

  const hasToRenderActivities =
    lessonProgress?.activitiesProgress.length !== 0 || hasHomework;

  const bookNotificationCount = (lessonProgress: LessonProgress): number => {
    let count = 0;

    if (lessonProgress.bookProgress) {
      if (lessonProgress.bookProgress.progress < 1.0) {
        count = 1;
      } else {
        count = 0;
      }
    }

    return count;
  };

  const activityNotificationCount = (
    lessonProgress: LessonProgress,
    scheduledLesson: ScheduledLesson,
  ): number => {
    let count = 0;

    const hasHomework = scheduledLesson.homeworkActivity !== null;
    const homeworkProgress = lessonProgress.homeworkProgress;
    let hasHomeworkGrade: boolean = false;
    if (homeworkProgress && 'activityProgress' in homeworkProgress) {
      hasHomeworkGrade = homeworkProgress.activityProgress.grade !== null;
    }

    if (lessonProgress) {
      count = lessonProgress?.activitiesProgress?.filter(
        activityProgress => !activityProgress.hasDone,
      ).length;

      if (hasHomework) {
        count += hasHomeworkGrade ? 0 : 1;
      }
    }
    return count;
  };

  const challengeNotificationCount = (
    lessonProgress: LessonProgress,
  ): number => {
    let count = 0;

    if (lessonProgress) {
      if (lessonProgress?.challengeProgress?.hasDone) {
        count = 0;
      } else {
        count = 1;
      }
    }

    return count;
  };

  return (
    <div
      data-testid="studentScheduledLesson"
      className={`self-stretch grow-0 flex flex-col justify-start items-stretch gap-1.5 px-3.5 py-2.5 border rounded-xl max-w-620 select-none ${
        scheduledLesson.hasDone
          ? 'bg-success/10 border-success'
          : 'bg-secondary-content border-secondary/40'
      }`}
    >
      <div className="self-stretch grow-0 flex flex-row justify-start items-center gap-2.5 p-0">
        {scheduledLesson.hasDone ? (
          <CheckCircleIcon className="w-8 h-8 text-success" />
        ) : (
          <span className="rounded-full w-8 h-8 grow-0 object-contain border-2 border-dashed border-secondary/40 bg-transparent"></span>
        )}

        <Link
          id="classLink"
          to={`/courses/${klass ? klass.coursePathSlug : ''}/lesson/${
            scheduledLesson.lesson.id
          }/class/${scheduledLesson.klass}`}
          style={{ height: 46 }}
          className="flex items-center"
        >
          <span
            id="scheduledLessonName"
            className="font-rubik font-500 duration-200 text-primary hover:text-secondary cursor-pointer underline text-16 break-words"
          >
            {formatLessonName(scheduledLesson.lesson, scheduledLesson)}
          </span>
        </Link>
      </div>

      <div className="flex gap-7 justify-between">
        <div className="flex justify-between items-center gap-5">
          <PillComponent
            className={`badge-primary ${
              scheduledLesson.hasDone ? '' : 'badge-outline'
            }`}
          >
            <ButtonText
              id="scheduledLessonDate"
              text={`${moment(scheduledLesson.datetime).format('DD/MM')}`}
            />
          </PillComponent>

          <BodyTextSmall
            id="scheduledLessonTime"
            text={`${moment(scheduledLesson.datetime).format('HH:mm')}`}
            className="leading-none tracking-normal text-right text-base-content"
          />
        </div>

        <div id="links" className="flex justify-start items-center gap-2 p-0">
          {lessonProgress?.bookProgress && (
            <Link
              id="bookClassLink"
              to={`/courses/${klass ? klass.coursePathSlug : ''}/lesson/${
                scheduledLesson.lesson.id
              }/class/${klassId}/book/chapter/1`}
              className="flex items-center"
            >
              <LessonIconContainer
                notification={bookNotificationCount(lessonProgress)}
                type="studentBook"
                size="w-6 h-6"
              />
            </Link>
          )}

          {hasToRenderActivities && lessonProgress && (
            <Link
              id="activityClassLink"
              to={`/courses/${klass ? klass.coursePathSlug : ''}/lesson/${
                scheduledLesson.lesson.id
              }/class/${scheduledLesson.klass}/activities`}
              className="flex items-center"
            >
              <LessonIconContainer
                notification={activityNotificationCount(
                  lessonProgress,
                  scheduledLesson,
                )}
                type="activity"
                size="w-6 h-6"
              />
            </Link>
          )}

          {lessonProgress?.challengeProgress && (
            <Link
              id="extraActivityClassLink"
              to={`/courses/${klass ? klass.coursePathSlug : ''}/lesson/${
                scheduledLesson.lesson.id
              }/class/${scheduledLesson.klass}/challenge`}
              className="flex items-center"
            >
              <LessonIconContainer
                notification={challengeNotificationCount(lessonProgress)}
                type="challenge"
                size="w-6 h-6"
              />
            </Link>
          )}
        </div>
        <ConditionalRenderer condition={lessonTeacher}>
          {lessonTeacher && (
            <PillComponent className="min-w-min badge-primary hidden badge-outline">
              <Link
                id="scheduledTeacherLink"
                to={`/profile/${lessonTeacher.username}`}
                className="flex items-center gap-2"
              >
                <AvatarName user={lessonTeacher} />
              </Link>
            </PillComponent>
          )}
        </ConditionalRenderer>
      </div>
    </div>
  );
}
