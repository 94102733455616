import { motion } from 'framer-motion';
import { fadeIn } from '@/utils/animations/commom';
import ConditionalRenderer from '../ConditionalRenderer';
import TextWithCopy from '../dataDisplay/TextWithCopy';
import Text, { TextProps } from '../dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';

type Item = {
  status: 'loading' | 'success' | 'error';
  text?: string;
};

type RowListDisplayProps = {
  items: Item[];
  itemsKeys: string[];
  hasCopyButton?: boolean;
  width?: number;
};

export default function RowListTextDisplay({
  items,
  itemsKeys,
  hasCopyButton = false,
  width,
}: RowListDisplayProps) {
  const defaultTextProps: Partial<TextProps> = {
    format: 'rubik-400',
    className: 'h-fit truncate',
  };
  const scrollBarClassName =
    'scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thin scrollbar-track-rounded-ful scrollbar-thumb-rounded-full';
  return (
    <div style={{ width: `${width}%` }} className="pr-2">
      <motion.ul
        className={`flex flex-col w-full gap-2 overflow-y-auto ${scrollBarClassName}`}
        {...fadeIn}
      >
        {items.map(({ status, text = '' }, index, array) => {
          return (
            <motion.li
              {...fadeIn}
              key={itemsKeys[index]}
              className="flex flex-col gap-2 px-4"
            >
              <ConditionalRenderer
                condition={status !== 'loading'}
                fallback={
                  <LoadingIcon className="w-6 text-primary self-center" />
                }
              >
                <ConditionalRenderer
                  condition={hasCopyButton && status !== 'error'}
                  fallback={<Text {...defaultTextProps} text={text} />}
                >
                  <TextWithCopy {...defaultTextProps} text={text} />
                </ConditionalRenderer>
              </ConditionalRenderer>

              <ConditionalRenderer condition={index < array.length - 1}>
                <hr className="h-px border-primary/40" />
              </ConditionalRenderer>
            </motion.li>
          );
        })}
      </motion.ul>
    </div>
  );
}
