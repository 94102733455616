import i18n from '../i18n';
import { ActivityTypeEnum } from '../models/Activity';
import { AttemptAnswer } from '../models/ActivityProgress';

export const answersActivitiesType = [
  ActivityTypeEnum.TRUE_OR_FALSE,
  ActivityTypeEnum.QUIZ,
  ActivityTypeEnum.DND_ORDENATION,
  ActivityTypeEnum.DND_POSITIONAL,
];

type getAnswerByTypeReturn = AttemptAnswer | undefined;

export const getAnswerByType = ({
  type,
  data,
  ...content
}: any): getAnswerByTypeReturn => {
  if (!questionHasAnswer(type) || !('answers' in content)) {
    return undefined;
  } else {
    const { answers } = content;
    switch (type) {
      case ActivityTypeEnum.TRUE_OR_FALSE:
        return answers;
      case ActivityTypeEnum.QUIZ:
        const answer = data.quizAnswers.findIndex(
          ({ answerVerifier }: any) => answers.answer === answerVerifier,
        );
        return {
          ...answers,
          answer,
        };
      case ActivityTypeEnum.DND_ORDENATION:
        return answers;
      case ActivityTypeEnum.DND_POSITIONAL:
        return answers;

      default:
        return undefined;
    }
  }
};

export const questionHasAnswer = (type: ActivityTypeEnum) =>
  answersActivitiesType.includes(type);

export const stringAnswer = (answer: number | boolean | null) => {
  if (answer === -1 || answer === null) {
    return '-';
  } else if (typeof answer === 'boolean') {
    return i18n.t(`lesson.activity.${answer ? 'true' : 'false'}`);
  } else {
    return String.fromCharCode(answer + 65);
  }
};
