import ErrorComponent, {
  ErrorActionButton,
} from '@/components/common/ErrorComponent';
import { RefreshIcon, ViewGridIcon } from '@heroicons/react/outline';
import * as Sentry from '@sentry/react';
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import { useErrorTratatives } from './ErrorBoundary.hooks';

export default function ErrorBoundary({ children }: PropsWithChildren) {
  const hasError = useRef(false);

  const { pathname } = useLocation();
  const boundaryKey = hasError.current ? pathname : 'error-boundary';
  const onUnmountErrorFallback = useCallback(() => {
    hasError.current = false;
  }, []);
  const onMountErrorFallback = useCallback(() => {
    hasError.current = true;
  }, []);

  return (
    <Sentry.ErrorBoundary
      key={boundaryKey}
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFallback
          error={error}
          resetError={resetError}
          onMount={onMountErrorFallback}
          onUnmount={onUnmountErrorFallback}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

type ErrorBoundaryFallbackProps = {
  error: Error;
  resetError(): void;
  onMount?(): void;
  onUnmount?(): void;
};

function ErrorBoundaryFallback({
  resetError,
  onMount,
  onUnmount,
}: ErrorBoundaryFallbackProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'errors.errorPage',
  });

  const { tratativeFn } = useErrorTratatives();

  const { pathname } = useLocation();

  const panelButton: ErrorActionButton = {
    icon: <ViewGridIcon />,
    text: t('panelButton'),
    href: '/',
  };

  const reloadButton: ErrorActionButton = {
    action: resetError,
    text: t('reloadButton'),
    icon: <RefreshIcon />,
  };

  const isHome = pathname === '/';

  const actionButtons = isHome ? [reloadButton] : [panelButton, reloadButton];

  useEffect(() => {
    tratativeFn();
    onMount?.();
    return onUnmount;
  }, [onMount, onUnmount, tratativeFn]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <ErrorComponent
        errorTextTitle={t('reactException.title')}
        errorTextSubTitle={t('reactException.subtitle')}
        hideStatusCode
        actionButtons={actionButtons}
      />
    </div>
  );
}
