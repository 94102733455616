import CardListItem from '@/components/common/dataDisplay/CardListItem';
import { AbsenceNotification } from '@/models/AbsenceNotification';
import React, { PropsWithChildren } from 'react';
import AbsenceNotificationItem from '../AbsenceNotificationItem';
import { ListAbsenceNotificationParams } from '@/data/services/absenceNotificationServices';
import Text from '@/components/common/dataDisplay/Text';
import Badge from '@/components/common/dataDisplay/Badge';
import { useGroupItems } from './AbsenceNotificationGroups.hooks';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { useTranslation } from 'react-i18next';
import ButtonInfiniteList from '@/components/common/ButtonInfiniteList';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { absenceNotificationsQueryKeys } from '@/data/services/querykeys';

export type AbsenceNotificationGroupBy = 'all' | 'teacher' | 'klass';

export type AbsenceNotificationScrollHandler = {
  hasNextPage: boolean;
  fetchNextPage(): Promise<unknown>;
  isFetchingNextPage: boolean;
};

type AbsenceNotificationGroupProps = {
  groupBy: AbsenceNotificationGroupBy;
  ungroupedNotifications: AbsenceNotification[];
  absenceNotificationFilters?: ListAbsenceNotificationParams;
  notificationScrollHandler: AbsenceNotificationScrollHandler;
};

export default function AbsenceNotificationGroups({
  groupBy,
  ungroupedNotifications,
  absenceNotificationFilters = {},
  notificationScrollHandler,
}: AbsenceNotificationGroupProps) {
  const { isLoading, items } = useGroupItems({ groupBy });

  const ungroupedList = ungroupedNotifications.map((absenceNotification, i) => (
    <AbsenceNotifcationCard
      index={i}
      key={absenceNotification.id}
      absenceNotification={absenceNotification}
    />
  ));

  const groupedList = items.map(({ key, title, notificationFilters }) => (
    <GroupItemList
      title={title}
      key={key}
      notificationFilters={{
        ...absenceNotificationFilters,
        ...notificationFilters,
      }}
    />
  ));

  const list = groupBy === 'all' ? ungroupedList : groupedList;

  return (
    <AbsenceNotificationsListConteiner isLoadingGroups={isLoading}>
      {list}
    </AbsenceNotificationsListConteiner>
  );
}

const LIST_CLASS_NAME = 'flex flex-col gap-4';

type AbsenceNotificationsListItem = {
  isLoadingGroups?: boolean;
};

const AbsenceNotificationsListConteiner: React.FC<
  PropsWithChildren<AbsenceNotificationsListItem>
> = ({ isLoadingGroups, children }) => {
  return (
    <ConditionalRenderer
      condition={!isLoadingGroups}
      fallback={<LoadingIcon className="w-1/6 text-accent/40 mx-auto" />}
    >
      {children}
    </ConditionalRenderer>
  );
};

type GroupProps = {
  notificationFilters?: ListAbsenceNotificationParams;
};

type GroupItemListProps = GroupProps & {
  title?: string;
};

const GroupItemList = ({ title, notificationFilters }: GroupItemListProps) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.absenceReports',
  });

  const {
    results: absenceNotifications,
    meta,
    isInitialLoading: isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteService({
    ...absenceNotificationsQueryKeys.list({
      ...notificationFilters,
      pageSize: 5,
    })._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });
  const count = meta?.pagination.count;

  return (
    <li className="flex flex-col gap-2.5">
      <Badge
        count={count && t('totalItems', { count })}
        classNameContainer="self-start max-w-[85%]"
        position="top-0 left-full"
        className="translate-x-2 w-max"
      >
        <Text className="text-12 text-secondary truncate" text={title} />
      </Badge>
      <ConditionalRenderer
        condition={!isLoading}
        fallback={<LoadingIcon className="w-1/6 text-accent/40 mx-auto" />}
      >
        <ButtonInfiniteList
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          listClassName={`${LIST_CLASS_NAME} items-center`}
        >
          {absenceNotifications.map((absenceNotification, i) => (
            <AbsenceNotifcationCard
              index={i}
              key={absenceNotification.id}
              absenceNotification={absenceNotification}
            />
          ))}
        </ButtonInfiniteList>
      </ConditionalRenderer>
    </li>
  );
};

type AbsenceNotifcationCardProps = {
  absenceNotification: AbsenceNotification;
  index: number;
};

const AbsenceNotifcationCard: React.FC<AbsenceNotifcationCardProps> = ({
  absenceNotification,
  index,
}) => {
  return (
    <CardListItem color={index % 2 === 0 ? 'accent' : 'blue'}>
      <AbsenceNotificationItem absenceNotification={absenceNotification} />
    </CardListItem>
  );
};
