import requester from '@/axios';
import { VideoActivityElement } from '@/models/ActivityElement';

export const getVideoActivity = async (id: number) => {
  const { data: video } = await requester().get<VideoActivityElement>(
    `video-activity/${id}/`,
  );
  return video;
};

export const deleteVideoActivity = async (questionId: number) => {
  await requester().delete<VideoActivityElement>(
    `video-activity/${questionId}/`,
  );
};
