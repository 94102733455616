import { useTranslation } from 'react-i18next';
import PillComponent from '../../../common/dataDisplay/PillComponent';
import Text from '../../../common/dataDisplay/Text';
import { ModalState } from '../ModalHandler';

interface AnticipatePostponePillProps {
  state: ModalState;
  setModalState: () => void;
  isDisable: boolean;
}

export const AnticipatePostponePill = ({
  state,
  setModalState,
  isDisable,
}: AnticipatePostponePillProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const clickableStyle =
    'cursor-pointer hover:opacity-75 transistion transition-all duration-75 ease-in-out';

  return (
    <PillComponent
      className={`cursor-pointer ease-in-out duration-150 origin-center ${
        isDisable ? 'disabled' : ''
      }`}
      onClick={setModalState}
    >
      <Text
        text={t(`${state.toLocaleLowerCase()}`)}
        className={`text-primary h-5 flex items-center ${clickableStyle}`}
        format="rubik-400"
      />
    </PillComponent>
  );
};
