import { Fragment } from 'react';
import Klass from '@/models/Klass';
import Text from '@/components/common/dataDisplay/Text';
import WeekdayKlass from './WeekdayKlass';
import moment from 'moment';

export default function WeekdayColumn({
  weekday,
  klasses,
}: {
  weekday: number;
  klasses?: Klass[];
}) {
  const Column = ({ extraText }: { extraText?: string }) => {
    const selectedKlasses = (klasses: Klass[]) => {
      if (extraText === 'AM') {
        return klasses?.filter(
          klass => parseInt(moment(klass.klassEndDate).format('HH')) <= 12,
        );
      } else if (extraText === 'PM') {
        return klasses?.filter(
          klass => parseInt(moment(klass.klassEndDate).format('HH')) > 12,
        );
      } else return klasses;
    };

    return (
      <div className="flex flex-col items-center gap-4 w-24 lg:w-28 xl:w-40">
        <Text
          text={
            weekday === 6
              ? `${moment.weekdays(weekday).slice(0, 3)} - ${extraText}`
              : moment.weekdays(weekday).slice(0, 3)
          }
          className="bg-primary capitalize text-base-100 w-fit px-3 py-1 rounded-full"
        />
        {klasses &&
          selectedKlasses(klasses).map(klass => {
            return <WeekdayKlass key={klass.id} klass={klass} />;
          })}
      </div>
    );
  };
  if (weekday === 0) {
    return <Fragment />;
  } else if (weekday === 6)
    return (
      <Fragment>
        <Column extraText="AM" />
        <Column extraText="PM" />
      </Fragment>
    );
  else return <Column />;
}
