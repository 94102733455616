import { SaveIcon } from '@heroicons/react/outline';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import requester from '@/axios';
import Activity from '@/models/Activity';
import {
  VideoActivityElement,
  VideoPlayerEnum,
} from '@/models/ActivityElement';
import MainButton from '@/components/common/buttons/MainButton';
import SelectInput from '@/components/common/dataInput/SelectInput';
import alert from '@/utils/UseAlert';
import ContainerActivityEdit from './commom/ContainerActivityEdit';

export interface VideoElementEditProps
  extends Omit<VideoActivityElement, 'activity'> {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: any) => void;
}

export default function VideoElementEdit(props: VideoElementEditProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityVideo',
  });
  const [errors, setErrors] = useState<any[]>([]);
  const [isButtonActivated, setIsButtonActivated] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const handleUrlChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onEdit({ ...props, url: event.currentTarget.value });
  };

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onEdit({ ...props, title: event.currentTarget.value });
  };

  const handleSubtitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === '') {
      props.onEdit({ ...props, subtitle: null });
    } else {
      props.onEdit({ ...props, subtitle: event.currentTarget.value });
    }
  };

  const handleVideoPlayerChange = (value: VideoPlayerEnum) => {
    props.onEdit({ ...props, videoPlayer: value });
  };

  useEffect(() => {
    if (props.changeStatus === 'new') {
      const keysActivityVideo = ['videoPlayer', 'url', 'title', 'subtitle'];
      const hasKey = keysActivityVideo.find(key => Object.hasOwn(props, key));

      if (hasKey) return setIsButtonActivated(true);

      return setIsButtonActivated(false);
    }

    if (props.changeStatus) return setIsButtonActivated(true);
    setIsButtonActivated(false);
  }, [props, props.changeStatus]);

  function checkVideo(): string[] | null {
    const errors: string[] = [];

    if (props.url === '' || props.url === undefined) {
      errors.push('URL');
    }

    if (props.title === '' || props.title === undefined) {
      errors.push('title');
    }

    if (props.subtitle === '' || props.subtitle === undefined) {
      errors.push('subtitle');
    }

    if (props.videoPlayer === undefined) {
      errors.push('player');
    }

    if (errors.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  const handleClickSave = async () => {
    const ErrorID = checkVideo();
    if (ErrorID !== null) {
      setErrors(ErrorID);
    } else {
      setErrors([]);

      if (props.changeStatus === 'new') {
        try {
          setIsRequesting(true);
          const { data } = await requester().post(`video-activity/`, {
            activity: props.activity.id,
            title: props.title,
            subtitle: props.subtitle,
            videoPlayer: props.videoPlayer,
            url: props.url,
            type: props.type,
            identifier: props.identifier,
            order: props.order,
          });
          props.onSave({ ...props, id: data.id });
          alert.success(t('saveSuccess'));
        } catch (err) {
          alert.error(t('saveError'));
        } finally {
          setIsRequesting(false);
        }
      } else if (props.changeStatus === 'changed') {
        try {
          setIsRequesting(true);
          await requester().patch(`video-activity/${props.id}/`, {
            title: props.title,
            subtitle: props.subtitle,
            videoPlayer: props.videoPlayer,
            url: props.url,
            type: props.type,
            identifier: props.identifier,
          });
          props.onSave({ ...props });
          alert.success(t('saveSuccess'));
        } catch (err) {
          alert.error(t('saveError'));
        } finally {
          setIsRequesting(false);
        }
      }
    }
  };

  return (
    <ContainerActivityEdit className="astro-select" id="VideoElementEdit">
      <div>
        <label className="font-rubik text-base-content">{t('title')}</label>

        <br />

        {errors && errors.some(errorID => errorID === 'title') ? (
          <label className="font-rubik text-error error-label">
            {t('titleError')}
          </label>
        ) : (
          ''
        )}

        <Input
          className="rounded-lg border-primary font-600  font-rubik"
          id={'video-activity-title'}
          value={props.title}
          onChange={handleTitleChange}
        />
      </div>

      <div>
        <label className="font-rubik text-base-content">{t('subtitle')}</label>

        <br />

        {errors && errors.some(errorID => errorID === 'subtitle') ? (
          <label className="font-rubik text-error">{t('subtitleError')}</label>
        ) : (
          ''
        )}

        <Input
          className="rounded-lg border-primary font-600 font-rubik"
          id={'video-activity-subtitle'}
          value={props.subtitle}
          onChange={handleSubtitleChange}
        />
      </div>

      <div className="flex flex-col">
        <label className="font-rubik text-base-content">
          {t('videoPlayer')}
        </label>
        {errors && errors.some(errorID => errorID === 'player') ? (
          <label className="font-rubik text-error">
            {t('videoPlayerError')}
          </label>
        ) : (
          ''
        )}

        <SelectInput
          testId="videoSelectPlayer"
          id="video-select-player"
          defaultValue={props.videoPlayer}
          onChange={event =>
            handleVideoPlayerChange(event.target.value as VideoPlayerEnum)
          }
        >
          {(
            Object.keys(VideoPlayerEnum) as (keyof typeof VideoPlayerEnum)[]
          ).map(player => {
            return (
              <option key={player} value={String(player)}>
                {VideoPlayerEnum[player]}
              </option>
            );
          })}
        </SelectInput>
      </div>

      <div>
        <label className="font-rubik text-base-content">{t('videoID')}</label>
        <br />
        {errors && errors.some(errorID => errorID === 'URL') ? (
          <label className="font-rubik text-error error-label">
            {t('videoIDError')}
          </label>
        ) : (
          ''
        )}

        <Input
          className="rounded-lg border-primary font-600  font-rubik"
          id={'video-activity-video-id'}
          value={props.url}
          onChange={handleUrlChange}
        />
      </div>

      <p className="text-14">
        {t('exampleMessage1')}
        <span className="font-700 underline">'3LXuQyz1elk'</span>
        {t('exampleMessage2')}
        <span className="font-700 ">
          https://www.youtube.com/watch?v=3LXuQyz1elk
        </span>
      </p>

      <div className="flex">
        <MainButton
          disabled={!isButtonActivated}
          loading={isRequesting}
          icon={<SaveIcon />}
          id="video-activity-save"
          text={t('save')}
          onClick={handleClickSave}
        />
      </div>
    </ContainerActivityEdit>
  );
}
