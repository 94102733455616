import { useTranslation } from 'react-i18next';
import TextInput from '../../../../common/dataInput/TextInput';
import ConditionalRenderer from '../../../../common/ConditionalRenderer';

interface DNDActivityEditProps {
  title: string | '';
  subtitle: string | '';
  activityFieldsErrors: string[];
  onChangeActivityFields: (
    value: string,
    keyActivity: 'title' | 'subtitle',
  ) => void;
  isLoading: boolean;
}

export default function DNDActivityEdit(props: DNDActivityEditProps) {
  const {
    title,
    subtitle,
    activityFieldsErrors,
    onChangeActivityFields,
    isLoading,
  } = props;

  const { t } = useTranslation('translation', {
    keyPrefix:
      'activity.manageActivity.dndPositionalElementEdit.dndActivityInfo',
  });

  const activityError =
    activityFieldsErrors &&
    activityFieldsErrors.some(error => error === 'noTitle');

  return (
    <div
      data-testid="dndActivityEdit"
      className={`flex flex-col gap-5 ${isLoading ? 'disabled' : ''}`}
    >
      <div>
        <label
          data-testid="activityTitleLabel"
          className="font-rubik text-base-content block"
        >
          {t('title')}
        </label>
        <ConditionalRenderer condition={activityError}>
          <label
            data-testid="activityTitleLabelError"
            className="font-rubik text-error error-label"
          >
            {t('dndErrors.noTitle')}
          </label>
        </ConditionalRenderer>

        <TextInput
          fontWeight="font-400"
          className="rounded-lg border-primary h-9 font-600 font-rubik"
          type="text"
          testId="activityTitleInput"
          name="question"
          color={activityError && !title ? 'warning' : 'primary'}
          required
          onChange={({ target: { value } }) =>
            onChangeActivityFields(value, 'title')
          }
          defaultValue={title}
        />
      </div>
      <div>
        <label
          data-testid="activitySubtitleLabel"
          className="font-rubik text-base-content"
        >
          {t('subtitle')}
        </label>

        <TextInput
          fontWeight="font-400"
          className="rounded-lg border-primary h-9 font-600 font-rubik"
          type="text"
          testId="activitySubtitleInput"
          name="question"
          required
          onChange={({ target: { value } }) =>
            onChangeActivityFields(value, 'subtitle')
          }
          defaultValue={subtitle}
        />
      </div>
    </div>
  );
}
