import ErrorDispatcher from './ErrorDispatcher';

export class LessonProgressMissingError implements ErrorDispatcher {
  userId: number;
  lessonId: number;

  constructor(userId: number, lessonId: number) {
    this.userId = userId;
    this.lessonId = lessonId;
  }

  public getError() {
    return new Error(
      `Lesson progress is missing - student: ${this.userId} - lesson: ${this.lessonId}`,
    );
  }
}
