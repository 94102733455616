import { Fragment } from 'react';
import { createSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BookOpenIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  DotsVerticalIcon,
  UsersIcon,
} from '@heroicons/react/outline';

import Klass from '@/models/Klass';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { translateKlassName } from '@/utils/translateKlassName';
import StudentListTooltip from '@/components/common/dataDisplay/StudentListTooltip';
import IconButton from '../common/buttons/IconButton';

interface TeacherKlassesListProps {
  klasses: Klass[];
}

export default function TeacherKlassesList({
  klasses,
}: TeacherKlassesListProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherKlasses',
  });

  const defaultIconClassName = 'h-6 text-primary';
  const getLinkByOption = (klass: Klass, option: string) => {
    const linksByOption = {
      [t('informations')]: `/admin/classes/info/?${createSearchParams({
        name: klass.name.replace('#', ''),
      })}`,
      [t('progress')]: '/class/' + klass.id + '/progress',
      [t('classes')]: `/admin/classes/lessons/?${createSearchParams({
        name: klass.name.replace('#', ''),
      })}`,
      [t('sheet')]: `/admin/classes/sheet/?${createSearchParams({
        name: klass.name.replace('#', ''),
      })}`,
    };

    return linksByOption[option as keyof typeof linksByOption] || '';
  };

  const getOptionsByKlassId = (klass: Klass) => [
    {
      id: `lessons${klass.id}`,
      text: t('classes'),
      icon: <BookOpenIcon className={defaultIconClassName} />,
      to: getLinkByOption(klass, t('classes')),
    },
    {
      id: `progress${klass.id}`,
      text: t('progress'),
      icon: <DocumentReportIcon className={defaultIconClassName} />,
      to: getLinkByOption(klass, t('progress')),
    },
    {
      id: `sheet${klass.id}`,
      text: t('sheet'),
      icon: <DocumentTextIcon className={defaultIconClassName} />,
      to: getLinkByOption(klass, t('sheet')),
    },
  ];

  const hasData = klasses.length;

  if (!hasData) {
    return (
      <div className="flex justify-center items-center h-full flex-col gap-y-2 text-accent font-500">
        <Text text={t('noClassesPhrase')} />
        <Text text="\ (•◡•) /" />
      </div>
    );
  }
  return (
    <ul className="gap-y-3.5 flex flex-col">
      {klasses.map(klass => {
        const disabledLink = !klass.enrollsCount;

        return (
          <li
            key={klass.id}
            className="flex h-fit justify-between items-center gap-x-2.5"
          >
            <Link
              to={getLinkByOption(klass, t('informations'))}
              className="h-fit flex w-[80%]"
            >
              <Text
                text={translateKlassName(klass.name)}
                format="rubik-500"
                className="text-primary"
                isLink
              />
            </Link>

            <StudentListTooltip klassId={klass.id}>
              <span
                data-testid="klassLength"
                className="flex text-primary items-center gap-x-1"
              >
                <Text text={klass.enrollsCount} format="rubik-500" />
                <UsersIcon className="h-3.5 stroke-[3]" />
              </span>
            </StudentListTooltip>

            <Tooltip
              testId={`tooltipOption${klass.id}`}
              color="white"
              options={{
                interactive: true,
                delayHide: 500,
              }}
              text={
                <Fragment>
                  {getOptionsByKlassId(klass).map(option => {
                    if (option.text === t('progress')) {
                      return (
                        <ConditionalWrapper
                          key={option.id}
                          condition={!disabledLink}
                          wrapper={
                            <Link
                              data-testid={option.id}
                              to={option.to}
                              className="flex items-center"
                            />
                          }
                        >
                          <ConditionalWrapper
                            condition={disabledLink}
                            wrapper={<Tooltip text={t('noStudentsToFollow')} />}
                          >
                            <div
                              className={`flex gap-x-2 items-center transition ease-in-out 
                                duration-150 py-1.5 text-primary hover:text-primary/40
                                ${
                                  disabledLink
                                    ? 'cursor-not-allowed'
                                    : 'cursor-pointer'
                                }`}
                            >
                              {option.icon}
                              <Text
                                text={option.text}
                                format="rubik-400"
                                className="normal-case"
                              />
                            </div>
                          </ConditionalWrapper>
                        </ConditionalWrapper>
                      );
                    }

                    return (
                      <Link
                        data-testid={option.id}
                        key={option.text}
                        to={option.to}
                        className="cursor-pointer flex items-center gap-x-2 transition 
                            ease-in-out duration-150 py-1.5 text-primary hover:text-primary/40"
                      >
                        {option.icon}
                        <Text
                          text={option.text}
                          format="rubik-400"
                          className="normal-case"
                        />
                      </Link>
                    );
                  })}
                </Fragment>
              }
            >
              <DotsVerticalIcon className="h-6 text-primary visible flex lg:invisible lg:hidden" />
            </Tooltip>

            <div
              className="hidden invisible lg:visible lg:flex gap-x-1.5"
              data-testid="dropdownDesktop"
            >
              {getOptionsByKlassId(klass).map(option => {
                if (option.text === t('progress')) {
                  const tooltipMessage = disabledLink
                    ? t('noStudentsToFollow')
                    : option.text;
                  return (
                    <Tooltip text={tooltipMessage} key={option.id}>
                      <IconButton
                        testId={option.id}
                        icon={option.icon}
                        href={option.to}
                        disabled={disabledLink}
                      />
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip key={option.id} text={option.text}>
                    <IconButton
                      testId={option.id}
                      icon={option.icon}
                      href={option.to}
                    />
                  </Tooltip>
                );
              })}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
