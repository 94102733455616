import { useTranslation } from 'react-i18next';
import PageTitle from '@/components/common/PageTitle';
import Layout from '@/components/template/Layout';
import useAuth from '@/data/hook/useAuth';
import { useState } from 'react';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import 'moment-timezone';
import { PersonalInfo } from './AccountPersonal';
import { SystemInfo } from './AccountSystem';
import { AccountInfo } from './AccountPassword';
import HeadTitle from '@/components/common/HeadTitle';
import Text from '@/components/common/dataDisplay/Text';

export default function AccountPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountPage',
  });
  const { user } = useAuth();
  const tabOptions = ['personalInfo', 'accountInfo', 'systemInfo'];
  const [tab, setTab] = useState('personalInfo');

  return (
    <Layout>
      <HeadTitle routeInfo={t('accountTitle')} />
      <PageTitle
        headingText={t('accountTitle')}
        subheadingText={t('accountSubheading')}
      />
      <div role="tablist" className="tabs tabs-lifted w-fit">
        {tabOptions.map(option => {
          return (
            <div
              role="tab"
              key={option}
              onClick={() => setTab(option)}
              className={`tab text-primary ${
                tab === option
                  ? 'tab-active before:border-primary-content after:border-primary-content bg-base-100 border-primary-content font-500'
                  : 'bg-primary-content border-b-primary-content'
              }`}
            >
              <Text text={t(option)} className="text-ellipsis" />
            </div>
          );
        })}
      </div>
      <div className="bg-base-100 flex flex-col gap-3 p-4 border border-primary-content xs:rounded-tr-2xl rounded-b-2xl shadow-default w-full md:w-[700px] lg:w-[822px] md:min-w-fit">
        <ConditionalRenderer condition={tab === 'personalInfo'}>
          <PersonalInfo user={user} />
        </ConditionalRenderer>
        <ConditionalRenderer condition={tab === 'accountInfo'}>
          <AccountInfo user={user} />
        </ConditionalRenderer>
        <ConditionalRenderer condition={tab === 'systemInfo'}>
          <SystemInfo user={user} />
        </ConditionalRenderer>
      </div>
    </Layout>
  );
}
