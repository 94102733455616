import {
  AcademicCapIcon,
  BookOpenIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { formatLessonName } from '@/functions/lessonsName';
import { ActivityProgressShortView } from '@/models/ActivityProgress';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ViewAvatar from '@/components/store/ViewAvatar';
import useAuth from '@/data/hook/useAuth';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ScheduledLesson from '@/models/ScheduledLesson';
import { useEffect, useState } from 'react';
import Klass from '@/models/Klass';
import { useQueries, useQuery } from '@tanstack/react-query';
import Skeleton from '@/components/common/Skeleton';
import ScheduledLessonReport, {
  PresenceEnum,
} from '@/models/ScheduledLessonReport';
import { ApiError } from '@/models/Errors';
import { Link } from 'react-router-dom';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import {
  coursePathsQueryKeys,
  scheduledLessonReportsQueryKeys,
  scheduledLessonsQueryKeys,
  shortActivitiesProgressesQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useListService from '@/data/hook/useListService';

interface DashboardProgressProps {
  klass: Klass;
}

export default function DashboardProgress({ klass }: DashboardProgressProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });
  const { user, isLoading } = useAuth();

  const {
    data: coursePath,
    isInitialLoading: isLoadingCoursePath,
    error: coursePathError,
  } = useQuery({
    ...coursePathsQueryKeys.get(klass.coursePathSlug),
    enabled: !!klass,
  });

  const [courseError, setCourseError] = useState('');

  useEffect(() => {
    if (coursePathError) {
      const apiError = new ApiError(coursePathError as any);
      setCourseError(apiError.getErrorMessage());
    }
  }, [coursePathError]);

  const {
    results: scheduledLessons,
    isInitialLoading: isLoadingScheduledLessons,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({
      klassId: klass.id,
      isActive: true,
      pageSize: 50,
    }),
    enabled: !!coursePath,
  });

  if (isLoadingCoursePath)
    return (
      <Skeleton className="bg-primary-content rounded-2xl lg:rounded-lg h-[287px] lg:h-[178px]" />
    );
  else if (courseError)
    return (
      <Text text={courseError} format="rubik-500" className="text-primary" />
    );

  return (
    <div
      data-testid="dashboardProgress"
      className="flex flex-col md:flex-row justify-center items-stretch p-5 sm:p-8 md:p-6 
      gap-3.5 sm:gap-6 md:gap-3.5 rounded-lg sm:rounded-2xl bg-accent-content w-[100%] shadow-default"
    >
      <div className="flex flex-row min-w-fit justify-between gap-[5%] md:gap-3.5">
        <ConditionalRenderer condition={user}>
          {user && (
            <ViewAvatar
              userId={user.id}
              isLoading={isLoading}
              className="w-[47.5%] h-auto aspect-[4/2.5] sm:w-[40%] md:w-44 lg:w-52"
            />
          )}
        </ConditionalRenderer>

        <div
          className="flex flex-col justify-between items-center gap-2 sm:gap-3.5 w-[47.5%] md:w-40
    lg:w-52 shrink-0"
        >
          <div className="flex flex-row self-start items-center gap-2.5">
            <AcademicCapIcon
              className="shrink-0 w-5 md:w-6 h-auto text-base-100 bg-accent rounded-full p-0.5"
              strokeWidth={1.3}
            />
            <Text
              text={t('yourCourse')}
              format="rubik-500"
              className="text-accent grow"
              size="text-16 sm:text-18 md:text-20"
            />
          </div>

          <Text
            testId="courseName"
            text={coursePath?.course.name}
            format="rubik-700"
            size="text-16 sm:text-24"
            className="text-center flex flex-row items-center leading-none tracking-normal uppercase italic break-word text-accent"
          />

          <RoundedButton
            testId="accessClassroomButton"
            text={t('accessClasses')}
            className="w-[100%]"
            href="/courses"
          />
        </div>
      </div>
      <div className="divider md:divider-horizontal border-b md:border-b-0 md:border-r-0 border-secondary/40 before:bg-secondary/40 after:bg-secondary/40 m-0 lg:mx-2 h-0 md:h-40 md:w-0" />
      <div className="w-full flex flex-row justify-center items-start gap-[5%] md:gap-3.5">
        <div className="flex flex-col h-full items-center w-[47.5%] gap-6">
          <div className="flex flex-row self-start items-start md:items-center gap-2.5">
            <BookOpenIcon
              className="shrink-0 w-5 md:w-6 h-auto text-base-100 bg-accent rounded-full p-0.5"
              strokeWidth={1.5}
            />

            <Text
              text={t('totalClasses')}
              format="rubik-500"
              className="text-accent self-center"
              size="text-16 sm:text-18 md:text-20"
            />
          </div>

          <LessonList
            klassId={klass.id}
            scheduledLessons={scheduledLessons}
            isLoading={isLoadingScheduledLessons}
            coursePathSlug={klass.coursePathSlug}
          />
        </div>
        <div className="divider md:divider-horizontal border-b md:border-b-0 md:border-r-0 border-secondary/40 before:bg-secondary/40 after:bg-secondary/40 m-0 lg:mx-2 h-0 md:h-full md:w-0 " />
        <div className="flex flex-col h-full items-center w-[47.5%] gap-6">
          <div className="flex flex-row self-start items-start md:items-center gap-2.5 ">
            <PencilIcon
              className="shrink-0 w-5 md:w-6 h-auto text-base-100 bg-accent rounded-full p-0.5"
              strokeWidth={1.5}
            />
            <Text
              text={t('totalActivities')}
              format="rubik-500"
              className="text-accent self-center"
              size="text-16 sm:text-18 md:text-20"
            />
          </div>

          <ActivityList
            klassId={klass.id}
            scheduledLessons={scheduledLessons}
            isLoading={isLoadingScheduledLessons}
            coursePathSlug={klass.coursePathSlug}
          />
        </div>
      </div>
    </div>
  );
}

interface LessonListProps {
  klassId: number;
  scheduledLessons: ScheduledLesson[];
  isLoading: boolean;
  coursePathSlug: string;
}

function LessonList({
  klassId,
  scheduledLessons,
  isLoading,
  coursePathSlug,
}: LessonListProps) {
  const scheduledLessonReportFilters = { klass: klassId, isActive: true };
  const {
    results: scheduledLessonReports,
    isInitialLoading: isLoadingReport,
    error,
  } = useListService({
    ...scheduledLessonReportsQueryKeys.list({
      ...scheduledLessonReportFilters,
      pageSize: 50,
    }),
    enabled: !!klassId,
  });

  const [reportError, setReportError] = useState('');

  useEffect(() => {
    if (error) {
      const apiError = new ApiError(error as any);
      setReportError(apiError.getErrorMessage());
    }
  }, [error]);

  const presences = scheduledLessonReports.filter(
    report => report.presence === PresenceEnum.ATTENDED,
  ).length;

  const scheduledLessonsCount = scheduledLessons?.length;

  if (isLoadingReport || isLoading)
    return <Skeleton className="w-full h-full bg-primary-content rounded-lg" />;

  if (reportError) {
    return (
      <Text
        testId="scheduledLessonsError"
        text={reportError}
        format="rubik-500"
        className="text-primary"
      />
    );
  }

  return (
    <ConditionalRenderer condition={!!scheduledLessons}>
      <div className="flex flex-col items-start gap-2 w-[100%]">
        <div className="flex self-end items-end">
          <Text
            text={presences}
            format="rubik-500"
            size="text-16 md:text-24"
            className="flex text-secondary items-center"
          />

          <Text
            testId="lessonsCount"
            text={'/' + scheduledLessonsCount}
            format="rubik-500"
            size="text-12 md:text-16"
            className="flex text-accent items-center"
          />
        </div>
        <ul className="flex flex-row flex-wrap gap-1 items-start">
          {scheduledLessons.map(scheduledLesson => {
            const report = scheduledLessonReports.find(
              report => report.scheduledLesson === scheduledLesson.id,
            );
            return (
              <LessonItem
                key={scheduledLesson.id}
                scheduledLesson={scheduledLesson}
                scheduledLessonReport={report}
                coursePathSlug={coursePathSlug}
              />
            );
          })}
        </ul>
      </div>
    </ConditionalRenderer>
  );
}

function LessonItem({
  scheduledLesson,
  scheduledLessonReport,
  coursePathSlug,
}: {
  scheduledLesson: ScheduledLesson;
  scheduledLessonReport?: ScheduledLessonReport;
  coursePathSlug: string;
}) {
  const isActive = scheduledLesson.isActive;

  const presenceStatus = scheduledLessonReport?.presence;

  const lessonName = formatLessonName(scheduledLesson.lesson, scheduledLesson);

  const presence = {
    ATTENDED: 'bg-accent',
    MISSED: 'bg-error',
    RESCHEDULED: 'bg-warning',
    NONE: 'bg-accent/40',
  };

  const classroomUrl = `/courses/${coursePathSlug}/lesson/${scheduledLesson.lesson.id}/class/${scheduledLesson.klass}`;

  return (
    <ConditionalRenderer condition={isActive}>
      <li>
        <Tooltip text={lessonName} color="accent">
          <Link to={classroomUrl} data-testid="lessonItem">
            <span
              className={`flex w-3 h-3 sm:w-5 sm:h-5 hover:opacity-25 transition duration-200 ease-in-out ${
                presence[presenceStatus ?? 'NONE']
              }`}
            />
          </Link>
        </Tooltip>
      </li>
    </ConditionalRenderer>
  );
}

interface ActivityListProps {
  scheduledLessons: ScheduledLesson[];
  isLoading: boolean;
  coursePathSlug: string;
  klassId: number;
}

function ActivityList({
  scheduledLessons,
  isLoading,
  coursePathSlug,
  klassId,
}: ActivityListProps) {
  const activitiesId = scheduledLessons.flatMap(
    scheduledLesson => scheduledLesson.lesson.activities,
  );

  const activitiesRequest = useQueries({
    queries: activitiesId.map(activityId => ({
      ...shortActivitiesProgressesQueryKeys.list({
        activityId,
        isActive: true,
      }),
      enabled: !!scheduledLessons.length && !!activitiesId.length,
    })),
  });

  const activitiesCount = activitiesRequest?.length;

  const hasDoneActivities = activitiesRequest.filter(
    ({ data }) => data?.results.at(0)?.hasDone,
  ).length;

  if (isLoading) {
    return <Skeleton className="w-full h-full bg-primary-content rounded-lg" />;
  }
  return (
    <div className="flex flex-col gap-2 w-[100%]">
      <div className="flex self-end items-end">
        <Text
          text={hasDoneActivities}
          format="rubik-500"
          size="text-16 md:text-24"
          className="flex text-secondary items-center"
        />

        <Text
          testId="activitiesCount"
          text={'/' + activitiesCount}
          format="rubik-500"
          size="text-12 md:text-16"
          className="flex text-accent items-center"
        />
      </div>
      <ul
        className={`flex flex-wrap self-start gap-1 items-start ${scrollBarClassName}`}
      >
        {activitiesRequest?.map(({ data, isInitialLoading }, index) => {
          return (
            <ActivityProgressItem
              key={index}
              activityProgress={data?.results.at(0)}
              isLoading={isInitialLoading}
              coursePathSlug={coursePathSlug}
              klassId={klassId}
            />
          );
        })}
      </ul>
    </div>
  );
}

interface ActivityProgressItemProps {
  activityProgress?: ActivityProgressShortView;
  isLoading: boolean;
  coursePathSlug: string;
  klassId: number;
}

function ActivityProgressItem({
  activityProgress,
  isLoading,
  coursePathSlug,
  klassId,
}: ActivityProgressItemProps) {
  const hasDone = activityProgress?.hasDone;

  const classroomUrl = `/courses/${coursePathSlug}/lesson/${activityProgress?.lessonId}/class/${klassId}/activities/${activityProgress?.id}`;

  if (isLoading)
    return (
      <Skeleton className="flex w-2.5 h-2.5 sm:w-3.5 sm:h-3.5 bg-primary-content" />
    );
  return (
    <ConditionalRenderer condition={!!activityProgress}>
      <li>
        <Tooltip text={activityProgress?.activityName} color="accent">
          <Link to={classroomUrl} data-testid="activityItem">
            <span
              className={`flex w-2.5 h-2.5 sm:w-3.5 sm:h-3.5 hover:opacity-50 transition duration-200 ease-in-out ${
                hasDone ? 'bg-accent' : 'bg-accent/40'
              } `}
            />
          </Link>
        </Tooltip>
      </li>
    </ConditionalRenderer>
  );
}
