import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { FilterType } from '@/models/FilterType';
import { XIcon } from '@heroicons/react/outline';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import Tag from '../dataDisplay/Tag';
import useFilterParams from '@/utils/UseFilterParams';
import ConditionalRenderer from '../ConditionalRenderer';
import IconButton from '../buttons/IconButton';
import useTranslateFilter from '@/utils/translateFilter';
import { unitsQueryKeys } from '@/data/services/querykeys';

export type QuickFilterProps = {
  filters?: string[];
  type: QuickFilterType;
};

type QuickFilterType = FilterType;

export default function QuickFilter({ filters = [], type }: QuickFilterProps) {
  const { translateItem } = useTranslateFilter();

  const unitsQueries = useQueries({
    queries: filters?.map(filter => ({
      ...unitsQueryKeys.get(Number(filter)),
      keepPreviousData: true,
      enabled: !!filter && (type === 'unitId' || type === 'unit'),
      staleTime: REQUEST_STALE_TIME_IN_MS,
      placeholderData: {
        id: filter,
        name: '',
      },
    })),
  });

  const units = unitsQueries.map(
    ({ data, isPlaceholderData: isLoading, status }) => {
      return {
        unit: data,
        loading: isLoading,
        status,
      };
    },
  );

  type QuickFilterItem = {
    value?: string;
    id?: string;
    loading?: boolean;
    status?: string;
  };

  const list = useMemo(() => {
    switch (type) {
      case 'unitId':
      case 'unit':
        const unitItems: QuickFilterItem[] = units.map(
          ({ unit, loading, status }) => {
            return {
              value: unit?.name,
              id: String(unit?.id),
              loading,
              status,
            };
          },
        );
        return unitItems;
      case 'klassStatus':
        const klassStatusItems: QuickFilterItem[] = filters.map(item => {
          return {
            value: translateItem({ type, item }),
            id: item,
          };
        });
        return klassStatusItems;
      case 'userStatus':
        const userStatusItems: QuickFilterItem[] = filters.map(item => {
          return {
            value: translateItem({ type, item }),
            id: item,
          };
        });
        return userStatusItems;
      case 'userType':
        const userTypeItems: QuickFilterItem[] = filters.map(item => {
          return {
            value: translateItem({ type, item }),
            id: item,
          };
        });
        return userTypeItems;
      case 'courseType':
        const courseTypeItems: QuickFilterItem[] = filters.map(item => {
          return {
            value: translateItem({ type, item }),
            id: item,
          };
        });
        return courseTypeItems;
      case 'enrollmentStatus':
        const enrollmentItems: QuickFilterItem[] = filters.map(item => {
          return {
            value: translateItem({ type, item }),
            id: item,
          };
        });
        return enrollmentItems;
      default:
        return [];
    }
  }, [filters, translateItem, type, units]);

  const { setFilterParams, deleteFilterValue, deleteFilterParams } =
    useFilterParams({});

  const filterType =
    type === 'klassStatus' || type === 'userStatus' ? 'status' : type;

  return (
    <ConditionalRenderer condition={list.length}>
      <li className="flex items-center w-fit">
        <ul className="flex flex-wrap items-center gap-1 justify-end">
          {list.map(item => (
            <Tag
              loading={item?.loading}
              key={item?.id}
              className="rounded-full px-3 py-1 text-14 font-500 gap-2 flex-row-reverse truncate"
              text={item.value ?? ''}
              icon={<XIcon className="w-4 cursor-pointer" />}
              onClick={() => {
                deleteFilterValue(filterType, item.id);
                setFilterParams({ pageNumber: 1 });
              }}
            />
          ))}
        </ul>
        <ConditionalRenderer condition={list.length > 1}>
          <IconButton
            className="px-2"
            icon={<XIcon className="w-4 cursor-pointer text-error" />}
            onClick={() => deleteFilterParams(filterType)}
          />
        </ConditionalRenderer>
      </li>
    </ConditionalRenderer>
  );
}
