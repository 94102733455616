import Text from '../../common/dataDisplay/Text';
import { HtmlPreview } from '../../common/dataDisplay/HtmlPreview';
import { useTranslation } from 'react-i18next';
import AvatarCharacter from '@/components/store/AvatarCharacter';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Tag from '@/components/common/dataDisplay/Tag';
import { useQuery } from '@tanstack/react-query';
import { avatarsQueryKeys, usersQueryKeys } from '@/data/services/querykeys';

interface HomeworkContentProps {
  content?: string;
  teacherId?: number;
}

function HomeworkContent({ content, teacherId }: HomeworkContentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });
  const options = {
    enabled: !!teacherId,
  };

  const { data: avatar, isInitialLoading: loadingAvatar } = useQuery({
    ...avatarsQueryKeys.get(teacherId ?? 0),
    ...options,
  });

  const { data: teacher, isInitialLoading: loadingTeacher } = useQuery({
    ...usersQueryKeys.get(teacherId ?? 0),
    ...options,
  });

  if (!content) {
    return <Text text={`${t('noHomework')} (°ロ°)☝`} />;
  }

  return (
    <div className="flex flex-row w-full gap-4">
      <div className="flex flex-col items-center gap-1 w-40">
        <AvatarCharacter userAvatar={avatar} isLoading={loadingAvatar} />
        <Tag
          text={handleUserFullName(teacher)}
          color="primary"
          className="text-12 rounded-full"
          loading={loadingTeacher}
        />
      </div>
      <div className="flex flex-row gap-0 w-full">
        <div className="border border-primary border-r-0 border-b-0 -rotate-45 w-3 h-3 bg-base-100 mt-4 -mr-1.5" />
        <div className="border border-primary bg-base-100 rounded-lg flex p-3 w-full">
          <HtmlPreview
            format="book"
            html={content}
            className="flex break-all"
          />
        </div>
      </div>
    </div>
  );
}

export default HomeworkContent;
