import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Text from '@/components/common/dataDisplay/Text';
import Modal from '../Modal';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import ScheduledLessonReport, {
  GradeEnum,
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import { StudentHomeworkItem } from './StudentHomeworkItem';
import { HomeworkGrade } from '@/models/HomeworkGrade';
import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import { updateMgActivityProgress } from '@/data/services/mgActivityProgressServices';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import { homeworkProgressQueryKeys } from '@/data/services/querykeys';
import Enrollment from '@/models/Enrollment';

interface ModalHomeworkProps {
  isVisible: boolean;
  onClickCancel: (e?: any) => void;
  lessonName?: string;
  previousScheduledLessonReports?: ScheduledLessonReport[];
  homeworkProgresses?: HomeworkActivityProgress[];
  enrollments: Enrollment[];
}

export const ModalHomework = ({
  isVisible,
  onClickCancel,
  lessonName,
  enrollments,
  previousScheduledLessonReports,
  homeworkProgresses,
}: ModalHomeworkProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalHomework',
  });

  const { t: tGrade } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport.homeworkGradeMarker',
  });

  const queryClient = useQueryClient();

  const [grades, setGrades] = useState<HomeworkGrade[]>([]);

  const [noModify, setNoModify] = useState<boolean>(true);

  const saveHomeworkGrades = async (homeworkGrades: HomeworkGrade[]) => {
    for (const homeworkGrade of homeworkGrades) {
      const { grade, mgActivityProgressId, hasDone } = homeworkGrade;
      await updateMgActivityProgress(mgActivityProgressId, {
        grade,
        hasDone,
      });
    }
  };

  const { mutate: save, isLoading: isSavingHomeworkGrades } = useMutation(
    saveHomeworkGrades,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(homeworkProgressQueryKeys.list._def);
        onClickCancel();
        alert.success(tGrade('saved'));
      },
      onError: (error: any) => {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const defineHomeworkGrade = (
    mgActivityProgressId: number,
    grade: GradeEnum | null,
    hasDone: boolean,
  ) => {
    const auxGrades = grades;
    const findIndex = auxGrades.findIndex(
      grade => grade.mgActivityProgressId === mgActivityProgressId,
    );

    if (findIndex !== -1) {
      auxGrades.splice(findIndex, 1, { mgActivityProgressId, grade, hasDone });
    } else {
      auxGrades.push({ mgActivityProgressId, grade, hasDone });
    }

    setGrades(auxGrades);
    setNoModify(false);
  };

  useEffect(() => {
    if (!isVisible) {
      setGrades([]);
      setNoModify(true);
    }
  }, [isVisible]);

  return (
    <Modal testId="modalHomework" visible={isVisible} onClose={onClickCancel}>
      <div className="flex flex-col gap-6">
        <Text
          testId="lessonName"
          text={lessonName}
          size="text-20"
          className="text-primary"
          format="rubik-500"
        />
        <Text text={t('addHomeworkAssessment')} size="text-16" />
        {isVisible && (
          <div className="flex flex-col gap-4">
            {enrollments.map(({ student }, i) => {
              const findReport = previousScheduledLessonReports?.find(
                studentReport => studentReport.student === student.id,
              );

              const shouldRender =
                findReport &&
                [
                  ScheduledLessonReportEnum.ACTIVE,
                  ScheduledLessonReportEnum.TRANSFERED,
                ].includes(findReport.status);
              const homeworkProgress = homeworkProgresses?.[i];

              if (shouldRender && homeworkProgress) {
                return (
                  <StudentHomeworkItem
                    homeworkProgress={homeworkProgress}
                    key={student.id}
                    student={student}
                    defineHomeworkGrade={defineHomeworkGrade}
                  />
                );
              } else {
                return <React.Fragment key={student.id} />;
              }
            })}
          </div>
        )}

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            testId="modalCancelButton"
            color="neutral"
            className="w-full"
            text={t('cancelButton')}
            onClick={onClickCancel}
          />
          <RoundedButton
            disabled={noModify}
            testId="modalConfirmButton"
            loading={isSavingHomeworkGrades}
            text={t('confirmButton')}
            className="w-full"
            onClick={() => save(grades)}
          />
        </div>
      </div>
    </Modal>
  );
};
