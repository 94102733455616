import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/common/dataDisplay/Text';
import Klass from '../../../models/Klass';
import LessonProgress from '../../../models/LessonProgress';
import ScheduledLesson from '../../../models/ScheduledLesson';
import { StudentCalendarList } from './StudentCalendarList';

interface StudentCalendarProgressViewProps {
  scheduledLessonsData?: null | ScheduledLesson[];
  lessonsProgressData: null | LessonProgress[];
  activeKlass: null | Klass;
}

export const StudentCalendarProgressView = ({
  scheduledLessonsData,
  lessonsProgressData,
  activeKlass,
}: StudentCalendarProgressViewProps) => {
  const [pastLessons, setPastLessons] = useState<null | ScheduledLesson[]>(
    null,
  );

  const [nextLessons, setNextLessons] = useState<null | ScheduledLesson[]>(
    null,
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPage',
  });

  useEffect(() => {
    if (!nextLessons && scheduledLessonsData) {
      setNextLessons(
        scheduledLessonsData.filter(({ datetime }) =>
          moment(datetime).isAfter(new Date()),
        ),
      );
    }
  }, [lessonsProgressData, nextLessons, scheduledLessonsData]);

  useEffect(() => {
    if (!pastLessons && scheduledLessonsData) {
      setPastLessons(
        scheduledLessonsData.filter(({ datetime }) =>
          moment(datetime).isBefore(new Date()),
        ),
      );
    }
  }, [lessonsProgressData, pastLessons, scheduledLessonsData]);

  return (
    <div className="flex flex-col gap-4 w-fit">
      <div className="flex flex-col gap-4">
        <Text
          text={t('nextClasses').toUpperCase()}
          format="rubik-500"
          className="text-primary text-18"
        />
        <StudentCalendarList
          klass={activeKlass}
          scheduledLessons={nextLessons}
          lessonsProgress={lessonsProgressData}
        />
      </div>

      <div className="flex flex-col gap-4">
        <Text
          text={t('pastClasses').toUpperCase()}
          format="rubik-500"
          className="text-primary text-18"
        />
        <StudentCalendarList
          klass={activeKlass}
          scheduledLessons={pastLessons}
          lessonsProgress={lessonsProgressData}
        />
      </div>
    </div>
  );
};
