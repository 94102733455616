import { GroundToken, InteractiveToken, Token } from '@ctrlplayteam/astro-code';

import gema from '@/assets/map/default/coin.png';
import dir from '@/assets/map/default/dir.png';
import enemy from '@/assets/map/default/enemy.png';
import grass from '@/assets/map/default/grass.png';
import lava from '@/assets/map/default/lava.png';
import limit from '@/assets/map/default/sky.jpg';
import stone from '@/assets/map/default/target.png';
import Sprite from '@ctrlplayteam/astro-code/dist/types/map/sprite';
import { useTranslation } from 'react-i18next';

export function useGameTokens() {
  const [tNames] = useTranslation('translation', {
    keyPrefix: 'game.tokens.names',
  });
  const [tDescribes] = useTranslation('translation', {
    keyPrefix: 'game.tokens.describes',
  });

  const sprites: Record<Token, Sprite> = {
    [GroundToken.DANGER]: lava,
    [GroundToken.REGULAR]: grass,
    [GroundToken.FINAL]: stone,
    [GroundToken.LIMIT]: limit,
    [InteractiveToken.PLAYER]:
      'https://ctrlplayteam.github.io/scratch-gui-astro/static/assets/c4fc02a1c15975c80e3f9efa6a1e20c0.png',
    [InteractiveToken.COLLECTIBLE]: gema,
    [InteractiveToken.ENEMY]: enemy,
    [InteractiveToken.DIRECTION]: dir,
  };

  const names: Record<Token, string> = {
    [GroundToken.DANGER]: tNames(GroundToken.DANGER),
    [GroundToken.REGULAR]: tNames(GroundToken.REGULAR),
    [GroundToken.FINAL]: tNames(GroundToken.FINAL),
    [GroundToken.LIMIT]: tNames(GroundToken.LIMIT),
    [InteractiveToken.PLAYER]: tNames(InteractiveToken.PLAYER),
    [InteractiveToken.COLLECTIBLE]: tNames(InteractiveToken.COLLECTIBLE),
    [InteractiveToken.ENEMY]: tNames(InteractiveToken.ENEMY),
    [InteractiveToken.DIRECTION]: tNames(InteractiveToken.DIRECTION),
  };

  const describes: Record<Token, string> = {
    [GroundToken.DANGER]: tDescribes(GroundToken.DANGER),
    [GroundToken.REGULAR]: tDescribes(GroundToken.REGULAR),
    [GroundToken.FINAL]: tDescribes(GroundToken.FINAL),
    [GroundToken.LIMIT]: tDescribes(GroundToken.LIMIT),
    [InteractiveToken.PLAYER]: tDescribes(InteractiveToken.PLAYER),
    [InteractiveToken.COLLECTIBLE]: tDescribes(InteractiveToken.COLLECTIBLE),
    [InteractiveToken.ENEMY]: tDescribes(InteractiveToken.ENEMY),
    [InteractiveToken.DIRECTION]: tDescribes(InteractiveToken.DIRECTION),
  };

  return { sprites, names, describes };
}
