import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import StudentCourseProgress, {
  SimplifiedCourseProgress,
} from '@/models/StudentCourseProgress';
import { BaseFilters } from '@/models/Service';
import { Course, CourseTypeEnum } from '@/models/Course';

export type CourseProgressFilters = BaseFilters & {
  klassId?: number;
  coursePath?: number;
  isActive?: boolean;
  student?: number;
  courseType?: CourseTypeEnum[];
};

export const listSimplifiedCourseProgressByUserId = async (
  studentId: number,
  params: CourseProgressFilters = {},
) => {
  const { data } = await requester().get<
    JsonApiResponse<SimplifiedCourseProgress>
  >(`students/${studentId}/progress/`, {
    params,
  });
  return data;
};

export const listCourseProgresses = async (
  params: CourseProgressFilters = {},
) => {
  const { data } = await requester().get<
    JsonApiResponse<StudentCourseProgress>
  >(`course-progress/`, {
    params,
  });
  return data;
};

export async function listNextSteps(courseProgressId: number) {
  const { data } = await requester().get<JsonApiResponse<Course>>(
    `course-progress/${courseProgressId}/next-steps/`,
  );

  return data;
}
