import { Fragment, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LoadingView from '@/pages/courses/LoadingView';
import { ElementPreviewFactoryProps } from './ElementPreviewFactory';
import ActivityHeading from './ActivityHeading';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import { updateTextActivityProgress } from '@/data/services/activityElement/textActivityProgressServices';
import ErrorComponent from '@/components/common/ErrorComponent';
import useActivityView from '@/data/hook/useActivityView';
import alert from '@/utils/UseAlert';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import { TextActivityProgress } from '@/models/ActivityProgress';
import { TextActivityElement } from '@/models/ActivityElement';
import { UpdateQuestionAttempt } from '@/models/QuestionAttempt';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  textActivitiesQueryKeys,
  textActivityProgressesQueryKeys,
} from '@/data/services/querykeys';

export default function TextElement(props: ElementPreviewFactoryProps) {
  const { user } = useAuth();

  const { questionId, progressId, activityMode } = props;

  const { blockStepNavigate, invalidate, status } = useActivityView();

  const studentView = isStudent(user?.userType);

  const queryClient = useQueryClient();

  const { queryKey: progressKey, queryFn: textActivityProgressFn } =
    textActivityProgressesQueryKeys.get({
      textActivityId: questionId,
      textProgressId: progressId,
    });

  const {
    data: questionProgress,
    isInitialLoading: loadingQuestionProgress,
    error: questionProgressError,
  } = useQuery({
    queryKey: progressKey,
    queryFn: textActivityProgressFn,
    enabled:
      studentView && !!progressId && !!questionId && status === 'inProgress',
  });

  const questionProgressErrorDetail = getErrorMessage(questionProgressError);

  const {
    data: question,
    isInitialLoading: loadingQuestion,
    error: questionError,
  } = useQuery({
    ...textActivitiesQueryKeys.get(questionId),
    enabled: !studentView && !!questionId,
  });

  const questionErrorDetail = getErrorMessage(questionError);

  const onTimerSuccess = async ({
    questionId,
    questionProgressId,
  }: UpdateQuestionAttempt) => {
    blockStepNavigate(true);
    const updated = await updateTextActivityProgress(
      {
        textActivityId: questionId,
        textProgressId: questionProgressId,
      },
      { isDone: true },
    );

    return updated;
  };

  const { mutateAsync: update } = useMutation(onTimerSuccess, {
    async onSuccess() {
      await queryClient.invalidateQueries(progressKey);

      await invalidate();
    },
    onSettled() {
      blockStepNavigate(false);
    },
    onError(error: any) {
      alert.error(getErrorMessage(error));
    },
  });

  const loading = loadingQuestion || loadingQuestionProgress;

  const errorDetail = questionErrorDetail || questionProgressErrorDetail;

  if (loading) {
    return <LoadingView />;
  }

  if (errorDetail) {
    return <ErrorComponent errorTextTitle={errorDetail} />;
  }

  if (questionProgress) {
    return (
      <Element
        questionProgress={questionProgress}
        activityMode={activityMode}
        update={update}
      />
    );
  }

  if (question) {
    return <Element question={question} activityMode={activityMode} />;
  }

  return null;
}

type ElementProps = Pick<ElementPreviewFactoryProps, 'activityMode'> & {
  questionProgress?: TextActivityProgress;
  question?: TextActivityElement;
  update?: ({
    questionId,
    questionProgressId,
  }: UpdateQuestionAttempt) => Promise<TextActivityProgress>;
  attemptElement?: any;
};

function Element({
  questionProgress,
  question,
  activityMode,
  update,
}: ElementProps) {
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (questionProgress && activityMode === 'activity') {
      timer = setTimeout(() => {
        update?.({
          questionId: questionProgress.textActivity.id,
          questionProgressId: questionProgress.id,
        });
      }, 5 * 1000);
    }
    return () => clearTimeout(timer);
  }, [activityMode, questionProgress, update]);

  if (questionProgress) {
    const { textActivity: question } = questionProgress;

    return <Content question={question} />;
  }

  if (question) {
    return <Content question={question} />;
  }

  return <Fragment />;
}

type ContentProps = {
  question: TextActivityElement;
};

function Content({ question }: ContentProps) {
  return (
    <div className="flex flex-col gap-4">
      <ActivityHeading
        heading={question.title}
        subheading={question.subtitle}
      />
      <HtmlPreview format="book" html={question.content} />
    </div>
  );
}
