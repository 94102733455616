import { useState } from 'react';

export default function useToggle(startOpen = false) {
  const [isOpen, setIsOpen] = useState(startOpen);

  const toggle = () => {
    setIsOpen(prev => !prev);
  };
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return {
    isOpen,
    toggle,
    open,
    close,
  };
}
