import { slideHorizontal } from '@/utils/animations/commom';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import ConditionalRenderer from '../ConditionalRenderer';
import { BaseInputProps } from './BaseInput';

export interface NumberInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    Omit<BaseInputProps, 'labelPosition' | 'register'> {
  color?: 'primary' | 'warning';
  errorLabelText?: string;
  centerText?: boolean;
  labelPosition?: 'left' | 'right';
  onBlur?: () => void;
  showDecButton?: boolean;
  showIncButton?: boolean;
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const {
      label,
      className,
      showDecButton,
      showIncButton,
      icon,
      testId,
      color,
      errorLabelText,
      fontWeight,
      bgColor,
      fontSize,
      isLoading,
      labelPosition = 'top',
      disabled,
      prefix,
      ...rest
    } = props;

    const [mouseOver, setMouseOver] = useState(false);

    const dispatchChangeEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
      const manualEvent = new Event('change', { bubbles: true });
      e.currentTarget.parentNode
        ?.querySelector('input')
        ?.dispatchEvent(manualEvent);
    };

    const onClickStepDown = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.parentNode?.querySelector('input')?.stepDown();
      dispatchChangeEvent(e);
    };

    const onClickStepUp = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.parentNode?.querySelector('input')?.stepUp();
      dispatchChangeEvent(e);
    };

    return (
      <div
        className="relative form-control flex flex-row gap-1.5"
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => {
          setMouseOver(false);
          rest.onBlur?.();
        }}
        onBlur={rest.onBlur}
      >
        <ConditionalRenderer condition={labelPosition === 'left'}>
          <label htmlFor={rest.id} className="label leading-none text-14">
            {label}
          </label>
        </ConditionalRenderer>
        <div className="relative flex justify-center">
          <ConditionalRenderer condition={showDecButton}>
            <AnimatePresence>
              {mouseOver && (
                <motion.button
                  {...slideHorizontal(20)}
                  type="button"
                  transition={{ duration: 0.1 }}
                  className="absolute min-h-full bg-primary rounded-r-none rounded-l-md text-base-100 -left-5 w-7 p-1"
                  onClick={onClickStepDown}
                >
                  <MinusIcon className="h-3 w-3" />
                </motion.button>
              )}
            </AnimatePresence>
          </ConditionalRenderer>
          <input
            {...rest}
            ref={ref}
            disabled={disabled}
            data-testid={testId}
            type="number"
            className={twMerge(
              `w-full z-10 disabled:text-base-content focus-visible:ring focus:outline-none text-center font-500 text-16  placeholder:font-400 input input-bordered disabled:cursor-default  input-primary focus-visible:ring-primary-content disabled:bg-base-100 disabled:border-none input-sm p-0 `,
              fontWeight,
              fontSize,
              bgColor,
              className,
            )}
          />
          <ConditionalRenderer condition={showIncButton}>
            <AnimatePresence>
              {mouseOver && (
                <motion.button
                  {...slideHorizontal(-20)}
                  type="button"
                  transition={{ duration: 0.1 }}
                  className="absolute min-h-full bg-primary rounded-l-none rounded-r-md text-base-100 -right-5 w-7 p-1 flex justify-end items-center"
                  onClick={onClickStepUp}
                >
                  <PlusIcon className="h-3 w-3" />
                </motion.button>
              )}
            </AnimatePresence>
          </ConditionalRenderer>
        </div>

        <ConditionalRenderer condition={labelPosition === 'right'}>
          <label htmlFor={rest.id} className="label leading-none text-14">
            {label}
          </label>
        </ConditionalRenderer>
      </div>
    );
  },
);

export default NumberInput;
