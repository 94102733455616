import requester from '@/axios';
import Modal from '@/components/common/modals/Modal';
import useAuth from '@/data/hook/useAuth';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import JsonApiResponse from '@/models/JsonApiResponse';
import ScheduledDay from '@/models/ScheduledDay';
import TrialEnrollment from '@/models/TrialEnrollment';
import LoadingView from '@/pages/courses/LoadingView';
import alert from '@/utils/UseAlert';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import TextInput from '@/components/common/dataInput/TextInput';
import TrialInfoCard from './TrialInfoCard';
import { CtrlPlayFullLogoColorIcon } from '@/components/icons';
import {
  AdditionalInformation,
  StudentAgeEnum,
  UserTypeEnum,
} from '@/models/User';
import Layout from '@/components/template/Layout';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DateInput from '@/components/common/dataInput/DateInput';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Skeleton from '@/components/common/Skeleton';
import Text from '@/components/common/dataDisplay/Text';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

interface Inputs {
  firstNameParent: string;
  lastNameParent: string;
  email: string;
  phoneNumber: string;
  fistNameChild: string;
  lastNameChild: string;
  dateOfBirth: string;
  cityAndState: string;
}

interface ChildInformations {
  firstName: string;
  lastName: string;
  userType: UserTypeEnum.STUDENT;
  dateOfBirth: Date;
  cityAndState: string;
}

export default function ConfirmInfoView() {
  const INFORMATION_CHILD = '@astro:informationChildTrial';

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { trialLessonId } = useParams();
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, control } = useForm<Inputs>();
  const storage = localStorage.getItem(INFORMATION_CHILD);
  const storageInformationChild: ChildInformations | null = storage
    ? JSON.parse(storage)
    : storage;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: dataUserProfile, isInitialLoading: isLoadingUserProfile } =
    useQuery(
      ['userProfile'],
      () =>
        requester()
          .get(`/userprofiles/${user?.id}`)
          .then(res => res.data),
      {
        enabled: !!user?.id,
      },
    );

  const {
    data: responseAdditionalInformation,
    isLoading: isLoadingAdditionalInformation,
    refetch,
  } = useQuery(
    ['additionalInformationParent'],
    () =>
      requester().get<AdditionalInformation>(
        '/users/parent/additional-information/',
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: trialData, isLoading: isLoadingTrialLesson } = useQuery(
    ['trialLessonId'],
    () =>
      requester()
        .get<ScheduledDay>(`trial-lesson/scheduled-day/${trialLessonId}/`)
        .then(({ data }) => {
          return data;
        }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const fetchEmailActivation = useCallback(async () => {
    await requester().post('users/parent-email-activation/', {
      token: searchParams.get('token'),
      uid: searchParams.get('uid'),
    });

    queryClient.invalidateQueries(['additionalInformationParent']);
  }, [queryClient, searchParams]);

  const onSubmit: SubmitHandler<Inputs> = async data => {
    try {
      const { data: dataAdditionalInformation } = await refetch();
      const isActiveEmail = dataAdditionalInformation?.data.isActiveEmail;
      setIsLoading(true);
      const courseType = await requester()
        .get<JsonApiResponse<CoursePath>>(
          `courses/?course_type=${searchParams.get('course_type')}&status=${
            VersioningStatusEnum.PUBLISHED
          }`,
        )
        .then(res =>
          res.data.results.find(
            ({ course }) =>
              course.coursePathStatus === VersioningStatusEnum.PUBLISHED,
          ),
        );

      await Promise.all([
        requester().patch(`users/${user?.id}/`, {
          firstName: data.firstNameParent,
          lastName: data.lastNameParent,
          email: data.email,
        }),
        requester().patch(`userprofiles/${user?.id}/`, {
          phone_number: data.phoneNumber,
        }),
      ]);

      if (!isActiveEmail) {
        localStorage.setItem(
          INFORMATION_CHILD,
          JSON.stringify({
            firstName: data.fistNameChild,
            lastName: data.lastNameChild,
            userType: UserTypeEnum.STUDENT,
            dateOfBirth: data.dateOfBirth,
            cityAndState: data.cityAndState,
          }),
        );
        await requester()
          .post(`/users/${user?.id}/parent-email-confirm/`, {
            course_type: searchParams.get('course_type'),
            trial_lesson_id: Number(trialLessonId),
          })
          .catch(() => {
            alert.error('Verifique se o email que inseriu é válido.');
          });
        setModalIsVisible(true);
        return;
      }

      const [city, state] =
        responseAdditionalInformation!.data.location.split(', ');

      const createdStudent = await requester().post('users/', {
        firstName: data.fistNameChild,
        lastName: data.lastNameChild,
        userType: 'Student',
        unitsIds: user?.unitsIds ?? [1],
        profile: {
          city,
          state,
        },
      });

      await requester().post('relationships/', {
        student: createdStudent.data.id,
        parent: user?.id,
        relation: 'Father',
      });

      const studentId = createdStudent.data.id;

      const {
        data: { id },
      } = await requester().post<TrialEnrollment>('trial-lesson/enrollment/', {
        student: studentId,
        course_id: courseType?.course.id,
        scheduled_day_id: trialData!.id,
      });

      localStorage.removeItem(INFORMATION_CHILD);
      navigate(`@/components/confirm/${id}/${trialData!.id}`, {
        relative: 'path',
      });
    } catch (error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get('token') && searchParams.get('uid')) {
      fetchEmailActivation();
    }
  }, [fetchEmailActivation, searchParams]);

  const DateInformation = () => {
    const dateTimeMoment = moment(new Date(trialData!.dateTime));
    const dateTimeFormat = dateTimeMoment.format('dddd, D [de] MMMM,');
    const hourStart = dateTimeMoment.format(`H[h] [às]`);
    const hoursEnd = dateTimeMoment.add(1, 'hour').format('H[h]');

    return (
      <React.Fragment>
        <Text text={dateTimeFormat} />
        <Text text={`${hourStart} ${hoursEnd}`} />
      </React.Fragment>
    );
  };

  return (
    <Layout>
      {isLoading && (
        <LoadingView classNameContent="fixed top-0 left-2/4 -translate-x-2/4 z-40 bg-base-100/60 w-full" />
      )}
      <div className="flex flex-col gap-8 items-center justify-center w-full min-h-screen self-center">
        <CtrlPlayFullLogoColorIcon className="w-44" />
        <div className="flex flex-col w-full max-w-4xl items-center gap-1.5">
          {modalIsVisible && (
            <Modal onClose={() => setModalIsVisible(false)} visible>
              <div
                data-testid="successModal"
                className="flex flex-col items-center gap-4"
              >
                <Text
                  text="Confirme seu endereço de email"
                  className="text-primary text-24 font-400"
                />
                <CheckCircleIcon className="w-20 h-w-20 text-success" />
                <Text
                  text="Enviamos um link de confirmação para o e-mail cadastrado, clique no link para prosseguir com seu agendamento."
                  className="text-center"
                />
              </div>
            </Modal>
          )}
          <Link to="/" className="self-end">
            <Text
              text="voltar"
              format="rubik-500"
              className="text-primary text-16 cursor-pointer"
            />
          </Link>
          <div className="flex w-full gap-5 flex-col items-center md:flex-row md:items-start">
            {isLoadingAdditionalInformation ||
            isLoadingTrialLesson ||
            isLoadingUserProfile ? (
              <React.Fragment>
                <Skeleton className="bg-primary-content w-full h-[544px] rounded-2xl" />
                <Skeleton className="bg-primary-content w-full max-w-[320px] h-[444px] rounded-2xl" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex w-full flex-col items-center gap-3.5 px-8 py-5 rounded-2xl shadow-default   bg-base-100"
                >
                  <Text
                    text="Confirme os Dados"
                    format="poppins-600"
                    className="text-32 text-primary"
                  />
                  <div className="flex items-end justify-between flex-row gap-x-3.5 w-full">
                    <TextInput
                      defaultValue={user?.firstName ?? ''}
                      label="Nome do responsável"
                      className="w-full"
                      register={register('firstNameParent')}
                      required
                    />
                    <TextInput
                      defaultValue={user?.lastName ?? ''}
                      label="Sobrenome do responsável"
                      className="w-full"
                      register={register('lastNameParent')}
                      required
                    />
                  </div>
                  <TextInput
                    defaultValue={user?.email ?? ''}
                    label="E-mail do responsável"
                    type="email"
                    className="w-full"
                    register={register('email')}
                    required
                  />
                  <TextInput
                    defaultValue={dataUserProfile?.phoneNumber ?? ''}
                    label="Celular do responsável"
                    type="phone"
                    className="w-full"
                    register={register('phoneNumber')}
                    required
                  />
                  <div className="flex items-end justify-between flex-row gap-x-3.5 w-full">
                    <TextInput
                      defaultValue={storageInformationChild?.firstName ?? ''}
                      label="Nome do(a) aluno(a)"
                      className="w-full"
                      register={register('fistNameChild')}
                      required
                    />
                    <TextInput
                      defaultValue={storageInformationChild?.lastName ?? ''}
                      label="Sobrenome do(a) aluno(a)"
                      className="w-full"
                      register={register('lastNameChild')}
                      required
                    />
                  </div>
                  <div className="flex items-start flex-col gap-x-3.5 w-full">
                    <label className="label p-0">
                      Nascimento do(a) aluno(a)
                    </label>

                    <Controller
                      control={control}
                      name={'dateOfBirth'}
                      render={({ field: { onChange, value } }) => (
                        <DateInput
                          labelPosition="left"
                          testId="startDate"
                          value={value}
                          onDateChange={date => onChange(date)}
                        />
                      )}
                    />
                  </div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="w-full md:w-[70%] px-6 py-2 rounded-md leading-none text-base-100 bg-gradient-to-r
                from-secondary to-primary enabled:hover:from-secondary/40 enabled:hover:to-primary/40
                disabled:from-secondary-content disabled:to-secondary-content focus-visible:ring-secondary/40"
                  >
                    Agendar Aula Experimental
                  </button>
                </form>
                <TrialInfoCard
                  studentAge={
                    responseAdditionalInformation?.data.studentAge ||
                    StudentAgeEnum.FOURTEEN_TO_SEVENTEEN
                  }
                  className="max-w-[320px] text-16"
                  header={
                    <Text
                      text="Pedido"
                      format="rubik-500"
                      className="text-center flex justify-center text-accent px-6 py-2.5 rounded-full bg-base-100 w-full"
                    />
                  }
                >
                  <div className="w-full flex flex-col gap-5">
                    <Text
                      text="Aula Experimental (60min)"
                      format="rubik-500"
                      className="text-center flex justify-center text-accent w-full"
                    />
                    <Text
                      text="A aula acontecerá dentro dessa plataforma. O aluno precisará de um computador ou notebook com microfone e câmera para fazer a aula."
                      className="flex"
                    />
                    <div className="w-full flex flex-col">
                      <DateInformation />
                      <button className="rounded-full flex self-end px-2.5 py-1 text-14 bg-accent/40 text-accent leading-none">
                        alterar
                      </button>
                    </div>
                  </div>
                </TrialInfoCard>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
