export const GuestPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <div
        className="flex flex-wrap items-center gap-6 justify-center 2xl:justify-start"
        id="newRegistredDashboard"
      >
        <img src="/images/DashboardAlternative1.svg" alt="" />
        <img src="/images/DashboardAlternative2.svg" alt="" />
        <img src="/images/DashboardAlternative3.svg" alt="" />
      </div>
    </div>
  );
};
