import { Draggable, DraggableProps } from '@hello-pangea/dnd';
import { ItemDND } from '@/models/DNDOrdenation';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { DragIcon } from '../../../../../icons';
import Text from '../../../../../common/dataDisplay/Text';
import TextInput from '../../../../../common/dataInput/TextInput';
import { CameraIcon, TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';
import isAceptableType, {
  bannerConfig,
} from '../../../../../../utils/VerifyImageFile';
import TooltipHandler from '../../../../../common/TooltipHandler';

interface DNDOrdenationItemEditProps
  extends Pick<DraggableProps, 'draggableId' | 'index'> {
  item: ItemDND;
  indexColumn: number;
  onChangeFieldsItem: ({
    item,
    keyItem,
    value,
    index,
  }: {
    item: ItemDND;
    keyItem: string;
    value: string | File;
    index: number;
  }) => void;
  handleDeleteItem: ({
    indexColumn,
    indexItem,
  }: {
    indexColumn: number;
    indexItem: number;
  }) => void;

  isBlockedDeleteItem: boolean;
}

export default function DNDOrdenationItemEdit(
  props: DNDOrdenationItemEditProps,
) {
  const { allowBigChanges } = useCourseEditing();
  const {
    item,
    indexColumn,
    onChangeFieldsItem,
    handleDeleteItem,
    isBlockedDeleteItem,
    ...rest
  } = props;
  const { index } = rest;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDNDOrdenation',
  });

  const { t: tVersioningRules } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const isBlockedAction = isBlockedDeleteItem || !allowBigChanges;

  const validateUpload = (file: File) =>
    !isAceptableType(bannerConfig.accept, file.type) &&
    !!URL.createObjectURL(file);

  const noContentError =
    item.errors && item.errors.some(value => value === 'noContent');

  const maxLengthError =
    item.errors && item.errors.some(value => value === 'maxLength');

  const itemError = noContentError || maxLengthError;

  return (
    <Draggable {...rest} isDragDisabled={!allowBigChanges}>
      {provided => (
        <div
          data-testid={'item' + index}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {item.errors &&
            item.errors.map(error => {
              const errors =
                error !== 'noAltText' && error !== 'maxLengthAltText';
              return (
                <ConditionalRenderer
                  condition={itemError && errors}
                  key={error}
                >
                  <label
                    data-testid={error + 'LabelText'}
                    className="font-rubik text-error error-label"
                  >
                    {t(error)}
                  </label>
                </ConditionalRenderer>
              );
            })}

          <div className="gap-3 flex flex-row items-center">
            <div
              {...provided.dragHandleProps}
              className={
                !allowBigChanges ? 'cursor-not-allowed' : 'cursor-pointer'
              }
            >
              <DragIcon data-testid={'dragIcon' + index} />
            </div>

            <Text testId={'orderItem' + index} text={index + 1} />

            <TextInput
              fontWeight="font-400"
              placeholder={t('text')}
              className="rounded-lg border-primary min-h-[9px] grow font-600 font-rubik"
              type="text"
              testId={'textInputItem' + index}
              required
              defaultValue={item.text || ''}
              onChange={({ target }) =>
                onChangeFieldsItem({
                  item,
                  index,
                  keyItem: 'text',
                  value: target.value,
                })
              }
            />

            <DNDItemImageEdit
              item={item}
              index={index}
              onChangeFieldsItem={onChangeFieldsItem}
            />

            <TooltipHandler
              tooltipMessage={tVersioningRules('cantModify')}
              renderTooltip={!allowBigChanges}
            >
              <label
                data-testid={'addImageItemButton' + index}
                className={`rounded-full p-1 relative
              ${
                allowBigChanges
                  ? 'bg-secondary-content cursor-pointer'
                  : 'bg-neutral-content cursor-not-allowed'
              }`}
              >
                <input
                  disabled={!allowBigChanges}
                  id="cameraInput"
                  accept="image/png, image/svg, image/jpg, image/jpeg"
                  type="file"
                  className="opacity-0 absolute top-0 bottom-0 right-0 left-0 -z-10"
                  onChange={({ target }) => {
                    if (target.files) {
                      const value = target.files.item(0);
                      if (value && !validateUpload(value)) {
                        onChangeFieldsItem({
                          keyItem: 'image',
                          value,
                          item,
                          index,
                        });
                      }
                    }
                  }}
                />
                <CameraIcon
                  className={`w-6 h-6 ${
                    allowBigChanges ? 'text-secondary' : 'text-neutral/50'
                  }`}
                />
              </label>
            </TooltipHandler>

            <TooltipHandler
              tooltipMessage={
                allowBigChanges
                  ? t('textTooltipRemoveItem')
                  : tVersioningRules('cantModify')
              }
              renderTooltip={isBlockedAction}
            >
              <button
                disabled={isBlockedAction}
                onClick={() =>
                  handleDeleteItem({ indexColumn, indexItem: index })
                }
                data-testid={'removeItemButton' + index}
                className={
                  isBlockedAction ? 'cursor-not-allowed' : 'cursor-pointer'
                }
              >
                <TrashIcon
                  className={`w-5 h-5 ${
                    isBlockedAction ? 'text-neutral/50' : 'text-error'
                  }`}
                />
              </button>
            </TooltipHandler>
          </div>
        </div>
      )}
    </Draggable>
  );
}

interface DNDItemImageEditProps {
  item: ItemDND;
  index: number;
  onChangeFieldsItem: ({
    item,
    keyItem,
    value,
    index,
  }: {
    item: ItemDND;
    keyItem: string;
    value: string;
    index: number;
  }) => void;
}

function DNDItemImageEdit(props: DNDItemImageEditProps) {
  const { item, index, onChangeFieldsItem } = props;

  const { image, altText } = item;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDNDOrdenation',
  });

  if (image) {
    const noAltTextImageError =
      item.errors && item.errors.some(value => value === 'noAltText');

    const maxLengthAltTextImageError =
      item.errors && item.errors.some(value => value === 'maxLengthAltText');

    const itemError = noAltTextImageError || maxLengthAltTextImageError;

    function handleImagePreview(image: string | File) {
      if (typeof image === 'string') return image;
      return URL.createObjectURL(image);
    }

    return (
      <div data-testid={'imageItemEdit' + index} className="flex w-[50%] gap-3">
        <div
          data-testid={'imageItem' + index}
          className="flex gap-5 items-center justify-center"
        >
          <img src={handleImagePreview(image)} alt="Preview" className="w-32" />
        </div>
        <div className="flex flex-col w-full justify-center">
          {item.errors &&
            item.errors.map(error => {
              const errors =
                error === 'noAltText' || error === 'maxLengthAltText';

              return (
                <ConditionalRenderer
                  condition={itemError && errors}
                  key={error}
                >
                  <label
                    data-testid={error + 'LabelText'}
                    className="font-rubik text-error error-label"
                  >
                    {t(error)}
                  </label>
                </ConditionalRenderer>
              );
            })}

          <TextInput
            color={itemError && !item.altText ? 'warning' : 'primary'}
            fontWeight="font-400"
            className="rounded-lg border-primary h-9 w-full font-600 font-rubik"
            type="text"
            testId={'altImageItem' + index}
            name="question"
            required
            defaultValue={altText ?? ''}
            placeholder={t('descriptionImage')}
            onChange={({ target }) =>
              onChangeFieldsItem({
                item,
                index,
                keyItem: 'altText',
                value: target.value,
              })
            }
          />
        </div>
      </div>
    );
  }

  return null;
}
