import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
} from 'axios';
import { camelize, decamelize, decamelizeKeys } from 'humps';
import qs from 'qs';

import {
  isRefreshTokenExpired,
  redirectToLogin,
  refreshAccessTokenWithRefreshToken,
} from './functions/auth';
import config from './config';
import {
  getTokenResponse,
  removeTokenResponse,
} from './functions/tokenSettings';
import i18n from './i18n';
import { RequestObserver } from './utils/observers/RequestObserver';
import { createRequestEvent } from './utils/requestEvent';
import transformKeys from './utils/transformKeys';
import { TRANSFORM_KEYS_PAYLOAD_IGNORE } from './constants';

export const camelizeResponseKeysFn = (data: any, _: any) => {
  try {
    const jsonData = JSON.parse(data);
    return transformKeys(jsonData, {
      transformFn: camelize,
      ignoreKeys: TRANSFORM_KEYS_PAYLOAD_IGNORE.map(decamelize),
    });
  } catch (e) {
    return data;
  }
};

export const decamelizeRequestKeysFn = (data: any, _: any) => {
  if (data instanceof FormData) return decamelizeFormData(data);
  else
    return JSON.stringify(
      transformKeys(data, {
        transformFn: decamelize,
        ignoreKeys: TRANSFORM_KEYS_PAYLOAD_IGNORE,
      }),
    );
};

function decamelizeFormData(formData: FormData) {
  var decamelizedFormData = new FormData();
  for (var [key, value] of formData.entries()) {
    decamelizedFormData.set(decamelize(key), value);
  }
  return decamelizedFormData;
}

export const addAuthHeaderInterceptor = (client: AxiosInstance) => {
  const tokenResponse = getTokenResponse();
  const access = tokenResponse?.access;

  client.interceptors.request.use(
    function (config) {
      config.headers = {
        Authorization: access ? 'Bearer ' + access : '',
        ...config.headers,
      };
      if (config.params?.pageSize) {
        config.params['page[size]'] = config.params.pageSize;
        delete config.params.pageSize;
      }
      if (config.params?.pageNumber) {
        config.params['page[number]'] = config.params.pageNumber;
        delete config.params.pageNumber;
      }
      config.params = decamelizeKeys(config.params);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};

export async function handleRefreshTokenInterceptorError(
  error: any,
  client: AxiosInstance,
  interceptor: any,
) {
  const errorResponse = error?.response;

  if (!errorResponse || errorResponse.status !== 401) {
    return Promise.reject(error);
  } else if (errorResponse.status === 401 && isRefreshTokenExpired()) {
    removeTokenResponse();
    redirectToLogin();
    return Promise.reject(error);
  }

  client.interceptors.response.eject(interceptor);

  try {
    const access_token = await refreshAccessTokenWithRefreshToken(client);
    errorResponse.config.headers['Authorization'] = `Bearer ${access_token}`;
    addRefreshTokenInterceptor(client);
    return client(errorResponse.config);
  } catch (err) {
    removeTokenResponse();
    redirectToLogin();
    return Promise.reject(err);
  }
}

export function addRefreshTokenInterceptor(client: AxiosInstance) {
  const interceptor: any = client.interceptors.response.use(
    response => response,
    error => handleRefreshTokenInterceptorError(error, client, interceptor),
  );
}

const requestObserver = new RequestObserver();

export const getRequestObserver = (): Omit<RequestObserver, 'notifyAll'> =>
  requestObserver;

export const requestObserverInterceptor = (
  response: AxiosResponse<any, any> | AxiosError,
  requestObserver: RequestObserver,
) => {
  requestObserver.notifyAll(createRequestEvent(response));
  if (response instanceof AxiosError) return Promise.reject(response);

  return response;
};

const addRequestObserversInterceptor = (client: AxiosInstance) => {
  const interceptor = (response: AxiosResponse<any, any>) =>
    requestObserverInterceptor(response, requestObserver);
  client.interceptors.response.use(interceptor, interceptor);
};

const requester = (
  responseTransformers: AxiosResponseTransformer[] = [],
): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: config.BASE_URL,
    timeout: config.API_TIMEOUT_MILISECONDS,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'Accept-Language': i18n.language === 'pt_BR' ? 'pt-br' : i18n.language,
    },
    transformRequest: [decamelizeRequestKeysFn],
    transformResponse: [camelizeResponseKeysFn, ...responseTransformers],
    paramsSerializer: params =>
      qs.stringify(params, {
        encode: false,
        indices: false,
      }),
  });

  addAuthHeaderInterceptor(axiosInstance);

  addRefreshTokenInterceptor(axiosInstance);

  addRequestObserversInterceptor(axiosInstance);

  return axiosInstance;
};

export const requesterS3 = (config?: AxiosRequestConfig): AxiosInstance =>
  axios.create(config);

export default requester;
