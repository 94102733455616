import {
  activityNotification,
  bookNotification,
  challengeNotification,
  homeworkNotification,
  toolUrlNotification,
  watchedInstructionNotification,
} from '@/utils/lessonProgressNotifications';
import { LessonBarColor } from './LessonBar';
import LessonIcon from '../LessonIcon';
import Badge from '@/components/common/dataDisplay/Badge';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Link } from 'react-router-dom';
import Text from '@/components/common/dataDisplay/Text';
import { formatLessonName } from '@/functions/lessonsName';
import BookProgress from '@/models/BookProgress';
import { LessonBarProps } from './LessonBar';
import { pathChecker } from '@/utils/pathChecker';
import { setClassroomPath } from '@/utils/setClassroomPath';

export type LessonBarLinkProps = LessonBarProps;

export default function LessonBarLink({
  slugCourseName,
  lesson,
  blocked,
  scheduledLesson,
  lessonProgress,
  color,
  hideProgress,
}: LessonBarLinkProps) {
  const bookNotificationToInteger = (bookProgress?: BookProgress): 0 | 1 => {
    if (bookProgress)
      return bookNotification(lessonProgress?.bookProgress, hideProgress) < 100
        ? 1
        : 0;
    return 0;
  };

  const bookNotificationSum = bookNotificationToInteger(
    lessonProgress?.bookProgress,
  );

  const notificationSum =
    bookNotificationSum +
    activityNotification(lessonProgress?.activitiesProgress) +
    homeworkNotification(lessonProgress?.homeworkProgress) +
    challengeNotification(lessonProgress?.challengeProgress) +
    toolUrlNotification(
      lessonProgress?.hasAccessedTool ?? true,
      lesson.toolUrl,
    ) +
    watchedInstructionNotification(
      lessonProgress?.hasWatchedInstruction ?? true,
      lesson.instructionsUrl,
    );

  const blockLink = pathChecker();

  const lessonName = formatLessonName(lesson, scheduledLesson);

  const mainTool = lesson.tools.find(tool => tool.isMain);

  const lessonBanner = mainTool?.image ?? lesson.bannerImg;

  const setTextColor = (color?: LessonBarColor) => {
    const textColors: { [key in LessonBarColor]: string } = {
      primary: 'text-primary',
      secondary: 'text-secondary',
      accent: 'text-accent',
    };

    return textColors[color ?? 'primary'];
  };

  const block = !slugCourseName || blockLink || blocked;

  const baseUrl = `/courses/${slugCourseName}/lesson/${lesson.id}`;

  const klassId = scheduledLesson?.klass;

  const link =
    slugCourseName && !block
      ? setClassroomPath({
          baseUrl,
          klassId,
        })
      : '';

  return (
    <Link
      data-testid="lessonBarLink"
      to={link}
      className={`flex gap-4 items-center w-full overflow-x-hidden py-1.5 pl-4 ${
        block ? 'cursor-default' : ''
      }`}
    >
      <ConditionalWrapper
        condition={!!lessonProgress}
        wrapper={
          <Badge
            className="z-50 lg:hidden"
            count={notificationSum}
            position="bottom-8 left-10"
          />
        }
      >
        <LessonIcon
          lessonBanner={lessonBanner}
          progress={lessonProgress?.progress}
        />
      </ConditionalWrapper>
      <Text
        format="rubik-500"
        size="text-18"
        text={lessonName}
        className={`truncate ${setTextColor(color) ?? 'text-secondary'}`}
      />
    </Link>
  );
}
