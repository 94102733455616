import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ElementPreviewFactoryProps } from '../ElementPreviewFactory';
import LoadingView from '@/pages/courses/LoadingView';
import useActivityView from '@/data/hook/useActivityView';
import { DNDOrdenationCorrectAnswer } from '@/models/ActivityProgress';
import DNDOrdenationColumnList from './DNDOrdenationColumnList/DNDOrdenationColumnList';
import { updateDndOrdenationActivityProgress } from '@/data/services/activityElement/dndOrdenationActivityProgressServices';
import DNDOrdenationColumnProgressList from './DNDOrdenationColumnProgressList/DNDOrdenationColumnProgressList';
import alert from '@/utils/UseAlert';
import {
  dndOrdenationActivitiesProgressesQueryKeys,
  dndOrdenationActivitiesQueryKeys,
} from '@/data/services/querykeys';

export interface DNDOrdenationElementProps extends ElementPreviewFactoryProps {
  answer?: DNDOrdenationCorrectAnswer | null;
}

export default function DNDOrdenationElement(props: DNDOrdenationElementProps) {
  const {
    questionId,
    progressId,
    isAdmin,
    activityMode,
    answer,
    attemptElement,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });
  const { blockStepNavigate, invalidate, status } = useActivityView();
  const { queryKey: dndOrdenationProgressQueryKey, queryFn: progressQueryDn } =
    dndOrdenationActivitiesProgressesQueryKeys.get({
      dndOrdenationProgressId: progressId,
      questionId,
    });

  const queryClient = useQueryClient();

  const {
    data: dndOrdenationActivity,
    isInitialLoading: isLoadingDndOrdenationActivity,
  } = useQuery({
    enabled: !!isAdmin,
    ...dndOrdenationActivitiesQueryKeys.get(questionId),
  });

  const {
    data: dndOrdenationActivityProgress,
    isInitialLoading: isLoadingDndOrdenationActivityProgress,
  } = useQuery({
    queryKey: dndOrdenationProgressQueryKey,
    queryFn: progressQueryDn,
    enabled:
      !isAdmin && !!questionId && !!progressId && status === 'inProgress',
  });

  const handleAnswer = async (hasChange: boolean) => {
    try {
      blockStepNavigate(true);
      const data = await updateDndOrdenationActivityProgress(
        {
          questionId,
          dndOrdenationProgressId: progressId,
        },
        { isDone: hasChange },
      );

      queryClient.invalidateQueries(dndOrdenationProgressQueryKey);

      if (data) {
        await invalidate();
      }
    } catch (error) {
      alert.error(t('answerError'));
    } finally {
      blockStepNavigate(false);
    }
  };

  if (
    isLoadingDndOrdenationActivity ||
    isLoadingDndOrdenationActivityProgress
  ) {
    return <LoadingView />;
  }

  if (dndOrdenationActivity) {
    return (
      <DNDOrdenationColumnList dndOrdenationActivity={dndOrdenationActivity} />
    );
  }

  if (dndOrdenationActivityProgress) {
    return (
      <DNDOrdenationColumnProgressList
        dndOrdenationActivityProgress={dndOrdenationActivityProgress}
        attemptElement={attemptElement}
        activityMode={activityMode}
        setHasChange={handleAnswer}
        answerColumns={answer?.columns}
      />
    );
  }

  return null;
}
