import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import ToggleButtonGroup, {
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import useAuth from '@/data/hook/useAuth';
import { unitsQueryKeys, usersQueryKeys } from '@/data/services/querykeys';
import { isSuperAdmin, isUnitAdmin } from '@/functions/auth';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { ApiError } from '@/models/Errors';
import User, { UserTypeEnum } from '@/models/User';
import alert from '@/utils/UseAlert';
import { accordion } from '@/utils/animations/commom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TeamForm from './TeamForm';
import { updateUser } from '@/data/services/userServices';

export default function AddTeam({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'manageTeam' });

  type ToggleValue = 'new' | 'unit';
  const [formMode, setFormMode] = useState<ToggleValue>('new');

  const toggleButtons: ToggleButton<ToggleValue>[] = [
    { value: 'new', text: t('newUser') },
    { value: 'unit', text: t('fromUnit') },
  ];
  return (
    <div className={show ? 'overflow-visible' : 'overflow-hidden'}>
      <AnimatePresence>
        {show && (
          <motion.div
            className="flex flex-col gap-2 content-end items-end self-end mt-2.5"
            {...accordion}
          >
            <Fragment>
              <ToggleButtonGroup
                buttons={toggleButtons}
                value={formMode}
                onChange={value => setFormMode(value as ToggleValue)}
                exclusive
              />
              <ConditionalRenderer
                condition={formMode === 'new'}
                fallback={<AddFromUnit onCancel={() => setShow(false)} />}
              >
                <TeamForm
                  formMode="create"
                  className="py-2 w-[600px]"
                  onCancel={() => setShow(false)}
                />
              </ConditionalRenderer>
            </Fragment>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export const AddFromUnit = ({ onCancel }: { onCancel?: () => void }) => {
  const { user: authUser } = useAuth();
  const { t } = useTranslation('translation', { keyPrefix: 'manageTeam' });
  const [hasUnit, setHasUnit] = useState(!!authUser?.unitsIds.length);
  const [hasUser, setHasUser] = useState(false);

  const isDisabled = !hasUser || !hasUnit;

  const queryClient = useQueryClient();
  const isMultipleUnits =
    authUser && isUnitAdmin(authUser.userType) && authUser.unitsIds?.length > 1;
  interface SelectUser {
    user: User;
    unitId: number;
  }
  const { control, handleSubmit, reset } = useForm<SelectUser>({
    defaultValues: {
      unitId: authUser?.unitsIds[0],
    },
  });

  const onAdd = async ({ user, unitId }: SelectUser) => {
    let unitsIds = user.unitsIds;
    unitsIds.push(unitId);
    await updateUser(user.id, { unitsIds });
    onCancel?.();
  };
  const { mutate: update, isLoading: isUpdating } = useMutation(onAdd, {
    onSuccess: () => {
      reset();
      alert.success(t('addUser.successCreateUser'));
      queryClient.invalidateQueries(usersQueryKeys.list._def);
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });
  const onSubmit = async (data: SelectUser) => {
    const alreadyHasUnit = data.user?.unitsIds?.includes(data.unitId);
    if (alreadyHasUnit) {
      alert.error(t('errorUserAlreadyHasUnit'));
      return;
    }

    update(data);
  };
  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="user"
        render={({ field: { onChange } }) => (
          <InfiniteSearchInput
            service={usersQueryKeys.list}
            onSelect={user => {
              onChange(user);
              setHasUser(true);
            }}
            displayName={user => handleUserFullName(user)}
            input={{
              testId: 'addFromOtherUnitUser',
              placeholder: t('selectUser'),
              className: 'w-96',
            }}
            filters={{
              userType: [UserTypeEnum.TEACHER, UserTypeEnum.UNIT_ADMIN],
              pageSize: 10,
            }}
            className="w-full flex self-center"
          />
        )}
      />
      <ConditionalRenderer
        condition={isSuperAdmin(authUser?.userType) || isMultipleUnits}
      >
        <Controller
          control={control}
          name="unitId"
          render={({ field: { onChange } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              displayName={unit => unit.name}
              filters={{
                idIn: isUnitAdmin(authUser?.userType)
                  ? authUser?.unitsIds.toString()
                  : undefined,
              }}
              onSelect={unit => {
                onChange(unit.id);
                setHasUnit(true);
              }}
              input={{
                testId: 'addFromOtherUnit',
                placeholder: t('selectUnit'),
                label: t('unitLabel'),
                className: 'w-96',
              }}
              className="w-full flex self-center"
            />
          )}
        />
      </ConditionalRenderer>
      <SaveCancelGroup
        cancel={{
          onClick: () => {
            reset();
            onCancel?.();
          },
        }}
        save={{ disabled: isUpdating || isDisabled }}
      />
    </form>
  );
};
