import { useTranslation } from 'react-i18next';
import ControlButton from '../activities/elements/ControlButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { HTMLAttributes } from 'react';
import ConditionalRenderer from './ConditionalRenderer';
import { twMerge } from 'tailwind-merge';

interface BeforeNextButtonProps extends HTMLAttributes<HTMLDivElement> {
  hidePrev: boolean;
  isOnLast?: boolean;
  prevText: string;
  nextText: string;
  buttonColor?: 'text-base-content' | 'text-primary';
  goNext: () => void;
  goBack?: () => void;
  onFinish?: () => void;
  isNextRequest?: boolean;
  isPrevRequest?: boolean;
  className?: string;
  hideNext?: boolean;
  disabled?: boolean;
}

export default function Controls(props: BeforeNextButtonProps) {
  const {
    goNext,
    goBack,
    onFinish,
    hidePrev,
    isOnLast,
    isNextRequest,
    prevText,
    buttonColor,
    isPrevRequest,
    nextText,
    hideNext,
    className,
    disabled,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.controlButton',
  });
  return (
    <div
      className={twMerge(
        className,
        `flex w-full ${hidePrev ? 'justify-end' : 'justify-between'}
       ${disabled ? 'pointer-events-none opacity-60' : ''}`,
      )}
    >
      <ConditionalRenderer condition={!hidePrev}>
        <ControlButton.Root
          className={buttonColor}
          onClick={goBack}
          testId="controlPrev"
        >
          <ControlButton.Icon
            icon={<ChevronLeftIcon className="w-5 h-5" />}
            isLoading={isPrevRequest}
          />
          <ControlButton.Text text={prevText} />
        </ControlButton.Root>
      </ConditionalRenderer>

      <ConditionalRenderer condition={!hideNext}>
        <ControlButton.Root
          className="text-secondary"
          onClick={isOnLast ? onFinish : goNext}
          testId="controlNext"
        >
          <ControlButton.Text text={isOnLast ? t('finish') : nextText} />
          <ControlButton.Icon
            icon={<ChevronRightIcon className="w-5 h-5" />}
            isLoading={isNextRequest}
          />
        </ControlButton.Root>
      </ConditionalRenderer>
    </div>
  );
}
