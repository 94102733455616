import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  CogIcon,
  MoonIcon,
  SaveIcon,
  SunIcon,
  PhotographIcon,
} from '@heroicons/react/outline';
import { isEqual } from 'lodash';

import User from '@/models/User';
import Language from '@/components/admin/settings/Language';
import alert from '@/utils/UseAlert';
import MainButton from '@/components/common/buttons/MainButton';
import Tag from '@/components/common/dataDisplay/Tag';
import { updateUser } from '@/data/services/userServices';
import SelectTimeZone from '@/components/common/dataInput/SelectTimeZone';
import Text from '@/components/common/dataDisplay/Text';
import useConfigContext from '@/data/hook/config';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { meQueryKeys } from '@/data/services/querykeys';

interface TimezoneInput {
  timezone: string;
}

export const SystemInfo = ({ user }: { user?: User | null }) => {
  const { userSettings, setTheme } = useConfigContext();

  const theme = userSettings?.theme;

  const {
    handleSubmit,
    control,
    watch,
    formState: { defaultValues },
  } = useForm<TimezoneInput>({
    defaultValues: { timezone: user?.timezone },
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountPage',
  });
  const queryClient = useQueryClient();
  const [hasChanges, setHasChanges] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const onSubmit: SubmitHandler<TimezoneInput> = async ({ timezone }) => {
    if (user && timezone !== user.timezone) {
      try {
        setIsRequesting(true);
        await updateUser(user.id, { timezone });
        queryClient.invalidateQueries(meQueryKeys.get._def);
        moment.tz.setDefault(timezone);
        alert.success(t('saveSuccess'));
      } catch (error) {
        const message = getErrorMessage(error);
        alert.error(message);
      } finally {
        setIsRequesting(false);
      }
    }
  };

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  return (
    <main className="grid gap-4">
      <section className="grid gap-2">
        <Tag
          text={t('accountSystemTitle')}
          icon={<CogIcon className="w-6 h-6" />}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="timezone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectTimeZone
                input={{
                  label: t('alterTimezone'),
                  testId: 'timezoneInput',
                }}
                value={value}
                onChange={onChange}
              />
            )}
          />

          <MainButton
            text={t('saveButton')}
            icon={<SaveIcon />}
            dataTestId="saveTimezone"
            loading={isRequesting}
            type="submit"
            disabled={!hasChanges}
            className="mt-3"
          />
        </form>
      </section>
      <section className="grid gap-2">
        <Tag
          text={t('visualSettings')}
          icon={<PhotographIcon className="w-6 h-6" />}
        />
        <Text text={t('theme')} />
        <div className="flex gap-2 items-center">
          <SunIcon className="w-5 h-5" />
          <input
            type="checkbox"
            className="toggle"
            defaultChecked={theme === 'dark'}
            onChange={e =>
              e.target.checked ? setTheme('dark') : setTheme('light')
            }
          />
          <MoonIcon className="w-5 h-5" />
        </div>
        <Language />
      </section>
    </main>
  );
};
