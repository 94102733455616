import {
  AcademicCapIcon,
  AnnotationIcon,
  BookOpenIcon,
  CalendarIcon,
  CogIcon,
  CollectionIcon,
  IdentificationIcon,
  LibraryIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';
import useAuth from '@/data/hook/useAuth';
import i18n from '@/i18n';
import { UserTypeEnum, UserStatusEnum } from '@/models/User';
import { isStudent } from '@/functions/auth';

export interface Option {
  testId: string;
  title: string;
  onClick?: (params?: any) => void;
  icon: JSX.Element;
  url: string;
  roles: UserTypeEnum[] | 'ALL';
  renderCondition: {
    hasCondition: boolean;
    condition?: boolean;
  };
}

const defaultWitdh = 'h-5 w-5';

export default function useOptions() {
  const { user } = useAuth();

  const studentStatus = [
    UserStatusEnum.ACTIVE_ENROLLMENT,
    UserStatusEnum.WAITING_ENROLLMENT,
  ];

  const options: Option[] = [
    {
      testId: 'sidebarHome',
      title: i18n.t('sideMenu.home'),
      icon: <ViewGridIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarCourses',
      title: i18n.t('sideMenu.courses'),
      icon: <AcademicCapIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/courses',
      renderCondition: {
        hasCondition: true,
        condition:
          user?.userType !== UserTypeEnum.STUDENT ||
          studentStatus.includes(user?.status),
      },
    },
    {
      testId: 'sidebarProjects',
      title: i18n.t('sideMenu.projects'),
      icon: <CollectionIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/projects',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarClassroom',
      title: i18n.t('sideMenu.classroom'),
      icon: <BookOpenIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.STUDENT],
      url: '/classroom',
      renderCondition: {
        hasCondition: true,
        condition: false,
      },
    },
    {
      testId: 'sidebarFeedback',
      title: i18n.t('sideMenu.feedback'),
      icon: <AnnotationIcon className={defaultWitdh} />,
      roles: [
        UserTypeEnum.TEACHER,
        UserTypeEnum.UNIT_ADMIN,
        UserTypeEnum.SUPER_ADMIN,
      ],
      url: '/student-lesson-feedback',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarCalendar',
      title: i18n.t('sideMenu.calendar'),
      icon: <CalendarIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/calendar',
      renderCondition: {
        hasCondition: true,
        condition: !isStudent(user?.userType),
      },
    },
    {
      testId: 'sidebarHowItWorks',
      title: i18n.t('sideMenu.howWorks'),
      icon: <LibraryIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.PARENT],
      url: '/como-funciona',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarAdmin',
      title: i18n.t('sideMenu.admin'),
      icon: <IdentificationIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN],
      url: '/admin',
      renderCondition: { hasCondition: false },
    },
    {
      testId: 'sidebarTeacher',
      title: i18n.t('sideMenu.teacher'),
      icon: <IdentificationIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.TEACHER],
      url: '/teacher',
      renderCondition: { hasCondition: false },
    },
    {
      testId: 'sidebarConfig',
      title: i18n.t('sideMenu.config'),
      icon: <CogIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/account',
      renderCondition: { hasCondition: false },
    },
  ];
  return options;
}
