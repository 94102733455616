import ErrorDispatcher from './ErrorDispatcher';

type BookErrorDispatcherType = 'create' | 'update' | 'delete' | 'updateOrder';

export class BookErrorDispatcher implements ErrorDispatcher {
  lessonId: number;
  bookId: number;
  type: BookErrorDispatcherType;
  chapterId?: number;

  constructor(
    lessonId: number,
    bookId: number,
    type: BookErrorDispatcherType,
    chapterId?: number,
  ) {
    this.lessonId = lessonId;
    this.bookId = bookId;
    this.type = type;
    this.chapterId = chapterId;
  }

  public getError() {
    const errors: Record<BookErrorDispatcherType, () => Error> = {
      create: this.createError,
      update: this.updateError,
      delete: this.deleteError,
      updateOrder: this.updateOrderError,
    };

    return errors[this.type].bind(this)();
  }

  public createError() {
    return new Error(
      `An error on create chapter - lesson: ${this.lessonId} - book: ${this.bookId}`,
    );
  }

  public updateError() {
    return new Error(
      `An error on update chapter - lesson: ${this.lessonId} - book: ${this.bookId} - chapter: ${this?.chapterId}`,
    );
  }

  public deleteError() {
    return new Error(
      `An error on delete chapter - lesson: ${this.lessonId} - book: ${this.bookId} - chapter: ${this?.chapterId}`,
    );
  }

  public updateOrderError() {
    return new Error(
      `An error on delete chapter - lesson: ${this.lessonId} - book: ${this.bookId}`,
    );
  }
}
