import { Fragment, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import ModalWarning from '@/components/common/modals/ModalWarning';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  PresenceEnum,
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import ScheduledLessonReport from '@/models/ScheduledLessonReport';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import User, { UserTypeEnum } from '@/models/User';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import {
  UpdateScheduledLessonBody,
  updateScheduledLesson,
} from '@/data/services/scheduledLessonsServices';
import alert from '@/utils/UseAlert';
import { CategoryEnum, RewardEnum } from '@/models/Rewards';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { StudentReportEdit } from '@/components/teacher/StudentReportEdit';
import MainButton from '@/components/common/buttons/MainButton';
import { ContentGivenSelect } from '@/components/teacher/ContentGivenSelect';
import { ScheduledLessonAnnotationsEdit } from '@/components/teacher/ScheduledLessonAnnotationsEdit';
import useAuth from '@/data/hook/useAuth';
import { HomeworkEdit } from '@/components/teacher/HomeworkEdit';
import {
  createStudentReport,
  updateStudentReport,
} from '@/data/services/scheduledLessonReportServices';
import {
  createTransaction,
  getRewardsByStudentId,
} from '@/data/services/transactionServices';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { handleMultipleRejectRequests } from '@/utils/erroHandlers';
import {
  categoryRewardsQueryKeys,
  enrollmentsQueryKeys,
  homeworkActivitiesQueryKeys,
  scheduledLessonReportsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { StudentReports as Reports } from '@/components/scheduledLessonReport/StudentReports';
import PresenceCheckbox from '@/components/common/dataDisplay/PresenceCheckbox';
import useListService from '@/data/hook/useListService';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';
import { LoadingIcon } from '@/components/icons';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { Transaction } from '@/models/Transaction';

interface ScheduledLessonReportProps {
  scheduledLesson: ScheduledLesson;
  klass: Klass;
  isLoading?: boolean;
  invalidateScheduledLesson?: () => Promise<void>;
}

export default function ScheduledLessonReportView({
  scheduledLesson,
  klass,
  invalidateScheduledLesson,
}: ScheduledLessonReportProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const { user } = useAuth();

  const [openModal, setOpenModal] = useState(false);

  const [enrollment, setEnrollment] = useState<Enrollment>();

  const hasHomework = !isNaN(scheduledLesson?.homeworkActivity ?? NaN);

  const { results: homeworks } = useListService({
    ...homeworkActivitiesQueryKeys.list({
      activity: scheduledLesson?.homeworkActivity ?? 0,
    }),
    enabled: hasHomework,
  });

  const homework = homeworks.at(0);

  const updateCurrentReport = async (changes: UpdateScheduledLessonBody) =>
    updateScheduledLesson(scheduledLesson.id, changes);

  const { isLoading: isUpdating, mutateAsync: updateLessonReport } =
    useMutation(updateCurrentReport, {
      onError(error) {
        alert.error(getErrorMessage(error));
      },
      async onSuccess() {
        alert.success(t('savedScheduledLesson'));
        await invalidateScheduledLesson?.();
      },
    });

  async function onDone(hasDone: boolean) {
    if (hasDone) setOpenModal(true);
    else await updateLessonReport({ hasDone });
  }

  const className =
    'flex flex-wrap [&>*]:w-[48%] [&>*]:min-w-[350px] sm:[&>*]:min-w-[600px] lg:[&>*]:min-w-[400px] gap-4 w-full justify-center my-4';

  function isFromUnitKlass() {
    return user?.unitsIds.includes(klass.unitId) ?? false;
  }

  type Permissions = 'Presence' | 'HasDone' | 'Reports' | 'Cash';

  type UserPermission = {
    [key in `has${Permissions}Permission`]: boolean;
  };
  function isTeacherInKlass() {
    const isTeacherInKlass =
      scheduledLesson.teacher === user?.id || klass.teacherId === user?.id;
    return isTeacherInKlass;
  }

  function getUserPermissions(): UserPermission {
    function hasHasDonePermission() {
      switch (user?.userType) {
        case UserTypeEnum.SUPER_ADMIN:
          return true;
        case UserTypeEnum.UNIT_ADMIN:
          return isFromUnitKlass();
        case UserTypeEnum.TEACHER:
          return !scheduledLesson.hasDone;
        default:
          return false;
      }
    }

    function hasReportPermissions() {
      switch (user?.userType) {
        case UserTypeEnum.SUPER_ADMIN:
          return true;
        case UserTypeEnum.UNIT_ADMIN:
          return isFromUnitKlass();
        case UserTypeEnum.TEACHER:
          return isFromUnitKlass() && isTeacherInKlass();
        default:
          return false;
      }
    }

    return {
      hasPresencePermission: hasHasDonePermission(),
      hasHasDonePermission: hasHasDonePermission(),
      hasReportsPermission: hasReportPermissions(),
      hasCashPermission: hasHasDonePermission(),
    };
  }

  const userPermissions: UserPermission = getUserPermissions();

  function handleEnrollment(enrollment?: Enrollment) {
    setEnrollment(enrollment);
  }

  async function handleCancel() {
    setOpenModal(false);
  }

  return (
    <Fragment>
      <LessonReportModal
        klass={klass}
        scheduledLesson={scheduledLesson}
        open={openModal}
        onConfirm={updateLessonReport}
        onCancel={handleCancel}
      />
      <div className={className}>
        <Reports
          permissions={userPermissions}
          klass={klass}
          homeworkId={homework?.id}
          handleEnrollment={handleEnrollment}
          scheduledLesson={scheduledLesson}
        />

        <StudentReportEdit
          homeworkId={homework?.id}
          enrollment={enrollment}
          canEditReport={userPermissions.hasReportsPermission}
          canEditCash={userPermissions.hasCashPermission}
          scheduledLesson={scheduledLesson}
        />

        <div className="flex flex-col gap-1.5">
          <ContentGivenSelect
            courseSlug={klass.coursePathSlug}
            scheduledLessonData={scheduledLesson}
          />

          <ScheduledLessonAnnotationsEdit
            notes={scheduledLesson.notes}
            isLoading={isUpdating}
            onClick={async notes => await updateLessonReport({ notes })}
            disabled={!userPermissions.hasHasDonePermission}
          />
        </div>
        <HomeworkEdit
          klassId={klass.id}
          scheduledLesson={scheduledLesson}
          homeworkActivity={homework}
        />
      </div>

      <SubmitButton
        locked={!userPermissions.hasHasDonePermission}
        onDone={onDone}
        isLoading={isUpdating}
        hasDone={scheduledLesson.hasDone}
      />
    </Fragment>
  );
}

const SubmitButton = ({
  locked,
  isLoading,
  hasDone,
  onDone,
}: {
  isLoading: boolean;
  hasDone: boolean;
  onDone: (hasDone: boolean) => void;
  locked: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  type ButtonProps = {
    className: string;
    text: string;
    icon: JSX.Element;
    tooltipMessage?: string;
  };

  type ButtonStates = 'finish' | 'unfinish' | 'block';

  const buttonProps: Record<ButtonStates, ButtonProps> = {
    finish: {
      className:
        'bg-primary hover:ring hover:bg-success hover:ring-success max-w-[269px]',
      text: t('classCompleted'),
      icon: <CheckIcon />,
    },

    unfinish: {
      className:
        'bg-error hover:ring hover:ring-error-content hover:bg-error max-w-[300px]',
      text: t('classNotCompleted'),
      icon: <XIcon />,
    },

    block: {
      className: `border px-2.5 py-1.5 ${
        hasDone
          ? 'bg-success-content border-success text-success'
          : 'bg-neutral text-neutral-content border-neutral'
      }  font-500 cursor-auto`,
      text: hasDone ? t('classCompletedButtonPhrase') : t('classNotCompleted'),
      icon: <CheckIcon />,
      tooltipMessage: hasDone ? t('hasDoneDesmark') : t('hasNoPermission'),
    },
  };

  const { className, text, icon, tooltipMessage } =
    buttonProps[locked ? 'block' : hasDone ? 'unfinish' : 'finish'];

  return (
    <ConditionalWrapper
      condition={locked}
      wrapper={
        <Tooltip
          placement="right"
          classNameContainer="w-fit"
          text={tooltipMessage}
        />
      }
    >
      <MainButton
        dataTestId="adminHasDoneButton"
        color="custom"
        text={text}
        icon={icon}
        iconSize="h-6 w-6"
        size="medium"
        className={twMerge(
          'relative p-2.5 text-center text-base-100 ',
          className,
          locked && 'pointer-events-none opacity-50',
        )}
        loading={isLoading}
        onClick={() => !locked && onDone(!hasDone)}
      />
    </ConditionalWrapper>
  );
};

type StudentPresenceProps = {
  student?: User;
  presence?: PresenceEnum | null;
};

function StudentPresence({ student, presence }: StudentPresenceProps) {
  const studentPresence = !presence ? PresenceEnum.MISSED : presence;

  if (student)
    return (
      <span className="flex gap-4 items-center">
        <PresenceCheckbox presence={studentPresence} />
        <Avatar
          className="h-fit cursor-auto"
          testId="studentAvatar"
          size="6"
          userId={student.id}
        />
        <Text
          testId="studentFullName"
          text={handleUserFullName(student)}
          format="rubik-500"
          className="flex text-primary cursor-auto"
        />
      </span>
    );

  return null;
}

type studentPresence = {
  enrollment: Enrollment;
  report?: ScheduledLessonReport;
};

type StudentPresencesProps = {
  mode: 'presences' | 'absences';
  studentPresences: studentPresence[];
};

function StudentPresences({ mode, studentPresences }: StudentPresencesProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  return (
    <div className="flex flex-col gap-3 justify-center">
      <ConditionalRenderer condition={!!studentPresences.length}>
        <Text
          text={t(mode)}
          format="rubik-500"
          className="text-center flex self-center"
          size="text-14"
        />
      </ConditionalRenderer>
      {studentPresences.map(({ enrollment, report }) => (
        <StudentPresence
          key={enrollment.id}
          student={enrollment.student}
          presence={report?.presence}
        />
      ))}
    </div>
  );
}

type LessonReportModalProps = {
  klass: Klass;
  scheduledLesson: ScheduledLesson;
  open: boolean;
  onConfirm: UseMutateAsyncFunction<
    ScheduledLesson,
    unknown,
    UpdateScheduledLessonBody,
    unknown
  >;
  onCancel(): void;
};

function LessonReportModal({
  klass,
  scheduledLesson,
  open,
  onConfirm,
  onCancel,
}: LessonReportModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const queryClient = useQueryClient();

  const { data: reward } = useQuery({
    ...categoryRewardsQueryKeys.get(CategoryEnum.PRESENCE),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: open,
  });

  const { data: teacher } = useQuery({
    ...usersQueryKeys.get(klass.teacherId),
    enabled: !!klass.teacherId && open,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const { results: enrollments } = useListService({
    ...enrollmentsQueryKeys.list({
      klassId: scheduledLesson.klass,
      status: [EnrollmentStatusEnum.ACTIVE],
      current: true,
    }),
    enabled: !!scheduledLesson && open,
  });

  const { results: reports, isFetching: fetchingReports } = useListService({
    ...scheduledLessonReportsQueryKeys.list({
      scheduledLesson: scheduledLesson.id,
      status: [ScheduledLessonReportEnum.ACTIVE],
    }),
    enabled: !!scheduledLesson && open,
  });

  const studentPresences: studentPresence[] = enrollments.map(enrollment => ({
    report: reports.find(report => report.student === enrollment.student.id),
    enrollment,
  }));

  const absences = studentPresences.filter(
    studentPresence =>
      studentPresence.report?.presence === PresenceEnum.MISSED ||
      !studentPresence.report?.presence,
  );

  const attendances = studentPresences.filter(
    studentPresence =>
      studentPresence.report?.presence === PresenceEnum.ATTENDED,
  );

  const makeTransaction = async (studentPresence: studentPresence) => {
    if (!reward) return alert.error(t('requestMessages.genericError'));

    const account = await getRewardsByStudentId(
      studentPresence.enrollment.student.id,
    );

    let transaction: Transaction = {
      studentAccount: account.id,
      rewardType: RewardEnum.COIN,
      transactionType: 'WON',
      reason: t('presence'),
      lesson: scheduledLesson.lesson.id,
      category: CategoryEnum.PRESENCE,
      scheduledLesson: scheduledLesson.id,
    };

    if (studentPresence.report) {
      transaction = {
        ...transaction,
        amount: studentPresence.report.presenceReward || reward.amount,
      };

      if (!studentPresence.report.presenceReward)
        await updateStudentReport({
          id: studentPresence.report.id,
          changes: {
            presenceReward: reward.amount,
          },
        });
    }

    await createTransaction(transaction);
  };

  async function addReward(attendances: studentPresence[]) {
    const saveRewardsRequests = attendances.map(makeTransaction);

    const responses = await Promise.allSettled(saveRewardsRequests);
    handleMultipleRejectRequests(responses);
  }

  async function addAbsence(absences: studentPresence[]) {
    const createAbsenceReports = absences.map(
      async ({ enrollment, report }) => {
        if (report) {
          await updateStudentReport({
            id: report.id,
            changes: {
              presence: PresenceEnum.MISSED,
              presenceReward: null,
            },
          });
        } else
          await createStudentReport({
            scheduledLesson: scheduledLesson.id,
            student: enrollment.student.id,
            presence: PresenceEnum.MISSED,
            presenceReward: null,
          });
      },
    );

    const responses = await Promise.allSettled(createAbsenceReports);
    handleMultipleRejectRequests(responses);
  }

  async function handleConfirm() {
    await onConfirm({ hasDone: !scheduledLesson.hasDone });

    if (attendances.length) await addReward(attendances);

    if (absences.length) await addAbsence(absences);

    const event: EventTrack = {
      category: klass.coursePathSlug,
      action: 'Conclude Lesson',
      label: handleUserFullName(teacher),
      value: scheduledLesson.lesson.order,
    };

    eventTrack(event);

    await queryClient.invalidateQueries(
      scheduledLessonReportsQueryKeys.list._def,
    );

    onCancel();
  }

  return (
    <ModalWarning
      visible={open}
      onClickConfirm={handleConfirm}
      onClickCancel={onCancel}
      translationString="lessonReportModal"
      objectTarget={
        <div className="flex justify-center items-center">
          <ConditionalRenderer
            condition={fetchingReports}
            fallback={
              <div className="flex flex-col gap-4">
                <StudentPresences
                  mode="presences"
                  studentPresences={attendances}
                />
                <StudentPresences mode="absences" studentPresences={absences} />
              </div>
            }
          >
            <LoadingIcon className="w-20 text-primary-content" />
          </ConditionalRenderer>
        </div>
      }
    />
  );
}
