import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import useAnimationOnView from '@/data/hook/useMotionViewAnimation';
import { Lesson } from '@/models/Lesson';
import LessonProgress from '@/models/LessonProgress';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import { buildMotionLIProps } from '@/utils/animations/lessonCardAnimation';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import LessonBarLink from './LessonBarLink';
import LessonBarIcons from './LessonBarIcons';
import LessonBarDate from './LessonBarDate';
import LessonProgressBar from './LessonProgressBar';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import BackButton from '@/components/common/BackButton';
import { pathChecker } from '@/utils/pathChecker';
import LessonBarStatus from './LessonBarStatus';

export type LessonBarColor = 'primary' | 'secondary' | 'accent';

export type LessonBarProps = {
  lesson: Lesson;
  scheduledLesson?: ScheduledLesson;
  slugCourseName?: string;
  lessonProgress?: LessonProgress;
  index?: number;
  blocked?: boolean;
  color?: LessonBarColor;
  backUrl?: string;
  hasBackbutton?: boolean;
  hideProgress?: boolean;
};

export default function LessonBar({
  lesson,
  lessonProgress,
  scheduledLesson,
  blocked,
  index = 0,
  color,
  slugCourseName,
  backUrl,
  hasBackbutton,
}: LessonBarProps) {
  const { t } = useTranslation('translation');

  const freeLesson = scheduledLesson?.type === ScheduledLessonTypeEnum.FREE;

  const { variants, custom } = buildMotionLIProps(
    index,
    'right',
    index < 5 ? 0.1 : 0,
  );

  const motionLiProps = useAnimationOnView({
    variants,
    custom,
  });

  const setBgColor = (color?: LessonBarColor) => {
    const bgColors: { [key in LessonBarColor]: string } = {
      primary: 'bg-primary-content',
      secondary: 'bg-secondary-content',
      accent: 'bg-accent-content',
    };

    return bgColors[color ?? 'primary'];
  };

  const className = `rounded-lg shadow-sm flex flex-col w-full ${
    blocked ? 'opacity-60 disabled' : 'cursor-pointer'
  } ${setBgColor(color) ?? 'bg-secondary-content'} items-center relative`;

  const hideCondition = pathChecker();

  return (
    <ConditionalWrapper
      condition={blocked ?? false}
      wrapper={
        <Tooltip
          options={{ placement: 'auto' }}
          text={t('klassProgressPage.lessonBlocked')}
          classNameContainer="w-full"
        />
      }
    >
      <motion.li
        {...motionLiProps}
        data-testid="lessonBar"
        className={className}
      >
        <div className="relative flex w-full justify-between pr-4">
          <LessonBarLink
            slugCourseName={slugCourseName}
            lesson={lesson}
            lessonProgress={lessonProgress}
            scheduledLesson={scheduledLesson}
            color={color}
            blocked={blocked}
            hideProgress={freeLesson}
          />
          <div className="flex gap-4 items-center shrink-0">
            <LessonBarStatus status={lesson.status} />
            <ConditionalRenderer condition={!hideCondition}>
              <LessonBarIcons
                lesson={lesson}
                lessonProgress={lessonProgress}
                scheduledLesson={scheduledLesson}
                slugCourseName={slugCourseName}
                blocked={blocked}
                hideProgress={freeLesson}
              />

              <LessonBarDate
                scheduledLesson={scheduledLesson}
                presence={lessonProgress?.scheduledLessonReport?.presence}
              />
            </ConditionalRenderer>
            <ConditionalRenderer condition={hasBackbutton}>
              <span className="hidden md:flex items-center">
                <BackButton url={backUrl} />
              </span>
            </ConditionalRenderer>
          </div>
        </div>
        <LessonProgressBar
          progress={lessonProgress?.progress}
          color={color}
          hideProgress={freeLesson}
        />
      </motion.li>
    </ConditionalWrapper>
  );
}
