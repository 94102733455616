import React from 'react';
import { Tooltip } from '../dataDisplay/Tooltip';
import Skeleton from '../Skeleton';
import ConditionalRenderer from '../ConditionalRenderer';
import { BaseInputProps } from './BaseInput';

export interface SelectInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement>,
    BaseInputProps {
  color?: 'primary' | 'warning';
  hiddenSelect?: boolean;
  messageTool?: string;
  hasTooltip?: boolean;
}

export default function SelectInput(props: SelectInputProps) {
  const {
    label,
    className,
    testId,
    color,
    errorLabelText,
    children,
    hiddenSelect,
    bgColor,
    register,
    messageTool,
    hasTooltip = false,
    isLoading,
    labelPosition = 'top',
    labelWidth = 'w-36',
    defaultValue,
    fontWeight,
    ...rest
  } = props;

  if (isLoading) {
    return (
      <Skeleton className="min-w-[120px] h-9 bg-primary-content rounded-md" />
    );
  }

  return (
    <div
      className={`flex ${
        labelPosition === 'left' ? 'flex-row w-full gap-3' : 'flex-col'
      } ${className}`}
    >
      <ConditionalRenderer condition={label}>
        <label
          className={`label p-0 ${
            labelPosition === 'left'
              ? labelWidth + ' font-500 justify-start'
              : ''
          }`}
        >
          {label}
        </label>
      </ConditionalRenderer>
      <div className="flex flex-col justify-center relative w-full">
        <ConditionalRenderer condition={errorLabelText}>
          <label className="label text-error p-0">
            <span data-testid="errorLabel" className="text-14">
              {errorLabelText}
            </span>
          </label>
        </ConditionalRenderer>
        <Tooltip enable={hasTooltip} text={messageTool}>
          <ConditionalRenderer
            condition={!hiddenSelect}
            fallback={
              <span className="font-500 px-3 input-sm">
                {rest.value || defaultValue}
              </span>
            }
          >
            <select
              {...rest}
              {...register}
              defaultValue={defaultValue}
              data-testid={testId}
              id={testId}
              className={`select select-sm w-full py-1 pr-9 px-3 focus-visible:ring focus:outline-none placeholder:font-400 input input-bordered ${
                color === 'warning'
                  ? 'input-error focus-visible:ring-error-content'
                  : 'input-primary focus-visible:ring-primary-content'
              } input-sm pt-0 ${
                className || ''
              } disabled:cursor-default disabled:bg-base-100 disabled:border-none ${
                bgColor || ''
              } ${fontWeight || 'font-500'}`}
            >
              {children}
            </select>
          </ConditionalRenderer>
        </Tooltip>
      </div>
    </div>
  );
}
