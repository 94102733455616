import { Draggable, DraggableProps } from '@hello-pangea/dnd';
import { DNDItem } from '@/models/DNDItem';
import useCourseEditing from '@/data/hook/useCourseEditing';
import isAceptableType, {
  bannerConfig,
} from '../../../../../../utils/VerifyImageFile';
import { DragIcon } from '../../../../../icons';
import Text from '../../../../../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../common/dataInput/TextInput';
import { ColumnType, OnChangesFieldsItem } from './DNDPositionalItemListEdit';
import TooltipHandler from '../../../../../common/TooltipHandler';
import { CameraIcon, TrashIcon } from '@heroicons/react/outline';
import ImageInput from '../../../../../common/dataInput/ImageInput';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';
import { HandleRemoveItem } from '../DNDPositionalElementEdit';
import React from 'react';

interface DNDPositionalDraggableItemEditProps
  extends Pick<DraggableProps, 'draggableId' | 'index'> {
  question: DNDItem;
  answer: DNDItem;
  isBlockedDeleteItem: boolean;
  onChangesFieldsItem: OnChangesFieldsItem;
  handleRemoveItem: HandleRemoveItem;
}

export default function DNDPositionalDraggableItemEdit(
  props: DNDPositionalDraggableItemEditProps,
) {
  const {
    question,
    answer,
    onChangesFieldsItem,
    isBlockedDeleteItem,
    handleRemoveItem,
    ...rest
  } = props;
  const { index } = rest;

  const { allowBigChanges } = useCourseEditing();

  const isBlockedChanges = isBlockedDeleteItem || !allowBigChanges;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.dndPositionalElementEdit.dndColumnEdit',
  });

  const { t: tVersioningRules } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const noContentQuestionError =
    question.errors && question.errors.some(value => value === 'noContent');

  const maxLengthQuestionError =
    question.errors && question.errors.some(value => value === 'maxLength');

  const questionError = noContentQuestionError || maxLengthQuestionError;

  const noContentAnswerError =
    answer.errors && answer.errors.some(value => value === 'noContent');

  const maxLengthAnswerError =
    answer.errors && answer.errors.some(value => value === 'maxLength');

  const answerError = noContentAnswerError || maxLengthAnswerError;

  const validateUpload = (file?: any) => {
    return (
      !isAceptableType(bannerConfig.accept, file.type) &&
      !!URL.createObjectURL(file)
    );
  };

  const tooltipMessage = allowBigChanges
    ? t('textTooltipReorderItem')
    : tVersioningRules('cantModify');

  return (
    <Draggable {...rest} isDragDisabled={isBlockedChanges}>
      {provided => (
        <div
          className="gap-3 flex items-center"
          data-testid={'item' + index}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            {...provided.dragHandleProps}
            className={isBlockedChanges ? 'cursor-not-allowed' : ''}
          >
            <TooltipHandler
              tooltipMessage={tooltipMessage}
              renderTooltip={isBlockedChanges}
            >
              <DragIcon data-testid={'dragIcon' + index} />
            </TooltipHandler>
          </div>

          <Text testId={'orderItem' + index} text={index + 1} />
          <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-col gap-3 w-full">
              <div className="flex flex-col w-full">
                {question.errors &&
                  question.errors.map(error => {
                    const errors =
                      error !== 'noAltText' && error !== 'maxLengthAltText';

                    return (
                      <ConditionalRenderer
                        condition={questionError && errors}
                        key={error}
                      >
                        <label
                          data-testid={error + 'LabelText'}
                          className="font-rubik text-error error-label"
                        >
                          {t(`dndErrors.${error}`)}
                        </label>
                      </ConditionalRenderer>
                    );
                  })}

                <div className="flex w-full gap-3 items-center">
                  <TextInput
                    fontWeight="font-400"
                    placeholder={t('question')}
                    className="rounded-lg border-primary min-h-[9px] grow font-600 font-rubik"
                    color={
                      questionError && !question.text && !question.image
                        ? 'warning'
                        : 'primary'
                    }
                    type="text"
                    testId={'questionItemInput' + index}
                    required
                    defaultValue={question.text || ''}
                    onChange={({ target }) =>
                      onChangesFieldsItem({
                        columnType: 'question',
                        keyItem: 'text',
                        value: target.value,
                        item: question,
                        index: index,
                      })
                    }
                  />
                  <DNDItemImageEdit
                    index={index}
                    item={question}
                    columnType="question"
                    onChangesFieldsItem={onChangesFieldsItem}
                  />

                  <label
                    data-testid={'questionAddImageItemButton' + index}
                    className={`rounded-full bg-secondary-content p-1 relative w-fit h-fit ${
                      allowBigChanges ? 'cursor-pointer' : 'opacity-50'
                    }`}
                  >
                    <ImageInput
                      isDisabled={!allowBigChanges}
                      id="cameraInput"
                      accept="image/png, image/svg, image/jpg, image/jpeg"
                      className="opacity-0 absolute top-0 bottom-0 right-0 left-0 -z-10"
                      type="file"
                      hideRemoveButton
                      image={question.image || null}
                      onChange={({ target }) => {
                        if (target.files) {
                          const value = target.files.item(0);
                          if (value && !validateUpload(value)) {
                            onChangesFieldsItem({
                              columnType: 'question',
                              keyItem: 'image',
                              value: value,
                              item: question,
                              index: index,
                            });
                          }
                        }
                      }}
                    />
                    <CameraIcon className="w-6 h-6 text-secondary cursor-pointer" />
                  </label>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3 w-full">
              <div className="flex flex-col w-full">
                {answer.errors &&
                  answer.errors.map(error => {
                    const errors =
                      error !== 'noAltText' && error !== 'maxLengthAltText';
                    return (
                      <ConditionalRenderer
                        condition={answerError && errors}
                        key={error}
                      >
                        <label
                          data-testid={error + 'LabelText'}
                          className="font-rubik text-error error-label"
                        >
                          {t(`dndErrors.${error}`)}
                        </label>
                      </ConditionalRenderer>
                    );
                  })}

                <div className="flex w-full gap-3 items-center">
                  <TextInput
                    fontWeight="font-400"
                    placeholder={t('answer')}
                    className="rounded-lg border-primary min-h-[9px] grow font-600 font-rubik"
                    color={
                      answerError && !answer.text && !answer.image
                        ? 'warning'
                        : 'primary'
                    }
                    type="text"
                    testId={'answerItemInput' + index}
                    required
                    defaultValue={answer.text || ''}
                    onChange={({ target }) =>
                      onChangesFieldsItem({
                        columnType: 'answer',
                        keyItem: 'text',
                        value: target.value,
                        item: answer,
                        index: index,
                      })
                    }
                  />
                  <DNDItemImageEdit
                    index={index}
                    columnType="answer"
                    item={answer}
                    onChangesFieldsItem={onChangesFieldsItem}
                  />
                  <label
                    data-testid={'answerAddImageItemButton' + index}
                    className={`rounded-full bg-secondary-content p-1 relative ${
                      allowBigChanges ? 'cursor-pointer' : 'opacity-50'
                    }`}
                  >
                    <ImageInput
                      isDisabled={!allowBigChanges}
                      id="cameraInput"
                      accept="image/png, image/svg, image/jpg, image/jpeg"
                      className="opacity-0 absolute top-0 bottom-0 right-0 left-0 -z-10 hover:cursor-pointer"
                      type="file"
                      hideRemoveButton
                      image={answer.image || null}
                      onChange={({ target }) => {
                        if (target.files) {
                          const value = target.files.item(0);
                          if (value && !validateUpload(value)) {
                            onChangesFieldsItem({
                              columnType: 'answer',
                              keyItem: 'image',
                              value: value,
                              item: answer,
                              index: index,
                            });
                          }
                        }
                      }}
                    />
                    <CameraIcon className="w-6 h-6 text-secondary cursor-pointer" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <TooltipHandler
            tooltipMessage={
              allowBigChanges
                ? t('textTooltipRemoveItem')
                : tVersioningRules('cantModify')
            }
            renderTooltip={isBlockedChanges}
          >
            <button
              disabled={isBlockedChanges}
              onClick={() =>
                handleRemoveItem({
                  questionItem: question,
                  answerItem: answer,
                  index: index,
                })
              }
              data-testid={'removeItemButton' + index}
              className="text-error disabled:text-neutral/50 disabled:cursor-default"
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </TooltipHandler>
        </div>
      )}
    </Draggable>
  );
}

interface DNDItemImageEditProps {
  item: DNDItem;
  index: number;
  columnType: ColumnType;
  onChangesFieldsItem: OnChangesFieldsItem;
}

function DNDItemImageEdit(props: DNDItemImageEditProps) {
  const { item, index, columnType, onChangesFieldsItem } = props;

  const { image, altText } = item;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.dndPositionalElementEdit.dndColumnEdit',
  });

  if (image) {
    const noAltTextImageError =
      item.errors && item.errors.some(value => value === 'noAltText');

    const maxLengthAltTextImageError =
      item.errors && item.errors.some(value => value === 'maxLengthAltText');

    const itemError = noAltTextImageError || maxLengthAltTextImageError;

    function imageTypeof(image: string | File) {
      if (typeof image === 'string') return image;
      return URL.createObjectURL(image);
    }

    return (
      <div
        data-testid={columnType + 'ImageItemEdit' + index}
        className="flex w-[50%] gap-3"
      >
        <div
          data-testid={columnType + 'ImageItem' + index}
          className="flex gap-5 items-center justify-center"
        >
          <img src={imageTypeof(image)} alt={altText ?? ''} className="w-32" />
        </div>
        <div className="flex flex-col w-full justify-center">
          {item.errors &&
            item.errors.map(error => {
              const errors =
                error === 'noAltText' || error === 'maxLengthAltText';

              return (
                <ConditionalRenderer
                  condition={itemError && errors}
                  key={error}
                >
                  <label
                    data-testid={error + 'LabelText'}
                    className="font-rubik text-error error-label"
                  >
                    {t(`dndErrors.${error}`)}
                  </label>
                </ConditionalRenderer>
              );
            })}

          <TextInput
            color={itemError && !item.altText ? 'warning' : 'primary'}
            fontWeight="font-400"
            className="rounded-lg border-primary h-9 w-full font-600 font-rubik"
            type="text"
            testId={'altImageItem' + index}
            name="question"
            required
            defaultValue={altText ?? ''}
            placeholder={t('descriptionImage')}
            maxLength={255}
            onChange={({ target }) =>
              onChangesFieldsItem({
                columnType,
                keyItem: 'altText',
                value: target.value,
                item: item,
                index: index,
              })
            }
          />
        </div>
      </div>
    );
  }

  return null;
}
