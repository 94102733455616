import React, { KeyboardEventHandler } from 'react';
import phoneHandler from '@/functions/phoneHandler';
import ConditionalRenderer from '../ConditionalRenderer';
import Skeleton from '../Skeleton';
import { BaseInputProps } from './BaseInput';

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    BaseInputProps {
  type?: 'text' | 'email' | 'phone' | 'password';
  color?: 'primary' | 'warning';
  errorLabelText?: string;
  typeInput?: string;
  centerText?: boolean;
  maxLenght?: number;
  mask?(value: string): string;
  onClickIcon?(): void;
  prefixColor?: string;
}

interface IPreClasses {
  default: string;
  login: string;
}

export default function TextInput(props: TextInputProps) {
  const {
    label,
    className,
    icon,
    testId,
    type,
    typeInput,
    color,
    errorLabelText,
    dataTestId,
    fontWeight,
    register,
    bgColor,
    fontSize,
    centerText,
    isLoading,
    labelPosition = 'top',
    errorLabelPosition = 'top',
    labelWidth = 'w-36',
    maxLenght,
    mask,
    disabled,
    onClickIcon,
    prefix,
    prefixColor,
    ...rest
  } = props;
  const name = register?.name;
  const preClasses: IPreClasses = {
    default: `${
      color === 'warning'
        ? 'input-error focus-visible:ring-error-content'
        : 'input-primary focus-visible:ring-primary-content'
    } input-sm pt-0.5`,
    login: 'h-10 focus:input-primary focus-visible:ring-primary-content',
  };
  const maskHandler: KeyboardEventHandler<HTMLInputElement> = e => {
    const input = e.currentTarget;
    if (mask) {
      input.value = mask(input.value);
    }
  };

  return (
    <div
      className={`form-control relative flex ${className || ''} ${
        labelPosition === 'left' ? 'flex-row w-full gap-3' : 'flex-col'
      }`}
    >
      <ConditionalRenderer condition={label}>
        <label
          htmlFor={name}
          className={`label leading-none p-0 ${
            labelPosition === 'left'
              ? labelWidth + ' font-500 justify-start'
              : 'text-14 pb-0.5'
          } ${fontSize || ''} ${
            errorLabelText && labelPosition === 'left' ? 'mt-5' : ''
          }`}
        >
          {label}
        </label>
      </ConditionalRenderer>
      <div className="flex-col w-full justify-center flex">
        <ConditionalRenderer condition={errorLabelText}>
          <label
            data-testid="errorLabel"
            className={`label text-error p-0 ${
              errorLabelPosition === 'bottom' ? 'absolute top-full w-full' : ''
            } ${fontSize || 'text-14'}`}
          >
            {errorLabelText}
          </label>
        </ConditionalRenderer>
        <div className="flex items-center relative w-full">
          <ConditionalRenderer
            condition={!isLoading}
            fallback={
              <Skeleton className="h-8 rounded-lg w-full bg-primary-content" />
            }
          >
            <ConditionalRenderer condition={!!prefix}>
              <span
                className={`flex w-[5rem] font-500 text-16 absolute pl-2 cursor-not-allowed ${
                  prefixColor ?? 'text-primary'
                }`}
              >
                {prefix}
              </span>
            </ConditionalRenderer>
            <input
              {...register}
              {...rest}
              disabled={disabled}
              data-testid={testId || dataTestId}
              type={type}
              onKeyUp={type === 'phone' ? phoneHandler : maskHandler}
              maxLength={type === 'phone' ? 15 : maxLenght}
              className={`w-full disabled:text-base-content focus-visible:ring focus:outline-none ${
                centerText ? 'text-center' : ''
              } ${fontWeight || 'font-500'} ${
                fontSize || 'text-16'
              } placeholder:font-400 input input-bordered disabled:cursor-default ${
                bgColor || ''
              } disabled:bg-base-100 disabled:border-none ${
                typeInput
                  ? preClasses[typeInput as keyof IPreClasses]
                  : preClasses.default
              } input-sm pt-0.5 ${
                color === 'warning'
                  ? 'input-error focus-visible:ring-error-content'
                  : 'input-primary focus-visible:ring-primary-content'
              } ${icon ? 'pr-8' : ''} ${prefix ? 'pl-[5rem]' : ''}`}
            />
          </ConditionalRenderer>
          <ConditionalRenderer condition={icon}>
            <label
              onClick={onClickIcon}
              className={`${onClickIcon ? 'cursor-pointer' : ''} ${
                errorLabelText ? 'text-error' : 'text-primary'
              } ${
                disabled ? 'pointer-events-none' : ''
              } w-4 h-4 absolute right-2.5`}
            >
              {icon}
            </label>
          </ConditionalRenderer>
        </div>
      </div>
    </div>
  );
}
