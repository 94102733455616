interface BodyTextSmallProps {
  text: string | undefined;
  className?: string;
  weight?: '500' | '600' | '700';
  id?: string;
  testId?: string;
}

export default function BodyTextSmall(props: BodyTextSmallProps) {
  return (
    <p
      data-testid={props.testId}
      id={props.id}
      className={`font-rubik align-middle content-center leading-none font-${
        props.weight || '400'
      } text-14 ${props.className}`}
    >
      {props.text}
    </p>
  );
}
