import { useEffect } from 'react';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Controls from '@/components/common/Controls';
import { ChapterStatusEnum } from '@/models/ChapterProgress';
import useStudentContext from '@/data/hook/student';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { updateChapterProgress } from '@/data/services/chapterProgressServices';
import useLessonContext from '@/data/hook/lesson';
import Skeleton from '@/components/common/Skeleton';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  bookProgressesQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';

export default function StudentMaterialControl({
  baseUrl,
}: {
  baseUrl: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.controlButton',
  });

  const { slugCourseName, chapterOrder } = useParams();

  const { lessonProgress, lesson, updateLessonProgress } = useLessonContext();

  const { order } = lesson || {};

  const { queryFn: bookProgressFn, queryKey: bookProgressQueryKey } =
    bookProgressesQueryKeys.get(lessonProgress?.bookProgress.id ?? 0);

  const [loadingOpenChapter, setLoadingOpenChapter] = useState(false);

  const { data: bookProgress, isInitialLoading: loading } = useQuery({
    enabled: !!lessonProgress?.bookProgress.id,
    keepPreviousData: true,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    queryFn: bookProgressFn,
    queryKey: bookProgressQueryKey,
  });

  const queryClient = useQueryClient();

  const chaptersProgress = bookProgress?.chaptersProgress ?? [];

  const chapter = chaptersProgress?.find(
    progress => progress.chapterOrder === Number(chapterOrder),
  );

  const { klass } = useStudentContext();

  const { data: klassTeacher } = useQuery({
    ...usersQueryKeys.get(klass?.teacherId ?? 0),
    enabled: !!klass?.teacherId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const timerRef = useRef(0);

  useEffect(() => {
    timerRef.current = chapter?.timer ?? 0;
  }, [chapter?.timer]);

  useEffect(() => {
    const interval = setInterval(() => {
      timerRef.current = timerRef.current + 1;
    }, 1000);
    return () => clearInterval(interval);
  }, [timerRef]);

  useEffect(() => {
    async function openChapter() {
      if (chapter) {
        const progress = {
          status: ChapterStatusEnum.OPEN,
        };

        try {
          setLoadingOpenChapter(true);
          await updateChapterProgress({
            chapterId: chapter.chapterId,
            chapterProgressId: chapter.id,
            chapterProgress: progress,
          });
        } catch (error) {
          alert.error(getErrorMessage(error));
        } finally {
          setLoadingOpenChapter(false);
        }
      }
    }

    if (chapter && chapter.status === ChapterStatusEnum.NEVER_OPEN) {
      openChapter();
    }
  }, [chapter, queryClient]);

  const updateProgress = async () => {
    const event: EventTrack = {
      category: slugCourseName || 'Course',
      action: 'Conclude Chapter',
      label: handleUserFullName(klassTeacher),
      value: order ?? 0,
    };

    eventTrack(event);

    if (!lessonProgress) return;

    await queryClient.invalidateQueries(bookProgressQueryKey);

    await updateLessonProgress(lessonProgress.lesson.id);
  };

  const { mutate: update, isLoading: updating } = useMutation(
    updateChapterProgress,
    {
      async onSuccess() {
        await updateProgress();
      },
    },
  );

  async function handleBookChapter(direction: -1 | 1) {
    const order = Number(chapterOrder);

    if (!chapter) throw new Error('chapter not found');

    const status =
      chapter.status !== ChapterStatusEnum.READ
        ? ChapterStatusEnum.READ
        : undefined;

    const timer = timerRef.current;

    const progress = {
      timer,
      status,
    };

    update({
      chapterId: chapter.chapterId,
      chapterProgressId: chapter.id,
      chapterProgress: progress,
    });

    navigate(`${baseUrl}/book/chapter/${order + direction}`);

    window.scroll({ top: 0, behavior: 'smooth' });
  }

  if (loading)
    return (
      <Skeleton className="flex w-full h-5 bg-primary-content rounded-md" />
    );

  if (chaptersProgress)
    return (
      <Controls
        disabled={updating || loadingOpenChapter}
        buttonColor="text-primary"
        nextText={t('bookNext')}
        prevText={t('bookPrev')}
        hidePrev={chapterOrder === '1'}
        isOnLast={Number(chapterOrder) === chaptersProgress.length}
        goBack={() => handleBookChapter(-1)}
        goNext={() => handleBookChapter(1)}
        onFinish={() => {
          handleBookChapter(1);
          navigate(baseUrl);
          window.scroll({ top: 0, behavior: 'smooth' });
        }}
      />
    );

  return null;
}
