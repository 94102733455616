import { useState, createContext, useCallback } from 'react';

import { getUserSettings, updateSettings } from '@/functions/userSettings';

export interface SidebarContextProps {
  isOpen: boolean;
  handleClickExpandedMenu?(): void;
  closeSidebar?(): void;
  UpdateSidebarState: (isOpen: boolean) => void;
  setIsMenuCollapsed: (state: boolean) => void;
}

const SidebarContext = createContext<SidebarContextProps>({
  isOpen: false,
  setIsMenuCollapsed(state) {},
  UpdateSidebarState(isOpen) {},
});

export function SidebarProvider(props: any) {
  const userSettings = getUserSettings();
  const sidebarStatus = userSettings?.sidebar;

  const sidebarBooleanStatus = sidebarStatus === 'true';

  const [isOpen, setIsOpen] = useState(sidebarBooleanStatus);

  const UpdateSidebarState = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);

    updateSettings({ updates: { sidebar: String(isOpen) } });
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        UpdateSidebarState,
        isOpen,
        setIsMenuCollapsed: setIsOpen,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
}

export default SidebarContext;
