import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import Tool from './Tool';

export type LessonTab =
  | 'details'
  | 's-material'
  | 't-material'
  | 'activities'
  | 'challenge';

export type LessonUpdateKeyType = 'course' | 'class';

export interface Lesson {
  id: number;
  courseId: number;
  name: string;
  description: string;
  bannerImg?: string;
  activitiesCount: number;
  toolUrl?: string;
  instructionsUrl?: string;
  motivation?: {
    message: string;
  };
  activities: number[];
  book: number | null;
  teacherBook: number | null;
  status: VersioningStatusEnum;
  order: number;
  version: string;
  readBook: number;
  challenge: number | null;
  tools: Tool[];
  teacherRating?: {
    average: number | null;
    count: number;
  };
  inBank: boolean;
  isPublicInBank: boolean;
  author: number;
}

export interface LessonMeta {
  id: number;
  originCourse: string;
  coursesCount: number;
}

export function implementsOfLesson(obj: any): obj is Lesson {
  if (!obj) return false;

  return (
    'id' in obj &&
    'name' in obj &&
    'description' in obj &&
    'activities' in obj &&
    'book' in obj &&
    'teacherBook' in obj &&
    'status' in obj &&
    'version' in obj &&
    'challenge' in obj &&
    'tools' in obj &&
    'inBank' in obj &&
    'author' in obj
  );
}
