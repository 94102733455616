import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import ConditionalRenderer from '../ConditionalRenderer';

interface OutlineButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  testId?: string;
  active?: boolean;
  text?: string;
  className?: string;
  disabled?: boolean;
  href?: string;
  icon?: JSX.Element;
}

const disableClasses: string = 'disabled disabled:opacity-25';

const regularClasses: string =
  'text-primary bg-base-100 enabled:hover:bg-primary-content focus-visible:ring-primary/40';
const activeClasses: string =
  'bg-primary text-base-100 enabled:hover:bg-primary focus-visible:ring-secondary/40';

export default function OutlineButton(props: OutlineButtonProps) {
  const { testId, active, text, className, disabled, href, icon, ...rest } =
    props;
  function renderButton() {
    return (
      <button
        {...rest}
        data-testid={testId}
        disabled={disabled || !props.onClick}
        className={`${active ? activeClasses : regularClasses} ${
          disabled ? disableClasses : ''
        } ${twMerge(
          `shrink-0 px-3 py-1 text-16 leading-none border-primary border flex rounded-full enabled:active:scale-95 items-center font-rubik duration-150 transition disabled:cursor-default focus-visible:ring focus:outline-none focus-visible:ring-primary/40`,
          className,
        )}`}
      >
        <ConditionalRenderer condition={!!icon}>{icon}</ConditionalRenderer>
        {text || props.children}
      </button>
    );
  }

  return href ? <Link to={href}>{renderButton()}</Link> : renderButton();
}
