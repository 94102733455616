import CollapsableCard from './CollapsableCard';
import { ClipboardListIcon, RefreshIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import CardListItem from '../common/dataDisplay/CardListItem';
import PendingHomeworkItem from './PendingHomeworkItem';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Text from '../common/dataDisplay/Text';
import { useQueryClient } from '@tanstack/react-query';
import useAuth from '@/data/hook/useAuth';
import IconButton from '../common/buttons/IconButton';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { pendingHomeworksQueryKeys } from '@/data/services/querykeys';
import useStatisticService from '@/data/hook/useStatistics';

export default function PendingHomeworks() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.pendingHomeworks',
  });
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const filters = {
    teacherId: user?.id,
  };

  const { queryKey: pendingHomeworksQuerykey, queryFn } =
    pendingHomeworksQueryKeys.list(filters)._ctx.infinity;

  const {
    results: pendingHomeworks,
    isFetching: isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    queryKey: pendingHomeworksQuerykey,
    queryFn,
  });

  const statisticsOptions =
    pendingHomeworksQueryKeys.list(filters)._ctx.totalSum;
  const { statistic } = useStatisticService(statisticsOptions);

  const pendingCountQuerykey = statisticsOptions.queryKey;

  const onClickRefresh = () => {
    queryClient.invalidateQueries(pendingHomeworksQuerykey);
    queryClient.invalidateQueries(pendingCountQuerykey);
  };
  const totalPending = statistic?.totalPendingHomeworksCount;

  const shouldRenderList = isLoading || pendingHomeworks?.length;
  return (
    <CollapsableCard
      headerIcon={<ClipboardListIcon className="w-6 stroke-[2]" />}
      title={t('homeworkForCorrection').toUpperCase()}
      isCollapsable={!!pendingHomeworks.length}
      color="orange"
      isLoading={isLoading}
      badgeCount={totalPending}
      header={
        <IconButton
          onClick={onClickRefresh}
          icon={<RefreshIcon className="w-6" />}
        />
      }
      hasNextPage={hasNextPage}
      onReachEnd={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
    >
      <ConditionalRenderer
        fallback={
          <div className="flex justify-center text-center items-center h-full flex-col gap-y-2 text-warning font-500">
            <Text text={t('noHomeworks')} />
            <Text text="\ (•◡•) /" />
          </div>
        }
        condition={shouldRenderList}
      >
        {pendingHomeworks.map((pendingHomework, i) => (
          <CardListItem
            color={i % 2 === 0 ? 'orange' : 'blue'}
            key={pendingHomework.scheduledLesson.id}
          >
            <PendingHomeworkItem pendingHomework={pendingHomework} />
          </CardListItem>
        ))}
      </ConditionalRenderer>
    </CollapsableCard>
  );
}
