import { createSearchParams, useNavigate } from 'react-router-dom';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import Skeleton from '@/components/common/Skeleton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useStatisticService from '@/data/hook/useStatistics';
import attendancePercentage from '@/utils/statistics/attendencePercentage';
import truncateString from '@/utils/truncateString';
import { scheduledLessonStatisticsQueryKeys } from '@/data/services/querykeys';

interface KlassesStudentInfoProps {
  enrollment: Enrollment;
  klassId: number;
}

export default function KlassesStudentInfo(props: KlassesStudentInfoProps) {
  const { enrollment, klassId } = props;
  const student = enrollment.student;

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  const { statistic: studentStatistic, isInitialLoading: isLoading } =
    useStatisticService(
      scheduledLessonStatisticsQueryKeys.list({
        klassId,
        studentId: student.id,
      }),
    );

  const studentFullName = handleUserFullName(student);

  const studentUrl = `/admin/students/info/?${createSearchParams({
    search: handleUserFullName(student),
  })}`;
  const navigate = useNavigate();
  const handleOnClick = () => navigate(studentUrl);
  const loading = <Skeleton className="h-4 w-4 rounded bg-primary-content" />;
  const percentage = attendancePercentage({
    presences: studentStatistic?.presencesCount || 0,
    totalReports:
      (studentStatistic?.presencesCount || 0) +
      (studentStatistic?.absencesCount || 0),
  }).toFixed(0);

  const grayscale =
    enrollment.status !== EnrollmentStatusEnum.ACTIVE
      ? 'grayscale-[80] opacity-30'
      : '';

  return (
    <div className="flex w-fit h-fit border-b border-neutral-content">
      <div
        className={`flex flex-col gap-2.5 w-48 py-3 h-20 justify-center ${grayscale}`}
      >
        <div
          onClick={handleOnClick}
          className="flex items-center gap-2.5 cursor-pointer"
        >
          <Avatar userId={student.id} />
          <Text
            text={truncateString(studentFullName, 50)}
            size="text-14"
            format="rubik-500"
            className="underline w-full shrink text-primary break-words"
          />
        </div>
        <div className="flex items-center justify-between text-14">
          <div className="flex items-center gap-1">
            <Text text={`${t('attendances')}:`} />
            <ConditionalRenderer condition={!isLoading} fallback={loading}>
              <Text
                testId="studentLessonCount"
                text={studentStatistic?.presencesCount || 0}
                className="text-primary"
              />
            </ConditionalRenderer>
          </div>
          <div className="flex items-center gap-1">
            <Text text={`${t('absences')}:`} />
            <ConditionalRenderer condition={!isLoading} fallback={loading}>
              <Text
                testId="studentMissedCount"
                text={studentStatistic?.absencesCount || 0}
                className="text-primary"
              />
            </ConditionalRenderer>
          </div>
          <div className="flex items-center gap-1">
            <Text text="PP:" />
            <ConditionalRenderer condition={!isLoading} fallback={loading}>
              <Text
                testId="studentPP"
                text={`${percentage || 0}%`}
                className="text-primary"
              />
            </ConditionalRenderer>
          </div>
        </div>
      </div>
    </div>
  );
}
