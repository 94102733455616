import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import Unit from '@/models/Unit';

export interface ListUnitsFilters {
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  idIn?: string;
}

export const listUnits = async (params: ListUnitsFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Unit>>(`units/`, {
    params,
  });

  return data;
};

type UpdateUnit = Partial<Omit<Unit, 'id'>>;

export const updateUnit = async (id: number, changes: UpdateUnit) => {
  const { data: unit } = await requester().patch<Unit>(`units/${id}/`, changes);
  return unit;
};

export type CreateUnit = Omit<Unit, 'id' | 'recess' | 'isActive'>;

export const createUnit = async (body: CreateUnit) => {
  const { data: unit } = await requester().post<Unit>(`units/`, body);
  return unit;
};

export const getUnitRecessCalendar = async (unitId: number) => {
  const { data } = await requester().get<Unit>(
    `units/${unitId}/recess-calendar/`,
  );

  return data;
};

export const getUnit = async (unitId: number) => {
  const { data } = await requester().get<Unit>(`units/${unitId}/`);
  return data;
};
