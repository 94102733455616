import { useTranslation } from 'react-i18next';

import KlassesIcon from './KlassesIcon';
import { ScheduledLessonReportEnum } from '@/models/ScheduledLessonReport';
import LessonProgress from '@/models/LessonProgress';
import Text from '@/components/common/dataDisplay/Text';
import {
  bookNotification,
  presenceNotification,
} from '@/utils/lessonProgressNotifications';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import Skeleton from '@/components/common/Skeleton';

interface KlassesIconCellProps {
  studentLessonProgress?: LessonProgress;
  isDisabled: boolean;
  testId?: string;
  scheduledLesson: ScheduledLesson;
  isLoading?: boolean;
}

export default function KlassesIconCell(props: KlassesIconCellProps) {
  const {
    studentLessonProgress,
    isDisabled,
    testId,
    scheduledLesson,
    isLoading,
  } = props;
  const isTransfer =
    studentLessonProgress?.scheduledLessonReport?.status ===
    ScheduledLessonReportEnum.TRANSFERED;
  const { t } = useTranslation();

  const freeLesson = scheduledLesson.type === ScheduledLessonTypeEnum.FREE;

  const handleBookNotification = (
    studentLessonProgress: LessonProgress | undefined,
  ): number => {
    if (studentLessonProgress && studentLessonProgress.bookProgress) {
      return bookNotification(studentLessonProgress.bookProgress, freeLesson);
    }

    return -1;
  };
  const bookNotificatonCount = handleBookNotification(studentLessonProgress);

  const activityNotificationCount = (
    studentLessonProgress: LessonProgress | undefined,
  ): number => {
    if (
      studentLessonProgress &&
      studentLessonProgress.activitiesProgress.length
    ) {
      return studentLessonProgress.activitiesProgress.filter(
        hasDone => !hasDone.hasDone,
      ).length;
    }
    return -1;
  };

  const activityNotification = activityNotificationCount(studentLessonProgress);
  const presenceNotificationCount = presenceNotification(
    studentLessonProgress?.scheduledLessonReport?.presence,
  );

  if (isLoading) {
    return (
      <Skeleton className="w-52 h-[81px] bg-primary-content shrink-0 border-b border-neutral-content" />
    );
  }

  if (!studentLessonProgress?.id) {
    return (
      <Text
        className="flex w-52 h-[81px] p-3.5 items-center justify-center text-14 text-center border-b border-neutral-content text-base-content/50"
        text={t('klassProgressPage.noProgress')}
      />
    );
  }

  return (
    <div
      data-testid={testId}
      className="w-fit h-fit border-b border-neutral-content"
    >
      <div
        className={`flex gap-6 relative shrink-0 justify-center items-center w-52 p-3.5 h-20 ${
          isDisabled ? 'grayscale-[20] opacity-30' : ''
        }`}
      >
        <ConditionalRenderer condition={isTransfer}>
          <div className="absolute top-0 h-full w-3 rounded-r-md left-0 bg-primary-content text-12">
            <Text
              className="rotate-90 mt-2"
              text={t('klassProgressPage.transferred')}
            />
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={bookNotificatonCount !== -1}>
          <KlassesIcon
            notificationCount={bookNotificatonCount}
            type="studentBook"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={activityNotification !== -1}>
          <KlassesIcon
            notificationCount={activityNotification}
            type="activity"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={studentLessonProgress}>
          <KlassesIcon
            disabled={presenceNotificationCount === -1}
            notificationCount={presenceNotificationCount}
            type="attendance"
          />
        </ConditionalRenderer>
      </div>
    </div>
  );
}
