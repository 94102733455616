import alert from '@/utils/UseAlert';
import { GameError, LogExceptionHandler } from '@ctrlplayteam/astro-code';

export class GameExceptionHandler extends LogExceptionHandler {
  tFunc: (key: string) => string;
  constructor(tFunc: (key: string) => string) {
    super();
    this.tFunc = tFunc;
  }
  catchException(e: GameError) {
    super.catchException(e);
    alert.error(this.tFunc(e.code));
  }
}
