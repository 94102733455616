import React from 'react';
import chapterFormatter from '../../functions/chapterFormatter';
import Chapter from '../../models/Chapter';
import BodyText from './BodyText';
import { useTranslation } from 'react-i18next';
import { HtmlPreview } from './dataDisplay/HtmlPreview';
import { stringToStringArray } from '../../utils/stringToStringArray';

interface ChapterRenderProps {
  chapter?: Chapter;
}

export default function ChapterRender({ chapter }: ChapterRenderProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'klassView' });

  if (!chapter) {
    return <BodyText text={t('noneMaterial')} className="text-base-content" />;
  }

  return (
    <div>
      <Background bottom />
      <Background />
      <ChapterHandler chapter={chapter} />
    </div>
  );
}

interface ChapterHandlerProps {
  chapter: Chapter;
}

const ChapterHandler: React.FC<ChapterHandlerProps> = ({ chapter }) => {
  const isOldBook = chapter?.content[0] === '[';
  const chapterContent = isOldBook
    ? chapterFormatter(chapter.title, stringToStringArray(chapter.content))
    : chapter.content;

  return <HtmlPreview format="book" html={chapterContent} />;
};

function Background({ bottom }: { bottom?: boolean }) {
  return (
    <div
      className={`content-none pointer-events-none w-9/12 h-96 bg-cover opacity-10 absolute 
          ${bottom ? 'bottom-0 right-0 -scale-x-100' : 'top-0 left-0'} `}
      style={{
        backgroundImage: 'url(/images/background-book.png)',
        transform: `scale(${bottom ? -1 : 1})`,
        zIndex: 1,
      }}
    />
  );
}
