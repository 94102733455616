import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '@/components/template/Layout';
import AdminButton from '@/components/common/AdminButton';
import useAdminContext from '@/data/hook/admin';
import Text from '@/components/common/dataDisplay/Text';
import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';
import LongCollapse from '@/components/common/dataDisplay/LongCollapse';
import Skeleton from '@/components/common/Skeleton';
import AddTrialLesson from '@/components/admin/team/AddTrialLesson';
import { CollapseContent } from './ManageTrialContent';
import TableStatistics from '@/components/common/table/TableStatistics';
import HeadTitle from '@/components/common/HeadTitle';
import { isAdmin } from '@/functions/auth';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import {
  scheduledDaysQueryKeys,
  unitsQueryKeys,
} from '@/data/services/querykeys';
import useListService from '@/data/hook/useListService';

export default function ManageTrialLesson() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTrialLesson',
  });

  const {
    results: trialLessonList,
    isInitialLoading: isLoadingLessons,
    invalidate,
  } = useListService({
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...scheduledDaysQueryKeys.list(),
  });

  const totalSeats = trialLessonList
    ? trialLessonList.reduce((count, lesson) => {
        return count + lesson.seats;
      }, 0)
    : 0;

  const { teachers } = useAdminContext();

  const { user: userLoged } = useAuth();

  const authorizedUser = isAdmin(userLoged?.userType);

  const { results: units } = useListService({
    ...unitsQueryKeys.list({ idIn: getAuthorizedUnits(userLoged)?.toString() }),
    enabled: authorizedUser,
  });

  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const seatsPerTeacher = parseFloat((totalSeats / teachers.length).toFixed(1));

  useEffect(() => {
    setOpen(userLoged?.userType === UserTypeEnum.UNIT_ADMIN);
    return () => setOpen(false);
  }, [userLoged?.userType]);

  const statisticsCards = [
    {
      title: t('totalSeats'),
      info: totalSeats || 0,
      isLoading: isLoadingLessons,
    },
    {
      title: t('seatsPerTeacher'),
      info: seatsPerTeacher || 0,
      isLoading: isLoadingLessons || seatsPerTeacher === Infinity,
    },
  ];

  const loading = isLoadingLessons || !trialLessonList;

  return (
    <Layout>
      <HeadTitle routeInfo={t('trialLessons')} />
      <div className="flex flex-col w-full gap-4">
        <TableStatistics
          headingText={t('title')}
          statisticsCards={statisticsCards}
          roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          adminButton={
            <AdminButton
              title={t('add')}
              testId="trialLessonButton"
              onClick={() => setOpenForm(!openForm)}
              open={openForm}
            />
          }
        />
        <ConditionalRenderer condition={openForm}>
          <div className="flex justify-end w-full">
            <AddTrialLesson setOpenForm={setOpenForm} />
          </div>
        </ConditionalRenderer>

        <div className="flex flex-col gap-2.5">
          {units.map(unit => {
            return (
              <LongCollapse
                key={unit.id}
                testId={`longCollapse${unit.id}`}
                header={<Text format="rubik-500" text={unit.name} />}
                open={open}
              >
                <ConditionalRenderer
                  condition={loading}
                  fallback={
                    <CollapseContent
                      invalidate={invalidate}
                      unitLessons={trialLessonList.filter(
                        lesson => lesson.unitId === unit.id,
                      )}
                    />
                  }
                >
                  <Skeleton className="w-full rounded-xl bg-primary-content h-12" />
                </ConditionalRenderer>
              </LongCollapse>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
