import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isEqual } from 'lodash';

import User from '@/models/User';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TextInput from '@/components/common/dataInput/TextInput';
import UsernameInput from '@/components/common/dataInput/UsernameInput';
import { postUsername, postEmail } from '@/data/services/userServices';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import { meQueryKeys } from '@/data/services/querykeys';

export type AccountFormProps = {
  user?: User;
  editing?: boolean;
  setEditing?: (state: boolean) => void;
};

export default function AccountForm({
  user,
  editing,
  setEditing,
}: AccountFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountPage',
  });

  type AccountFields = {
    username?: string;
    email?: string;
  };

  const defaultValues: AccountFields = useMemo(() => {
    return {
      username: user?.username,
      email: user?.email,
    };
  }, [user?.email, user?.username]);

  const { register, handleSubmit, reset, control, watch } =
    useForm<AccountFields>({
      defaultValues,
    });

  const [hasChanges, setHasChanges] = useState(false);

  const queryClient = useQueryClient();

  const invalidate = async () =>
    await queryClient.invalidateQueries(meQueryKeys.get._def);

  const { mutateAsync: updateUsername, isLoading: updatingUsername } =
    useMutation(postUsername);

  const { mutateAsync: updateEmail, isLoading: updatingEmail } =
    useMutation(postEmail);

  const updatingAccount = updatingUsername || updatingEmail;

  const onSubmitInfo: SubmitHandler<AccountFields> = async ({
    username,
    email,
  }) => {
    const hasChangedUsername = username && !isEqual(username, user?.username);
    const hasChangedEmail = email && !isEqual(email, user?.email);

    const hasChanges = hasChangedUsername || hasChangedEmail;

    try {
      if (hasChanges) {
        if (hasChangedUsername) {
          await updateUsername(username);
        }

        if (hasChangedEmail) {
          await updateEmail(email);
        }
        alert.success(t('saveSuccess'));
        await invalidate();
        setHasChanges(false);
      }
    } catch (error) {
      alert.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => subscribe.unsubscribe();
  }, [defaultValues, watch]);

  const hasEmptyUsername = !watch('username');
  const hasEmptyEmail = !watch('email');

  const hasEmptyField = hasEmptyUsername || hasEmptyEmail;

  const onReset = () => {
    reset();
    setEditing?.(false);
    setHasChanges(false);
  };

  if (user) {
    return (
      <form
        data-testid="redefineAccountInfo"
        onSubmit={handleSubmit(onSubmitInfo)}
        className="grid gap-4"
      >
        <div className="flex flex-col sm:flex-row gap-5">
          <TextInput
            label={t('fieldEmail')}
            testId="emailField"
            disabled={!editing}
            register={register('email', {
              onChange: () => setHasChanges(true),
            })}
            defaultValue={user.email}
            className="w-full sm:w-72 md:w-80 lg:w-96"
          />

          <UsernameInput
            testId="usernameField"
            control={control}
            onChange={() => setHasChanges(true)}
            user={user}
            className="w-full sm:w-72 md:w-80 lg:w-96"
            disabled={!editing}
            errorLabelPosition="bottom"
          />
        </div>
        <ConditionalRenderer condition={editing}>
          <SaveCancelGroup
            disable={!editing}
            loading={updatingAccount}
            save={{
              disabled: !hasChanges || hasEmptyField,
              testId: 'saveButton',
            }}
            cancel={{
              onClick: onReset,
              testId: 'cancelButton',
              disabled: updatingAccount,
            }}
          />
        </ConditionalRenderer>
      </form>
    );
  }

  return null;
}
