import { LogoutIcon } from '@heroicons/react/outline';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../data/hook/useAuth';
import useSidebar from '../../../data/hook/useSidebar';
import ComponentGuard from '../../common/ComponentGuard';
import { AvatarBadge } from '../../common/dataDisplay/AvatarBadge';
import SidebarItem from '../SidebarItem';
import useOptions from './Options';
import { RewardsView } from '../../common/dataDisplay/RewardsView';
import ConditionalRenderer from '../../common/ConditionalRenderer';

function SidebarMobile() {
  const { t } = useTranslation('translation', { keyPrefix: 'sideMenu' });
  const { logout } = useAuth();
  const { isOpen, UpdateSidebarState } = useSidebar();
  const overlayRef = useRef<HTMLElement>(null);
  const options = useOptions();
  const closeMenu = () => UpdateSidebarState(false);
  const { user } = useAuth();
  const handleOverlayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const isOverlayComponent = overlayRef.current === e.target;
    if (isOpen && isOverlayComponent) {
      UpdateSidebarState(false);
    }
  };

  return (
    <aside
      onClick={handleOverlayClick}
      ref={overlayRef}
      className={`${
        isOpen
          ? 'fixed lg:hidden w-full h-screen bg-black bg-opacity-30'
          : 'absolute'
      } z-50 right-0 `}
    >
      <label
        data-testid="sidebarMobileButton"
        className="swap swap-rotate z-[499] absolute top-3 right-3 lg:hidden w-8 h-8"
      >
        <input
          onChange={e => {
            UpdateSidebarState(e.target.checked);
          }}
          checked={isOpen}
          type="checkbox"
        />
        <XIcon className="swap-on text-base-100 w-8 h-8" />
        <MenuIcon className="swap-off text-primary w-8 h-8" />
      </label>
      <div
        data-testid="sidebarComponentMobile"
        id="sidebar"
        className={`fixed ${
          isOpen ? 'right-0' : '-right-[100vw]'
        } bg-gradient-to-t from-primary transition-all duration-400  to-secondary h-full flex z-[498]  flex-col justify-between px-10 pt-[3vh] pb-[5vh] w-[80%] sm:w-[50%]`}
      >
        <div className="flex flex-col gap-[5vh]">
          <div className="flex flex-col gap-2">
            <img
              className="w-full max-w-[144px] self-start"
              src="/images/CtrlPlayFullLogoWhite.png"
              alt="Ctrl+Play"
            />
            <div className="flex justify-center gap-3 lg:hidden">
              <RewardsView />
              <ConditionalRenderer condition={user}>
                <AvatarBadge
                  testId="sidebarProfile"
                  size="14"
                  onClick={closeMenu}
                  user={user}
                />
              </ConditionalRenderer>
            </div>
          </div>
          <div className="flex flex-col gap-[3vh]">
            {options.map(({ renderCondition, ...option }, index) => {
              const handleRenderCondition = renderCondition.hasCondition
                ? renderCondition.condition
                : undefined;
              if (option.roles === 'ALL') {
                return (
                  <SidebarItem
                    key={index}
                    url={option.url}
                    testId={option.testId + 'Mobile'}
                    isMenuOpen={isOpen}
                    icon={option.icon}
                    text={option.title}
                    renderCondition={handleRenderCondition}
                    onClick={closeMenu}
                  />
                );
              } else {
                return (
                  <ComponentGuard key={index} roles={option.roles}>
                    <SidebarItem
                      url={option.url}
                      testId={option.testId + 'Mobile'}
                      renderCondition={handleRenderCondition}
                      isMenuOpen={isOpen}
                      icon={option.icon}
                      text={option.title}
                      onClick={closeMenu}
                    />
                  </ComponentGuard>
                );
              }
            })}
          </div>
        </div>
        <SidebarItem
          url="/login"
          isMenuOpen={isOpen}
          onClick={() => {
            UpdateSidebarState(false);
            logout!();
          }}
          icon={<LogoutIcon />}
          testId="sidebarLogoutMobile"
          text={t('leave')}
        />
      </div>
    </aside>
  );
}

export default SidebarMobile;
