import { useState } from 'react';
import Link from '../../components/common/LinkText';
import RoundedButton from '../../components/common/buttons/RoundedButton';
import Text from '../../components/common/dataDisplay/Text';
import TextInput from '../../components/common/dataInput/TextInput';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import requester from '../../axios';
import { ApiError } from '../../models/Errors';
import Modal from '../../components/common/modals/Modal';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { UserTypeEnum } from '../../models/User';
import RadioButton from '../../components/common/buttons/radioButton';
import HeadTitle from '../../components/common/HeadTitle';

interface Login_FieldsProps {
  id?: string;
  onChangeMode(mode: string): void;
}

interface Inputs {
  email: string;
  userType?: UserTypeEnum;
  username?: string;
}

interface IRequestResult {
  type: 'none' | 'sucess' | 'error' | 'warning';
  message: string;
  title?: string;
}

function FormInstruction({
  result,
  close,
}: {
  result: IRequestResult;
  close: () => void;
}) {
  switch (result.type) {
    case 'sucess':
      return (
        <Modal closeButtonTestId="closeButton" onClose={close} visible>
          <div
            data-testid="successModal"
            className="flex flex-col items-center gap-4"
          >
            <Text
              format="poppins-600"
              size="text-20"
              className="text-primary"
              text={result.title}
            />
            <CheckCircleIcon className="w-20 h-w-20 text-success" />
            <Text text={result.message} className="text-center" />
          </div>
        </Modal>
      );
    case 'warning':
      return (
        <Text
          size="text-16"
          format="rubik-400"
          text={`${result.message}`}
          className="text-warning bg-warning-content text-center py-1 rounded-md px-3 max-w-48"
        />
      );
    case 'error':
      return (
        <Text
          size="text-16"
          format="rubik-400"
          text={`${result.message}`}
          className="text-error bg-error-content text-center py-1 rounded-md px-3 max-w-48"
        />
      );
    default:
      return (
        <Text
          size="text-16"
          format="rubik-400"
          text={result.message}
          className="text-center text-base-content"
        />
      );
  }
}

export default function SendEmail(props: Login_FieldsProps) {
  const { id, onChangeMode } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'login.sendEmail',
  });
  const { t: commonT } = useTranslation('translation', {
    keyPrefix: 'common',
  });
  const initialRequestResultState: IRequestResult = {
    type: 'none',
    message: t('emailPlaceholder'),
  };
  const { register, handleSubmit, watch } = useForm<Inputs>();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isParent, setIsParent] = useState(false);

  const [requestResult, setRequestResult] = useState<IRequestResult>(
    initialRequestResultState,
  );

  const isValidateParentCase = ({ userType, username }: Partial<Inputs>) => {
    if (!userType) {
      setRequestResult({
        message: t('userTypeRequired'),
        type: 'error',
      });
      return false;
    } else if (userType === UserTypeEnum.STUDENT && !username) {
      setRequestResult({
        message: t('usernameRequired'),
        type: 'error',
      });
      return false;
    } else {
      return true;
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async ({
    email,
    userType,
    username,
  }) => {
    try {
      setIsRequesting(true);
      setRequestResult(initialRequestResultState);
      if (isParent && !isValidateParentCase({ userType, username })) {
        return;
      }
      await requester().post<void, null, Inputs>('users/reset_password/', {
        email,
        userType,
        username,
      });
      setRequestResult({
        title: t('emailSent'),
        type: 'sucess',
        message: t('checkEmail'),
      });
    } catch (err: any) {
      const api = new ApiError(err);

      if (api.statusCode === 400) {
        const isUserTypeError = api.codes.includes(
          'reset_password_validation_error',
        );
        setIsParent(isUserTypeError);
        setRequestResult({
          type: isUserTypeError ? 'warning' : 'error',
          message: isUserTypeError ? t('specifyUser') : api.getErrorMessage(),
        });
      } else {
        setRequestResult({
          type: 'error',
          message: api.getErrorMessage(),
        });
      }
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <div id={id} className="flex flex-col">
      <HeadTitle routeInfo={t('forgotPassword')} />

      <Text
        format="rubik-500"
        text={t('weRecoverPassword')}
        className="pb-1 text-center text-primary text-24"
      />

      <span className="flex justify-center pb-12">
        <FormInstruction
          result={requestResult}
          close={() => setRequestResult(initialRequestResultState)}
        />
      </span>

      <form onSubmit={handleSubmit(onSubmit)} data-testid="sendEmailForm">
        {isParent && (
          <div className="flex items-center gap-4 mb-2">
            <RadioButton
              testId="parentChoice"
              register={register('userType')}
              value={UserTypeEnum.PARENT}
              text={commonT(`userType.${UserTypeEnum.PARENT}`)}
            />
            <RadioButton
              testId="studentChoice"
              register={register('userType')}
              value={UserTypeEnum.STUDENT}
              text={commonT(`userType.${UserTypeEnum.STUDENT}`)}
            />
          </div>
        )}
        <TextInput
          register={register('email')}
          aria-label="email"
          placeholder={t('emailPlaceholder')}
          className="mx-auto mb-6 "
          fontSize="text-14"
          fontWeight="font-400"
          required
          typeInput="login"
          type="email"
          name="email"
          label={`${t('emailLabel')}:`}
        />
        {watch('userType') === UserTypeEnum.STUDENT && (
          <TextInput
            register={register('username')}
            className="mx-auto mb-6 "
            fontSize="text-14"
            placeholder={t('usernamePlaceholder')}
            fontWeight="font-400"
            typeInput="login"
            label={'username:'}
          />
        )}
        <span className="flex justify-end">
          <Link
            id="loginLink"
            text={t('back')}
            onClick={() => onChangeMode('login')}
          />
        </span>

        <div className="flex justify-center">
          <RoundedButton
            loading={isRequesting}
            text={t('submit')}
            className="w-[50%]"
          />
        </div>
      </form>
    </div>
  );
}
