import { CalendarIcon } from '@heroicons/react/outline';
import React from 'react';
import { DayPicker, Matcher, useInput } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { ptBR, enUS } from 'date-fns/locale';
import ConditionalRenderer from '../ConditionalRenderer';
import moment from 'moment-timezone';
import { BaseInputProps } from './BaseInput';
import Popper from '../dataDisplay/Popper';
import { twMerge } from 'tailwind-merge';
import { timezonedToBrowserDate } from '@/utils/datetimes';

interface DateInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    BaseInputProps {
  position?: string;
  onDateChange: (newValue: string) => void;
  disabled?: boolean;
  disabledDates?: Matcher[];
  value?: string;
  timezone?: string;
}

export default function DateInput(props: DateInputProps) {
  const {
    placeholder,
    errorLabelPosition,
    errorLabelText,
    fontSize,
    className,
    register,
    testId,
    position,
    onDateChange,
    disabled,
    disabledDates,
    label,
    labelPosition = 'top',
    labelWidth = 'w-36',
    value,
    timezone = 'America/Sao_Paulo',
    ...rest
  } = props;
  const {
    i18n: { language },
  } = useTranslation();
  const localeLanguage = {
    locale: language === 'pt_BR' ? ptBR : enUS,
  };

  const { inputProps, dayPickerProps, setSelected } = useInput({
    fromYear: 1960,
    toYear: new Date().getFullYear(),
    locale: localeLanguage.locale,
    format: 'dd/MM/yyyy',
    required: true,
    defaultSelected: value
      ? timezonedToBrowserDate(value, timezone)
      : undefined,
  });

  type SetDateParams = {
    dateDisplay: Date;
    dateValue: string;
  };

  const setDate = ({ dateDisplay, dateValue }: SetDateParams) => {
    onDateChange(dateValue);
    setSelected(dateDisplay);
  };

  const getCorrectDate = (dateSelected?: Date): SetDateParams | undefined => {
    if (dateSelected) {
      return {
        dateDisplay: dateSelected,
        dateValue: moment(dateSelected)
          .clone()
          .tz(timezone)
          .format('YYYY-MM-DD[T]HH:mm:ssZ'),
      };
    }
  };

  const onSelectDate = (selectedDate?: Date) => {
    const correctDate = getCorrectDate(selectedDate);
    if (correctDate) setDate(correctDate);
  };

  const onBlur = () => {
    if (dayPickerProps.selected) {
      onDateChange(dayPickerProps.selected.toISOString());
    }
  };

  const localeMoment = () => {
    const brwoserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(dayPickerProps.selected).tz(brwoserTimezone).locale(language);
  };

  return (
    <div className={twMerge('flex items-center relative w-full', className)}>
      <div
        className={`flex  ${
          labelPosition === 'left' ? 'flex-row w-full gap-3' : 'flex-col'
        }`}
      >
        <ConditionalRenderer condition={label}>
          <label
            className={`label p-0 ${
              labelPosition === 'left'
                ? labelWidth + ' font-500 justify-start'
                : 'text-14'
            } `}
          >
            {label}
          </label>
        </ConditionalRenderer>
        <div className="flex-col w-full justify-center flex">
          <ConditionalRenderer condition={errorLabelText}>
            <label
              data-testid="errorLabel"
              className={`label text-error p-0 ${
                errorLabelPosition === 'bottom'
                  ? 'absolute top-full w-full'
                  : ''
              } ${fontSize || 'text-14'}`}
            >
              {errorLabelText}
            </label>
          </ConditionalRenderer>

          <Popper
            referenceClassName="w-full"
            referenceContent={
              <div className="relative flex items-center w-full">
                <input
                  {...rest}
                  {...inputProps}
                  value={value ? localeMoment().format('L') : inputProps.value}
                  data-testid={testId}
                  placeholder={placeholder}
                  className={twMerge(
                    'border-primary focus-visible:ring disabled:border-transparent focus:outline-none input input-bordered bg-base-100 input-sm pt-0.5 placeholder:font-400 font-500 w-full',
                    className,
                  )}
                  disabled={disabled}
                  onBlur={onBlur}
                />
                <ConditionalRenderer condition={!disabled}>
                  <label className="text-primary w-4 h-4 absolute right-2.5 cursor-pointer">
                    <CalendarIcon data-testid="calendarButton" />
                  </label>
                </ConditionalRenderer>
              </div>
            }
            popperContent={
              <DayPicker
                {...register}
                {...dayPickerProps}
                disabled={disabledDates}
                mode="single"
                captionLayout="dropdown-buttons"
                className="bg-base-content rounded-lg p-4 shadow-default shadow-neutral-content"
                onSelect={onSelectDate}
                modifiersClassNames={{
                  selected: 'bg-secondary/40 text-base-100',
                  today: 'font-600',
                }}
                classNames={{
                  head: 'text-primary',
                  day: 'text-base-content rounded rounded-full hover:bg-base-300 hover:text-base-100 focus:bg-base-100 w-full aspect-square',
                  caption: 'capitalize flex flex-row justify-between',
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
