import React from 'react';
import { useTranslation } from 'react-i18next';
import ActivityProgress from '@/models/ActivityProgress';
import RoundedButton from '../../common/buttons/RoundedButton';
import { useMutation } from '@tanstack/react-query';
import useLessonContext from '@/data/hook/lesson';
import { updateActivityProgress } from '@/data/services/activityProgressServices';

interface StudentChallengeProgressProps {
  challengeProgress?: ActivityProgress | null;
}

function StudentChallengeProgress({
  challengeProgress,
}: StudentChallengeProgressProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.challengeView',
  });
  const hasDone = challengeProgress?.hasDone;
  const { updateLessonProgress, lesson } = useLessonContext();

  const updateChallengeProgress = async () => {
    if (!challengeProgress?.id) throw new Error('No challenge progress id');
    const result = await updateActivityProgress(challengeProgress.id, {
      hasDone: true,
    });
    return result;
  };

  const { mutate: onUpdate, isLoading } = useMutation(updateChallengeProgress, {
    onSuccess: async () => {
      if (lesson && lesson.id) await updateLessonProgress(lesson.id);
    },
  });

  if (!challengeProgress) {
    return <React.Fragment />;
  }

  return (
    <RoundedButton
      testId="viewedButton"
      className="w-min self-end"
      text={t('visualized')}
      onClick={() => onUpdate()}
      disabled={hasDone}
      loading={isLoading}
      color={hasDone ? 'success' : 'gradient'}
    />
  );
}

export default StudentChallengeProgress;
