import { Popover, PopoverProps } from 'antd';
import React from 'react';

interface AntdTooltipProps extends PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  testId?: string;
  className?: string;
}

const AntdTooltip = (props: AntdTooltipProps) => {
  return (
    <Popover
      {...props}
      data-testid={props.testId}
      id="calendar-page"
      className={`cursor-default ${props.className || ''}`}
      overlay="border border-primary rounded-2.5"
      content={props.content}
      overlayInnerStyle={{
        padding: '10px 12px',
        borderRadius: '0.625rem',
        border: 'solid 1px var(--main-blue)',
      }}
    >
      {props.children}
    </Popover>
  );
};

export default AntdTooltip;
