import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Answer from './quiz/Answer';
import { QuizActivityProgress, QuizQuestion } from '@/models/ActivityProgress';
import LoadingView from '@/pages/courses/LoadingView';
import { ElementPreviewFactoryProps } from './ElementPreviewFactory';
import { QuizActivityElement, QuizAnswersType } from '@/models/ActivityElement';
import ActivityHeading from './ActivityHeading';
import useActivityView from '@/data/hook/useActivityView';
import { updateQuizProgress } from '@/data/services/activityElement/quizProgressServices';
import alert from '@/utils/UseAlert';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import ErrorComponent from '@/components/common/ErrorComponent';
import { QuestionAttempt, QuizAttemptAnswer } from '@/models/QuestionAttempt';
import { QuizAnswer } from '@/models/QuestionAnswer';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  quizActivitiesQueryKeys,
  quizActivityProgressesQueryKeys,
} from '@/data/services/querykeys';

export default function QuizElement(props: ElementPreviewFactoryProps) {
  const { activityMode, questionId, progressId, attemptElement, answer } =
    props;

  const { user } = useAuth();

  const queryClient = useQueryClient();

  const { blockStepNavigate, invalidate, status } = useActivityView();

  const studentView = isStudent(user?.userType);

  const { queryFn: quizProgressQueryFn, queryKey: progressKey } =
    quizActivityProgressesQueryKeys.get({
      quizId: questionId,
      quizProgressId: progressId,
    });

  const {
    data: questionProgress,
    isInitialLoading: loadingQuestionProgress,
    error: questionProgressError,
  } = useQuery({
    queryFn: quizProgressQueryFn,
    queryKey: progressKey,
    enabled:
      studentView && !!progressId && !!questionId && status === 'inProgress',
    keepPreviousData: true,
  });

  const questionProgressErrorDetail = getErrorMessage(questionProgressError);

  const {
    data: question,
    isInitialLoading: loadingQuestion,
    error: questionError,
  } = useQuery({
    ...quizActivitiesQueryKeys.get(questionId),
    enabled: !studentView && !!questionId,
  });

  const questionErrorDetail = getErrorMessage(questionError);

  const saveAnswer = async (answer: QuizQuestion) => {
    blockStepNavigate(true);
    const questionProgress = await updateQuizProgress(
      { quizId: questionId, quizProgressId: progressId },
      {
        isDone: true,
        answer: answer.answerVerifier,
      },
    );

    return questionProgress;
  };

  const { mutateAsync: updateAnswer } = useMutation(saveAnswer, {
    async onSuccess() {
      await queryClient.invalidateQueries(progressKey);
      await invalidate();
    },

    onError(error: any) {
      alert.error(getErrorMessage(error));
    },

    onSettled() {
      blockStepNavigate(false);
    },
  });

  const loading = loadingQuestionProgress || loadingQuestion;

  const errorDetail = questionProgressErrorDetail || questionErrorDetail;

  if (loading) {
    return <LoadingView />;
  }

  if (errorDetail) {
    return <ErrorComponent errorTextTitle={errorDetail} />;
  }

  if (questionProgress) {
    return (
      <Element
        activityMode={activityMode}
        updateAnswer={updateAnswer}
        answer={answer}
        attemptElement={attemptElement}
        questionProgress={questionProgress}
      />
    );
  }

  if (question) {
    return (
      <Element
        question={question}
        activityMode={activityMode}
        answer={answer}
      />
    );
  }

  return <Fragment />;
}

type ElementProps = Pick<
  ElementPreviewFactoryProps,
  'activityMode' | 'answer'
> & {
  questionProgress?: QuizActivityProgress;
  question?: QuizActivityElement;
  updateAnswer?: (answer: QuizQuestion) => Promise<QuizActivityProgress>;
  attemptElement?: any;
};

function Element({
  question,
  activityMode,
  answer,
  attemptElement,
  updateAnswer,
  questionProgress,
}: ElementProps) {
  if (questionProgress) {
    const { quizActivity: question, answer: progressAnswer } = questionProgress;

    const selectedAnswer = () => {
      if (attemptElement && attemptElement?.answers) {
        const attempt = new QuestionAttempt(attemptElement);

        const answers = new QuizAttemptAnswer(attempt.answers);

        return question.quizAnswers.find(
          selected => selected.answerVerifier === answers.answer,
        );
      }

      return question.quizAnswers.find(
        selected => selected.answerVerifier === progressAnswer,
      );
    };

    return (
      <Content
        question={question}
        activityMode={activityMode}
        updateAnswer={updateAnswer}
        selectedAnswer={selectedAnswer()}
      />
    );
  }

  if (question) {
    const correctAnswer = () => {
      if (answer) {
        const quizAnswer = answer as QuizAnswer;

        return quizAnswer.correctAnswer;
      }
    };

    return (
      <Content
        question={question}
        activityMode={activityMode}
        selectedAnswer={correctAnswer()}
      />
    );
  }

  return <Fragment />;
}

type ContentProps = Pick<ElementPreviewFactoryProps, 'activityMode'> & {
  question: QuizActivityElement;
  updateAnswer?: (answer: QuizQuestion) => Promise<QuizActivityProgress>;
  selectedAnswer?: QuizQuestion;
};

function Content({
  question,
  activityMode,
  updateAnswer,
  selectedAnswer,
}: ContentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.quiz',
  });

  const [selected, setSelected] = useState<QuizQuestion>();

  useEffect(() => {
    setSelected(selectedAnswer);
    return () => setSelected(undefined);
  }, [selectedAnswer]);

  const imageType = question.answerType === QuizAnswersType.IMAGE;

  const className = imageType
    ? 'grid grid-cols-1 md:grid-cols-2 items-stretch'
    : 'flex';

  const updateSelectedAnswer = async (answer: QuizQuestion) => {
    setSelected(answer);
    await updateAnswer?.(answer);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-8 w-full">
      <ActivityHeading
        heading={question.question}
        content={question.content}
        subheading={t('statement')}
      />
      <div
        className={`${className} flex-col w-full gap-7 justify-center items-center`}
      >
        {question.quizAnswers.map((answer, index) => (
          <Answer
            answerType={question.answerType}
            key={answer.id}
            value={answer as QuizQuestion}
            index={index}
            selectedAnswer={selected as QuizQuestion}
            disabled={activityMode !== 'activity'}
            updateAnswer={updateSelectedAnswer}
          />
        ))}
      </div>
    </div>
  );
}
