import Popper from '@/components/common/dataDisplay/Popper';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Control } from 'react-hook-form';
import { CodeFields } from './CodeElementEditForm';
import { WorkspaceOptionsForm } from './WorkspaceOptionsForm';
import { useTranslation } from 'react-i18next';
import { Workspace } from '@ctrlplayteam/astro-code';

export type WorkspaceConfigProps = {
  control: Control<CodeFields>;
  values: Partial<CodeFields>;
  workspace?: Workspace;
};

const WorkspaceConfig = ({
  control,
  values: defaultValues,
  workspace,
}: WorkspaceConfigProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityCode.workspaceOptions',
  });
  return (
    <div className="absolute right-2 top-2">
      <Popper
        referenceContent={
          <div className="border-2 border-primary flex gap-3 py-1 px-2 items-center rounded-md bg-base-100">
            {t('title')}
            <ChevronDownIcon className="h-5 text-primary" />
          </div>
        }
        placement="left-end"
        popperClassName="transform translate-y-9 -translate-x-[100%]"
        popperContent={
          <WorkspaceOptionsForm
            control={control}
            values={defaultValues}
            workspace={workspace}
          />
        }
        hover
      />
    </div>
  );
};

export default WorkspaceConfig;
