import React, { ReactNode, Fragment } from 'react';
import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';

interface ComponentGuardianProps {
  children?: ReactNode;
  roles: UserTypeEnum[];
}

function ComponentGuard({ children, roles }: ComponentGuardianProps) {
  const { user } = useAuth();
  if (!user?.userType) {
    return <Fragment />;
  }
  if (roles.length === 0) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  if (!roles.includes(user?.userType)) {
    return <Fragment />;
  }
  return <Fragment>{children}</Fragment>;
}

export default ComponentGuard;
