import { useState } from 'react';
import SearchInput, { SearchOption } from './SearchInput';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { BaseInputProps } from './BaseInput';
import { twMerge } from 'tailwind-merge';

type EnumInputType = 'numeric' | 'string';

type EnumInputProps<T> = BaseInputProps & {
  enumModel: any;
  selected?: T;
  displayName: (option: T) => string;
  onSelect(item: T): void;
  onDeselect?(): void;
  disabled?: boolean;
  type: EnumInputType;
  className?: string;
};

export default function EnumInput<T>({
  enumModel,
  selected,
  displayName,
  onSelect,
  onDeselect,
  label,
  errorLabelText,
  testId,
  disabled,
  type,
  className,
}: EnumInputProps<T>) {
  function getEnumValues() {
    const values = Object.values(enumModel) as (keyof typeof enumModel)[];
    if (type === 'numeric') {
      return values.filter(value => !isNaN(Number(value)));
    }

    return values;
  }

  const values = getEnumValues();

  const options = values.map<SearchOption<typeof enumModel>>(value => ({
    value,
    key: value.toString(),
  }));

  const [value, setValue] = useState(selected ? displayName(selected) : '');

  const handleSelect = (item: T) => {
    onSelect(item);
    setValue(displayName(item));
  };

  const handleDeselect = () => {
    onDeselect?.();
    setValue('');
  };

  return (
    <SearchInput
      input={{
        label: disabled ? undefined : label,
        errorLabelText,
        testId,
        disabled,
      }}
      className={twMerge('w-full', className)}
      options={options}
      onSelect={handleSelect}
      displayName={displayName}
      value={value}
      hasItemSelected={!!value}
      inputIcon={<ChevronDownIcon className="w-4 text-primary" />}
      onDeselect={handleDeselect}
    />
  );
}
