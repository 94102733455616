import { Link } from 'react-router-dom';
import { Button } from 'antd';

export default function PasswordReset() {
  return (
    <>
      <h1 className=" mx-auto text-center font-700 text-20 ">
        {' '}
        Senha mudada, clique abaixo para realizar login{' '}
      </h1>

      <Link to={`/login`}>
        <Button>Logar</Button>
      </Link>
    </>
  );
}
