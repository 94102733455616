import Text from '@/components/common/dataDisplay/Text';
import Skeleton from '@/components/common/Skeleton';
interface StatisticsCardProps {
  title: string;
  info: string | number;
  isLoading?: boolean;
  className?: string;
  testId?: string;
  size?: 'small' | 'regular';
}

export default function StatisticsCard(props: StatisticsCardProps) {
  const { className, info, title, isLoading, testId, size } = props;

  const text = typeof info === 'number' ? Math.round(info) : info;

  return (
    <div
      data-testid={testId}
      className={`flex ${
        size === 'small'
          ? 'px-3 xl:px-4 py-1.5 aspect-[5.7] xl:aspect-[4.9] h-9 xl:h-12 text-14 xl:text-16'
          : 'flex-col aspect-[2/1.5] h-28 xs:h-32 px-2 py-6 xs:px-4 xs:py-8'
      }  rounded-2xl shadow-default   w-auto justify-between items-center ${
        className || 'bg-base-100 text-secondary'
      }`}
    >
      <Text text={title} format="rubik-500" className="uppercase text-center" />
      {isLoading ? (
        <Skeleton className="bg-accent/40 rounded-xl w-6 h-4" />
      ) : (
        <Text format="rubik-500" text={text} className="text-base-content" />
      )}
    </div>
  );
}
