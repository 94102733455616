import { Fragment } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import getKlassStudents from '@/functions/klassActiveStudents';
import LoadingView from '@/pages/courses/LoadingView';
import ErrorComponent from '@/components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import ActivityItemDisplay from '../ActivityItemDisplay';
import ActivityProgress from '@/models/ActivityProgress';
import User from '@/models/User';
import ActivityAttemptInfo from '../ActivityAttemptInfo';
import { XIcon } from '@heroicons/react/solid';
import { createStepByAttempt } from '@/utils/activitiesSteps';
import useActivityView from '@/data/hook/useActivityView';
import SubmissionContainer from './SubmissionContainer';
import StudentActivitiesList from './StudentActivitiesList';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import useActivities from '@/data/hook/useActivities';
import Steps, { Step } from '@/components/common/Steps/Steps';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';
import {
  activityProgressAttemptsQueryKeys,
  activityProgressesQueryKeys,
  enrollmentsQueryKeys,
} from '@/data/services/querykeys';

export default function TeamActivityItem() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.teacher.activity',
  });

  const navigate = useNavigate();

  const { klassId, activityId, slugCourseName = '', lessonId } = useParams();

  const { activities } = useActivities({ activityIds: [Number(activityId)] });

  const activity = activities.length ? activities[0] : undefined;
  const {
    data: enrollmentsResponse,
    isInitialLoading: loadingEnrollments,
    isError: enrollmentsError,
  } = useQuery({
    ...enrollmentsQueryKeys.list({
      klassId: Number(klassId),
      status: [EnrollmentStatusEnum.ACTIVE],
    }),
  });
  const enrollments = enrollmentsResponse?.results ?? [];

  const students = getKlassStudents(enrollments) || [];

  const studentsQueries = useQueries({
    queries: students.map(student => ({
      ...activityProgressesQueryKeys.list({
        activityId: Number(activityId),
        user: student.id,
      }),
      enabled: !!students.length && !isNaN(Number(activityId)),
    })),
  });

  const goActivityView = () => {
    navigate(
      getKlassViewBaseUrl({
        klassId: Number(klassId),
        lessonId: Number(lessonId),
        slugCourseName,
      }) + `/activities/${activityId}/view`,
    );
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  if (activity?.status === 'loading') {
    return <LoadingView />;
  }

  if (activity?.status === 'error') {
    return (
      <ErrorComponent
        statusCode={404}
        errorTextSubTitle={t('errorTextSubTitle')}
        errorTextTitle={t('errorTextTitle')}
      />
    );
  }

  if (activity?.data) {
    return (
      <div>
        <ActivityItemDisplay
          isViewMode
          activity={activity.data}
          onClick={goActivityView}
        />
        <StudentActivitiesList
          students={students}
          isKlassLoading={loadingEnrollments}
          isKlassError={enrollmentsError}
        >
          {studentsQueries.map(
            (
              { data: progress, isInitialLoading: isLoading, isError },
              index,
            ) => (
              <Submission
                goActivity={goActivityView}
                key={students[index].id}
                {...{ isError, isLoading }}
                activityProgress={progress?.results.at(0)}
                student={students[index]}
              />
            ),
          )}
        </StudentActivitiesList>
      </div>
    );
  }

  return <Fragment />;
}

interface SubmissionProps {
  isLoading: boolean;
  isError: boolean;
  activityProgress?: ActivityProgress;
  student: User;
  goActivity(): void;
}

function Submission(props: SubmissionProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });
  const { activeStep, setActiveStep } = useActivityView();
  const { isError, isLoading, student, activityProgress, goActivity } = props;
  const { firstName } = student;
  const { isInitialLoading: loadingAttempt, data: attempt } = useQuery({
    enabled: !!activityProgress && !!activityProgress.bestAttempt,
    ...activityProgressAttemptsQueryKeys.get({
      activityProgressId: activityProgress?.id ?? 0,
      attemptId: activityProgress?.bestAttempt?.id ?? 0,
    }),
  });

  const onClick = (index: number) => {
    sessionStorage.setItem('astro@current_question', index.toString());
    setActiveStep(index);
    goActivity();
  };

  if (isLoading || loadingAttempt) {
    return (
      <SubmissionContainer isLoading={isLoading} studentName={firstName} />
    );
  }

  if (isError) {
    return <Fragment />;
  }

  if (activityProgress) {
    const { bestAttempt } = activityProgress;

    const score = (bestAttempt?.grade || 0) * 100;

    function NotDoneMessage() {
      return (
        <div className="flex gap-1 items-center">
          <XIcon className="w-4" />
          <span>{t('notDone')}</span>
        </div>
      );
    }

    return (
      <SubmissionContainer isLoading={isLoading} studentName={firstName}>
        {attempt?.content?.length && (
          <Steps
            activeStep={activeStep}
            steps={attempt?.content?.map<Step>(step =>
              createStepByAttempt(step, index => onClick(index)),
            )}
            hideIndicator
            loading={loadingAttempt}
          />
        )}

        <ActivityAttemptInfo
          text={
            <ConditionalRenderer
              condition={bestAttempt}
              fallback={<NotDoneMessage />}
            >
              {`${t('score').toUpperCase()}: ${Math.round(score)}%`}
            </ConditionalRenderer>
          }
          color={bestAttempt ? 'purple' : 'orage'}
        />
      </SubmissionContainer>
    );
  }

  return <Fragment />;
}
