import { useEffect, useState } from 'react';
import NumberInput from '../../dataInput/NumberInput';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type MatrixMeta = {
  cols: number;
  rows: number;
};

type ResizeMatrixInputsProps = {
  value: MatrixMeta;
  showLimits: boolean;
  onChange: (value: Partial<MatrixMeta>) => void;
  className?: string;
};

export default function ResizeMatrixInputs({
  value,
  onChange,
  showLimits,
  className,
}: ResizeMatrixInputsProps) {
  const [tEditor] = useTranslation('translation', { keyPrefix: 'game.edit' });
  const adjust = showLimits ? 0 : 2;

  const [ghostValue, setValue] = useState<MatrixMeta>(value);

  const handleChange =
    (key: keyof MatrixMeta) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value);
      if (value < 1) return;
      setValue({
        ...ghostValue,
        [key]: value + adjust,
      });
      if (value < 3 || value > 13) return;
      if (isNaN(value)) return;
      onChange({
        [key]: parseInt(e.target.value) + adjust,
      });
    };

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <div className={twMerge('flex gap-3 items-center', className)}>
      <NumberInput
        id="rows"
        className="w-10 h-8"
        value={ghostValue.rows - adjust}
        onChange={handleChange('rows')}
        max={13}
        onBlur={() => {
          onChange(ghostValue);
          setValue(value);
        }}
        label={tEditor('resize.rows')}
        labelPosition="left"
        showDecButton
        showIncButton
      />
      x
      <NumberInput
        id="cols"
        className="w-10 h-8"
        value={ghostValue.cols - adjust}
        onChange={handleChange('cols')}
        max={13}
        onBlur={() => {
          onChange(ghostValue);
          setValue(value);
        }}
        label={tEditor('resize.cols')}
        labelPosition="right"
        showDecButton
        showIncButton
      />
    </div>
  );
}
