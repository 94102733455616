import { ErrorCapturer } from '@/adapters/ErrorCapturer';
import InfinityList from '@/components/common/InfinityList';
import Skeleton from '@/components/common/Skeleton';
import LegalInformationCourseCollapse from '@/components/courses/LegalInformationCourseCollapse';
import LessonBar from '@/components/lessons/LessonBar/LessonBar';
import useLessonProgresses from '@/data/hook/useLessonProgresses';
import useScheduledLessons from '@/data/hook/useScheduledLessons';
import { LessonProgressMissingError } from '@/errors/lessonProgress';
import ScheduledLesson from '@/models/ScheduledLesson';
import LoadingView from '@/pages/courses/LoadingView';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { shouldShowScheduledLesson } from '@/utils/shouldShowScheduledLesson';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@/components/common/dataDisplay/Text';
import { useQuery } from '@tanstack/react-query';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

type StudentKlassLessonsProps = {
  klassId?: number;
  courseSlug?: string;
  nextLesson?: ScheduledLesson;
  studentId?: number;
};

export default function StudentKlassLessons({
  klassId,
  courseSlug,
  nextLesson,
  studentId,
}: StudentKlassLessonsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgress.lessonProgressList',
  });

  const { data: coursePath } = useQuery({
    ...coursePathsQueryKeys.get(courseSlug ?? ''),
    enabled: !!courseSlug,
    refetchOnMount: false,
  });

  const {
    results: scheduledLessons,
    error,
    fetchNextPage,
    isFetchingNextPage,
    loading,
    refetching,
    hasNextPage,
  } = useScheduledLessons({ klassId });

  const scheduledOptm = JSON.stringify(scheduledLessons);

  const scheduledLessonsMemo = useMemo(() => {
    const scheduledLessons = JSON.parse(scheduledOptm) as ScheduledLesson[];
    return scheduledLessons.map(scheduledLesson => {
      return (
        <LessonItem
          key={scheduledLesson.id}
          courseSlug={courseSlug}
          scheduledLesson={scheduledLesson}
          nextLesson={nextLesson}
          studentId={studentId}
        />
      );
    });
  }, [courseSlug, nextLesson, scheduledOptm, studentId]);

  if (loading || refetching) return <LoadingView classNameContent="h-fit" />;

  if (error) {
    return (
      <Text
        testId="error"
        text={error}
        format="rubik-500"
        className="text-primary"
      />
    );
  }

  if (scheduledLessons.length) {
    return (
      <InfinityList
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={fetchNextPage}
        className="gap-4"
      >
        {scheduledLessonsMemo}

        <LegalInformationCourseCollapse course={coursePath} />
      </InfinityList>
    );
  }

  return (
    <Text
      testId="noLessons"
      text={t('noLessons')}
      format="rubik-500"
      className="text-primary"
    />
  );
}

type LessonItemProps = {
  courseSlug?: string;
  scheduledLesson: ScheduledLesson;
  nextLesson?: ScheduledLesson;
  studentId?: number;
};

function LessonItem({
  scheduledLesson,
  courseSlug,
  nextLesson,
  studentId,
}: LessonItemProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgress.lessonProgressList',
  });

  const { lessonProgresses } = useLessonProgresses({
    lessonIds: [scheduledLesson.lesson.id],
  });

  const lessonProgress = lessonProgresses?.length
    ? lessonProgresses[0].data
    : undefined;

  const status = lessonProgresses?.length
    ? lessonProgresses[0].status
    : 'loading';

  const error = lessonProgresses?.length
    ? lessonProgresses[0].error
    : undefined;

  const throwError = useRef(false);
  useEffect(() => {
    if (!lessonProgress && status === 'success' && !throwError.current) {
      const lessonProgressError = new LessonProgressMissingError(
        studentId ?? 0,
        scheduledLesson.lesson.id,
      );
      const errorCapturer = new ErrorCapturer(lessonProgressError);
      errorCapturer.dispatchError();
      throwError.current = true;
    }
  }, [lessonProgress, scheduledLesson.lesson.id, studentId, status]);

  if (status === 'loading')
    return (
      <Skeleton className="flex h-[75px] w-full rounded-lg bg-secondary-content " />
    );

  if (status === 'error') {
    return (
      <Text
        testId="error"
        text={getErrorMessage(error) || t('generalError')}
        format="rubik-500"
        className="text-primary"
      />
    );
  }

  if (!lessonProgress) {
    return (
      <Text
        testId="didNotFindLesson"
        text={t('didNotFindLesson')}
        format="rubik-500"
        className="text-primary"
      />
    );
  }

  if (lessonProgress) {
    return (
      <LessonBar
        slugCourseName={courseSlug}
        lesson={scheduledLesson.lesson}
        color="secondary"
        scheduledLesson={scheduledLesson}
        lessonProgress={lessonProgress}
        blocked={
          !shouldShowScheduledLesson(
            scheduledLesson,
            lessonProgress,
            nextLesson,
          )
        }
      />
    );
  }

  return <Fragment />;
}
