import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { isStudent } from '@/functions/auth';
import JsonApiResponse from '@/models/JsonApiResponse';
import LessonProgress from '@/models/LessonProgress';
import { QueryKey, UseQueryResult, useQueries } from '@tanstack/react-query';
import useAuth from './useAuth';
import { lessonsProgressesQueryKeys } from '../services/querykeys';

type UseLessonProgressProps = {
  lessonIds?: number[];
};

export default function useLessonProgresses({
  lessonIds = [],
}: UseLessonProgressProps) {
  const { user } = useAuth();

  const studentView = isStudent(user?.userType);
  const parseIdIntoQuery = (lessonId: number) =>
    lessonsProgressesQueryKeys.list({
      lesson: lessonId,
      pageSize: 1,
      isActive: true,
    });

  const queries = useQueries({
    queries: lessonIds?.map(lessonId => {
      return {
        enabled: !!lessonId && studentView,
        keepPreviousData: true,
        staleTime: REQUEST_STALE_TIME_IN_MS,
        ...parseIdIntoQuery(lessonId),
      };
    }),
  });
  type QueryKeysMap = { [key: number]: QueryKey };
  const queryKeys: QueryKeysMap = lessonIds.reduce(
    (prev, lessonId) => ({
      ...prev,
      [lessonId]: parseIdIntoQuery(lessonId).queryKey,
    }),
    {},
  );

  const extractData = (
    query: UseQueryResult<JsonApiResponse<LessonProgress>, unknown>,
  ) => {
    return {
      data: query.data?.results?.[0],
      status: query.status,
      error: query.error,
    };
  };

  const extractDataList = () => queries.map(extractData);

  const lessonProgresses = extractDataList();

  return {
    lessonProgresses,
    queryKeys,
  };
}
