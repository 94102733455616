import Activity from './Activity';
import { MGActivityProgress } from './MGActivity';

export enum AssignmentType {
  TEXT = 'TEXT',
  NOTHING = 'NOTHING',
}

export interface HomeworkActivity {
  id: number;
  activity: Activity;
  content: string;
  assignmentType: AssignmentType;
  isActive: boolean;
}

export interface HomeworkActivityProgress {
  id: number;
  homework: HomeworkActivity;
  answer: string | null;
  status: HomeworkActivityProgressStatus | null;
  createdAt: string;
  isDone: boolean;
  activityProgress: MGActivityProgress;
}

export enum HomeworkActivityProgressStatus {
  SENT = 'SENT',
  GRADED = 'GRADED',
  DISAPPROVED = 'DISAPPROVED',
}
