import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DNDPositionalCorrectAnswer } from '@/models/ActivityProgress';
import { ElementPreviewFactoryProps } from '../ElementPreviewFactory';
import DNDPositionalColumns from './DNDPositionalColumns/DNDPositionalColumns';
import LoadingView from '@/pages/courses/LoadingView';
import useActivityView from '@/data/hook/useActivityView';
import DNDPositionalColumnsProgress from './DNDPositionalColumnsProgress/DNDPositionalColumnsProgress';
import { updateDNDPositionalActivityProgress } from '@/data/services/activityElement/dndPositionalActivityProgress';
import alert from '@/utils/UseAlert';
import {
  dndPositionalActivitiesProgressesQueryKeys,
  dndPositionalActivitiesQueryKeys,
} from '@/data/services/querykeys';

export interface DNDPositionalElementProps extends ElementPreviewFactoryProps {
  answer?: DNDPositionalCorrectAnswer | null;
}

export default function DNDPositionalElement(props: DNDPositionalElementProps) {
  const {
    activityMode,
    answer,
    isAdmin,
    questionId,
    progressId,
    attemptElement,
  } = props;
  const { blockStepNavigate, invalidate, status } = useActivityView();

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });

  const { queryKey: dndPositionalProgressQueryKey, queryFn: progressQueryFn } =
    dndPositionalActivitiesProgressesQueryKeys.get({ progressId, questionId });

  const queryClient = useQueryClient();

  const handleAnswer = async (hasChange: boolean) => {
    try {
      blockStepNavigate(true);
      const data = await updateDNDPositionalActivityProgress(
        {
          activityId: questionId,
          activityProgressId: progressId,
        },
        { isDone: hasChange },
      );

      queryClient.invalidateQueries(dndPositionalProgressQueryKey);

      if (data) {
        await invalidate();
      }
    } catch (error) {
      alert.error(t('answerError'));
    } finally {
      blockStepNavigate(false);
    }
  };

  const {
    data: dndPositionalActivity,
    isInitialLoading: isLoadingDndPositionalActivity,
  } = useQuery({
    enabled: !!isAdmin,
    ...dndPositionalActivitiesQueryKeys.get(questionId),
  });

  const {
    data: dndPositionalActivityProgress,
    isInitialLoading: isLoadingDndPositionalActivityProgress,
  } = useQuery({
    enabled:
      !isAdmin && !!questionId && !!progressId && status === 'inProgress',
    queryKey: dndPositionalProgressQueryKey,
    queryFn: progressQueryFn,
  });

  if (
    isLoadingDndPositionalActivity ||
    isLoadingDndPositionalActivityProgress
  ) {
    return <LoadingView />;
  }

  if (dndPositionalActivity) {
    return (
      <DNDPositionalColumns dndPositionalActivity={dndPositionalActivity} />
    );
  }

  if (dndPositionalActivityProgress) {
    return (
      <DNDPositionalColumnsProgress
        dndPositionalActivityProgress={dndPositionalActivityProgress}
        activityMode={activityMode}
        setHasChange={handleAnswer}
        attemptElement={attemptElement}
        answerColumn={answer?.answerColumn}
      />
    );
  }

  return null;
}
