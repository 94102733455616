import React from 'react';
import Text from '../common/dataDisplay/Text';
import { calculateLevel } from '@/utils/xp/levelHandler';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Rewards from '@/models/Rewards';
import useAuth from '@/data/hook/useAuth';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type StudentLevelProgressProps = {
  rewardsAccount: undefined | Rewards;
  className?: string;
};

export default function StudentLevelProgress({
  rewardsAccount,
  className,
}: StudentLevelProgressProps) {
  const xp = rewardsAccount?.xp ?? 0;
  const levelCounter = calculateLevel(xp);
  const [t] = useTranslation('translation', {
    keyPrefix: 'profile',
  });
  const { user } = useAuth();

  const isCurrentUser = user?.id === rewardsAccount?.student;

  return (
    <ConditionalRenderer condition={!!rewardsAccount}>
      <div
        className={twMerge(
          'flex flex-col gap-4 w-full items-center text-primary text-center',
          className,
        )}
      >
        <Text
          format="rubik-500"
          text={t('level.levelName', { level: levelCounter.level })}
        />
        <ConditionalRenderer condition={isCurrentUser}>
          <progress
            className="progress progress-primary bg-primary-content"
            value={levelCounter.percentage.toFixed(2)}
            max="1"
          />
          <Text
            format="rubik-500"
            text={t('level.levelProgress', {
              currentXp: xp,
              targetXp: levelCounter.totalLevel,
            })}
          />
        </ConditionalRenderer>
      </div>
    </ConditionalRenderer>
  );
}
