import HomeworkContent from './HomeworkContent';
import RoundedButton from '../../common/buttons/RoundedButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Text from '../../common/dataDisplay/Text';
import { HtmlPreview } from '../../common/dataDisplay/HtmlPreview';
import {
  AssignmentType,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import MyCkeditor from '../../editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import { Rating } from '../../common/dataDisplay/Rating';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import alert from '@/utils/UseAlert';
import { updateHomeworkProgress } from '@/data/services/homeworkProgressServices';
import { ApiError } from '@/models/Errors';
import Tag from '../../common/dataDisplay/Tag';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import useLessonContext from '@/data/hook/lesson';
import { homeworkProgressQueryKeys } from '@/data/services/querykeys';

function StudentHomeworkView() {
  const queryClient = useQueryClient();

  const { scheduledLesson, lessonProgress, updateLessonProgress } =
    useLessonContext();

  const { homeworkProgress } = lessonProgress || {};

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });
  const navigate = useNavigate();

  const homeworkActivityId = scheduledLesson?.homeworkActivity;
  const isHomeworkActivity = !!homeworkActivityId;
  const hasHomework = !!homeworkProgress && 'homework' in homeworkProgress;
  const homeworkActivity = hasHomework ? homeworkProgress?.homework : undefined;
  const content = homeworkActivity?.content;
  const activityProgress = hasHomework
    ? homeworkProgress?.activityProgress
    : undefined;
  const answer = hasHomework ? homeworkProgress?.answer : undefined;
  const shouldShowAnswerField =
    homeworkActivity?.assignmentType !== AssignmentType.NOTHING && !answer;
  const hasGrade = activityProgress?.grade !== null;
  interface HomeworkAnswer {
    answer: string;
  }
  const { control, handleSubmit } = useForm<HomeworkAnswer>();
  const onSubmit = (changes: HomeworkAnswer) => {
    if (homeworkActivity && hasHomework && homeworkProgress?.id) {
      mutate({
        changes,
        homeworkId: homeworkActivity?.id,
        homeworkProgressId: homeworkProgress?.id,
      });
    } else {
      return null;
    }
  };

  interface UpdateLessonProgressParams {
    changes: Partial<HomeworkActivityProgress>;
    homeworkId: number;
    homeworkProgressId: number;
  }

  async function onUpdateHomeworkProgress(
    progressUpdate: UpdateLessonProgressParams,
  ) {
    const homeworkProgressUpdated = await updateHomeworkProgress(
      progressUpdate,
    );

    return homeworkProgressUpdated;
  }

  const { mutate, isLoading } = useMutation(onUpdateHomeworkProgress, {
    async onSuccess() {
      if (!lessonProgress) throw new Error('lesson progress not found');
      await updateLessonProgress(lessonProgress.lesson.id);
      alert.success(t('homework.success'));
      await queryClient.invalidateQueries(homeworkProgressQueryKeys.list._def);
      await queryClient.invalidateQueries(homeworkProgressQueryKeys.get._def);
    },
    onError(error) {
      const apiError = new ApiError(error as any);
      alert.error(apiError.getErrorMessage());
    },
  });

  return (
    <div className="flex flex-col w-full gap-8">
      <HomeworkContent teacherId={scheduledLesson?.teacher} content={content} />
      <ConditionalRenderer condition={answer && !hasGrade}>
        <Tag
          color="warning"
          className="self-center px-3 py-1 rounded-full"
          text={t('homework.waitingFeedback')}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={!!answer}>
        <div className="flex flex-col gap-2.5">
          <Text
            text={t('homework.feedback.submittion') + ':'}
            format="rubik-500"
          />
          <ConditionalRenderer condition={answer}>
            <HtmlPreview html={answer || ''} />
          </ConditionalRenderer>
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer condition={shouldShowAnswerField}>
        <Controller
          name="answer"
          control={control}
          render={({ field: { value, onChange } }) => (
            <MyCkeditor
              content={value}
              testId="studentHomeworkEditor"
              folder={ImageFolderEnum.HOMEWORK}
              handleContentChange={onChange}
            />
          )}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={hasGrade}>
        <div className="flex flex-col items-center gap-8">
          <hr className="h-px border-primary/40 w-full" />
          <Text
            className="text-secondary"
            text={t('homework.feedback.feedback')}
            format="rubik-500"
          />
          <Rating isDisabled length={5} value={activityProgress?.grade || 0} />
          <HtmlPreview html={activityProgress?.comment || ''} />
        </div>
      </ConditionalRenderer>

      {shouldShowAnswerField && isHomeworkActivity ? (
        <RoundedButton
          loading={isLoading}
          text={t('homework.submit')}
          className="w-40 flex self-end"
          onClick={handleSubmit(onSubmit)}
        />
      ) : (
        <RoundedButton
          text={t('goBack')}
          className="w-40 flex self-end"
          onClick={() => navigate(-1)}
        />
      )}
    </div>
  );
}

export default StudentHomeworkView;
