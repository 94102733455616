import MainButton from '@/components/common/buttons/MainButton';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import TextInput from '@/components/common/dataInput/TextInput';
import ModalGameEditor from '@/components/common/modals/ModalGameEditor/ModalGameEditor';
import { StartIcon } from '@/components/icons';
import useToggle from '@/data/hook/useToggle';
import { RefreshIcon } from '@heroicons/react/outline';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { useTabsPanelContext } from '@/components/common/dataDisplay/Tabs/Tabs';
import useConfigContext from '@/data/hook/config';
import { CodeActivityEditElement } from '@/models/ActivityElement';
import { ApiError } from '@/models/Errors';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import {
  AstroWorkspace,
  Game,
  MapRenderer,
  Workspace,
  blockCodeEditorSerializer,
  extractDimensions,
  mapSerializer,
  useMapCtx,
  useWorkspaceInterpreter,
} from '@ctrlplayteam/astro-code';
import { LightBulbIcon, PencilIcon, SaveIcon } from '@heroicons/react/outline';
import { isEqual } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CodeElementEditProps } from './CodeElementEdit';
import WorkspaceConfig from './WorkspaceOptions';

export type CodeFields = Pick<
  CodeActivityEditElement,
  | 'title'
  | 'map'
  | 'instructions'
  | 'tip'
  | 'maxBlocks'
  | 'teacherCode'
  | 'goal'
>;

export type CodeFieldsKeys = keyof CodeFields;

type CodeElementEditContentProps = Omit<CodeElementEditProps, 'activity'> & {
  error?: ApiError<CodeFieldsKeys>;
  onSave: (codeActivity: CodeFields) => void;
  isSaving?: boolean;
};

export default function CodeElementEditForm({
  question,
  onEdit,
  onSave,
  error,
  isSaving,
}: CodeElementEditContentProps) {
  const { map, teacherCode, changeStatus, ...codeQuestion } = question;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityCode',
  });
  const { isOpen, close, open } = useToggle(false);

  const [isRunning, setIsRunning] = useState(false);

  const [game, setGame] = useState(mapSerializer.json.load(map));

  const [workspace, setWorkspace] = useState<Workspace>();

  const { reset, ...ctx } = useMapCtx({ game });

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<CodeFields>({
    defaultValues: { ...codeQuestion, map, teacherCode },
    shouldFocusError: true,
  });

  const { run, ref: interpreterRef } = useWorkspaceInterpreter({
    workspace,
    controller: game.interactiveLayer.player.controller,
  });

  const onRunCode = () => {
    setIsRunning(true);
    run({
      stepDelay: 150,
      onCompleteRun: () => setIsRunning(false),
    });
  };

  game.interactiveLayer.player.events = {
    onWin: () => {
      setIsRunning(false);
      interpreterRef.current?.destroy();
    },
    onLose: () => {
      setIsRunning(false);
      interpreterRef.current?.destroy();
    },
  };

  const onWorkspaceChange = (workspace: Workspace) => {
    setWorkspace(workspace);
    if (workspace.getAllBlocks().length > 1) {
      const currentTeacherCode = blockCodeEditorSerializer.save(workspace);
      if (isEqual(currentTeacherCode, teacherCode)) return;
      game.reset();
      setValue('teacherCode', blockCodeEditorSerializer.save(workspace));
    }
  };

  const onSaveGame = (game: Game) => {
    setGame(game);
    setValue('map', mapSerializer.json.save(game));
    reset();
    close();
  };

  const onReset = () => {
    reset();
    game.reset();
    setIsRunning(false);
    interpreterRef.current?.destroy();
  };

  useEffect(() => {
    const w = watch(e => onEdit({ ...e, changeStatus, id: codeQuestion.id }));
    return () => w.unsubscribe();
  }, [changeStatus, codeQuestion, game, onEdit, watch]);

  useEffect(() => {
    if (workspace && teacherCode) {
      if (workspace.getAllBlocks().length !== 1) return;
      blockCodeEditorSerializer.load(teacherCode, workspace);
    }
  }, [teacherCode, workspace]);

  useEffect(() => {
    for (const errorField of error?.fields || []) {
      setError(errorField, {
        message: error?.getMessageByField(errorField),
      });
    }
  }, [error, setError]);

  const onSaveWrapper = (data: CodeFields) => onSave(data);

  const gameMatrixDimensions = extractDimensions(
    game.groundLayer.getEditableMatrix(),
  );

  return (
    <form
      onSubmit={handleSubmit(onSaveWrapper)}
      className="flex flex-col gap-7 overflow-hidden w-full"
    >
      <TextInput
        register={register('title', {
          required: t('title.required'),
        })}
        label={t('title.label')}
        id="title"
        errorLabelText={errors.title?.message}
        errorLabelPosition="bottom"
      />
      <div className="flex gap-5 flex-col lg:flex-row">
        <div className="flex gap-8 flex-row lg:flex-col w-full lg:w-fit">
          <div className="relative w-[60%] lg:w-fit flex flex-col gap-2">
            <ConditionalRenderer condition={!isOpen}>
              <div className={`overflow-x-auto ${scrollBarClassName}`}>
                <div className="scale-75 lg:scale-100">
                  <MemoMapRenderer {...ctx} game={game} />
                </div>
              </div>
            </ConditionalRenderer>
            <div className="flex justify-between items-center gap-2 flex-wrap">
              <div className="flex gap-2 ">
                <RoundedButton
                  onClick={onRunCode}
                  disabled={isRunning}
                  type="button"
                  className="lg:px-2.5 rounded-lg text-base-100 gap-1 font-600 font-poppins "
                >
                  <StartIcon className="w-4 h-4 text-primary-content" />
                  {t('map.run')}
                </RoundedButton>
                <RoundedButton
                  onClick={onReset}
                  type="button"
                  color="neutral"
                  className="lg:px-2.5 rounded-lg text-base-100 gap-1 font-600 font-poppins"
                >
                  <RefreshIcon className="w-4 h-4 text-primary-content" />
                  <ConditionalRenderer
                    condition={gameMatrixDimensions.cols > 5}
                  >
                    <p className="hidden lg:display">{t('map.reset')}</p>
                  </ConditionalRenderer>
                </RoundedButton>
              </div>
              <MainButton
                text={t('map.edit')}
                icon={<PencilIcon />}
                onClick={open}
              />
            </div>
          </div>
          <div className="relative p-2.5 border-base-300 border-[1px] rounded-[10px] flex flex-col gap-2.5 w-full">
            <Text
              text={t('instructions.label')}
              className="rubik-500 text-18 text-primary"
            />
            <TextAreaInput
              placeholder={t('instructions.placeholder')}
              register={register('instructions', {
                required: t('instructions.required'),
              })}
              errorLabelText={errors.instructions?.message}
              testId="instructions"
            />
            <MainButton
              text={t('tip.label')}
              icon={<LightBulbIcon />}
              className="bg-yellow-400 pointer-events-none text-primary-content"
            />
            <TextAreaInput
              placeholder={t('tip.placeholder')}
              register={register('tip')}
              testId="tip"
            />
          </div>
        </div>
        <div className="relative border-[1px] border-base-300 w-full rounded-[10px] overflow-hidden h-[400px] lg:h-auto">
          <WorkspaceWrapper
            workspace={workspace}
            maxBlocks={codeQuestion.maxBlocks}
            onWorkspaceChange={onWorkspaceChange}
          />
          <WorkspaceConfig
            workspace={workspace}
            control={control}
            values={{
              maxBlocks: codeQuestion.maxBlocks,
              goal: codeQuestion.goal,
            }}
          />
        </div>
      </div>
      <MainButton
        type="submit"
        icon={<SaveIcon />}
        text={t('save')}
        disabled={changeStatus === 'edit' || !changeStatus}
        loading={isSaving}
      />
      <ModalGameEditor
        game={game}
        visible={isOpen}
        onClose={close}
        onSave={onSaveGame}
      />
    </form>
  );
}

const MemoMapRenderer = memo(MapRenderer);

type WorkspaceProps = {
  workspace: Workspace | undefined;
  onWorkspaceChange: (workspace: Workspace) => void;
  maxBlocks?: number;
};

const WorkspaceWrapper = (props: WorkspaceProps) => {
  const { triggerListSize } = useTabsPanelContext();
  const { userSettings } = useConfigContext();

  const theme = userSettings?.theme || 'light';

  return (
    <AstroWorkspace data-trigger={triggerListSize} theme={theme} {...props} />
  );
};
