import requester from '@/axios';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { EmptyActivity } from '@/models/Activity';
import { CourseTypeEnum } from '@/models/Course';
import JsonApiResponse from '@/models/JsonApiResponse';
import { Lesson, LessonMeta } from '@/models/Lesson';
import { BaseFilters, ListServiceFunc } from '@/models/Service';
import { UserTypeEnum } from '@/models/User';

export type LessonFilter = {
  inBank?: boolean;
  authorId?: number;
  status?: VersioningStatusEnum;
  courseAbbreviation?: string;
  authorUnitId?: string;
  authorUserType?: UserTypeEnum[];
  tools?: string;
  slug?: string;
  klassId?: number;
  courseType?: CourseTypeEnum[];
  courseId?: number[];
} & BaseFilters;

export const listLessons: ListServiceFunc<
  Lesson,
  LessonFilter
> = async params => {
  const { data } = await requester().get(`lessons/`, {
    params,
  });

  return data;
};

export const getLesson = async (lessonId: number) => {
  const { data: lesson } = await requester().get<Lesson>(
    `lessons/${lessonId}/`,
  );
  return lesson;
};

export interface LessonRequestParams {
  lessonId: number | string;
  slugCourseName?: string;
  klassId?: number;
}

export type LessonBody = Omit<Lesson, 'id' | 'bannerImg' | 'tools'> & {
  bannerImg?: File | string | null;
  tools: number[];
};

export interface LessonParams {
  params: LessonRequestParams;
  body: Partial<LessonBody>;
}

export const updateLesson = async (
  { lessonId, slugCourseName }: LessonRequestParams,
  body: Partial<LessonBody>,
) => {
  const url = !slugCourseName
    ? `lessons/${lessonId}/`
    : `courses/${slugCourseName}/lessons/${lessonId}/`;
  const { bannerImg, ...restChanges } = body;

  const { data: updatedLesson } = await requester().patch<Lesson>(
    url,
    restChanges,
  );

  if (bannerImg || bannerImg === null) {
    const formData = new FormData();

    formData.set('bannerImg', bannerImg ?? '');

    const { data: updatedLesson } = await requester().patch<Lesson>(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      },
    );

    return updatedLesson;
  }

  return updatedLesson;
};

export const updateLessonsOrder = async (
  courseSlug: string,
  updatedLessons: { id: number }[],
) => {
  await requester().patch(
    `courses/${courseSlug}/lessons/order/`,
    updatedLessons,
  );
};

export const deleteLesson = async (
  lessonId: number,
  slugCourseName: string,
) => {
  await requester().delete(`courses/${slugCourseName}/lessons/${lessonId}/`);
};

interface ScheduledLessonId {
  id: number;
}

export const updateExtraLessonOrders = async (
  klassId: number,
  scheduledLessonIds: ScheduledLessonId[],
) => {
  await requester().patch(
    `classes/${klassId}/scheduled-lessons/order/`,
    scheduledLessonIds,
  );
};

export const publishExtraLessons = async (
  klassId: number,
  scheduledLessonsIds: ScheduledLessonId[],
) => {
  await requester().patch(
    `classes/${klassId}/scheduled-lessons/publish/`,
    scheduledLessonsIds,
  );
};

export const cancelExtraLessons = async (
  klassId: number,
  scheduledLessonsIds: ScheduledLessonId[],
) => {
  await requester().patch(
    `classes/${klassId}/scheduled-lessons/cancel/`,
    scheduledLessonsIds,
  );
};

interface EmptyActivitiesParams {
  slugCourseName?: string;
  klassId?: number;
}

const getEmptyActivitiesUrl = ({
  slugCourseName,
  klassId,
}: EmptyActivitiesParams) => {
  if (slugCourseName) return `courses/${slugCourseName}/check-activities/`;
  return `classes/${klassId}/scheduled-lessons/check-activities/`;
};

export const getEmptyActivities = async ({
  slugCourseName,
  klassId,
}: EmptyActivitiesParams) => {
  const apiUrl = getEmptyActivitiesUrl({ slugCourseName, klassId });

  const { data: emptyActivities } = await requester().get<EmptyActivity[]>(
    apiUrl,
  );

  return emptyActivities;
};

export type CreateLessonBody = Pick<Lesson, 'name' | 'motivation'> & {
  scheduledLessonId?: number;
};

export const createLesson = async (body: CreateLessonBody) => {
  const { data: lesson } = await requester().post<Lesson>('lessons/', body);
  return lesson;
};

export type LessonSlugFilter = BaseFilters & {
  isActive?: boolean;
  hasDone?: boolean;
  name?: string;
  slug?: string;
  ordering?:
    | 'klass_count'
    | '-klass_count'
    | 'lesson_rating_avg'
    | '-lesson_rating_avg';
};

export const listLessonsByCourseSlug = async (
  slug: string,
  params: LessonSlugFilter = {},
) => {
  const { data } = await requester().get<JsonApiResponse<Lesson>>(
    `courses/${slug}/lessons/`,
    { params },
  );

  return data;
};

export const listLessonsMeta = async (params: LessonFilter) => {
  const { data } = await requester().get<JsonApiResponse<LessonMeta>>(
    `lessons/metadata/`,
    {
      params,
    },
  );

  return data;
};

export const pushLesson = async (
  courseSlug: string,
  lesson: Partial<Lesson>,
) => {
  await requester().patch(`courses/${courseSlug}/use-lesson/`, {
    lesson: lesson.id,
    order: lesson.order,
  });
};

type LessonBank = {
  inBank?: boolean;
  isPublicInBank?: boolean;
};

export interface LessonInBankParams {
  lessonId: number;
  bank: LessonBank;
}

export const updateLessonInBank = async ({
  lessonId,
  bank,
}: LessonInBankParams) => {
  const { data } = await requester().patch<Lesson>(
    `lessons/${lessonId}/set-in-bank/`,
    bank,
  );

  return data;
};
export interface LessonToolParams {
  lessonId: number;
  toolId: number;
}

type UpdateLessonTool = {
  id: number;
  isMain: boolean;
  lessonId: number;
  toolId: number;
};

export const updateLessonTool = async ({
  toolId,
  lessonId,
}: LessonToolParams) => {
  const { data } = await requester().patch<UpdateLessonTool>(
    `lessons/${lessonId}/set-main-tool/`,
    {
      toolId,
    },
  );

  return data;
};

type CreateCourseLesson = {
  slugCourseName: string;
  body: Partial<Lesson>;
};

export const createCourseLesson = async ({
  slugCourseName,
  body,
}: CreateCourseLesson) => {
  const { data: lesson } = await requester().post(
    `courses/${slugCourseName}/lessons/`,
    body,
  );
  return lesson;
};
