import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import { useQuery } from '@tanstack/react-query';

import Text from '@/components/common/dataDisplay/Text';
import ScheduledLessonReport, {
  GradeEnum,
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import Enrollment from '@/models/Enrollment';
import { CategoryEnum } from '@/models/Rewards';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Overlay from '@/components/common/Overlay';
import useListService from '@/data/hook/useListService';
import {
  categoryRewardsQueryKeys,
  homeworkProgressQueryKeys,
} from '@/data/services/querykeys';
import ScheduledLesson from '@/models/ScheduledLesson';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useStudentReport from '@/data/hook/useStudentReport';
import { LoadingIcon } from '@/components/icons';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { StudentReportForm } from '../scheduledLessonReport/StudentReportForm';
import { handleUserFullName } from '@/functions/handleUserFullName';

export type StudentReportEditProps = {
  homeworkId?: number;
  enrollment?: Enrollment;
  canEditReport: boolean;
  canEditCash: boolean;
  scheduledLesson: ScheduledLesson;
};

export type ScheduledReportForm = ScheduledLessonReport & {
  notDone: boolean;
  homeworkGrade: GradeEnum | null;
  notes: string;
  allValues: number;
};

export function StudentReportEdit({
  scheduledLesson,
  enrollment,
  homeworkId,
  canEditCash,
  canEditReport,
}: StudentReportEditProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const studentId = enrollment?.student?.id;

  const scheduledLessonId = scheduledLesson.id;

  const { data: reward } = useQuery({
    ...categoryRewardsQueryKeys.get(CategoryEnum.PRESENCE),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const {
    report,
    loadingReport: loading,
    reportError: error,
    invalidateReports,
  } = useStudentReport({
    studentId,
    scheduledLessonId,
  });

  const {
    results: homeworkProgresses,
    invalidate: invalidadeHomeworkProgress,
  } = useListService({
    ...homeworkProgressQueryKeys.list(homeworkId ?? 0, {
      studentId: enrollment?.student.id,
    }),
    enabled: !!homeworkId && !!enrollment?.student.id,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const homeworkProgress = homeworkProgresses.at(0);

  const invalidate = async () => {
    await invalidateReports();
    await invalidadeHomeworkProgress();
  };

  const overlayCondition =
    report?.status !== ScheduledLessonReportEnum.ACTIVE || !canEditReport;

  return (
    <div
      data-testid="studentReportEdit"
      className="flex flex-col p-5 shadow-default relative rounded-xl w-full bg-base-100 gap-y-3 overflow-hidden"
    >
      <Text
        testId="addGradeTitle"
        text={t('addGrade')}
        format="rubik-500"
        className="text-center text-primary w-fit"
      />

      <ConditionalRenderer condition={loading}>
        <div className="flex items-center justify-center grow">
          <LoadingIcon className="w-20 text-primary-content" />
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer condition={error}>
        <Text
          text={getErrorMessage(error)}
          format="rubik-400"
          className="text-primary"
          size="text-14"
        />
      </ConditionalRenderer>

      {enrollment && report && reward ? (
        <Fragment>
          <ReportOverlay condition={overlayCondition} />
          <Text
            testId="nonMandatoryPhrase"
            text={t('nonMandatoryPhrase')}
            format="rubik-400"
            className="text-start"
          />
          <Text
            testId="addGradeTitle"
            text={handleUserFullName(enrollment.student)}
            format="rubik-500"
            size="text-20"
            className="text-center text-primary w-fit self-center"
          />
          <StudentReportForm
            report={report}
            enrollment={enrollment}
            reward={reward}
            canEditCash={canEditCash}
            homeworkProgress={homeworkProgress}
            invalidate={invalidate}
          />
        </Fragment>
      ) : (
        <Text
          testId="selectStudentReport"
          text={t('selectStudentReport')}
          format="rubik-400"
          className="text-start"
        />
      )}
    </div>
  );
}

function ReportOverlay({ condition }: { condition: boolean }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  return (
    <ConditionalRenderer condition={condition}>
      <Overlay
        type="other"
        color="base"
        className="flex flex-col gap-6 items-center justify-center text-center"
      >
        <Text
          text={t('cantEditReport')}
          format="rubik-500"
          size="text-24"
          className="text-primary"
        />
        <div className="flex flex-col gap-1">
          <Text text={t('reportFromAnotherTeacherMessage')} />
          <Text text={t('contactAdminMessage')} />
        </div>
      </Overlay>
    </ConditionalRenderer>
  );
}
