import React from 'react';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import MainButton from '../../../common/buttons/MainButton';
import { Avatar } from '../../../common/dataDisplay/Avatar';
import PillComponent from '../../../common/dataDisplay/PillComponent';
import LinkText from '../../../common/LinkText';
import Skeleton from '../../../common/Skeleton';
import ConditionalRenderer from '../../../common/ConditionalRenderer';
import User from '@/models/User';
import { handleUserFullName } from '@/functions/handleUserFullName';

interface TeacherPillProps {
  teacherInfo?: User;
  setModalState: () => void;
  isEditView: boolean;
  hasDone: boolean;
  isLoading: boolean;
}

export const TeacherPill = ({
  teacherInfo,
  setModalState,
  isEditView,
  hasDone,
  isLoading,
}: TeacherPillProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const formatTeacherName = (name?: string) =>
    !name ? '' : `${t('teacher')} ${name}`;

  if (isLoading) {
    return (
      <Skeleton className="flex w-44 h-8 rounded-full bg-primary-content" />
    );
  } else if (teacherInfo) {
    return (
      <PillComponent testId="alter-teacher">
        <ConditionalRenderer condition={!!teacherInfo}>
          {teacherInfo && (
            <Avatar userId={teacherInfo.id} testId="teacherAvatar" size="5" />
          )}
        </ConditionalRenderer>

        <LinkText
          className="ml-2"
          testId="teacher-link"
          text={formatTeacherName(teacherInfo.firstName) || ''}
          href={teacherInfo.username}
          onClick={e => {
            e.preventDefault();
            navigate(
              `/admin/teachers/?${createSearchParams({
                search: handleUserFullName(teacherInfo),
              })}`,
            );
          }}
        />
        <Collapse
          orientation="horizontal"
          in={isEditView && !hasDone}
          collapsedSize={0}
        >
          <MainButton
            icon={<SwitchHorizontalIcon />}
            color="custom"
            className="text-primary gap-1 ml-2"
            onClick={setModalState}
            text={t('alter')}
          />
        </Collapse>
      </PillComponent>
    );
  }

  return <React.Fragment />;
};
