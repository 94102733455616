import React, { useEffect, useState } from 'react';
import TextInput from '../../common/dataInput/TextInput';
import { Controller, useForm } from 'react-hook-form';
import Unit from '@/models/Unit';
import { useTranslation } from 'react-i18next';
import MainButton from '../../common/buttons/MainButton';
import { CheckCircleIcon, SaveIcon, XIcon } from '@heroicons/react/outline';
import { isEmpty } from 'lodash';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import {
  applyMultipleMasks,
  cnpjMask,
  emailMask,
  numberMask,
  zipcodeMask,
} from '../settings/MaskInput';
import SelectInput from '../../common/dataInput/SelectInput';
import { createUnit, updateUnit } from '@/data/services/unitServices';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError, IApiError } from '@/models/Errors';
import { AxiosError } from 'axios';
import alert from '@/utils/UseAlert';
import PlacesAutoCompleteInput from '../../common/dataInput/PlacesAutoCompleteInput';
import {
  extractCityAndState,
  returnLocationString,
} from '@/utils/placesLocationUtils';
import SelectTimeZone from '../../common/dataInput/SelectTimeZone';
import useAuth from '@/data/hook/useAuth';
import { buildChangedObject } from '@/utils/buildChangedObject';
import {
  unitsQueryKeys,
  unitsStatisticsQueryKeys,
} from '@/data/services/querykeys';

type DefaultValues = Omit<Unit, 'recess'>;

interface UnitFormProps {
  unit?: DefaultValues;
  className?: string;
  disabled?: boolean;
  formMode?: 'create' | 'edit';
  onCancel?(): void;
}

export default function UnitForm({
  unit,
  className,
  disabled,
  onCancel,
  formMode = 'edit',
}: UnitFormProps) {
  const { user } = useAuth();

  type Form = DefaultValues & {
    location: string;
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    control,
    formState: { errors, defaultValues },
  } = useForm<Form>({
    defaultValues: {
      ...unit,
      location: returnLocationString(unit?.city, unit?.state),
      timezone: unit?.timezone ?? user?.timezone,
    },
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.manageUnit',
  });
  const querClient = useQueryClient();
  const [hasChange, setHasChange] = useState(false);
  const blockSave = !hasChange || !isEmpty(errors);

  const status = {
    true: {
      text: t('typeStatus.active'),
      icon: <CheckCircleIcon className="w-5 h-5 text-primary" />,
    },
    false: {
      text: t('typeStatus.inactive'),
      icon: <CheckCircleIcon className="w-5 h-5 text-neutral/50" />,
    },
  };
  const handleStatusText = (statusValue?: string) => {
    return statusValue && statusValue in status
      ? status[statusValue as keyof typeof status].text
      : '';
  };
  const statusList = Object.keys(status);
  const mutateUpdate = (data: Partial<DefaultValues>) =>
    updateUnit(unit?.id ?? 0, data);
  const unitQueryKey = unitsQueryKeys.list._def;

  const { isLoading, mutate: update } = useMutation(mutateUpdate, {
    onSuccess({ recess, ...data }) {
      reset({
        ...data,
        location: returnLocationString(data.city, data.state),
      });
      setHasChange(false);
      alert.success(t('successEditUnitMessage'));
      onCancel?.();
      return querClient.invalidateQueries(unitQueryKey);
    },
    onError(error: any) {
      const apiError = new ApiError(error as AxiosError<IApiError>);
      alert.error(apiError.getErrorMessage());
    },
  });

  const { isLoading: isCreating, mutate: create } = useMutation(createUnit, {
    onSuccess() {
      reset();
      onCancel?.();
      alert.success(t('successAddUnitMessage'));
      const queryKey = unitsStatisticsQueryKeys.list._def;
      querClient.invalidateQueries(queryKey);
      return querClient.invalidateQueries(unitQueryKey);
    },
    onError(error: any) {
      const apiError = new ApiError(error as AxiosError<IApiError>);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = async ({ location, ...data }: Form) => {
    if (location) {
      const { city, state } = extractCityAndState(location);
      data.city = city;
      data.state = state ?? '';
    }
    if (formMode === 'edit') {
      if (!unit) {
        throw new Error('Unit not found');
      }
      const changes = buildChangedObject<DefaultValues>(unit, data);
      update(changes);
    } else {
      create(data);
    }
    setHasChange(false);
  };

  const onReset = () => {
    setHasChange(false);
    reset(defaultValues);
    onCancel?.();
  };

  useEffect(() => {
    const subscription = watch(() => setHasChange(true));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form
      autoComplete="off"
      className={`flex flex-col gap-4 ${className || ''}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        testId="nameInput"
        disabled={disabled}
        label={t('name')}
        register={register('name', {
          required: t('nameErrorMessage'),
        })}
        errorLabelText={errors.name?.message}
        labelPosition="left"
      />
      <TextInput
        testId="addressInput"
        disabled={disabled}
        label={t('address')}
        register={register('address', {
          required: t('addressErrorMessage'),
        })}
        errorLabelText={errors.address?.message}
        labelPosition="left"
      />
      <TextInput
        testId="neighbourhoodInput"
        disabled={disabled}
        label={t('neighbourhood')}
        register={register('neighbourhood')}
        labelPosition="left"
      />
      <TextInput
        disabled={disabled}
        testId="addressNumberInput"
        label={t('addressNumber')}
        register={register('addressNumber', {
          required: t('addressNumberErrorMessage'),
        })}
        labelPosition="left"
        errorLabelText={errors.addressNumber?.message}
      />
      <TextInput
        disabled={disabled}
        testId="addressComplementInput"
        label={t('addressComplement')}
        register={register('addressComplement')}
        labelPosition="left"
      />
      <TextInput
        disabled={disabled}
        testId="addressReferenceInput"
        label={t('addressReference')}
        register={register('addressReference')}
        labelPosition="left"
      />

      <PlacesAutoCompleteInput
        testId="locationInput"
        labelPosition="left"
        defaultValue={defaultValues?.location ?? ''}
        name="location"
        control={control}
        disabled={!!disabled}
        className="w-full"
        label={t('city')}
      />
      <TextInput
        testId="zipcodeInput"
        label={t('zipcode')}
        disabled={disabled}
        register={register('zipcode')}
        labelPosition="left"
        mask={zipcodeMask}
        maxLenght={9}
      />
      <TextInput
        testId="phoneInput"
        disabled={disabled}
        label={t('phone')}
        type="phone"
        register={register('phone')}
        labelPosition="left"
      />
      <TextInput
        testId="cnpjInput"
        disabled={disabled}
        label={t('cnpj')}
        register={register('cnpj')}
        labelPosition="left"
        mask={applyMultipleMasks(numberMask, cnpjMask)}
        maxLenght={18}
      />
      <TextInput
        testId="corporateNameInput"
        disabled={disabled}
        label={t('corporateName')}
        register={register('corporateName')}
        labelPosition="left"
      />
      <TextInput
        testId="emailInput"
        disabled={disabled}
        label={'Email'}
        register={register('email', {
          required: t('emailErrorMessage'),
        })}
        type="email"
        errorLabelText={errors.email?.message}
        labelPosition="left"
        mask={emailMask}
      />
      <SelectInput
        testId="isActiveInput"
        label={t('status')}
        disabled={disabled}
        register={register('isActive')}
        labelPosition="left"
        hiddenSelect={disabled}
        defaultValue={handleStatusText(`${defaultValues?.isActive}`)}
      >
        {statusList.map(key => (
          <option value={key} key={key}>
            {handleStatusText(key)}
          </option>
        ))}
      </SelectInput>

      <Controller
        name="timezone"
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectTimeZone
            input={{
              labelPosition: 'left',
              label: t('timezone'),
              testId: 'timezoneInput',
              disabled,
            }}
            className="w-full"
            value={value}
            onChange={onChange}
          />
        )}
      />

      <ConditionalRenderer condition={!disabled}>
        <div className="flex gap-3 justify-end">
          <MainButton
            type="reset"
            onClick={onReset}
            icon={<XIcon />}
            text={t('cancel')}
            color="neutral"
          />
          <MainButton
            disabled={blockSave}
            loading={isLoading || isCreating}
            type="submit"
            text={t('save')}
            icon={<SaveIcon />}
          />
        </div>
      </ConditionalRenderer>
    </form>
  );
}
