import { useTranslation } from 'react-i18next';
import useStudentContext from '../../../data/hook/student';
import PageTitle from '../../../components/common/PageTitle';
import { StudentCalendarProgressView } from './StudentCalendarProgressView';
import { GuestPage } from '../../guest/GuestPage';

export default function StudentCalendarView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPage',
  });

  const { klass, courseProgress } = useStudentContext();

  const RenderCalendar = () => {
    if (courseProgress && klass) {
      return (
        <StudentCalendarProgressView
          activeKlass={klass}
          lessonsProgressData={null}
        />
      );
    } else {
      return <GuestPage />;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <PageTitle
        headingText={t('calendar')}
        subheadingText={t('studentCalendarPhrase')}
        position="mb-0"
      />
      <RenderCalendar />
    </div>
  );
}
