import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/outline';

import { PresenceEnum } from '@/models/ScheduledLessonReport';
import Skeleton from '../Skeleton';
import ConditionalWrapper from '../ConditionalWrapper';
import { Tooltip } from './Tooltip';

type PresenceCheckboxProps = {
  presence?: PresenceEnum | null;
  disabled?: boolean;
  loading?: boolean;
  onClick?(presence: PresenceEnum): void;
};

export default function PresenceCheckbox({
  presence,
  disabled = false,
  loading,
  onClick,
}: PresenceCheckboxProps) {
  const disabledClassName = disabled
    ? 'cursor-not-allowed disabled grayscale-[80] opacity-30'
    : 'cursor-pointer';

  const className =
    'w-5 h-5 rounded-sm transition ease-in duration-250 flex justify-center items-center border-2 text-18 font-rubik font-400';

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  function setPresence(presence?: PresenceEnum | null) {
    switch (presence) {
      case PresenceEnum.ATTENDED:
        return {
          testId: 'presenceCheckbox',
          className: 'bg-primary border-primary',
          content: (
            <CheckIcon
              data-testid="attendedCheckIcon"
              className="w-4 h-4 bg-primary rounded-sm text-base-100"
            />
          ),
        };
      case PresenceEnum.MISSED:
        return {
          testId: 'missedCheckbox',
          className: 'bg-error-content border-error text-error',
          content: t('missedPresenceStatus'),
        };
      case PresenceEnum.RESCHEDULED:
        return {
          testId: 'rescheduledCheckbox',
          className: 'bg-primary/40 border-primary/40 text-base-100',
          content: 'R',
        };
      default:
        return { testId: 'checkbox', className: 'border-primary', content: '' };
    }
  }

  const values = Object.values(PresenceEnum);

  const [current, setCurrent] = useState<number>(0);

  const clickedRef = useRef(false);

  useEffect(() => {
    if (!clickedRef.current)
      setCurrent(values.findIndex(value => value === presence));
    else clickedRef.current = false;
  }, [presence, values]);

  const handleClick = () => {
    clickedRef.current = true;
    const next = (current + 1) % values.length;
    setCurrent(next);
    onClick?.(values[next]);
  };

  const checkbox = setPresence(presence);

  if (loading)
    return <Skeleton className="w-5 h-5 rounded-sm bg-primary-content" />;

  return (
    <ConditionalWrapper
      condition={disabled}
      wrapper={
        <Tooltip
          placement="right"
          classNameContainer="w-fit"
          text={t('hasNoPermission')}
        />
      }
    >
      <span
        data-testid={checkbox.testId}
        className={`${checkbox.className} ${className} ${disabledClassName}`}
        onClick={handleClick}
      >
        {checkbox.content}
      </span>
    </ConditionalWrapper>
  );
}
