import { TextActivityElement } from '@/models/ActivityElement';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import MainButton from '@/components/common/buttons/MainButton';
import requester from '@/axios';
import Activity from '@/models/Activity';
import { useEffect, useState } from 'react';
import { SaveIcon } from '@heroicons/react/outline';
import MyCkeditor from '@/components/editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import alert from '@/utils/UseAlert';
import ContainerActivityEdit from './commom/ContainerActivityEdit';

export interface TextElementEditProps
  extends Omit<TextActivityElement, 'activity'> {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: any) => void;
}

export default function TextElementEdit(props: TextElementEditProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityText',
  });

  const {
    onEdit,
    title,
    subtitle,
    content,
    changeStatus,
    activity,
    type,
    identifier,
    order,
    onSave,
    id,
  } = props;

  const [errors, setErrors] = useState<any[]>([]);
  const [isButtonActivated, setIsButtonActivated] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const handleContentChange = (editorValue: string | undefined) => {
    onEdit({ ...props, content: editorValue || '' });
  };

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    onEdit({ ...props, title: event.currentTarget.value || '' });
  };

  const handleSubtitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    onEdit({ ...props, subtitle: event.currentTarget.value || '' });
  };

  function checkText(): string[] | null {
    const errors: string[] = [];

    if (title === '' || title === undefined) {
      errors.push('title');
    }

    if (subtitle === '' || subtitle === undefined) {
      errors.push('subtitle');
    }

    if (content === '' || content === undefined || content === '<p><br></p>') {
      errors.push('content');
    }

    if (errors.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  const handleClickSave = async () => {
    const ErrorID = checkText();
    if (ErrorID !== null) {
      setErrors(ErrorID);
    } else {
      setErrors([]);

      if (changeStatus === 'new') {
        try {
          setIsRequesting(true);
          const { data } = await requester().post(`text-activity/`, {
            activity: activity.id,
            title: title,
            subtitle,
            content,
            type,
            identifier,
            order,
          });
          onSave({ ...props, id: data.id });

          alert.success(t('saveSuccess'));
        } catch (err) {
          alert.error(t('saveError'));
        } finally {
          setIsRequesting(false);
        }
      } else if (changeStatus === 'changed') {
        try {
          setIsRequesting(true);
          await requester().patch(`text-activity/${id}/`, {
            title,
            subtitle,
            content,
            type,
            identifier,
          });
          onSave({ ...props });
          alert.success(t('saveSuccess'));
        } catch (err) {
          alert.error(t('saveError'));
        } finally {
          setIsRequesting(false);
        }
      }
    }
  };

  useEffect(() => {
    if (props.changeStatus === 'new') {
      const keysActivityText = ['title', 'subtitle', 'content'];
      const hasKey = keysActivityText.find(key => Object.hasOwn(props, key));

      if (hasKey) return setIsButtonActivated(true);

      return setIsButtonActivated(false);
    }

    if (changeStatus === 'changed') return setIsButtonActivated(true);
    setIsButtonActivated(false);
  }, [changeStatus, props]);

  return (
    <ContainerActivityEdit className="flex flex-col gap-5" id="TextElementEdit">
      <div>
        <label className="font-rubik text-base-content">{t('title')}</label>
        <br />
        {errors && errors.some(errorID => errorID === 'title') ? (
          <label className="font-rubik text-error error-label">
            {t('titleError')}
          </label>
        ) : (
          ''
        )}
        <Input
          className="rounded-lg border-primary font-600  font-rubik"
          id="text-activity-title"
          value={title}
          onChange={handleTitleChange}
        />
      </div>

      <div>
        <label className="font-rubik text-base-content">{t('subtitle')}</label>
        <br />
        {errors && errors.some(errorID => errorID === 'subtitle') ? (
          <label className="font-rubik text-error error-label">
            {t('subtitleError')}
          </label>
        ) : (
          ''
        )}
        <Input
          className="rounded-lg border-primary font-600  font-rubik"
          id="text-activity-subtitle"
          value={subtitle}
          onChange={handleSubtitleChange}
        />
      </div>

      <div data-testid="textActivityContent" className="content-input">
        <label className="font-rubik text-base-content">{t('content')}</label>
        <br />
        {errors && errors.some(errorID => errorID === 'content') && (
          <label className="font-rubik text-error error-label">
            {t('contentError')}
          </label>
        )}
        <MyCkeditor
          key={id}
          content={content}
          format="book"
          folder={ImageFolderEnum.ACTIVITY}
          handleContentChange={handleContentChange}
        />
      </div>

      <div className="flex">
        <MainButton
          loading={isRequesting}
          disabled={!isButtonActivated}
          icon={<SaveIcon />}
          id="text-activity-save"
          text={t('save')}
          onClick={handleClickSave}
        />
      </div>
    </ContainerActivityEdit>
  );
}
