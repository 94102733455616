import { AxiosResponse } from 'axios';
import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import Klass, {
  KlassPayload,
  KlassProgress,
  KlassStatusEnum,
} from '@/models/Klass';
import { CourseTypeEnum } from '@/models/Course';

export async function getKlass(klassId: number) {
  const { data } = await requester().get<Klass>(`classes/${klassId}/`);

  return data;
}

export interface ListKlassesFilters {
  status?: string[];
  pageSize?: number;
  pageNumber?: number;
  name?: string;
  teacherId?: number;
  unitId?: number[];
  ordering?: orderingKlassDate;
  studentId?: number;
  courseId?: number[];
  enrollmentStatus?: string[];
  courseType?: CourseTypeEnum[];
  hasAbsenceNotification?: boolean;
}

export type orderingKlassDate =
  | 'klass_start_date'
  | '-klass_start_date'
  | 'klass_end_date'
  | '-klass_end_date';

export const listKlasses = async (params: ListKlassesFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Klass>>('classes/', {
    params: {
      ...params,
      courseId: params.courseId?.toString(),
      unitId: params.unitId?.toString(),
    },
  });

  return data;
};

export async function getKlassProgressById(klassId?: number) {
  const { data } = await requester().get<KlassProgress>(
    `classes/${klassId}/progress/`,
  );
  return data;
}
export const updateKlass = async (
  klassId: number,
  parcialKlass: Partial<Klass>,
) => {
  const { data } = await requester().patch(`classes/${klassId}/`, parcialKlass);
  return data;
};

type UpdateKlassStatus = Pick<Klass, 'status'>;

export const updateKlassStatus = async (
  klassId: number,
  body: UpdateKlassStatus,
) => {
  if (body.status === KlassStatusEnum.CANCELED) {
    throw new Error("Can't cancel class");
  }
  type Response = AxiosResponse<Klass>;
  const { data: klass } = await requester().patch<UpdateKlassStatus, Response>(
    `classes/${klassId}/status/`,
    body,
  );
  return klass;
};

export const createKlass = async (klass: KlassPayload) => {
  const { data } = await requester().post<Klass>(`classes/`, klass);
  return data;
};
