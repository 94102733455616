import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  TrueOrFalseActivity,
  TrueOrFalseActivityProgress,
} from '@/models/ActivityProgress';
import { ElementPreviewFactoryProps } from './ElementPreviewFactory';
import TrueOrFalseButton from './trueOrFalse/TrueOrFalseButton';
import ActivityHeading from './ActivityHeading';
import LoadingView from '@/pages/courses/LoadingView';
import useActivityView from '@/data/hook/useActivityView';
import { updateTrueOrFalseProgress } from '@/data/services/activityElement/trueOrFalseProgressServices';
import ErrorComponent from '@/components/common/ErrorComponent';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import {
  QuestionAttempt,
  TrueOrFalseAttemptAnwser,
} from '@/models/QuestionAttempt';
import { TrueOrFalseAnswer } from '@/models/QuestionAnswer';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  trueOrFalseActivitiesQueryKeys,
  trueOrFalseActivityProgressQueryKeys,
} from '@/data/services/querykeys';

export default function TrueOrfalseElement(props: ElementPreviewFactoryProps) {
  const { user } = useAuth();

  const queryClient = useQueryClient();

  const { blockStepNavigate, invalidate, status } = useActivityView();

  const studentView = isStudent(user?.userType);

  const { questionId, activityMode, answer, progressId } = props;
  const { attemptElement } = props;

  const { queryFn: trueOrFalseActivityProgressFn, queryKey: progressKey } =
    trueOrFalseActivityProgressQueryKeys.get({
      trueOrFalseId: questionId,
      trueOrFalseProgressId: progressId,
    });

  const {
    data: questionProgress,
    isInitialLoading: loadingQuestionProgress,
    isError: questionProgressError,
  } = useQuery({
    queryFn: trueOrFalseActivityProgressFn,
    queryKey: progressKey,
    enabled:
      studentView && !!progressId && !!questionId && status === 'inProgress',
  });

  const questionProgressErrorDetail = getErrorMessage(questionProgressError);

  const {
    data: question,
    isInitialLoading: loadingQuestion,
    error: questionError,
  } = useQuery({
    ...trueOrFalseActivitiesQueryKeys.get(questionId),
    enabled: !studentView && !!questionId,
  });

  const questionErrorDetail = getErrorMessage(questionError);

  const saveAnswer = async (answer: boolean) => {
    blockStepNavigate(true);
    const questionProgress = await updateTrueOrFalseProgress(
      { trueOrFalseId: questionId, trueOrFalseProgressId: progressId },
      {
        answer,
        isDone: true,
      },
    );

    return questionProgress;
  };

  const { mutateAsync: updateAnswer } = useMutation(saveAnswer, {
    async onSuccess() {
      await queryClient.invalidateQueries(progressKey);

      await invalidate();
    },

    onError(error: any) {
      alert.error(getErrorMessage(error));
    },

    onSettled() {
      blockStepNavigate(false);
    },
  });

  const loading = loadingQuestion || loadingQuestionProgress;

  const errorDetail = questionErrorDetail || questionProgressErrorDetail;

  if (loading) {
    return <LoadingView />;
  }

  if (errorDetail) {
    return <ErrorComponent errorTextTitle={errorDetail} />;
  }

  if (questionProgress) {
    return (
      <Element
        questionProgress={questionProgress}
        activityMode={activityMode}
        answer={answer}
        attemptElement={attemptElement}
        updateAnswer={updateAnswer}
      />
    );
  }

  if (question) {
    return (
      <Element
        question={question}
        activityMode={activityMode}
        answer={answer}
      />
    );
  }

  return <Fragment />;
}

type ElementProps = Pick<
  ElementPreviewFactoryProps,
  'activityMode' | 'answer'
> & {
  questionProgress?: TrueOrFalseActivityProgress;
  question?: TrueOrFalseActivity;
  updateAnswer?: (answer: boolean) => Promise<TrueOrFalseActivityProgress>;
  attemptElement?: any;
};

function Element({
  questionProgress,
  question,
  activityMode,
  updateAnswer,
  attemptElement,
  answer,
}: ElementProps) {
  if (questionProgress) {
    const { trueOrFalseActivity: question } = questionProgress;

    const selectedAnswer = (): boolean | undefined => {
      if (attemptElement && attemptElement.answers) {
        const attempt = new QuestionAttempt(attemptElement);

        const answers = new TrueOrFalseAttemptAnwser(attempt.answers);

        return answers.answer ?? undefined;
      }

      return questionProgress.answer ?? undefined;
    };

    return (
      <Content
        question={question}
        activityMode={activityMode}
        updateAnswer={updateAnswer}
        selectedAnswer={selectedAnswer()}
      />
    );
  }

  if (question) {
    const selectedAnswer = (): boolean | undefined => {
      if (answer) {
        const trueOrFalseAnswer = answer as TrueOrFalseAnswer;

        return trueOrFalseAnswer.isTrue;
      }
    };
    return (
      <Content
        question={question}
        activityMode={activityMode}
        updateAnswer={updateAnswer}
        selectedAnswer={selectedAnswer()}
      />
    );
  }

  return <Fragment />;
}

type ContentProps = Pick<ElementPreviewFactoryProps, 'activityMode'> & {
  question: TrueOrFalseActivity;
  updateAnswer?: (answer: boolean) => Promise<TrueOrFalseActivityProgress>;
  selectedAnswer?: boolean;
};

function Content({
  question,
  updateAnswer,
  selectedAnswer,
  activityMode,
}: ContentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.trueOrFalse',
  });

  const [selected, setSelected] = useState<boolean>();

  useEffect(() => {
    setSelected(selectedAnswer);
    return () => setSelected(undefined);
  }, [selectedAnswer]);

  const updateSelectedAnswer = async (answer: boolean) => {
    setSelected(answer);
    await updateAnswer?.(answer);
  };

  return (
    <div className="flex flex-col gap-8">
      <ActivityHeading
        heading={question.question}
        subheading={t('question')}
        content={question.content}
      />
      <div className="flex flex-col w-full sm:flex-row justify-center gap-4 items-center">
        <TrueOrFalseButton
          variant={true}
          selectedValue={selected}
          onClick={() => updateSelectedAnswer(true)}
          disabled={activityMode !== 'activity'}
        />
        <TrueOrFalseButton
          variant={false}
          selectedValue={selected}
          onClick={() => updateSelectedAnswer(false)}
          disabled={activityMode !== 'activity'}
        />
      </div>
    </div>
  );
}
