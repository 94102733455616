import { createDefaultGame, mapSerializer } from '@ctrlplayteam/astro-code';
import { v4 as uuidv4 } from 'uuid';
import { ActivityTypeEnum } from './Activity';
import ActivityElement, {
  CodeActivityEditElement,
  QuizAnswersType,
  TextActivityElement,
  TrueOrFalseActivityElement,
  VideoActivityElement,
  VideoPlayerEnum,
} from './ActivityElement';
import MultipleChoiceAnswer from './MultipleChoiceAnswer';

export default class ActivityElementFactory {
  create(type: ActivityTypeEnum): ActivityElement {
    const defaultValues: ActivityElement = {
      id: NaN,
      order: NaN,
      identifier: uuidv4(),
      type,
      changeStatus: 'new',
    };
    switch (type) {
      case ActivityTypeEnum.TEXT:
        const textQuestion: Omit<TextActivityElement, 'subtitle' | 'title'> = {
          ...defaultValues,
          content: '',
        };
        return textQuestion;
      case ActivityTypeEnum.CODE:
        const codeQuestion: CodeActivityEditElement = {
          ...defaultValues,
          goal: {
            coins: 0,
            checkpoints: 1,
          },
          map: mapSerializer.json.save(createDefaultGame()),
          instructions: '',
          title: '',
          teacherCode: {
            blocks: {
              languageVersion: 0,
              blocks: [
                {
                  type: 'main',
                  id: '1',
                  x: 10,
                  y: 10,
                  deletable: false,
                  movable: false,
                },
              ],
            },
          },
        };
        return codeQuestion;
      case ActivityTypeEnum.VIDEO:
        const videoQuestion: Omit<
          VideoActivityElement,
          'subtitle' | 'title' | 'url'
        > = {
          ...defaultValues,
          videoPlayer: VideoPlayerEnum.YOUTUBE,
        };

        return videoQuestion;
      case ActivityTypeEnum.TRUE_OR_FALSE:
        const trueOrFalseQuestion: Omit<
          TrueOrFalseActivityElement,
          'title' | 'subtitle' | 'question'
        > = {
          ...defaultValues,
          isTrue: true,
          content: '',
          reason: '',
        };
        return trueOrFalseQuestion;
      case ActivityTypeEnum.QUIZ:
        const quizQuestion = {
          ...defaultValues,
          answerType: QuizAnswersType.TEXT,
          quizAnswers: [
            new MultipleChoiceAnswer('', false),
            new MultipleChoiceAnswer('', false),
          ],
        };
        return quizQuestion;
      case ActivityTypeEnum.DND_ORDENATION:
        return {
          ...defaultValues,
        };
      case ActivityTypeEnum.DND_POSITIONAL:
        return {
          ...defaultValues,
        };
      default:
        return {
          ...defaultValues,
        };
    }
  }
}

export class IdentifierValidatorDecorator implements ActivityElementFactory {
  constructor(
    private readonly activityFactory: ActivityElementFactory,
    private questions: ActivityElement[],
  ) {}

  create(type: ActivityTypeEnum) {
    let newQuestion = this.activityFactory.create(type);
    const identifierAlreadyExists = this.questions.find(
      ({ identifier }) => identifier === newQuestion.identifier,
    );
    if (identifierAlreadyExists) {
      newQuestion = this.create(type);
    }
    return newQuestion;
  }
}
