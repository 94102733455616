import { useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';

import IconButton from '@/components/common/buttons/IconButton';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ModalDisable from '@/components/common/modals/ModalDisable';
import useAdminContext from '@/data/hook/admin';
import { getKlassEnrollments } from '@/functions/klassActiveStudents';
import i18n from '@/i18n';
import ScheduledDay from '@/models/ScheduledDay';
import alert from '@/utils/UseAlert';
import { listEnrollments } from '@/data/services/enrollmentServices';
import { deleteScheduledDay } from '@/data/services/scheduledDayServices';
import { ApiError } from '@/models/Errors';

type CollapseContentProps = {
  unitLessons?: ScheduledDay[];
  invalidate?: () => Promise<void>;
};

export const CollapseContent = ({
  unitLessons = [],
  invalidate,
}: CollapseContentProps) => {
  const [startOfWeek, setStartOfWeek] = useState(moment().startOf('week'));
  const [isDeleting, setIsDeleting] = useState(false);

  const { teachers } = useAdminContext();
  const { t } = useTranslation();

  const formatDate = (date: Moment | Date) => {
    return moment(date).locale(i18n.language).format('ddd, DD/MM');
  };

  const days = [];
  for (let i = 0; i < 7; i++) {
    const date = moment(startOfWeek).add(i, 'days');
    if (date.day() !== 0) {
      days.push(formatDate(date));
    }
  }

  const goToPreviousWeek = () => {
    setStartOfWeek(moment(startOfWeek).subtract(1, 'week'));
  };

  const goToNextWeek = () => {
    setStartOfWeek(startOfWeek.clone().add(1, 'week'));
  };

  const TrialDayCard = ({ trialDay }: { trialDay: ScheduledDay }) => {
    const { data: enrollments } = useQuery(
      ['klassEnrollments', trialDay.klass],
      () => listEnrollments({ klassId: trialDay.klass }),
      { enabled: !!trialDay.klass },
    );
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const teacher = teachers.find(teacher => teacher.id === trialDay.teacher);
    const totalStudents = getKlassEnrollments(enrollments?.results).length || 0;
    const totalSeats = totalStudents + trialDay.seats;

    const { mutate: deleteScheduled } = useMutation(deleteScheduledDay, {
      async onSuccess() {
        setDeleteModalOpen(false);
        setIsDeleting(false);
        alert.success(t('manageTrialLesson.deleteSuccess'));
        await invalidate?.();
      },

      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    });

    return (
      <div className="border bg-secondary-content border-secondary/40 relative rounded-lg p-2 flex items-center flex-col gap-1.5 text-center text-14 lg:w-28">
        <div className="flex flex-wrap w-full justify-center items-center">
          <Text
            format="rubik-500"
            text={
              teacher
                ? `${teacher?.firstName} ${teacher?.lastName.charAt(0)}.`
                : ''
            }
          />
          <Text format="rubik-500" text={`(${totalStudents}/${totalSeats})`} />
        </div>
        <div className="flex w-full justify-between items-center">
          <Text
            className="text-base-content"
            text={moment(trialDay.dateTime).format('HH:mm') + 'h'}
          />
          <Tooltip
            className="w-36"
            text={totalStudents > 0 ? t('deleteTooltip') : ''}
          >
            <IconButton
              testId={'deleteDate' + trialDay.id}
              icon={<TrashIcon className="w-4 text-error" />}
              disabled={totalStudents > 0}
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            />
          </Tooltip>
          <ModalDisable
            modalType="delete"
            isRequesting={isDeleting}
            visible={deleteModalOpen}
            selectedObjectName={moment(trialDay.dateTime)
              .locale(i18n.language)
              .format('ddd DD/MM HH:mm')}
            translationString={'modalDisableLesson'}
            onClickConfirm={() => deleteScheduled(trialDay.id)}
            onClickCancel={() => setDeleteModalOpen(false)}
          />
        </div>
      </div>
    );
  };
  if (!unitLessons.length)
    return <Text text={t('manageTrialLesson.noSchedule')} />;
  else
    return (
      <div className="flex items-start text-primary justify-between">
        <ChevronLeftIcon
          onClick={goToPreviousWeek}
          className="w-5 cursor-pointer"
        />

        {days.map((day, i) => (
          <div
            key={i}
            className="flex flex-col items-center gap-4 w-24 lg:w-28 xl:w-36"
          >
            <Text
              text={day}
              className="bg-primary text-base-100 text-center capitalize w-fit px-3 py-1 rounded-full"
            />
            {unitLessons
              .filter(lesson => formatDate(lesson.dateTime) === day)
              .map(trialDay => (
                <TrialDayCard key={trialDay.id} trialDay={trialDay} />
              ))}
          </div>
        ))}

        <ChevronRightIcon
          className="w-5 cursor-pointer"
          onClick={goToNextWeek}
        />
      </div>
    );
};
