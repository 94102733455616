import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InformationCircleIcon,
  LockClosedIcon,
  PencilIcon,
  SaveIcon,
  XIcon,
} from '@heroicons/react/outline';

import User from '@/models/User';
import Tag from '@/components/common/dataDisplay/Tag';
import PasswordInput from '@/components/common/dataInput/PasswordInput';
import MainButton from '@/components/common/buttons/MainButton';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import { postPassword } from '@/data/services/userServices';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import AccountForm from './AccountForm/AccountForm';

interface PasswordInputs {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
}

export const AccountInfo = ({ user }: { user?: User }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountPage',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);

  return (
    <React.Fragment>
      <div className="flex w-full justify-between">
        <Tag
          text={t('accountInfoTitle')}
          icon={<InformationCircleIcon className="w-6 h-6" />}
        />
        <MainButton
          text={t('editButton')}
          dataTestId="editEmailUsername"
          onClick={() => {
            setIsEdit(true);
          }}
          color="custom"
          className="text-primary gap-2 self-end"
          disabled={isEdit}
          icon={<PencilIcon />}
        />
      </div>
      <AccountForm user={user} editing={isEdit} setEditing={setIsEdit} />
      <div className="flex w-full justify-between">
        <Tag
          text={t('accountPasswordTitle')}
          icon={<LockClosedIcon className="w-6 h-6" />}
        />
        <MainButton
          text={t('editButton')}
          dataTestId="editPassword"
          onClick={() => {
            setIsEditPassword(true);
          }}
          color="custom"
          className="text-primary gap-2 self-end"
          disabled={isEditPassword}
          icon={<PencilIcon />}
        />
      </div>
      <PasswordForm
        isEditPassword={isEditPassword}
        setIsEditPassword={setIsEditPassword}
      />
    </React.Fragment>
  );
};

const PasswordForm = ({
  isEditPassword,
  setIsEditPassword,
}: {
  isEditPassword: boolean;
  setIsEditPassword: (state: boolean) => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountPage',
  });
  const { register, handleSubmit, reset } = useForm<PasswordInputs>();
  const [isRequestingPassword, setIsRequestingPassword] = useState(false);
  const [hasChangesPassword, setHasChangesPassword] = useState(false);
  const [errorLabel, setErrorLabel] = useState<string | undefined>(undefined);

  const onSubmitPassword: SubmitHandler<PasswordInputs> = async ({
    currentPassword,
    newPassword,
    reNewPassword,
  }) => {
    if (newPassword === reNewPassword) {
      try {
        setErrorLabel(undefined);
        setIsRequestingPassword(true);
        await postPassword(newPassword, reNewPassword, currentPassword);
        alert.success(t('saveSuccess'));
        setHasChangesPassword(false);
        setIsEditPassword(false);
        reset();
      } catch (err: any) {
        const apiError = new ApiError(err);
        if (apiError.fields.includes('newPassword')) {
          setErrorLabel(apiError.getMessageByField('newPassword'));
        } else {
          const message = apiError.getErrorMessage();
          setErrorLabel(message);
          alert.error(message);
        }
      } finally {
        setIsRequestingPassword(false);
      }
    } else {
      setErrorLabel(t('saveErrorDifferentPassword'));
    }
  };

  return (
    <form
      data-testid="redefinePasswordForm"
      className="flex flex-col gap-3"
      onSubmit={handleSubmit(onSubmitPassword)}
    >
      <PasswordInput
        label={t('currentPassword')}
        disabled={!isEditPassword}
        testId="currentPasswordField"
        register={register('currentPassword', {
          onChange: () => {
            setHasChangesPassword(true);
          },
        })}
        className="w-full sm:w-72 md:w-80 lg:w-96"
      />
      <div className="flex flex-col sm:flex-row md:flex-row gap-5">
        <PasswordInput
          label={t('fieldNewPassword')}
          testId="newPasswordField"
          disabled={!isEditPassword}
          register={register('newPassword')}
          errorLabelText={errorLabel}
          className="w-full sm:w-72 md:w-80 lg:w-96"
        />
        <PasswordInput
          testId="reNewPasswordField"
          label={t('fieldConfirmPassword')}
          disabled={!isEditPassword}
          register={register('reNewPassword')}
          errorLabelText={errorLabel}
          className="w-full sm:w-72 md:w-80 lg:w-96"
        />
      </div>
      <ConditionalRenderer condition={isEditPassword}>
        <div className="flex gap-2">
          <MainButton
            text={t('cancelButton')}
            disabled={!isEditPassword}
            icon={<XIcon />}
            type="reset"
            color="neutral"
            onClick={() => {
              reset();
              setHasChangesPassword(false);
              setIsEditPassword(false);
              setErrorLabel(undefined);
            }}
            className="mt-3"
          />
          <MainButton
            text={t('saveButton')}
            dataTestId="savePassword"
            disabled={!hasChangesPassword || !isEditPassword}
            icon={<SaveIcon />}
            type="submit"
            loading={isRequestingPassword}
            className="mt-3"
          />
        </div>
      </ConditionalRenderer>
    </form>
  );
};
