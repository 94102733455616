import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LoadingIcon } from '../../icons';
import ConditionalRenderer from '../ConditionalRenderer';

type ButtonColor = 'primary' | 'secondary' | 'neutral' | 'warning' | 'custom';
type ButtonSize = 'regular' | 'medium' | 'large';

type MainButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  id?: string;
  color?: ButtonColor;
  text: string;
  className?: string;
  icon?: JSX.Element;
  secondaryIcon?: JSX.Element;
  loading?: boolean;
  disabled?: boolean;
  href?: string;
  size?: ButtonSize;
  isExternalLink?: boolean;
  dataTestId?: string;
  iconSize?: string;
};

const buttonColorClasses: Record<ButtonColor, string> = {
  primary:
    'bg-primary text-base-100 enabled:hover:bg-primary/80 disabled:bg-primary/40 focus-visible:ring-primary/40',
  secondary:
    'bg-secondary text-base-100 enabled:hover:bg-secondary/80 disabled:bg-secondary-content focus-visible:ring-secondary/40',
  neutral:
    'bg-neutral-content text-neutral enabled:hover:bg-neutral-content/80 disabled:bg-neutral-content/50 disabled:text-neutral/50 focus-visible:ring-neutral/50',
  warning:
    'bg-error-content text-error enabled:hover:bg-error-content focus-visible:ring-error-content',
  custom:
    'enabled:hover:opacity-75 disabled:opacity-25 focus-visible:ring-primary-content',
};

const buttonSizeClasses: Record<ButtonSize, string> = {
  regular: 'text-14',
  medium: 'text-16',
  large: 'text-18',
};

export default function MainButton(props: MainButtonProps) {
  const { href } = props;
  if (href) return <RenderLink {...props} href={href} />;

  return <RenderButton {...props} />;
}

function RenderButton(props: MainButtonProps) {
  const {
    id,
    color,
    text,
    className,
    icon,
    loading,
    disabled,
    href,
    size,
    isExternalLink,
    dataTestId,
    secondaryIcon,
    iconSize,
    type = 'button',
    ...rest
  } = props;

  return (
    <button
      {...rest}
      type={type}
      data-testid={dataTestId}
      id={id}
      disabled={disabled || loading || false}
      className={` ${
        disabled || loading ? 'disabled' : ''
      } gap-2.5 leading-none w-fit min-w-min h-fit flex shrink-0 rounded-md enabled:active:scale-95 items-center font-rubik duration-150 transition disabled:cursor-default focus-visible:ring focus:outline-none ${
        buttonColorClasses[color || 'primary']
      } ${buttonSizeClasses[size || 'regular']} ${
        color === 'custom' ? '' : 'px-2.5 py-1.5'
      } ${className}`}
    >
      <ConditionalRenderer condition={icon || loading}>
        <div
          className={`${iconSize || 'w-4 h-4 '} duration-150 transition p-0`}
        >
          {loading ? <LoadingIcon /> : icon}
        </div>
      </ConditionalRenderer>
      {text}
      <ConditionalRenderer condition={!!secondaryIcon}>
        <div
          className={`${iconSize || 'w-4 h-4 '} duration-150 transition p-0`}
        >
          {secondaryIcon}
        </div>
      </ConditionalRenderer>
    </button>
  );
}

function RenderLink({ ...props }: MainButtonProps & { href: string }) {
  const { isExternalLink, href } = props;
  return (
    <Fragment>
      <ConditionalRenderer condition={isExternalLink}>
        <a href={href} target="_blank" rel="noreferrer">
          <RenderButton {...props} />
        </a>
      </ConditionalRenderer>
      <ConditionalRenderer condition={!isExternalLink}>
        <Link to={href}>
          <RenderButton {...props} />
        </Link>
      </ConditionalRenderer>
    </Fragment>
  );
}
