import { ClipboardListIcon, RefreshIcon } from '@heroicons/react/outline';
import Klass from '@/models/Klass';
import { useTranslation } from 'react-i18next';
import ReportsList from './ReportsList';
import CollapsableCard from './CollapsableCard';
import { useQueryClient } from '@tanstack/react-query';
import useAuth from '@/data/hook/useAuth';
import { ScheduledLessonFilters } from '@/data/services/scheduledLessonsServices';
import React from 'react';
import IconButton from '../common/buttons/IconButton';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';

type TeacherReportsProps = {
  klasses: Klass[];
};

export const TeacherReports: React.FC<TeacherReportsProps> = ({ klasses }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherReports',
  });
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const scheduledLessonFilters: ScheduledLessonFilters = {
    isPendingReport: true,
    teacher: user?.id,
  };

  const { queryKey: scheduledLessonsQuerykey, queryFn: listScheduledLessons } =
    scheduledLessonsQueryKeys.list(scheduledLessonFilters)._ctx.infinity;

  const {
    results: pastScheduledLessons,
    meta,
    isInitialLoading: isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteService({
    queryKey: scheduledLessonsQuerykey,
    queryFn: listScheduledLessons,
    enabled: !!user,
  });

  const count = meta?.pagination.count ?? 0;
  const isEmpty = pastScheduledLessons.length === 0;
  const onClickRefresh = () => {
    queryClient.invalidateQueries(scheduledLessonsQuerykey);
  };

  return (
    <CollapsableCard
      badgeCount={count}
      headerIcon={<ClipboardListIcon className="w-6 stroke-[2]" />}
      title={t('pendingReports').toLocaleUpperCase()}
      isCollapsable={!isEmpty}
      isLoading={isLoading}
      header={
        <IconButton
          onClick={onClickRefresh}
          icon={<RefreshIcon className="w-6" />}
        />
      }
      testId="teacherReports"
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onReachEnd={fetchNextPage}
    >
      <ReportsList klasses={klasses} scheduledLessons={pastScheduledLessons} />
    </CollapsableCard>
  );
};
