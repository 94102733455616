import { scrollBarClassName } from '@/utils/scrollBarClassName';
import {
  GroundToken,
  Item,
  MapEditor,
  Token,
  createDefaultGame,
  useGameBuilder,
  Game,
  Coords,
} from '@ctrlplayteam/astro-code';
import { CSSProperties, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../ConditionalRenderer';
import SaveCancelGroup from '../../buttons/SaveCancelGroup';
import { TagColor } from '../../dataDisplay/Tag';
import Text from '../../dataDisplay/Text';
import Checkbox from '../../dataInput/Checkbox';
import Modal, { ModalProps } from '../Modal';
import FocusItem from './FocusItem';
import { GameExceptionHandler } from './GameExceptionHandler';
import ResizeMatrixInputs from './ResizeInputs';
import SelectBlocks from './SelectBlocks';
import { useGameTokens } from './ModalGameEditor.hooks';

export type tokenTypes = 'ground' | 'interactive';
export type Styles = Pick<CSSProperties, 'color' | 'backgroundColor'> & {
  tag: TagColor;
};

export const colorByTokenType: Record<tokenTypes, Styles> = {
  ground: {
    color: 'text-success',
    backgroundColor: 'bg-success-content/50',
    tag: 'info',
  },
  interactive: {
    color: 'text-warning',
    backgroundColor: 'bg-warning/20',
    tag: 'warning',
  },
};

type ModalBankContentProps = {
  game?: Game;
  onSave?: (game: Game) => void;
  isSaving?: boolean;
  onClose?: () => void;
};

export type ModalBankProps = ModalBankContentProps &
  Omit<ModalProps, 'children'>;

export default function ModalGameEditor({
  game,
  onClose,
  onSave,
  isSaving,
  ...modalProps
}: ModalBankProps) {
  return (
    <Modal
      className="overflow-y-hidden h-[90vh] p-6 max-w-[80vw]"
      {...modalProps}
    >
      <ConditionalRenderer condition={modalProps.visible}>
        <ModalGameEditorContent
          game={game}
          isSaving={isSaving}
          onSave={onSave}
          onClose={onClose}
        />
      </ConditionalRenderer>
    </Modal>
  );
}

const ModalGameEditorContent = ({
  game: initialGame,
  isSaving,
  onSave,
  onClose,
}: ModalBankContentProps) => {
  const [tEditor] = useTranslation('translation', {
    keyPrefix: 'game.edit',
  });

  const [tExceptions] = useTranslation('translation', {
    keyPrefix: 'game.validators',
  });

  const { sprites } = useGameTokens();

  const gameBuilder = useGameBuilder({
    initialGame: initialGame || createDefaultGame({ sprites }),
    exceptionHandler: new GameExceptionHandler(tExceptions),
  });

  const [selectedItem, setSelectedItem] = useState<Item<Token>>(
    gameBuilder.builded.groundLayer.items[GroundToken.DANGER],
  );

  const game = gameBuilder.builded;

  const isInteractiveToken = !!game.interactiveLayer.getItemByToken(
    selectedItem.token,
  );

  const selectedLayer = isInteractiveToken ? 'interactive' : 'ground';

  const onChangeMatrix = ({ cols, rows }: { cols?: number; rows?: number }) => {
    cols = cols || game.groundLayer.matrix[0].length;
    rows = rows || game.groundLayer.matrix.length;
    const coords = {
      x: cols - 1,
      y: rows - 1,
    };
    gameBuilder.interactiveBuilder.withoutItemsOutsideMatrix(coords as Coords);
    gameBuilder.groundLayerBuilder.withMatrix({ cols, rows });
  };
  return (
    <Fragment>
      <div className={`relative h-[80vh] w-full ${scrollBarClassName}`}>
        <Text
          text={tEditor('title')}
          className="text-primary text-left"
          format="poppins-600"
          size="text-20"
        />
        <div className="flex gap-5 mt-5 w-full flex-wrap min-h-[90%]">
          <div
            className={`relative w-full flex items-center sm:w-[65%] bg-primary-content/50 rounded-xl p-3 ${scrollBarClassName}`}
          >
            <div className="relative mx-auto w-fit scale-75 lg:scale-100">
              <MapEditor
                gameBuilder={gameBuilder}
                selectedItem={selectedItem}
              />
              <ResizeMatrixInputs
                className="mt-2 mx-auto w-fit"
                value={{
                  cols: game.groundLayer.matrix[0]?.length,
                  rows: game.groundLayer.matrix.length,
                }}
                onChange={onChangeMatrix}
                showLimits={game.groundLayer.showLimits}
              />
            </div>
          </div>
          <div className="relative ml-auto w-full sm:max-w-[30%] ">
            <FocusItem item={selectedItem} selectedLayer={selectedLayer} />
            <div className="relative max-w-[400px] flex flex-col gap-2.5 top-3">
              <SelectBlocks
                type="ground"
                items={game.groundLayer.getEditableItems()}
                onSelect={setSelectedItem}
              />
              <SelectBlocks
                type="interactive"
                items={game.interactiveLayer.getEditableItems()}
                onSelect={setSelectedItem}
              />
              <div>
                <Checkbox
                  checked={game.interactiveLayer.allEnemiesShouldMove}
                  onChange={e =>
                    gameBuilder.interactiveBuilder.withAllEnemiesMove(
                      e.target.checked,
                    )
                  }
                  text={tEditor('allEnemiesShouldMove')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full fixed bg-base-100 left-0 bottom-0 p-5 z-10">
        <SaveCancelGroup
          className="ml-auto w-fit bottom-0"
          cancel={{
            onClick: onClose,
          }}
          save={{
            onClick: () => onSave?.(gameBuilder.builded),
          }}
          loading={isSaving}
        />
      </div>
    </Fragment>
  );
};
