import React from 'react';
import useAuth from '../../data/hook/useAuth';
import { UserTypeEnum } from '../../models/User';
import AdminCalendarView from './AdminCalendarView';
import Layout from '../../components/template/Layout';
import StudentCalendarView from './student/StudentCalendarView';
import { useTranslation } from 'react-i18next';
import HeadTitle from '../../components/common/HeadTitle';

export default function CalendarPage() {
  const { user } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin',
  });

  return (
    <Layout>
      <HeadTitle routeInfo={t('calendar')} />
      <RenderViewByRole role={user?.userType} />
    </Layout>
  );
}

interface RenderViewByRoleProps {
  role?: UserTypeEnum;
}

const RenderViewByRole = ({ role }: RenderViewByRoleProps) => {
  switch (role) {
    case UserTypeEnum.STUDENT:
      return <StudentCalendarView />;
    default:
      return <AdminCalendarView />;
  }
};
