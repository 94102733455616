import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Text from '../../dataDisplay/Text';
import { Fragment, useEffect, useState } from 'react';
import { WonMode } from './WonMode';
import { WithdrawMode } from './WithdrawMode';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import MainButton from '../../buttons/MainButton';
import { Transaction } from '@/models/Transaction';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createTransaction } from '@/data/services/transactionServices';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import useAdminContext from '@/data/hook/admin';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User from '@/models/User';
import { rewardsQueryKeys } from '@/data/services/querykeys';

export type RewardsMode = 'NONE' | 'WON' | 'WITHDRAW';

export type ModalRewardsProps = {
  isVisible: boolean;
  onClickConfirm: () => void;
  onClickCancel: (e?: any) => void;
  student: User;
};

export const ModalRewards = ({
  isVisible,
  onClickConfirm,
  onClickCancel,
  student,
}: ModalRewardsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalRewards',
  });
  const [mode, setMode] = useState<RewardsMode>('NONE');
  const studentName = handleUserFullName(student);
  const studentId = student.id;

  const changeMode = (activetedMode: RewardsMode) => {
    if (mode === activetedMode) {
      setMode('NONE');
    } else {
      setMode(activetedMode);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && !isVisible) setMode('NONE');

    return () => {
      mounted = false;
    };
  }, [isVisible]);

  return (
    <Modal testId="modalRewards" visible={isVisible} onClose={onClickCancel}>
      <div className="flex flex-col justify-center gap-4">
        <Text
          text={t('attentionPhrase')}
          className="text-primary"
          size="text-24"
          format="rubik-500"
        />
        <Text text={studentName} size="text-18" format="rubik-500" />
        <div className="flex gap-4 justify-center">
          <MainButton
            dataTestId="wonButton"
            color="custom"
            onClick={() => changeMode('WON')}
            className={`gap-2 px-3 py-1.5 rounded-full border-border-success border
             ${
               mode === 'WON'
                 ? 'bg-success text-base-100'
                 : 'text-success bg-base-100'
             }`}
            icon={<PlusIcon className="w-fit" />}
            text={t('rewardsButtons.addCoins')}
            size="medium"
          />
          <MainButton
            dataTestId="withdrawButton"
            color="custom"
            onClick={() => changeMode('WITHDRAW')}
            className={`gap-2 px-3 py-1.5 rounded-full border-border-success border
             ${
               mode === 'WITHDRAW'
                 ? 'bg-error text-base-100'
                 : 'text-error bg-base-100'
             }`}
            icon={<MinusIcon className="w-fit" />}
            text={t('rewardsButtons.withdrawCoins')}
            size="medium"
          />
        </div>
        <RenderModalMode
          mode={mode}
          studentId={studentId}
          onClickCancel={onClickCancel}
          onClickConfirm={onClickConfirm}
          isVisible={isVisible}
        />
      </div>
    </Modal>
  );
};

const RenderModalMode = ({
  mode,
  onClickConfirm,
  onClickCancel,
  studentId,
  isVisible,
}: {
  mode: RewardsMode;
  onClickConfirm: () => void;
  onClickCancel: () => void;
  studentId: number;
  isVisible: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalRewards',
  });
  const { rewardsOptions } = useAdminContext();

  const queryClient = useQueryClient();

  const { data: rewards, isInitialLoading } = useQuery({
    enabled: !!studentId && isVisible,
    ...rewardsQueryKeys.get(studentId),
  });

  const changeStudentRewards = async ({
    transaction,
    rewardId,
  }: {
    transaction?: Transaction;
    rewardId?: number;
  }) => {
    const isEnabledForTransaction =
      transaction?.reason && transaction?.amount && rewardId;

    if (!isEnabledForTransaction) throw new Error('transactionError');

    await createTransaction({ ...transaction, studentAccount: rewardId });
  };

  const { mutate: changeRewards, isLoading: isUpdatingRewards } = useMutation(
    changeStudentRewards,
    {
      onSuccess: () => {
        onClickConfirm();
        alert.success(t('messages.transactionSuccess'));
        queryClient.invalidateQueries(rewardsQueryKeys.get(studentId));
      },
      onError: (error: any) => {
        if (error?.message === 'transactionError') {
          alert.warning(t('messages.transactionWarning'));
          return;
        }
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const mods: { [key in RewardsMode]: JSX.Element } = {
    WON: (
      <WonMode
        rewardsOptions={rewardsOptions}
        onClickConfirm={transaction =>
          changeRewards({ transaction, rewardId: rewards?.id })
        }
        onClickCancel={onClickCancel}
        isWaitingTransactionRequest={isUpdatingRewards || isInitialLoading}
      />
    ),
    WITHDRAW: (
      <WithdrawMode
        onClickConfirm={transaction =>
          changeRewards({ transaction, rewardId: rewards?.id })
        }
        onClickCancel={onClickCancel}
        isWaitingTransactionRequest={isUpdatingRewards || isInitialLoading}
      />
    ),
    NONE: <Fragment />,
  };

  return mods[mode];
};
