import parse from 'html-react-parser';

export type DisplayFormat = 'book' | 'default' | 'none';

interface HtmlPreviewProps {
  testId?: string;
  html?: string;
  format?: DisplayFormat;
  className?: string;
}

export const formatClasses: Record<DisplayFormat, string> = {
  book: 'bookHtmlDisplay',
  default: 'defaultHtmlDisplay',
  none: '',
};

export const HtmlPreview = ({
  html,
  format,
  className,
  testId,
}: HtmlPreviewProps) => {
  const replaceHtml = (html: string) => {
    const replaceLinkHttps = html.replace(
      /<a href="https:\/\//gi,
      '<a target="_blank" href="https://',
    );

    const replaceLink = replaceLinkHttps.replace(
      /<a href="/gi,
      '<a target="_blank" href="https://',
    );

    const pattern1 =
      /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})&amp;ab_channel=([\w%]*)"><\/oembed>/gi;
    const pattern2 =
      /<oembed\s+url="https:\/\/youtu\.be\/([\w-]{11})\?t=([\w-]*)"><\/oembed>/gi;
    const pattern3 =
      /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})&amp;t=([\w-]*)"><\/oembed>/gi;
    const pattern4 =
      /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})"><\/oembed>/gi;
    const pattern5 =
      /<oembed\s+url="https:\/\/youtu\.be\/([\w-]{11})"><\/oembed>/gi;
    const pattern6 =
      /<oembed\s+url="(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)&?(?:[^"]+)?"><\/oembed>/gi;
    const pattern7 =
      /<oembed\s+url="(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)&?(?:list=[\w-]+&?)?(?:[^"]+)?"><\/oembed>/gi;

    const colorPattern1 = /color:\s*rgb\(0,\s*0,\s*0\)/g;
    const colorPattern2 = /color:\s*hsl\(0,\s*0%,\s*0%\)/g;
    const colorPattern3 = /color:#000000/g;

    const iframeReplacement =
      '<iframe class="videoHtmlDisplay" src="https://www.youtube.com/embed/$1"></iframe>';
    const colorReplacement = 'color: var(--base-content)';

    let replacedHtml = replaceLink.replace(pattern1, iframeReplacement);
    replacedHtml = replacedHtml.replace(pattern2, iframeReplacement);
    replacedHtml = replacedHtml.replace(pattern3, iframeReplacement);
    replacedHtml = replacedHtml.replace(pattern4, iframeReplacement);
    replacedHtml = replacedHtml.replace(pattern5, iframeReplacement);
    replacedHtml = replacedHtml.replace(pattern6, iframeReplacement);
    replacedHtml = replacedHtml.replace(pattern7, iframeReplacement);
    replacedHtml = replacedHtml.replace(colorPattern1, colorReplacement);
    replacedHtml = replacedHtml.replace(colorPattern2, colorReplacement);
    replacedHtml = replacedHtml.replace(colorPattern3, colorReplacement);

    return replacedHtml;
  };

  return (
    <div
      data-testid={testId}
      className={`${
        formatClasses[format || 'default']
      } font-rubik text-wrap break-words htmlDisplay ck-content ${
        className || ''
      }`}
    >
      {html && parse(replaceHtml(html))}
    </div>
  );
};
