import InfinityList from '@/components/common/InfinityList';
import PageTitle from '@/components/common/PageTitle';
import Text from '@/components/common/dataDisplay/Text';
import ToggleButtonGroup, {
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import FeedbackFilters, {
  FeedbackFiltersOptions,
} from '@/components/common/feedback/FeedbackFilters';
import FeedbackHeader from '@/components/common/feedback/FeedbackHeader';
import { LoadingIcon } from '@/components/icons';
import Layout from '@/components/template/Layout';
import useAuth from '@/data/hook/useAuth';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  courseBasesQueryKeys,
  klassesQueryKeys,
} from '@/data/services/querykeys';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { CourseTypeEnum } from '@/models/Course';
import { KlassStatusEnum } from '@/models/Klass';
import cleanupObj from '@/utils/cleanupObj';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FeedbackToggleValue = 'all' | 'course' | 'module' | 'klass';

export default function TeacherFeedbackPage() {
  const { user: authUser } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage',
  });
  const [feedbackType, setFeedbackType] = useState<FeedbackToggleValue>('all');
  const [filters, setFilters] = useState<FeedbackFiltersOptions>({
    courseId: [],
    courseType: [],
    unitId: [],
  });
  const cleanedFilters = cleanupObj(filters);
  cleanedFilters.unitId = cleanedFilters.unitId || getAuthorizedUnits(authUser);

  const buttons: ToggleButton<FeedbackToggleValue>[] = [
    {
      text: t('all'),
      value: 'all',
    },
    {
      text: t('byCourse'),
      value: 'course',
    },
    {
      text: t('byModule'),
      value: 'module',
    },
    {
      text: t('byKlass'),
      value: 'klass',
    },
  ];

  return (
    <Layout>
      <PageTitle headingText={t('pageTitle')} />

      <div className="flex flex-col md:grid md:grid-cols-4 gap-4">
        <FeedbackFilters
          type={feedbackType}
          filters={filters}
          setFilters={setFilters}
        />
        <div className="flex flex-col gap-2 w-full md:col-span-3">
          <ToggleButtonGroup
            value={feedbackType}
            onChange={setFeedbackType}
            buttons={buttons}
            exclusive
          />
          <RenderFeedbackContent type={feedbackType} filters={cleanedFilters} />
        </div>
      </div>
    </Layout>
  );
}

const RenderFeedbackContent = ({
  type,
  filters,
}: {
  type: FeedbackToggleValue;
  filters?: FeedbackFiltersOptions;
}) => {
  if (type === 'all') return <RenderAllContent filters={filters} />;
  if (type === 'course') return <RenderCourseContent filters={filters} />;
  if (type === 'module') return <RenderModuleContent filters={filters} />;
  if (type === 'klass') return <RenderKlassesContent filters={filters} />;
  else return <Fragment />;
};

const RenderAllContent = ({
  filters,
}: {
  filters?: FeedbackFiltersOptions;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage',
  });
  return (
    <FeedbackHeader
      testId="allFeedbackContent"
      filters={filters}
      title={t('all')}
      isForceOpen
    />
  );
};

const RenderCourseContent = ({
  filters,
}: {
  filters?: FeedbackFiltersOptions;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'courseTypes',
  });
  let courseTypeOptions: CourseTypeEnum[] = Object.values(
    CourseTypeEnum,
  ).filter(value => value !== CourseTypeEnum.CTRL_QUICK);

  if (!!filters?.courseType?.length) {
    courseTypeOptions = filters.courseType;
  }
  return (
    <Fragment>
      {courseTypeOptions.map(option => {
        return (
          <FeedbackHeader
            key={option}
            testId={'courseContent' + t(option)}
            title={t(option)}
            filters={{ ...filters, courseType: [option] }}
          />
        );
      })}
    </Fragment>
  );
};

const RenderModuleContent = ({
  filters,
}: {
  filters?: FeedbackFiltersOptions;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.header',
  });

  const {
    results: coursesList,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteService({
    ...courseBasesQueryKeys.list({
      coursePathStatus: VersioningStatusEnum.PUBLISHED,
      ...filters,
    })._ctx.infinity,
  });

  if (isLoading) {
    return (
      <div className="h-20 w-full flex items-center justify-center">
        <LoadingIcon className="w-10 h-10 text-primary" />
      </div>
    );
  }
  if (!isLoading && !coursesList.length) {
    return <Text text={t('notFound')} />;
  }

  return (
    <InfinityList
      onReachEnd={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      className="gap-4"
    >
      {coursesList?.map(course => (
        <FeedbackHeader
          key={course.id}
          testId={'moduleContent' + course.abbreviation}
          title={course.abbreviation}
          filters={{ ...filters, courseId: [course.id] }}
          hideIfEmpty
        />
      ))}
    </InfinityList>
  );
};

const RenderKlassesContent = ({
  filters,
}: {
  filters?: FeedbackFiltersOptions;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.header',
  });

  const {
    results: klassesList,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteService({
    ...klassesQueryKeys.list({
      status: [KlassStatusEnum.IN_PROGRESS, KlassStatusEnum.OPEN],
      ...filters,
    })._ctx.infinity,
  });

  if (isLoading) {
    return (
      <div className="h-20 w-full flex items-center justify-center">
        <LoadingIcon className="w-10 h-10 text-primary" />
      </div>
    );
  }
  if (!isLoading && !klassesList.length) {
    return <Text text={t('notFound')} />;
  }
  return (
    <InfinityList
      onReachEnd={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      className="gap-4"
    >
      {klassesList?.map(klass => (
        <FeedbackHeader
          key={klass.id}
          title={klass.name}
          testId={'klassContent' + klass.name}
          filters={{ klassId: klass.id, ...filters }}
        />
      ))}
    </InfinityList>
  );
};
