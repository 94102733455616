import { HTMLAttributes } from 'react';
import { LoadingIcon } from '../../icons';
import Text from '../../common/dataDisplay/Text';
import { MotionProps, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const ControlButtonComposite = {
  Text: TextButton,
  Icon: IconWithLoading,
  Root: RootButton,
};
export default ControlButtonComposite;

function TextButton({ text }: { text: string }) {
  return <Text format="rubik-500" text={text} className="uppercase" />;
}

function IconWithLoading({
  icon,
  isLoading,
}: {
  icon: JSX.Element;
  isLoading?: boolean;
}) {
  return (
    <div className="w-5 h-5 items-center duration-150 transition p-0">
      {isLoading ? <LoadingIcon /> : icon}
    </div>
  );
}

type ButtonRootProps = HTMLAttributes<HTMLButtonElement> &
  MotionProps & {
    testId?: string;
  };

function RootButton({
  children,
  className,
  testId,
  ...props
}: ButtonRootProps) {
  return (
    <motion.button
      {...props}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      data-testid={testId}
      className={twMerge(
        'h-6 md:h-8 cursor-pointer flex items-center gap-4 activity-button text-primary',
        className,
      )}
    >
      {children}
    </motion.button>
  );
}
