import { createContext, useEffect, useState } from 'react';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import LessonProgress from '@/models/LessonProgress';
import { Lesson } from '@/models/Lesson';
import { useQueryClient } from '@tanstack/react-query';
import { isStudent } from '@/functions/auth';
import useAuth from '../hook/useAuth';
import useStudentContext from '../hook/student';
import { shouldShowScheduledLesson } from '@/utils/shouldShowScheduledLesson';
import useLessonProgresses from '../hook/useLessonProgresses';
import {
  lessonsQueryKeys,
  scheduledLessonsQueryKeys,
} from '../services/querykeys';
import useInfiniteService from '../hook/useInfiniteService';
interface LessonsPaginate {
  prev?: Pick<ScheduledLesson, 'lesson' | 'type'>;
  next?: Pick<ScheduledLesson, 'lesson' | 'type'>;
}

export interface LessonContextProps {
  scheduledLesson?: ScheduledLesson;
  lessonProgress?: LessonProgress;
  lesson?: Lesson;
  errorLesson?: unknown;
  updateLessonProgress(lessonId: number): Promise<void>;
  pagination: LessonsPaginate;
  isLoading: boolean;
  setParams: (params: LessonContextParams) => void;
}

const LessonContext = createContext<LessonContextProps>(
  {} as LessonContextProps,
);

interface LessonContextParams {
  slugCourseName?: string;
  lessonId?: string;
  klassId?: string;
}

export function LessonProvider({ children }: { children: React.ReactNode }) {
  const [{ slugCourseName = '', lessonId, klassId }, setParams] =
    useState<LessonContextParams>({});

  const queryClient = useQueryClient();

  const { user } = useAuth();

  const { nextLesson, updateCourseProgress } = useStudentContext();

  const studentView = isStudent(user?.userType);

  const {
    results: lessons,
    error: errorLesson,
    isInitialLoading: isLoadingLessons,
    hasNextPage: hasMoreLessons,
    fetchNextPage: fextLessonsNextPage,
    isFetchingNextPage: isFetchingMoreLessons,
  } = useInfiniteService({
    ...lessonsQueryKeys.nestedList(slugCourseName ?? '')._ctx.infinity,
    enabled: !!lessonId && !klassId,
  });

  const klass = Boolean(klassId) ? Number(klassId) : undefined;
  const {
    results: scheduledLessons,
    isInitialLoading: isLoadingScheduledLessons,
    hasNextPage: hasMoreScheudledLessons,
    fetchNextPage: fextScheduledLessonsNextPage,
    isFetchingNextPage: isFetchingMoreScheduledLessons,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({ klassId: klass })._ctx.infinity,
    enabled: !!user && !!klass,
  });

  const { lessonProgresses, queryKeys } = useLessonProgresses({
    lessonIds: !isNaN(Number(lessonId)) ? [Number(lessonId)] : undefined,
  });

  const lessonProgress = lessonProgresses?.[0]?.data;

  const loadingLessonProgress = studentView
    ? lessonProgresses[0]?.status === 'loading'
    : false;

  const currentLessons = scheduledLessons.length
    ? scheduledLessons?.map(({ lesson, type }) => ({ lesson, type }))
    : lessons?.map(lesson => ({
        lesson,
        type: ScheduledLessonTypeEnum.NORMAL,
      }));

  const lessonIndex = currentLessons?.findIndex(
    ({ lesson }) => lesson.id === Number(lessonId),
  );

  const getPagination = (): LessonsPaginate => {
    if (lessonIndex === undefined || !currentLessons) return {};

    const prev = currentLessons[lessonIndex - 1];
    const next = currentLessons[lessonIndex + 1];

    const nextScheduledLesson = scheduledLessons?.[lessonIndex + 1];

    const shouldBlockNextLesson =
      studentView &&
      !shouldShowScheduledLesson(
        nextScheduledLesson,
        lessonProgress,
        nextLesson,
      );

    return {
      prev,
      next: shouldBlockNextLesson ? undefined : next,
    };
  };

  const updateLessonProgress = async (lessonId: number) => {
    if (!lessonProgress) throw new Error('lesson progress not found');
    const queryKey = queryKeys[lessonId];
    await queryClient.invalidateQueries(queryKey);

    await updateCourseProgress();
    return;
  };

  const scheduledLesson =
    lessonIndex !== undefined ? scheduledLessons?.[lessonIndex] : undefined;

  const lesson = lessonIndex !== undefined ? lessons?.[lessonIndex] : undefined;

  const isLoading =
    isLoadingLessons ||
    loadingLessonProgress ||
    isLoadingScheduledLessons ||
    isFetchingMoreLessons ||
    isFetchingMoreScheduledLessons;

  useEffect(() => {
    if (!lesson && hasMoreScheudledLessons) {
      fextScheduledLessonsNextPage();
    }
  }, [fextScheduledLessonsNextPage, hasMoreScheudledLessons, lesson]);

  useEffect(() => {
    if (!lesson && hasMoreLessons) {
      fextLessonsNextPage();
    }
  }, [fextLessonsNextPage, hasMoreLessons, lesson]);

  const values: LessonContextProps = {
    scheduledLesson: scheduledLesson,
    lessonProgress: lessonProgress,
    pagination: getPagination(),
    lesson: scheduledLesson?.lesson ?? lesson,
    errorLesson,
    isLoading,
    updateLessonProgress,
    setParams,
  };

  return (
    <LessonContext.Provider value={values}>{children}</LessonContext.Provider>
  );
}

export default LessonContext;
