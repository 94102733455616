import KlassesHeaderCell from './KlassesHeaderCell';
import KlassesIconCell from './KlassesIconCell';
import GivenContent from './GivenContent';
import ScheduledLesson from '@/models/ScheduledLesson';
import { isLessonBeforeToday } from '@/utils/ScheduledLessonDateValidator';
import Klass from '@/models/Klass';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import { ScheduledLessonReportEnum } from '@/models/ScheduledLessonReport';
import User from '@/models/User';
import { LessonProgressFilters } from '@/data/services/lessonProgressServices';
import useListService from '@/data/hook/useListService';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import LessonProgress from '@/models/LessonProgress';
import { useQuery } from '@tanstack/react-query';
import {
  lessonsProgressesQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';

interface KlassesLessonColProps {
  scheduledLesson: ScheduledLesson;
  klass?: Klass;
  enrollments: Enrollment[];
  teacher?: User;
}

export default function KlassesLessonCol(props: KlassesLessonColProps) {
  const { scheduledLesson, enrollments, klass, teacher } = props;
  const courseSlug = klass?.coursePathSlug || '';
  const isBeforeToday = isLessonBeforeToday(scheduledLesson);
  const isUpcoming = !isBeforeToday && !scheduledLesson.hasDone;
  const studentIds = enrollments.map(enroll => enroll.student.id);

  const { data: newTeacher } = useQuery({
    ...usersQueryKeys.get(scheduledLesson.teacher),
    enabled: teacher?.id !== scheduledLesson.teacher && !isUpcoming,
  });

  const progressFilters: LessonProgressFilters = {
    lesson: scheduledLesson.lesson.id,
    klassId: klass?.id,
    studentId: studentIds,
  };

  const { results: lessonProgressList, isLoading: isLoadingProgress } =
    useListService({
      staleTime: REQUEST_STALE_TIME_IN_MS,
      enabled: !!enrollments.length,
      ...lessonsProgressesQueryKeys.list(progressFilters),
    });

  const getProgressInfo = (
    status: EnrollmentStatusEnum,
    progress?: LessonProgress,
  ) => {
    const isDisabled =
      progress === undefined || status !== EnrollmentStatusEnum.ACTIVE;
    const differentCourseAndLesson =
      status === EnrollmentStatusEnum.COURSE_TRANSFERRED &&
      !progress?.scheduledLessonReport?.presence &&
      progress?.scheduledLessonReport?.status !==
        ScheduledLessonReportEnum.TRANSFERED;
    const isCancelledNoPresence =
      progress?.scheduledLessonReport?.status ===
        ScheduledLessonReportEnum.CANCELED &&
      !progress?.scheduledLessonReport?.presence;
    const hideIconCell = isCancelledNoPresence || differentCourseAndLesson;

    return { isDisabled, hideIconCell };
  };

  return (
    <div
      data-testid={`klassesLessonCol${scheduledLesson.order}`}
      key={scheduledLesson.id}
      className="flex flex-col h-full grow border-r border-neutral-content"
    >
      <KlassesHeaderCell
        scheduledLesson={scheduledLesson}
        courseSlug={courseSlug}
      />
      <ConditionalRenderer
        condition={!!enrollments.length}
        fallback={
          <div className="h-14 border-b border-neutral-content flex shrink-0" />
        }
      >
        {enrollments
          .sort((a, b) => a.student.id - b.student.id)
          .map(enrollment => {
            const student = enrollment.student;
            const lessonProgress = lessonProgressList?.find(
              progress =>
                progress?.scheduledLessonReport?.student === student.id,
            );
            const progressInfo = getProgressInfo(
              enrollment.status,
              lessonProgress,
            );

            return (
              <KlassesIconCell
                testId={`studentIconCell${scheduledLesson.order}`}
                key={student.id}
                isLoading={isLoadingProgress}
                isDisabled={progressInfo.isDisabled}
                studentLessonProgress={
                  progressInfo.hideIconCell ? undefined : lessonProgress
                }
                scheduledLesson={scheduledLesson}
              />
            );
          })}
      </ConditionalRenderer>

      <GivenContent
        scheduledLesson={scheduledLesson}
        teacher={newTeacher}
        klassId={klass?.id || 0}
      />
    </div>
  );
}
