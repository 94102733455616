import React from 'react';
import User from '@/models/User';
import DataStudentsTable from './DataStudentsTable';
import SearchAndFilterButtons from '../../common/SearchAndFilterButtons';
import { StudentHeader } from '@/utils/HeaderTypes';
import Header from '../../common/table/Header';
import Text from '../../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import { LoadingIcon } from '../../icons';
import useFilterParams from '@/utils/UseFilterParams';
import { ListUsersFilters } from '@/data/services/userServices';
import { QuickFilterProps as QuickFilterItem } from '@/components/common/filter/QuickFilter';
import QuickFilters from '@/components/common/filter/QuickFilters';
import { useNavigate } from 'react-router-dom';

interface ListStudentsProps {
  students: User[];
  titles: StudentHeader;
  isSearch: boolean;
  isLoading?: boolean;
}

export default function ListStudents({
  titles,
  students,
  isSearch,
  isLoading,
}: ListStudentsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageStudent.dataUserTable',
  });

  const navigate = useNavigate();

  const { filterParams, setFilterParams, deleteFilterParams } =
    useFilterParams<ListUsersFilters>({});

  const search = filterParams.get('search') ?? '';

  const hasSearch = !!search || isSearch;

  const hasParams = [...filterParams.keys()].length !== 0;

  const requestSearch = (value: string) => {
    if (!value) {
      deleteFilterParams('search');
    } else {
      setFilterParams({ search: value });
    }

    setFilterParams({ pageNumber: 1 });
  };

  function clearSearch() {
    deleteFilterParams('search');
    navigate('/admin/students');
  }

  function clearFilters() {
    deleteFilterParams(['search', 'status', 'unit']);
    navigate('/admin/students');
  }

  function clearSorts() {
    deleteFilterParams('ordering');
    setFilterParams({ pageNumber: 1 });
  }

  function clearFiltersAndSorts() {
    clearFilters();
    clearSorts();
  }

  const quickFilters: QuickFilterItem[] = [
    {
      type: 'unit',
      filters: filterParams.getAll('unit'),
    },
    {
      type: 'enrollmentStatus',
      filters: filterParams.getAll('enrollmentStatus'),
    },
  ];

  const withoutStudentsText = hasParams ? t('searchNotFound') : t('noStudents');

  return (
    <React.Fragment>
      <div className="flex gap-4 justify-between items-center">
        <SearchAndFilterButtons
          searchInitial={search}
          onChange={debounce(e => requestSearch(e.target.value), 500)}
          isSearch={hasSearch}
          clearFiltersButton={clearFilters}
          clearOrderButton={clearSorts}
          clearAllButton={clearFiltersAndSorts}
          clearSearch={clearSearch}
        />
        <QuickFilters quickFilters={quickFilters} />
      </div>
      <div>
        <table className="flex flex-col gap-y-2.5">
          <Header headers={Object.values(titles)} />
          <ConditionalRenderer condition={!isLoading}>
            <DataStudentsTable titles={titles} students={students} />
          </ConditionalRenderer>
        </table>
        <ConditionalRenderer condition={!students.length && !isLoading}>
          <Text
            text={withoutStudentsText}
            className="flex justify-center mt-5"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={isLoading}>
          <div className="flex w-full h-[518px] justify-center items-center p-3.5">
            <LoadingIcon className="text-primary/40 w-10 h-10" />
          </div>
        </ConditionalRenderer>
      </div>
    </React.Fragment>
  );
}
