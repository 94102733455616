import { useTranslation } from 'react-i18next';
import Klass from '@/models/Klass';
import PageTitle from '@/components/common/PageTitle';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { translateKlassName } from '@/utils/translateKlassName';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import useAuth from '@/data/hook/useAuth';
import { useState } from 'react';
import { klassesQueryKeys } from '@/data/services/querykeys';

interface ReportHeaderProps {
  klass: Klass;
  onSelect: (klassId: number) => void;
}

export const ReportHeader = ({ onSelect, klass }: ReportHeaderProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const { user } = useAuth();

  const [selected, setSelected] = useState<Klass | undefined>(klass);

  return (
    <div data-testid="reportHeader" className="grid lg:grid-cols-2 gap-4">
      <PageTitle headingText={t('title')} position="mb-0" testId="pageTitle" />
      <InfiniteSearchInput
        service={klassesQueryKeys.list}
        displayName={klass => translateKlassName(klass.name)}
        input={{ testId: 'klassInput' }}
        selectedItem={selected}
        filters={{ unitId: getAuthorizedUnits(user) }}
        onSelect={item => onSelect(item.id)}
        className="lg:max-w-[60%] justify-self-center lg:justify-self-end"
        onDeselect={() => setSelected(undefined)}
        createOption={klass => ({
          key: klass.id,
          value: klass,
          isDisabled: klass.enrollsCount <= 0,
          tooltipMessage: t('noActiveStudentsInKlass'),
        })}
      />
    </div>
  );
};
