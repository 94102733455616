import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../dataInput/TextInput';
import { CategoryEnum, RewardEnum } from '../../../../models/Rewards';
import RoundedButton from '../../buttons/RoundedButton';
import { Transaction } from '../../../../models/Transaction';

interface WithdrawModeProps {
  onClickConfirm: (transition?: Transaction) => void;
  onClickCancel: (e?: any) => void;
  isWaitingTransactionRequest: boolean;
}

export const WithdrawMode = ({
  onClickConfirm,
  onClickCancel,
  isWaitingTransactionRequest,
}: WithdrawModeProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalRewards',
  });
  const [transition, setTransition] = useState<Transaction>({
    rewardType: RewardEnum.COIN,
    transactionType: 'WITHDRAW',
  });

  return (
    <div data-testid="withdrawMode" className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 justify-center items-center">
        <TextInput
          placeholder={t('inputPlaceholderReason')}
          className="bg-base-100 w-96"
          onChange={e => {
            setTransition({
              ...transition,
              reason: e.target.value,
            });
          }}
        />
        <TextInput
          centerText
          placeholder={t('inputPlaceholderValue')}
          className="bg-base-100 w-32"
          onChange={e => {
            const value = e.target.value;
            e.target.value = value.replace(/[^0-9]/g, '');
            setTransition({
              ...transition,
              amount: Number(value.replace(/[^0-9]/g, '')),
              category: CategoryEnum.PURCHASE,
            });
          }}
        />
      </div>
      <div className="flex w-full justify-around gap-6">
        <RoundedButton
          color="neutral"
          className="w-full"
          text={t('cancelButton')}
          onClick={onClickCancel}
        />
        <RoundedButton
          color="warning"
          loading={isWaitingTransactionRequest}
          text={t('confirmWithdrawButton')}
          className="w-full"
          onClick={() => onClickConfirm(transition)}
        />
      </div>
    </div>
  );
};
