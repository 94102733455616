interface Observer<T> {
  action: () => void;
  id: T;
}

class Observable<T> {
  public observers: Observer<T>[];
  constructor() {
    this.observers = [];
  }

  subscribe(e: Observer<T>) {
    this.observers.push(e);
  }

  unsubscribe(e: Observer<T>) {
    this.observers = this.observers.filter(
      subscriber => subscriber.id !== e.id,
    );
  }

  notifyAll(origin?: T) {
    this.observers
      .filter(observer => observer.id !== origin)
      .forEach(observer => observer.action());
  }
}

export default Observable;
