import NextLessonView from '@/components/klass/student/StudentKlassLessons/NextLessonView/NextLessonView';
import KlassLessons from '@/components/klass/student/StudentKlassLessons/StudentKlassLessons';
import { useTranslation } from 'react-i18next';
import Text from '@/components/common/dataDisplay/Text';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { useQuery } from '@tanstack/react-query';
import { nextScheduledLessonsQueryKeys } from '@/data/services/querykeys';

type KlassProgressProps = {
  klassId?: number;
  courseSlug?: string;
  studentId?: number;
};

export default function KlassProgress({
  klassId,
  courseSlug,
  studentId,
}: KlassProgressProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgress',
  });

  const {
    data: nextLesson,
    isInitialLoading: loadingNextLesson,
    error,
  } = useQuery({
    ...nextScheduledLessonsQueryKeys.get(klassId ?? 0),
    enabled: !!klassId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const nextLessonError = getErrorMessage(error);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-wrap justify-between items-center gap-4">
        <Text
          text={t('myLessons')}
          format="poppins-600"
          className="text-24 text-primary"
        />
        <NextLessonView
          nextLesson={nextLesson}
          error={nextLessonError}
          loading={loadingNextLesson}
        />
      </div>

      <KlassLessons
        klassId={klassId}
        courseSlug={courseSlug}
        nextLesson={nextLesson}
        studentId={studentId}
      />
    </div>
  );
}
