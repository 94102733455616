import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import PasswordInput from '../../common/dataInput/PasswordInput';
import { CheckCircleIcon } from '@heroicons/react/outline';
import RoundedButton from '../buttons/RoundedButton';
import User from '../../../models/User';
import Text from '../dataDisplay/Text';
import { handleUserFullName } from '../../../functions/handleUserFullName';
import ConditionalRenderer from '../ConditionalRenderer';
import { updateStudentPassword } from '../../../data/services/userServices';
import { ApiError } from '../../../models/Errors';
import alert from '../../../utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import { LoadingIcon } from '../../icons';
import { SubmitHandler, useForm } from 'react-hook-form';

type ModalChangePasswordProps = {
  visible: boolean;
  onClickCancel: (e?: any) => void;
  user: User;
};

type Mode = 'password' | 'success' | 'none';

export default function ModalChangePassword(props: ModalChangePasswordProps) {
  const { visible, onClickCancel, user } = props;

  const [mode, setMode] = useState<Mode>('password');

  const changeModalMode = (mode: Mode) => {
    setMode(mode);
  };

  const onClose = () => {
    changeModalMode('none');
    onClickCancel();
  };

  useEffect(() => {
    if (visible) setMode('password');
  }, [visible]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <ConditionalRenderer condition={mode === 'none'}>
        <div className="flex justify-center items-center h-44">
          <LoadingIcon className="w-1/6 text-primary/40" />
        </div>
      </ConditionalRenderer>
      <ConditionalRenderer condition={mode === 'password'}>
        <ChangePassword
          user={user}
          changeModalMode={changeModalMode}
          onClose={onClose}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={mode === 'success'}>
        <SuccessChangePassword onClose={onClose} />
      </ConditionalRenderer>
    </Modal>
  );
}

type ConfirmPassword = {
  password: string;
};

type ModalModeProps = {
  user: User;
  changeModalMode: (mode: Mode) => void;
  onClose: () => void;
  isLoading?: boolean;
};

function ChangePassword({ user, onClose, changeModalMode }: ModalModeProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalChangePassword',
  });

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmPassword>();

  const { mutate: changePassword, isLoading } = useMutation(
    updateStudentPassword,
    {
      onSuccess: () => {
        changeModalMode('success');
      },
      onError: (error: any) => {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmit: SubmitHandler<ConfirmPassword> = async data => {
    const userId = user.id;
    const { password } = data;
    changePassword({ userId, password });
  };

  return (
    <form
      className="flex flex-col gap-6 items-center w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Text
        text={t('passwordChangeMessage')}
        className="text-primary text-20"
        format="rubik-500"
      />

      <Text
        text={handleUserFullName(user)}
        className="text-base-content text-20"
        format="rubik-500"
      />

      <PasswordInput
        testId="modalPasswordInput"
        className="text-18"
        disabled={isLoading}
        errorLabelText={errors.password?.message}
        register={register('password', {
          required: tCommon('form.required'),
        })}
      />

      <div className="flex w-full justify-around gap-6">
        <RoundedButton
          text={t('cancel')}
          color="neutral"
          className="w-full"
          onClick={onClose}
        />

        <RoundedButton
          loading={isLoading}
          text={t('confirm')}
          className="w-full"
          type="submit"
        />
      </div>
    </form>
  );
}

function SuccessChangePassword({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalChangePassword',
  });
  return (
    <div className="flex flex-col gap-6 items-center w-full">
      <Text
        text={t('passwordChangeSuccess')}
        className="text-primary text-20"
        format="rubik-500"
      />

      <CheckCircleIcon className="w-16 h-16 text-success" />

      <Text text={t('emailSent')} className="leading-none text-base-content" />

      <div className="flex w-full justify-around gap-12">
        <RoundedButton className="w-full" text={t('close')} onClick={onClose} />
      </div>
    </div>
  );
}
