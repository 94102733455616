import { useTranslation } from 'react-i18next';
import { Control, FieldPath, Controller, FieldValues } from 'react-hook-form';

import { SheetTab } from '@/models/SheetTab';
import { BehaviorRatingEnum as StudentBehaviorRatingEnum } from '@/models/StudentAssessment';
import { BehaviorRatingEnum as KlassBehaviorRatingEnum } from '@/models/KlassAssessment';
import ConditionalRenderer from '../ConditionalRenderer';
import ConditionalWrapper from '../ConditionalWrapper';
import EnumInput from '../dataInput/EnumInput';
import { AssessmentPreview } from './BehaviorAssessmentPreview';

type TranslatePrefix =
  | 'studentBehaviorRating'
  | 'klassEnergyRating'
  | 'klassSpeedRating'
  | 'klassMaturityRating';

type BehaviorRatingEnum = StudentBehaviorRatingEnum | KlassBehaviorRatingEnum;

type BehaviorInputProps<T> = {
  control: Control<T & FieldValues>;
  name: FieldPath<T & FieldValues>;
  label: string;
  testId?: string;
  disabled?: boolean;
  previewValue?: BehaviorRatingEnum;
  tab: SheetTab;
  enumModel: any;
  translatePrefix?: TranslatePrefix;
};

export default function BehaviorInput<T>({
  control,
  disabled,
  label,
  previewValue,
  name,
  testId,
  tab,
  enumModel,
  translatePrefix = 'studentBehaviorRating',
}: BehaviorInputProps<T>) {
  const { t } = useTranslation('translation', {
    keyPrefix: translatePrefix,
  });

  const length = translatePrefix === 'studentBehaviorRating' ? 5 : 3;

  return (
    <ConditionalRenderer condition={tab !== 'view' || previewValue}>
      <ConditionalWrapper
        condition={disabled ?? false}
        wrapper={<div className="flex flex-col gap-2" />}
      >
        <ConditionalRenderer condition={disabled ?? false}>
          <AssessmentPreview
            text={label}
            value={previewValue}
            length={length}
          />
        </ConditionalRenderer>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <EnumInput
              type="numeric"
              testId={testId}
              label={label}
              selected={previewValue?.toString()}
              onSelect={value => onChange(value)}
              enumModel={enumModel}
              displayName={(option: string) => `${option} - ${t(option)}`}
              errorLabelText={error?.message}
              onDeselect={() => onChange(undefined)}
              disabled={disabled}
            />
          )}
        />
      </ConditionalWrapper>
    </ConditionalRenderer>
  );
}
