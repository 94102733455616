import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import { Avatar } from '@/models/Avatar';
import { useQuery } from '@tanstack/react-query';
import UserAvatar from '@/components/store/UserAvatar';
import User, { UserTypeEnum } from '@/models/User';
import Tag from '@/components/common/dataDisplay/Tag';
import { LoadingIcon } from '@/components/icons';
import Text from '@/components/common/dataDisplay/Text';
import { UsersIcon } from '@heroicons/react/outline';
import { useAvatar } from '@/data/context/AvatarContext';
import Klass from '@/models/Klass';
import getKlassStudents from '@/functions/klassActiveStudents';
import Skeleton from '@/components/common/Skeleton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import {
  avatarsQueryKeys,
  enrollmentsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';

interface KlassMembersProps {
  klass?: Klass;
  klassError: string | null;
  isLoadingKlass: boolean;
}

export default function KlassMembers({
  klass,
  klassError,
  isLoadingKlass,
}: KlassMembersProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.dashboardClass',
  });

  const { data: usersAvatarResponse, isInitialLoading: isLoadingUsersAvatar } =
    useQuery({
      ...avatarsQueryKeys.list({
        klass: klass?.id,
      }),
      enabled: !!klass,
    });
  const usersAvatar = usersAvatarResponse?.results ?? [];

  if (isLoadingKlass) {
    return (
      <Skeleton className="bg-primary-content rounded-xl h-[300px] w-full sm:w-[30%]" />
    );
  } else if (klassError) {
    return (
      <Text
        testId="klassErrorError"
        text={klassError}
        format="rubik-500"
        className="text-primary"
      />
    );
  }
  return (
    <div className="flex flex-col">
      <div className="flex px-6 py-2.5 gap-5 w-full sm:w-96 rounded-t-2xl items-center bg-accent text-base-100">
        <UsersIcon strokeWidth={2.5} className="w-4 h-4 sm:w-5 sm:h-5" />
        <Text
          format="rubik-500"
          size="text-16 sm:text-18"
          text={t('yourClass')}
          className="uppercase m-0"
        />
      </div>
      <div className="flex w-full sm:w-96 h-auto relative bg-accent/40 aspect-video rounded-b-2xl shadow-default  ">
        <img
          src="/images/cenario.png"
          alt=""
          className="w-full rounded-b-2xl"
        />
        {klass ? (
          <RenderUserAvatar
            klass={klass}
            usersAvatar={usersAvatar}
            isLoadingUsersAvatar={isLoadingUsersAvatar}
          />
        ) : (
          <Text
            format="rubik-500"
            className="text-20 rounded-b-2xl text-primary-content p-5 text-center w-full h-auto aspect-video absolute flex place-self-end self-end bg-primary/40 justify-self-end"
            text={t('teacherKlassMembers')}
          />
        )}
      </div>
    </div>
  );
}

interface UserAvatarProps {
  klass: Klass;
  usersAvatar?: Avatar[];
  isLoadingUsersAvatar: boolean;
}

const RenderUserAvatar = ({
  klass,
  usersAvatar,
  isLoadingUsersAvatar,
}: UserAvatarProps) => {
  const { user } = useAuth();
  const { userAvatar } = useAvatar();

  const status = [EnrollmentStatusEnum.ACTIVE, EnrollmentStatusEnum.CONCLUDED];
  const { data: enrollmentsResponse } = useQuery({
    ...enrollmentsQueryKeys.list({ klassId: klass.id, status }),
  });
  const enrollments = enrollmentsResponse?.results ?? [];

  const { data: klassTeacher } = useQuery({
    ...usersQueryKeys.get(klass.teacherId),
    enabled: !!klass.teacherId,
  });

  const students = klass ? getKlassStudents(enrollments) : [];

  if (isLoadingUsersAvatar) {
    return (
      <div className="flex w-full h-full items-center justify-center absolute">
        <LoadingIcon className="w-20 h-20 text-accent/40" />
      </div>
    );
  } else
    return (
      <React.Fragment>
        <ConditionalRenderer condition={klassTeacher}>
          {klassTeacher && (
            <AvatarTag
              className="self-center top-2 absolute w-full"
              user={klassTeacher}
            />
          )}
        </ConditionalRenderer>
        <div className="flex flex-wrap-reverse absolute bottom-3 justify-between w-full h-fit">
          <ConditionalRenderer condition={!!usersAvatar}>
            {usersAvatar &&
              students.map(student => {
                const isUser = student.id === user?.id;
                const avatar = isUser
                  ? userAvatar
                  : usersAvatar.find(avatar => avatar.user === student.id);
                return (
                  <div
                    key={avatar?.id}
                    className="odd:pb-4 aspect-square h-fit"
                  >
                    <AvatarTag className="aspect-square" user={student} />
                  </div>
                );
              })}
          </ConditionalRenderer>
        </div>
      </React.Fragment>
    );
};

const AvatarTag = ({ user, className }: { user: User; className?: string }) => {
  return (
    <Link
      to={`/profile/${user.username}`}
      className={`${className || ''} flex flex-col items-center gap-0.5 h-fit`}
    >
      <Tag
        text={user.firstName}
        color={user.userType === UserTypeEnum.STUDENT ? 'accent' : 'primary'}
        className="text-[0.5rem] rounded-full px-1 py-0.5"
      />
      <UserAvatar className="scale-90 xs:scale-125" userId={user.id} />
    </Link>
  );
};
