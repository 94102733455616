import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import {
  BehaviorAssessment,
  BehaviorRatingEnum,
  CommentAssessment,
  StudentAssessment,
} from '@/models/StudentAssessment';
import { fadeIn } from '@/utils/animations/commom';
import User from '@/models/User';
import CommentAssessmentForm from '../StudentCommentAssessmentForm/CommentAssessmentForm';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import {
  StudentAssessmentBody,
  createStudentAssessment,
  updateStudentAssessment,
} from '@/data/services/studentAssessmentsServices';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import { buildChangedObject } from '@/utils/buildChangedObject';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { SheetTab } from '@/models/SheetTab';
import BehaviorInput from '@/components/common/assessment/BehaviorInput';

type BehaviorAssessmentFormProps = {
  student: User;
  changeTab?(changed: SheetTab): void;
  pedagogicalSheetId?: number;
  updateAssessments?(): Promise<void>;
  studentAssessment?: StudentAssessment;
  disabled?: boolean;
  tab: SheetTab;
};

export default function BehaviorAssessmentForm({
  student,
  changeTab,
  pedagogicalSheetId,
  updateAssessments,
  studentAssessment: defaultValues,
  disabled,
  tab,
}: BehaviorAssessmentFormProps) {
  const createMode = !defaultValues;

  const { t } = useTranslation('translation', {
    keyPrefix: 'behaviorAssessmentForm',
  });

  const { handleSubmit, reset, watch, control } = useForm<StudentAssessment>({
    defaultValues,
  });

  const [hasChanges, setHasChanges] = useState(false);

  const onCancel = () => {
    setHasChanges(false);
    reset();
    changeTab?.('view');
  };

  const onSuccessAction = async () => {
    if (updateAssessments) await updateAssessments();
    changeTab?.('view');
    setHasChanges(false);
  };

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createStudentAssessment,
    {
      async onSuccess() {
        alert.success(t('successCreatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitCreate: SubmitHandler<StudentAssessment> = async ({
    behaviorAssessment,
    commentAssessment,
    klassId,
  }: StudentAssessment) => {
    if (!pedagogicalSheetId) throw new Error('pedagogical sheet not found');

    const assessment: StudentAssessmentBody = {
      pedagogicalSheetId,
      behaviorAssessment,
      commentAssessment: commentAssessment?.comment
        ? commentAssessment
        : undefined,
      klassId,
    };

    create(assessment);
  };

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    updateStudentAssessment,
    {
      async onSuccess() {
        alert.success(t('successUpdatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitUpdate: SubmitHandler<StudentAssessment> = async data => {
    if (!defaultValues?.id) throw new Error('assessment not found');

    if (defaultValues) {
      const assessmentId = defaultValues.id;

      const assessmentChanges = buildChangedObject<StudentAssessment>(
        defaultValues,
        data,
      );

      const hasCommentAssessment =
        !!defaultValues.commentAssessment || !!data.commentAssessment?.comment;

      const commentChanges = hasCommentAssessment
        ? buildChangedObject<CommentAssessment>(
            defaultValues.commentAssessment,
            data.commentAssessment,
          )
        : {};

      const commentAssessment = Object.keys(commentChanges).length
        ? commentChanges.comment
          ? commentChanges
          : {}
        : undefined;

      const behaviorChanges = buildChangedObject<BehaviorAssessment>(
        defaultValues.behaviorAssessment,
        data.behaviorAssessment,
      );

      const behaviorAssessment = Object.keys(behaviorChanges).length
        ? behaviorChanges
        : undefined;

      const body = {
        ...assessmentChanges,
        commentAssessment,
        behaviorAssessment,
      };
      update({ assessmentId, body });
    }
  };

  const onSubmit = createMode ? onSubmitCreate : onSubmitUpdate;

  const motionClassName = 'flex flex-col w-full gap-3';

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  return (
    <motion.form
      className={motionClassName}
      {...fadeIn}
      onSubmit={handleSubmit(onSubmit)}
    >
      <BehaviorInput
        control={control}
        label={t('labels.attention')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.attention}
        name="behaviorAssessment.attention"
        testId="attentionInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
      />

      <BehaviorInput
        control={control}
        label={t('labels.apprenticeship')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.apprenticeship}
        name="behaviorAssessment.apprenticeship"
        testId="apprenticeshipInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
      />

      <BehaviorInput
        control={control}
        label={t('labels.initiative')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.initiative}
        name="behaviorAssessment.initiative"
        testId="initiativeInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
      />

      <BehaviorInput
        control={control}
        label={t('labels.socialization')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.socialization}
        name="behaviorAssessment.socialization"
        testId="socializationInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
      />

      <BehaviorInput
        control={control}
        label={t('labels.organization')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.organization}
        name="behaviorAssessment.organization"
        testId="organizationInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
      />

      <ConditionalRenderer condition={!disabled}>
        <CommentAssessmentForm
          student={student}
          assessmentControl={control}
          studentAssessment={defaultValues}
        />
        <SaveCancelGroup
          className="self-start"
          loading={loadingCreate || loadingUpdate}
          save={{
            disabled: !hasChanges,
            testId: 'saveButton',
          }}
          cancel={{
            onClick: onCancel,
            testId: 'cancelButton',
          }}
        />
      </ConditionalRenderer>
    </motion.form>
  );
}
