import { v4 } from 'uuid';
import { DNDOrdenationActivity } from './ActivityElement';

export enum FlowColumn {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export class ColumnDND {
  id?: number;
  name: string;
  flow: FlowColumn;
  dndOrdenationActivity?: number;
  items: ItemDND[];
  order: number;
  editType?: 'new' | 'edit' | 'remove';
  errors?: string[];
  identifier?: string;

  constructor(
    name: string = '',
    flow: FlowColumn = FlowColumn.VERTICAL,
    items: ItemDND[] = [new ItemDND(), new ItemDND(), new ItemDND()],
    order: number = 1,
  ) {
    this.name = name;
    this.flow = flow;
    this.items = items;
    this.order = order;
    this.editType = 'new';
    this.identifier = v4();
  }
}

export class ItemDND {
  id?: number;
  dndOrdenationColumn?: number;
  image?: File | string;
  altText?: string;
  text?: string;
  order?: number;
  identifier?: string;
  editType?: 'new' | 'edit' | 'remove';
  errors?: string[];

  constructor(
    image: File | string | undefined = undefined,
    text: string | undefined = undefined,
    alt_text: string | undefined = undefined,
  ) {
    this.identifier = v4();
    this.image = image;
    this.text = text;
    this.altText = alt_text;
    this.editType = 'new';
  }
}

export interface ItemProgress {
  id: number;
  item: ItemDND;
  answerOrder: number;
}

export interface ColumnProgress {
  id: number;
  column: ColumnDND;
  itemsProgress: ItemProgress[];
}

export interface DndOrdenationActivityProgress {
  id: number;
  isDone: boolean;
  isCurrentQuestion: boolean;
  dndOrdenationActivity: DNDOrdenationActivity;
  columnsProgress: ColumnProgress[];
}
