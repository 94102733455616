import requester from '@/axios';
import ActivityProgress, {
  ActivityProgressShortView,
} from '@/models/ActivityProgress';
import JsonApiResponse from '@/models/JsonApiResponse';

export interface ActivityProgressFilterParams {
  pageNumber?: number;
  pageSize?: number;
  activityId?: number;
  user?: number;
  isActive?: boolean;
}
export async function listActivityProgress(
  params: ActivityProgressFilterParams = {},
) {
  const { data } = await requester().get<JsonApiResponse<ActivityProgress>>(
    'activity-progress/',
    {
      params,
    },
  );

  return data;
}

export async function listActivityProgressShortView(
  params: ActivityProgressFilterParams = {},
) {
  const { data } = await requester().get<
    JsonApiResponse<ActivityProgressShortView>
  >('activity-progress/short-view/', {
    params,
  });

  return data;
}

export const getActivityProgress = async (activityProgressId: number) => {
  const { data } = await requester().get<ActivityProgress>(
    `activity-progress/${activityProgressId}/`,
  );

  return data;
};

export const updateActivityProgress = async (
  activityProgressId: number,
  activityProgress: Partial<ActivityProgress>,
) => {
  const { data } = await requester().patch<ActivityProgress>(
    `activity-progress/${activityProgressId}/`,
    activityProgress,
  );
  return data;
};
