import useAuth from '@/data/hook/useAuth';
import { ApiError } from '@/models/Errors';
import ITag from '@/models/Tag';
import { UserTypeEnum } from '@/models/User';
import {
  PhotographIcon as OutlinePhotographIcon,
  PencilIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  PhotographIcon as SolidPhotographIcon,
} from '@heroicons/react/solid';
import { debounce } from 'lodash';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListTagFilters } from '@/data/services/toolServices';
import { isStudent } from '@/functions/auth';
import Tool from '@/models/Tool';
import { LoadingIcon } from '../../../icons';
import ComponentGuard from '../../ComponentGuard';
import ConditionalRenderer from '../../ConditionalRenderer';
import Skeleton from '../../Skeleton';
import IconButton from '../../buttons/IconButton';
import Popper from '../../dataDisplay/Popper';
import Tag from '../../dataDisplay/Tag';
import Text from '../../dataDisplay/Text';
import { Tooltip } from '../../dataDisplay/Tooltip';
import { BaseInputProps } from '../BaseInput';
import TextInput from '../TextInput';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { toolsQueryKeys } from '@/data/services/querykeys';

export type TagType = 'tool' | 'none';

export type SelectMainTag = {
  tagType: TagType;
  tag: ITag;
};

export interface TagInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement>,
    BaseInputProps {
  tagType: TagType;
  onSelectTag<ITag>(tagType: TagType, tag: ITag): void;
  selectedTags?: ITag[];
  openForm?(tagType: TagType, tag: ITag): void;
  onSelectMainTag?({ tag, tagType }: SelectMainTag): void;
}

export default function TagInput(props: TagInputProps) {
  const {
    tagType,
    className,
    errorLabelText,
    labelPosition = 'top',
    labelWidth = 'w-36',
    fontSize,
    onSelectTag,
    selectedTags,
    openForm,
    onSelectMainTag,
    disabled,
  } = props;

  const { user } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'tagInput',
  });

  const [search, setSearch] = useState<string>();

  const [tagError, setTagError] = useState('');

  const tagFilter: ListTagFilters = {
    name: search,
  };
  const {
    results: tools,
    isInitialLoading: initialLoadingTools,
    isRefetching: refetchingTools,
    error: toolError,
  } = useInfiniteService({
    ...toolsQueryKeys.list(tagFilter)._ctx.infinity,
    keepPreviousData: true,
    enabled: !isStudent(user?.userType) && tagType === 'tool',
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && toolError) {
      const apiError = new ApiError(toolError as any);
      setTagError(apiError.getErrorMessage());
    }

    return () => {
      mounted = false;
      setTagError('');
    };
  }, [toolError]);

  const defineTagType = (tagType: TagType) => {
    const keys = {
      tool: [tools, selectedTags as Tool[]],
      none: [[], selectedTags as ITag[]],
    };

    return keys[tagType];
  };

  const [tags, selected] = defineTagType(tagType);

  const onChangeFilter: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    if (target.value) {
      setSearch(target.value);
    } else {
      setSearch(undefined);
    }
  };

  return (
    <div className="form-control relative flex flex-col">
      <ConditionalRenderer condition={initialLoadingTools}>
        <Skeleton
          testId="tagInputSkeleton"
          className={`w-full h-8 rounded-md bg-primary-content ${
            className ?? 'lg:max-w-[100%]'
          }`}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={!!tags}>
        <Popper
          placement="bottom-start"
          disabled={disabled}
          referenceClassName="flex w-full"
          referenceContent={
            <div className="flex flex-col grow">
              <label
                htmlFor={tagType as string}
                className={`label p-0 cursor-pointer ${
                  labelPosition === 'left'
                    ? labelWidth + ' font-500 justify-start'
                    : 'text-14'
                } ${fontSize || ''} ${
                  errorLabelText && labelPosition === 'left' ? 'mt-5' : ''
                }`}
              >
                {t(tagType as string)}
              </label>
              <div
                className="flex items-center gap-2 h-8 rounded-lg px-2 border border-primary"
                data-testid="tagPreview"
              >
                {selected?.map(tag => {
                  function instanceOffTag(obj: any): obj is Tool {
                    return 'color' in obj;
                  }
                  const color = instanceOffTag(tag)
                    ? tag.color ?? '#738CFF'
                    : '#738CFF';

                  return (
                    <Tag
                      key={tag.id}
                      testId="selectedTag"
                      color="custom"
                      text={tag.name}
                      style={{
                        backgroundColor: color,
                        color: 'var(--base-100)',
                      }}
                      className="text-14 shadow-default h-6 px-2.5 gap-1"
                      icon={
                        <XIcon
                          className="w-4"
                          onClick={() => onSelectTag(tagType, tag)}
                        />
                      }
                    />
                  );
                })}
              </div>
            </div>
          }
          popperContent={
            <TagList
              selectedTags={selectedTags}
              tags={tags as ITag[]}
              refetchingTags={refetchingTools}
              tagType={tagType}
              onSelectTag={onSelectTag}
              onChangeFilter={onChangeFilter}
              openForm={openForm}
              onSelectMainTag={onSelectMainTag}
            />
          }
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={!!toolError}>
        <Text text={tagError} className="pt-2 text-primary" />
      </ConditionalRenderer>
    </div>
  );
}

interface TagListProps extends TagInputProps {
  tags: ITag[];
  selectedTags?: ITag[];
  refetchingTags: boolean;
  onChangeFilter: React.ChangeEventHandler<HTMLInputElement>;
}

function TagList(props: TagListProps) {
  const {
    tags,
    onSelectTag,
    tagType,
    selectedTags,
    refetchingTags,
    onChangeFilter,
    openForm,
    onSelectMainTag,
  } = props;

  const [data, selectedData = []] = defineTagType(tags, tagType, selectedTags);

  type TagWithSelected = ITag & { isSelected: boolean; isMain: boolean };

  const sortSelectedTags = (a: TagWithSelected, b: TagWithSelected) => {
    if (a.isSelected && !b.isSelected) {
      return -1;
    }

    if (!a.isSelected && b.isSelected) {
      return 1;
    }
    return 0;
  };

  const selectedTagsWithOrder = data
    .map<TagWithSelected>(tag => {
      const tool = selectedData?.find(({ id }) => id === tag.id) as Tool;
      return {
        ...tag,
        isSelected: !!tool,
        isMain: tool?.isMain ?? false,
      };
    })
    .sort(sortSelectedTags);

  const debouncedSearchChange = debounce(onChangeFilter, 500);

  const findMain = selectedTagsWithOrder?.find(({ isMain }) => isMain);

  return (
    <div className={mainClassNameList}>
      <TextInput
        fontWeight="font-400"
        icon={<SearchIcon />}
        className={`z-50 flex w-full ${refetchingTags ? 'disabled' : ''}`}
        testId="tagInput"
        autoComplete="off"
        onChange={e => debouncedSearchChange(e)}
      />

      <ConditionalRenderer condition={refetchingTags}>
        <div
          className="flex flex-col items-center relative w-72 z-30 justify-center"
          data-testid="refetchingTags"
        >
          <LoadingIcon className="w-10 text-primary-content" />
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer condition={!refetchingTags && !!data.length}>
        <ul
          data-testid="tagUnorderedList"
          className="flex flex-col w-full gap-2 overflow-y-scroll scrollbar-thumb-rounded-full 
          scrollbar-thin scrollbar-thumb-primary-content scrollbar-track-transparent h-36 overflow-visible"
        >
          {selectedTagsWithOrder?.map((tag, index) => (
            <TagItem
              index={index}
              key={tag.id}
              tag={tag}
              onSelectTag={onSelectTag}
              tagType={tagType}
              openForm={openForm}
              onSelectMainTag={onSelectMainTag}
              selected={tag.isSelected}
              isMain={
                tag?.isMain || (!findMain && index === 0 && tag.isSelected)
              }
            />
          ))}
        </ul>
      </ConditionalRenderer>

      <ConditionalRenderer condition={!data.length && !refetchingTags}>
        <div
          className="flex flex-col items-center relative w-72 z-30"
          data-testid="noTags"
        >
          <Text text="༼ つ ◕_◕ ༽つ" className="pt-2 text-primary" />
        </div>
      </ConditionalRenderer>
    </div>
  );
}

interface TagItemProps<ITag> extends TagInputProps {
  index: number;
  isMain?: boolean;
  tag: ITag;
  selected?: boolean;
  tagType: TagType;
  onSelectTag<ITag>(tagType: TagType, tag: ITag): void;
}

function TagItem<ITag>({
  selected,
  onSelectTag,
  tagType,
  tag,
  openForm,
  onSelectMainTag,
  isMain,
}: TagItemProps<ITag>) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tagInput',
  });

  const keys = {
    tool: tag as Tool,
    none: tag as any,
  };

  const item = keys[tagType];

  const testId = selected ? `selectedTag${item.id}` : `tagItem${item.id}`;

  const MainTagImageIcon = () => {
    if (isMain) return <SolidPhotographIcon className="w-4" />;
    return <OutlinePhotographIcon className="w-4" />;
  };

  return (
    <li
      data-testid={testId}
      className={`flex gap-2 items-center text-center transition ease-in-out duration-150
                  text-14 px-2 p-1 rounded-lg justify-between ${
                    selected ? 'text-primary' : ''
                  } ${
        isMain
          ? 'bg-primary/40 border-primary border-2'
          : 'border-primary/40 hover:text-primary hover:bg-primary-content border'
      }`}
    >
      <span
        onClick={() => onSelectTag(tagType, item)}
        className="flex gap-4 w-full cursor-pointer"
      >
        {item.name}
      </span>
      <span className="flex gap-4 w-fit">
        <ConditionalRenderer condition={selected}>
          <span className="flex gap-2 items-center">
            <ConditionalRenderer condition={isMain}>
              <Text text={t('banner').toLowerCase()} />
            </ConditionalRenderer>

            <ConditionalRenderer condition={tagType === 'tool'}>
              <Tooltip text={t('mainToolBanner')}>
                <IconButton
                  type="button"
                  icon={<MainTagImageIcon />}
                  onClick={() =>
                    onSelectMainTag && onSelectMainTag({ tagType, tag: item })
                  }
                />
              </Tooltip>
            </ConditionalRenderer>
            <CheckCircleIcon className="w-4" />
          </span>
        </ConditionalRenderer>

        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <Tooltip text={t('editTag')}>
            <IconButton
              type="button"
              icon={<PencilIcon className="w-4" />}
              onClick={() => openForm && openForm(tagType, item)}
            />
          </Tooltip>
        </ComponentGuard>
      </span>
    </li>
  );
}

function defineTagType<ITag>(
  tags: ITag[],
  tagType: TagType,
  selectedTags?: ITag[],
) {
  const keys = {
    tool: [tags as Tool[], selectedTags as Tool[]],
    none: [[], selectedTags as ITag[]],
  };

  return keys[tagType];
}

const mainClassNameList =
  'flex flex-col items-center relative w-72 z-30 p-4 bg-base-100 rounded-md shadow-default border border-primary-content h-auto max-h-64 gap-2 overflow-visible z-30';
