import { Dispatch, SetStateAction, useState } from 'react';
import {
  Course,
  CourseCategoryEnum,
  CourseDurationEnum,
  CourseDurationMinuteEnum,
  CourseTypeEnum,
} from '@/models/Course';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { addCourse, updateCourse } from '@/data/services/courseServices';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { validateUpload } from '@/utils/VerifyImageFile';
import TextInput from '@/components/common/dataInput/TextInput';
import SelectInput from '@/components/common/dataInput/SelectInput';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import MainButton from '@/components/common/buttons/MainButton';
import { SaveIcon, XIcon } from '@heroicons/react/outline';
import TooltipHandler from '@/components/common/TooltipHandler';
import ImageInput from '@/components/common/dataInput/ImageInput';
import { courseGenerator } from '@/functions/generators';

export interface CourseFormProps {
  course: Partial<Course>;
  updateCourseList(): void;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

export default function CourseForm(props: CourseFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageCourse.editCourse',
  });
  const { t: tCategory } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });

  const { t: tType } = useTranslation('translation', {
    keyPrefix: 'courseTypes',
  });

  const { course, updateCourseList, isEditMode, setIsEditMode } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hasChanges, setHasChanges] = useState(false);

  const [bannerImg, setBannerImg] = useState<File | string | null>(
    course.bannerImg ?? null,
  );

  const isNew = !course.id;

  const status = [
    {
      text: t('active'),
      value: true,
    },
    {
      text: t('inactive'),
      value: false,
    },
  ];

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Course>({
    defaultValues: course,
  });

  const onSubmitEditCourse: SubmitHandler<Course> = async data => {
    const { id } = data;
    try {
      setIsLoading(true);
      let changedFields = buildChangedObject<Partial<Course>>(course, data);

      const changedBanner =
        typeof bannerImg !== 'string' ? bannerImg : undefined;

      if (Object.keys(changedFields).length || changedBanner) {
        await updateCourse(id, { ...changedFields, bannerImg: changedBanner });
        feedbackForm();
        alert.success(t('saveEdited'));
      }
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitAddCourse: SubmitHandler<Course> = async data => {
    try {
      setIsLoading(true);

      await addCourse(data, bannerImg);

      reset(
        courseGenerator({
          id: 0,
          name: '',
          abbreviation: '',
          bannerImg: null,
        }),
      );
      setBannerImg(null);
      feedbackForm();
      alert.success(t('saveCreated'));
    } catch (error: any) {
      const apiError = new ApiError(error);
      alertError(apiError);
    } finally {
      setIsLoading(false);
    }
  };

  const alertError = (apiError: ApiError) => {
    if (apiError.fields.includes('abbreviation')) {
      const fieldMessage = apiError.getMessageByField('abbreviation');
      fieldMessage && alert.warning(fieldMessage);
    } else {
      alert.error(apiError.getErrorMessage());
    }
  };

  const feedbackForm = () => {
    updateCourseList();
    setHasChanges(false);
    setIsEditMode(false);
  };

  const onCancel = () => {
    reset(course);
    setBannerImg(course.bannerImg ?? null);
    setHasChanges(false);
    setIsEditMode(false);
  };

  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files && e.target.files.length) {
      const value = e.target.files.item(0);
      if (value && !validateUpload(value)) {
        setBannerImg(value);
        setHasChanges(true);
      }
    }
  };

  const onImageRemove = () => {
    setBannerImg(null);
    setHasChanges(true);
  };

  const onSubmit = isNew ? onSubmitAddCourse : onSubmitEditCourse;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full gap-4"
    >
      <TextInput
        color={errors.name?.message ? 'warning' : 'primary'}
        dataTestId="nameInput"
        label={t('name')}
        register={register('name', {
          maxLength: {
            message: t('errors.name.maxLength'),
            value: 200,
          },
          onChange: () => {
            setHasChanges(true);
          },
          required: t('wanings.emptyField'),
        })}
        disabled={!isEditMode}
        labelPosition="left"
        fontWeight="font-400"
        errorLabelText={errors.name?.message}
      />

      <TextInput
        color={errors.abbreviation?.message ? 'warning' : 'primary'}
        dataTestId="abbreviationInput"
        label={t('abbreviation')}
        register={register('abbreviation', {
          maxLength: {
            message: t('errors.abbreviation.maxLength'),
            value: 7,
          },

          onChange: () => {
            setHasChanges(true);
          },
          required: t('wanings.emptyField'),
        })}
        disabled={!isEditMode}
        labelPosition="left"
        fontWeight="font-400"
        errorLabelText={errors.abbreviation?.message}
      />

      <SelectInput
        testId="courseTypeInput"
        label={t('type')}
        labelPosition="left"
        register={register('courseType', {
          onChange: () => {
            setHasChanges(true);
          },
          required: t('wanings.emptyField'),
        })}
        disabled={!isEditMode}
        fontWeight="font-400"
        errorLabelText={errors.courseType?.message}
      >
        {Object.keys(CourseTypeEnum).map((type: string) => {
          return (
            <option key={type} value={type}>
              {tType(type)}
            </option>
          );
        })}
      </SelectInput>

      <SelectInput
        testId="durationInput"
        label={t('duration')}
        labelPosition="left"
        register={register('duration', {
          onChange: () => {
            setHasChanges(true);
          },
          required: t('wanings.emptyField'),
        })}
        disabled={!isEditMode}
        fontWeight="font-400"
        errorLabelText={errors.duration?.message}
      >
        {(
          Object.keys(CourseDurationEnum) as (keyof typeof CourseDurationEnum)[]
        ).map(state => {
          return (
            <option key={state} value={CourseDurationMinuteEnum[state]}>
              {CourseDurationEnum[state]}
            </option>
          );
        })}
      </SelectInput>
      <SelectInput
        testId="categoryInput"
        label={t('category')}
        labelPosition="left"
        register={register('category', {
          onChange: () => {
            setHasChanges(true);
          },
          required: t('wanings.emptyField'),
        })}
        disabled={!isEditMode}
        fontWeight="font-400"
      >
        {Object.keys(CourseCategoryEnum).map(category => {
          return (
            <option key={category} value={category}>
              {tCategory(category)}
            </option>
          );
        })}
      </SelectInput>

      <ConditionalRenderer condition={!isNew}>
        <SelectInput
          testId="isActiveInput"
          label={t('isActive')}
          labelPosition="left"
          register={register('isActive', {
            onChange: () => {
              setHasChanges(true);
            },
            required: t('wanings.emptyField'),
          })}
          disabled={!isEditMode}
          fontWeight="font-400"
          errorLabelText={errors.isActive?.message}
        >
          {status.map(({ text, value }) => {
            return (
              <option key={text} value={value.toString()}>
                {text}
              </option>
            );
          })}
        </SelectInput>
      </ConditionalRenderer>

      <ImageInput
        testId="bannerImgInput"
        className="w-fit"
        isDisabled={!isEditMode}
        image={bannerImg}
        onChange={onImageChange}
        onRemove={onImageRemove}
      />

      <ConditionalRenderer condition={isEditMode}>
        <span className="flex justify-end gap-5">
          <MainButton
            dataTestId="cancelButton"
            type="button"
            onClick={onCancel}
            icon={<XIcon />}
            text={t('cancelButton')}
            color="neutral"
          />

          <TooltipHandler
            renderTooltip={!hasChanges}
            tooltipMessage={t('wanings.noChanges')}
          >
            <MainButton
              dataTestId="saveButton"
              type="submit"
              icon={<SaveIcon />}
              text={t('saveButton')}
              loading={isLoading}
              disabled={!hasChanges}
            />
          </TooltipHandler>
        </span>
      </ConditionalRenderer>
    </form>
  );
}
