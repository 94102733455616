import { Lesson } from './Lesson';
import { ContentGivenEnum } from './ScheduledLessonReport';

export enum ScheduledLessonTypeEnum {
  NORMAL = 'NORMAL',
  EXTRA = 'EXTRA',
  FREE = 'FREE',
}

export default interface ScheduledLesson {
  id: number;
  order: number;
  klass: number;
  lesson: Lesson;
  datetime: string;
  notes: string;
  teacher: number;
  hasDone: boolean;
  url?: string;
  progress?: number;
  isActive: boolean;
  givenContent?: ContentGivenEnum;
  presences: number;
  absences: number;
  homeworkActivity: number | null;
  hasHomeworkActivity: boolean;
  type: ScheduledLessonTypeEnum;
}

export enum RescheduleTypeEnum {
  POSTPONE = 'POSTPONE',
  ANTICIPATE = 'ANTICIPATE',
}
