import { Fragment } from 'react';
import LessonIconContainer, {
  IconType,
} from '@/components/common/LessonIconContainer';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface KlassesIconProps {
  notificationCount: number;
  type: IconType;
  disabled?: boolean;
}

export default function KlassesIcon({
  notificationCount,
  type,
  disabled,
}: KlassesIconProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  const AttendanceType = () => {
    return (
      <Fragment>
        <div className="bg-error-content/80" />
        <LessonIconContainer
          type={type}
          notification={disabled ? undefined : notificationCount}
        />
        <ConditionalRenderer
          condition={disabled}
          fallback={
            <Text
              size="text-10"
              text={notificationCount === 0 ? t('attended') : t('missed')}
            />
          }
        >
          <Text size="text-10" text="n/a" />
        </ConditionalRenderer>
      </Fragment>
    );
  };

  const OtherTypes = () => {
    const status = () => {
      if (disabled) return 'n/a';

      if (type === 'studentBook') {
        if (notificationCount === 100) return t('done');
        return t('pending');
      }

      return notificationCount > 0 ? t('pending') : t('done');
    };
    return (
      <Fragment>
        <LessonIconContainer type={type} notification={notificationCount} />
        <Text size="text-10" text={disabled ? "n/a" : status()} />
      </Fragment>
    );
  };

  return (
    <div
      className={`flex flex-col gap-1 w-fit items-center ${
        disabled ? 'grayscale' : ''
      }`}
    >
      {type === 'attendance' ? <AttendanceType /> : <OtherTypes />}
    </div>
  );
}
