import { useTranslation } from 'react-i18next';
import useStudentContext from '@/data/hook/student';
import { GuestPage } from '../../guest/GuestPage';
import Text from '@/components/common/dataDisplay/Text';
import useAuth from '@/data/hook/useAuth';
import DashboardProgress from '@/components/dashboard/student/DashboardProgress/DashboardProgress';
import KlassMembers from '@/components/dashboard/student/KlassMembers';
import AstroCountdown from '@/components/common/AstroCountdown';
import LoadingView from '../../courses/LoadingView';
import CourseProgressSelect from '@/components/courses/student/CourseProgressSelect';

export default function StudentDashboardView() {
  const { user } = useAuth();
  const {
    klass,
    nextLesson,
    isLoadingNextLesson,
    isLoadingKlass,
    klassListError,
  } = useStudentContext();

  const nextLessonData = nextLesson?.id ? nextLesson : undefined;

  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });

  if (isLoadingKlass) {
    return <LoadingView />;
  } else if (klassListError) {
    return (
      <Text
        testId="klassErrorError"
        text={klassListError}
        format="rubik-500"
        className="text-primary"
      />
    );
  } else if (klass) {
    const isLoadingCountdown = isLoadingNextLesson || isLoadingKlass;
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <Text
            text={t('hello') + user?.firstName + ' 👋'}
            className="text-primary w-fit"
            format="poppins-600"
            size="text-28"
          />

          <Text
            text={t('wellcome.student')}
            format="rubik-400"
            size="text-16"
            className="break-words"
          />
        </div>

        <CourseProgressSelect />

        <DashboardProgress klass={klass} />

        <div className="flex gap-4 flex-col md:flex-row lg:flex-wrap">
          <KlassMembers
            isLoadingKlass={isLoadingKlass}
            klass={klass}
            klassError={klassListError}
          />
          <AstroCountdown
            activeKlass={klass}
            activeNextLesson={nextLessonData}
            isLoadingCountdown={isLoadingCountdown}
          />
        </div>
      </div>
    );
  }
  return <GuestPage />;
}
