import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { isEqual } from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, Control, Controller } from 'react-hook-form';

import {
  CommentAssessment,
  StudentAssessment,
} from '@/models/StudentAssessment';
import { fadeIn } from '@/utils/animations/commom';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import {
  StudentAssessmentBody,
  createStudentAssessment,
  updateStudentAssessment,
} from '@/data/services/studentAssessmentsServices';
import alert from '@/utils/UseAlert';
import { KlassStatusEnum } from '@/models/Klass';
import { ApiError } from '@/models/Errors';
import User, { UserStatusEnum, UserTypeEnum } from '@/models/User';
import useAuth from '@/data/hook/useAuth';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { handleUserFullName } from '@/functions/handleUserFullName';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { SheetTab } from '@/models/SheetTab';
import { klassesQueryKeys } from '@/data/services/querykeys';

type CommentAssessmentFormProps = {
  student: User;
  pedagogicalSheetId?: number;
  updateAssessments?(): Promise<void>;
  studentAssessment?: StudentAssessment;
  changeTab?(changed: SheetTab): void;
  assessmentControl?: Control<StudentAssessment>;
};

export default function CommentAssessmentForm({
  pedagogicalSheetId,
  updateAssessments,
  studentAssessment: defaultValues,
  changeTab,
  student,
  assessmentControl,
}: CommentAssessmentFormProps) {
  const createMode = !defaultValues;

  const { t } = useTranslation('translation', {
    keyPrefix: 'commentAssessmentForm',
  });

  const {
    handleSubmit,
    reset,
    watch,
    control: commentControl,
  } = useForm<StudentAssessment>({ defaultValues });

  const control = assessmentControl ?? commentControl;

  const [hasChanges, setHasChanges] = useState(false);

  const onCancel = () => {
    setHasChanges(false);
    reset();
    changeTab?.('view');
  };

  const onSuccessAction = async () => {
    if (updateAssessments) await updateAssessments();
    changeTab?.('view');
    setHasChanges(false);
  };

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createStudentAssessment,
    {
      async onSuccess() {
        alert.success(t('successCreatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    updateStudentAssessment,
    {
      async onSuccess() {
        alert.success(t('successUpdatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitUpdate: SubmitHandler<StudentAssessment> = async data => {
    if (!defaultValues?.id) throw new Error('assessment not found');

    if (defaultValues) {
      const assessmentId = defaultValues.id;

      const assessmentChanges = buildChangedObject<StudentAssessment>(
        defaultValues,
        data,
      );

      const commentChanges = buildChangedObject<CommentAssessment>(
        defaultValues.commentAssessment,
        data.commentAssessment,
      );

      const body = { ...assessmentChanges, commentAssessment: commentChanges };
      update({ assessmentId, body });
    }
  };

  const onSubmitCreate: SubmitHandler<StudentAssessment> = async ({
    commentAssessment,
    klassId,
  }: StudentAssessment) => {
    if (!pedagogicalSheetId) throw new Error('pedagogical sheet');

    const assessment: StudentAssessmentBody = {
      pedagogicalSheetId,
      commentAssessment,
      klassId,
    };

    create(assessment);
  };

  const onSubmit = createMode ? onSubmitCreate : onSubmitUpdate;

  const className = 'flex flex-col w-full gap-3';

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  const motionForm = (
    <motion.form
      className={className}
      {...fadeIn}
      onSubmit={handleSubmit(onSubmit)}
    />
  );

  const wrapperDiv = <div className={className} />;

  const areaPlaceholder = t('commentInput.placeholder.studentCommentAbout', {
    name: handleUserFullName(student),
  });

  return (
    <ConditionalWrapper
      condition={!assessmentControl}
      wrapper={motionForm}
      fallback={wrapperDiv}
    >
      <Controller
        name="commentAssessment.comment"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextAreaInput
            fontSize="text-14"
            label={
              assessmentControl
                ? t('commentInput.label.studentLabel')
                : undefined
            }
            testId="commentTextAreaInput"
            onChange={value => onChange(value)}
            placeholder={areaPlaceholder}
            value={value ?? ''}
            errorLabelText={error?.message}
          />
        )}
      />

      <KlassesSheetInput
        student={student}
        control={control}
        studentAssessment={defaultValues}
      />

      <ConditionalRenderer condition={!assessmentControl}>
        <SaveCancelGroup
          loading={loadingCreate || loadingUpdate}
          className="self-start"
          save={{
            disabled: !hasChanges,
            testId: 'saveButton',
          }}
          cancel={{
            onClick: onCancel,
            testId: 'cancelButton',
          }}
        />
      </ConditionalRenderer>
    </ConditionalWrapper>
  );
}

type KlassesSheetSelectProps = CommentAssessmentFormProps & {
  control: Control<StudentAssessment>;
};

function KlassesSheetInput({
  control,
  student,
  studentAssessment: defaultValues,
}: KlassesSheetSelectProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'commentAssessmentForm',
  });

  const { user } = useAuth();

  const authorizedUsers = [UserTypeEnum.TEACHER, UserTypeEnum.UNIT_ADMIN];

  const authorized = authorizedUsers.includes(
    user?.userType ?? UserTypeEnum.STUDENT,
  );

  const isActive = student.status === UserStatusEnum.ACTIVE_ENROLLMENT;

  const renderCondition = !defaultValues || !!defaultValues.klassId;

  const { data: klass, isInitialLoading: isLoading } = useQuery({
    ...klassesQueryKeys.get(defaultValues?.klassId ?? 0),
    enabled: !!defaultValues?.klassId,
    refetchOnMount: false,
  });

  return (
    <ConditionalRenderer condition={renderCondition}>
      <Controller
        name="klassId"
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <InfiniteSearchInput
            selectedItem={klass}
            service={klassesQueryKeys.list}
            displayName={option => option.name}
            onSelect={({ id }) => onChange(id)}
            options={{
              enabled: !!student.id && authorized && isActive,
              refetchOnMount: false,
            }}
            filters={{
              studentId: student.id,
              status: [KlassStatusEnum.OPEN, KlassStatusEnum.IN_PROGRESS],
              unitId: getAuthorizedUnits(user),
            }}
            input={{
              testId: 'klassInput',
              errorLabelText: error?.message,
              disabled: !!defaultValues?.klassId,
              isLoading,
              placeholder: t('klassInput.placeholder'),
              fontSize: 'text-14',
            }}
            onDeselect={() => onChange(undefined)}
            inputIcon={<ChevronDownIcon className="w-4 text-primary" />}
          />
        )}
      />
    </ConditionalRenderer>
  );
}
