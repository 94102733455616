import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ErrorComponent from '@/components/common/ErrorComponent';
import MainButton from '@/components/common/buttons/MainButton';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import ModalWarning from '@/components/common/modals/ModalWarning';
import useLessonContext from '@/data/hook/lesson';
import useActivityView from '@/data/hook/useActivityView';
import { updateActivityProgress } from '@/data/services/activityProgressServices';
import {
  activityProgressAttemptsQueryKeys,
  activityProgressesQueryKeys,
} from '@/data/services/querykeys';
import ActivityProgress, { ActivityMode } from '@/models/ActivityProgress';
import { UserTypeEnum } from '@/models/User';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  ClipboardIcon,
  EyeIcon,
  LogoutIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { LoadingIcon } from '../icons';
import Result from './elements/preview/quiz/Result';

interface ActivityResultProps {
  activityMode: ActivityMode;
  onRedo: () => void;
  onReview: () => void;
  onExit: () => void;
  hasDoneOnce?: boolean;
  activityProgress: ActivityProgress;
  setHasDoneOnce: Dispatch<SetStateAction<boolean>>;
  attemptId?: number;
}

export default function ActivityResult({
  activityMode,
  onExit,
  onRedo,
  onReview,
  hasDoneOnce,
  activityProgress,
  setHasDoneOnce,
  attemptId = 0,
}: ActivityResultProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });
  const { lessonId, slugCourseName, klassId } = useParams();
  const { updateLessonProgress } = useLessonContext();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  const { setActiveStep, resetState } = useActivityView();

  const { answersRevealed } = activityProgress;

  const baseURL = `/courses/${slugCourseName}/lesson/${lessonId}/class/${klassId}/activities/`;

  const queryClient = useQueryClient();

  function onAnswerKey(answersRevealed: boolean) {
    if (answersRevealed) {
      resetState('answerkey');
      setHasDoneOnce(false);
      queryClient.invalidateQueries(
        activityProgressesQueryKeys.get(activityProgress.id),
      );
      setActiveStep(0);
      navigate(baseURL + activityProgress.id);
    } else {
      setIsOpenModal(true);
    }
  }

  async function onUnlockAnswers() {
    try {
      const unlockedActivityProgress = await updateActivityProgress(
        activityProgress.id,
        {
          answersRevealed: true,
        },
      );

      if (lessonId && !isNaN(Number(lessonId))) {
        await updateLessonProgress(Number(lessonId));
        setIsOpenModal(false);

        onAnswerKey(unlockedActivityProgress.answersRevealed);
      }
    } catch (error: any) {
      alert.error(getErrorMessage(error));
    }
  }

  const {
    data: attempt,
    isInitialLoading: loadingAttempt,
    error: attemptError,
  } = useQuery({
    enabled: !!attemptId,
    ...activityProgressAttemptsQueryKeys.get({
      activityProgressId: activityProgress.id,
      attemptId,
    }),
  });

  const attemptErrorDetail = getErrorMessage(attemptError);

  if (loadingAttempt) {
    return (
      <div className="flex w-full justify-center h-72">
        <LoadingIcon className="w-40 text-primary/40" />
      </div>
    );
  }

  if (attemptErrorDetail) {
    return <ErrorComponent errorTextTitle={attemptErrorDetail} />;
  }

  if (attempt) {
    return (
      <div>
        <ComponentGuard roles={[UserTypeEnum.STUDENT]}>
          <ModalWarning
            visible={isOpenModal}
            translationString="modalAnswerKey"
            onClickConfirm={() => onUnlockAnswers()}
            onClickCancel={() => setIsOpenModal(false)}
          />
        </ComponentGuard>
        <Result
          answersRevealed={answersRevealed}
          hasDoneOnce={hasDoneOnce}
          activityAttempt={attempt}
          activityMode={activityMode}
        />
        <div className="grid grid-cols-1 xs:grid-cols-3 place-items-center gap-4 md:gap-6 pt-11">
          <ConditionalRenderer
            condition={hasDoneOnce}
            fallback={
              <FallbackCondition
                activityMode={activityMode}
                onExit={onExit}
                onRedo={onRedo}
                onReview={onReview}
              />
            }
          >
            <Fragment>
              <MainButton
                onClick={() => onAnswerKey(activityProgress.answersRevealed)}
                className="text-xs items-center justify-self-start"
                text={t('feedback').toUpperCase()}
                icon={<ClipboardIcon />}
              />
              <RoundedButton
                disabled={!(activityMode !== 'answerkey')}
                text={
                  activityMode !== 'answerkey'
                    ? t('result.remake')
                    : t('result.finished')
                }
                size="text-14 sm:text-16"
                className="w-full 2xl:w-[80%] col-end-7 col-span-2"
                onClick={onRedo}
              />
            </Fragment>
          </ConditionalRenderer>
        </div>
      </div>
    );
  }

  return <Fragment />;
}

type FallbackConditionProps = Pick<
  ActivityResultProps,
  'activityMode' | 'onRedo' | 'onReview' | 'onExit'
>;

function FallbackCondition({
  activityMode,
  onRedo,
  onReview,
  onExit,
}: FallbackConditionProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });

  return (
    <Fragment>
      <span className="flex flex-col gap-1.5 items-center">
        <MainButton
          className="leading-4 font-500"
          onClick={onReview}
          text={t('result.review').toLocaleUpperCase()}
          icon={<EyeIcon />}
        />
        <Text className="text-center" text={t('result.detailReview')} />
      </span>

      <ConditionalRenderer condition={activityMode !== 'answerkey'}>
        <span className="flex flex-col gap-1.5 items-center h-full">
          <MainButton
            color="secondary"
            onClick={onRedo}
            text={t('result.remake').toLocaleUpperCase()}
            icon={<PencilIcon />}
          />
          <Text className="text-center" text={t('result.detailRemake')} />
        </span>
      </ConditionalRenderer>

      <span className="row-span-1 h-full">
        <MainButton
          onClick={onExit}
          text={t('result.leave').toLocaleUpperCase()}
          icon={<LogoutIcon className="rotate-180" />}
        />
      </span>
    </Fragment>
  );
}
