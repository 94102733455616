import { VersioningStatusEnum } from '@/enums/VersioningStatus';

export enum MGActivityTypeEnum {
  HOMEWORK = 'HOMEWORK',
}

export interface MGActivity {
  id: number;
  status: VersioningStatusEnum;
  unit: number | null;
  name: string;
  isActive: true;
  activityAuthor: number;
  activityType: MGActivityTypeEnum;
}

export interface MGActivityProgress {
  id: number;
  activity: MGActivity;
  startDate: string;
  lastUpdate: string;
  hasDone: boolean;
  grade: number | null;
  comment: string | null;
}
