import requester from '@/axios';
import TokenResponse from '@/models/TokenResponse';

export type Auth = {
  username: string;
  password: string;
  remember: boolean;
};

export async function auth({ username, password }: Auth) {
  const { data } = await requester().post<TokenResponse>('auth/token/', {
    username,
    password,
  });
  return data;
}

export async function getMe() {
  const { data } = await requester().get('users/me/');
  return data;
}
