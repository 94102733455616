import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { createLesson } from '@/data/services/lessonServices';
import {
  createExtraScheduledLesson,
  updateScheduledLesson,
} from '@/data/services/scheduledLessonsServices';
import { formatLessonName } from '@/functions/lessonsName';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../common/buttons/RoundedButton';
import Text from '../../common/dataDisplay/Text';
import Modal from '../../common/modals/Modal';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';

interface AddExtraLessonModalProps {
  onCancel(): void;
  isVisible?: boolean;
  klassId: number;
  lesson?: Lesson;
  onError?(error: ApiError, extraScheduledLesson: number): void;
  updateScheduledLessons?(): Promise<void>;
}

export default function AddExtraLessonModal({
  onCancel,
  isVisible,
  klassId,
  lesson,
  onError,
  updateScheduledLessons,
}: AddExtraLessonModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin.addExtraLesson',
  });
  interface SelectScheduledLesson {
    scheduledLessonId: string;
  }
  const { control, handleSubmit, watch, resetField } =
    useForm<SelectScheduledLesson>();

  const onAssignLessonInScheduledLesson = async ({
    extraScheduledLessonId,
    lessonId,
  }: {
    extraScheduledLessonId: number;
    lessonId: number;
  }) => {
    await updateScheduledLesson(extraScheduledLessonId, {
      lessonId,
    });
  };

  const { mutateAsync: assignLesson } = useMutation(
    onAssignLessonInScheduledLesson,
    {
      onError(error: any, variables) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
        onError?.(apiError, variables.extraScheduledLessonId);
      },
    },
  );

  const handleCreateExtraLesson = async ({
    scheduledLessonId: id,
  }: SelectScheduledLesson) => {
    const scheduledLessonId = +id;
    const { id: extraScheduledLessonId } = await createExtraScheduledLesson(
      {
        klassId,
        scheduledLessonId,
      },
      {
        type: !!lesson
          ? ScheduledLessonTypeEnum.EXTRA
          : ScheduledLessonTypeEnum.FREE,
      },
    );
    if (!lesson)
      await createLesson({
        scheduledLessonId: extraScheduledLessonId,
        name: t('newLesson'),
        motivation: {
          message: '',
        },
      });
    else
      await assignLesson({
        extraScheduledLessonId,
        lessonId: lesson.id,
      });
  };

  const { isLoading, mutate } = useMutation(handleCreateExtraLesson, {
    async onSuccess() {
      resetField('scheduledLessonId');
      await updateScheduledLessons?.();
    },
    onError(error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = (data: SelectScheduledLesson) => mutate(data);

  const onDeselect = () => resetField('scheduledLessonId');

  return (
    <Modal visible={!!isVisible} onClose={onCancel}>
      <form
        data-testid="selectLessonForm"
        className="flex flex-col gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          text={t('title')}
          className="text-primary"
          format="rubik-500"
          size="text-20"
        />

        <Text text={t('selectLessonExplain')} />
        <Controller
          control={control}
          name="scheduledLessonId"
          render={({ field: { onChange } }) => (
            <InfiniteSearchInput
              onDeselect={onDeselect}
              service={scheduledLessonsQueryKeys.list}
              onSelect={({ id }) => onChange(id)}
              displayName={scheduledLesson =>
                formatLessonName(scheduledLesson.lesson, scheduledLesson)
              }
              input={{
                testId: 'scheduledLessonSelect',
                placeholder: t('selectLesson'),
              }}
              filters={{
                isActive: true,
                hasDone: false,
                klassId,
              }}
              options={{ enabled: isVisible && !isNaN(klassId) }}
              className="w-full flex self-center"
            />
          )}
        />
        <div className="flex gap-6">
          <RoundedButton
            type="button"
            onClick={onCancel}
            text={t('cancel')}
            color="neutral"
            className="w-full"
          />
          <RoundedButton
            loading={isLoading}
            testId="submitExtraLesson"
            text={t('add')}
            color="gradient"
            disabled={!watch('scheduledLessonId')}
            className="w-full"
          />
        </div>
      </form>
    </Modal>
  );
}
