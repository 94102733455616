import { useTranslation } from 'react-i18next';
import useStudentContext from '@/data/hook/student';
import { GuestPage } from '../../guest/GuestPage';
import {
  AstroBreadcrumb,
  breadcrumbArray,
} from '@/components/common/AstroBreadcrumb';
import React, { useEffect, useState } from 'react';
import { ICourse } from '@/models/Course';
import PageTitle from '@/components/common/PageTitle';
import Text from '@/components/common/dataDisplay/Text';
import CourseProgressList from '@/components/courses/CourseProgressList';
import ProgressBarCourse from '@/components/courses/ProgressBarCourse';
import LoadingView from '../LoadingView';
import KlassProgress from '@/components/courses/student/KlassProgress';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import HeadTitle from '@/components/common/HeadTitle';
import useAuth from '@/data/hook/useAuth';
import CourseProgressSelect from '@/components/courses/student/CourseProgressSelect';

export default function StudentCoursesView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView',
  });
  const {
    klass,
    nextSteps,
    courseProgress,
    courseProgressList,
    isLoadingKlass,
    klassListError,
    loadingCourseProgress,
  } = useStudentContext();

  const breadcrumbCourse: breadcrumbArray[] = [
    { url: '/', name: 'Home', routeType: 'home' },
    { url: '/courses', name: t('myCourses'), routeType: 'courses' },
    {
      name: courseProgress?.coursePath.course.name || '',
      url: '/courses',
      routeType: 'courses',
    },
  ];

  const [completedCourses, setCompletedCourses] = useState<ICourse[]>([]);

  const [sameActiveCourses, setSameActiveCourses] = useState<ICourse[]>([]);

  const [differentTypeCourses, setDifferentTypeCourses] = useState<ICourse[]>(
    [],
  );

  const courseAbbreviation = courseProgress?.coursePath.course.abbreviation;
  const title = `${courseAbbreviation} - ${t('myCourses')}`;

  const { user } = useAuth();

  useEffect(() => {
    let mounted = true;
    if (mounted && !completedCourses) {
      const completedProgresses: ICourse[] = [];
      courseProgressList?.forEach(({ progress, coursePath }) => {
        if (progress === 1) {
          completedProgresses.push(coursePath.course);
        }
      });
      setCompletedCourses(completedProgresses);
    }

    return () => {
      mounted = false;
    };
  }, [completedCourses, courseProgressList]);

  useEffect(() => {
    let mounted = true;
    if (mounted && klass && courseProgress) {
      if (courseProgress) {
        setSameActiveCourses([courseProgress.coursePath.course]);

        if (nextSteps?.length) {
          const filterSameActiveCourses = nextSteps.filter(
            data =>
              data.courseType === courseProgress.coursePath.course.courseType,
          );

          const filterDifferentCourses = nextSteps.filter(
            data =>
              data.courseType !== courseProgress.coursePath.course.courseType,
          );

          if (filterDifferentCourses) {
            setDifferentTypeCourses(filterDifferentCourses);
          }

          if (filterSameActiveCourses.length) {
            filterSameActiveCourses.forEach((_data, index) => {
              setSameActiveCourses(prev => [
                ...(prev || []),
                filterSameActiveCourses[index],
              ]);
            });
          }
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [courseProgress, klass, nextSteps]);

  if (isLoadingKlass) {
    return <LoadingView />;
  }

  if (klassListError) {
    return (
      <React.Fragment>
        <HeadTitle routeInfo={title} />
        <Text
          testId="klassListError"
          text={klassListError}
          format="rubik-500"
          className="text-primary"
        />
      </React.Fragment>
    );
  }

  if (klass) {
    return (
      <div className="flex flex-col gap-6">
        <ConditionalRenderer condition={!!courseAbbreviation}>
          <HeadTitle routeInfo={title} />
        </ConditionalRenderer>
        <AstroBreadcrumb breadcrumbList={breadcrumbCourse} />
        <div className="flex flex-col justify-between">
          <PageTitle
            headingText={t('myCourses')}
            subheadingText={t('myCoursesInstructions')}
            position="mb-3.5"
          />
          <CourseProgressSelect />
        </div>

        <CourseProgressList
          loading={loadingCourseProgress}
          courseProgress={courseProgress}
          courses={sameActiveCourses}
        />

        <KlassProgress
          studentId={user?.id}
          klassId={klass?.id}
          courseSlug={klass?.coursePathSlug}
        />

        <ConditionalRenderer condition={differentTypeCourses?.length}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2.5">
              <Text
                text={t('nextSteps')}
                className="text-28 text-primary"
                format="poppins-600"
              />
              <Text text={t('continueText')} />
            </div>
            <div className="flex flex-col gap-3">
              <ProgressBarCourse
                courseType={differentTypeCourses[0]?.courseType}
                hideProgress
              />
              <CourseProgressList courses={differentTypeCourses} />
            </div>
          </div>
        </ConditionalRenderer>
      </div>
    );
  }
  return <GuestPage />;
}
