import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../common/dataInput/TextInput';
import { ColumnDND } from '../../../../../../models/DNDOrdenation';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';

interface DNDOrdenationColumnNameEditProps {
  indexColumn: number;
  column: ColumnDND;
  onChangeColumnName: ({
    value,
    keyColumn,
  }: {
    value: string;
    keyColumn: string;
  }) => void;
}

export default function DNDOrdenationColumnNameEdit(
  props: DNDOrdenationColumnNameEditProps,
) {
  const { indexColumn, column, onChangeColumnName } = props;

  const questionColumnErros = column.errors;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDNDOrdenation',
  });

  const columnNameError =
    questionColumnErros && questionColumnErros.some(value => value === 'name');

  return (
    <div data-testid="dndOrdenationColumnNameEdit">
      <label
        data-testid="columnNameLabel"
        className="font-rubik text-base-content block"
      >
        {indexColumn + 1 + ' - ' + t('columnName')}
      </label>

      <ConditionalRenderer condition={!!columnNameError}>
        <label className="font-rubik text-error error-label">
          {t('nameColumnRequired')}
        </label>
      </ConditionalRenderer>

      <TextInput
        fontWeight="font-400"
        className="rounded-lg border-primary h-9 font-600 font-rubik"
        type="text"
        testId={`columnNameInput` + indexColumn}
        color={columnNameError && !column.name ? 'warning' : 'primary'}
        required
        defaultValue={column.name}
        onChange={({ target }) =>
          onChangeColumnName({ value: target.value, keyColumn: 'name' })
        }
      />
    </div>
  );
}
