import React, { useCallback, useState } from 'react';
import MultipleChoiceAnswer from '../../../../../models/MultipleChoiceAnswer';
import { TrashIcon } from '@heroicons/react/outline';
import useCourseEditing from '../../../../../data/hook/useCourseEditing';
import TooltipHandler from '../../../../common/TooltipHandler';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../common/buttons/IconButton';
import TextInput from '../../../../common/dataInput/TextInput';
import { QuizAnswersType } from '../../../../../models/ActivityElement';
import ImageInput from '../../../../common/dataInput/ImageInput';
import isAceptableType, {
  bannerConfig,
} from '../../../../../utils/VerifyImageFile';
import Text from '../../../../common/dataDisplay/Text';

interface AnswerListProps {
  id: number;
  answer: MultipleChoiceAnswer;
  answerType: QuizAnswersType;
  onChange: (quizAnswer: MultipleChoiceAnswer) => void;
  onRemoveClick: (answer: MultipleChoiceAnswer) => void;
  isRequesting: boolean;
  errorMessage?: string;
  allowRemove: boolean;
}

export default function AnswerListItem({
  id,
  answer,
  onChange,
  onRemoveClick,
  isRequesting,
  answerType,
  errorMessage,
  allowRemove,
}: AnswerListProps) {
  const { allowBigChanges } = useCourseEditing();
  const [answerItem, setAnswerItem] = useState<File | string>(answer.answer);
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityQuiz',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });
  const removeTooltipMessage = !allowBigChanges
    ? editRulesT('cantModify')
    : t('answers.cantRemove');
  const shouldAllowRemove = allowBigChanges && allowRemove;
  const validateUpload = (file?: any) => {
    return (
      !isAceptableType(bannerConfig.accept, file.type) &&
      !!URL.createObjectURL(file)
    );
  };

  const handleAltChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    answer.altText = target.value;
    onChange(answer);
  };

  const handleAnswerChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const target = e.currentTarget;

      if (!!target.files) {
        const image = target.files.item(0);
        if (image && !validateUpload(image)) {
          answer.answer = image;
          onChange(answer);
          setAnswerItem(image);
        }
      } else {
        answer.answer = target.value;
        onChange(answer);
        setAnswerItem(target.value);
      }
    },
    [answer, onChange],
  );

  const handleToggleCorrectAnswer = (isCorrect: boolean) => {
    answer.isCorrect = isCorrect;
    onChange(answer);
  };

  return (
    <React.Fragment>
      <Text text={errorMessage} className="text-error" />
      <div className="flex items-center AnswerListItem">
        {answerType === QuizAnswersType.TEXT ? (
          <TextInput
            onChange={handleAnswerChange}
            fontWeight="font-400"
            testId="answerValue"
            className="rounded-lg w-full border-primary h-9 font-rubik answer-value"
            defaultValue={answer.answer as string}
            color={errorMessage ? 'warning' : 'primary'}
          />
        ) : (
          <div className="flex flex-col gap-4 w-full">
            <ImageInput
              hideRemoveButton
              image={answerItem}
              onChange={handleAnswerChange}
            />
            <TextInput
              onChange={handleAltChange}
              fontWeight="font-400"
              className="rounded-lg border-primary font-rubik answer-value"
              defaultValue={answer.altText}
              label={t('imageDescription')}
            />
          </div>
        )}
        <TooltipHandler
          renderTooltip={!allowBigChanges}
          tooltipMessage={editRulesT('cantModify')}
        >
          <input
            type="checkbox"
            data-testid="trueFalseToggle"
            disabled={!allowBigChanges || isRequesting}
            defaultChecked={answer.isCorrect}
            className="toggle toggle-primary mx-2"
            onChange={e => handleToggleCorrectAnswer(e.target.checked)}
          />
        </TooltipHandler>
        <TooltipHandler
          renderTooltip={!shouldAllowRemove}
          tooltipMessage={removeTooltipMessage}
        >
          <IconButton
            disabled={!shouldAllowRemove}
            testId="removeButton"
            className="remove-button"
            onClick={() => onRemoveClick(answer)}
            icon={
              <TrashIcon
                className={`w-5 h-5 ${
                  shouldAllowRemove ? 'text-error' : 'text-neutral/50'
                }`}
              />
            }
          />
        </TooltipHandler>
      </div>
      <hr className="h-px my-8 border-primary/40" />
    </React.Fragment>
  );
}
