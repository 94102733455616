import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getUserSettings, updateSettings } from '@/functions/userSettings';
import { getTokenResponse, setTokenResponse } from '@/functions/tokenSettings';
import { UserSettings } from '@/models/UserSettings';
import ConsentModal from '@/components/user/settings/ConsentModal';

export type Themes = 'light' | 'dark';

export type ConfigContextProps = {
  acknowledged?: boolean;
  userSettings?: UserSettings;
  setUserAcknowledged: (acknowledged: boolean) => void;
  setTheme: (theme: Themes) => void;
};

const ConfigContext = createContext<ConfigContextProps>(
  {} as ConfigContextProps,
);

export function ConfigProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();

  const [consented, setConsented] = useState(false);

  const tokenResponse = getTokenResponse();

  const [acknowledged, setAcknowledged] = useState<boolean>();

  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    getUserSettings,
  );

  const setUserAcknowledged = (acknowledged: boolean) => {
    if (tokenResponse)
      setTokenResponse({
        tokenResponse,
        remember: userSettings?.remember === 'true',
      });

    updateSettings({
      updates: { ...userSettings, acknowledged: String(acknowledged) },
    });

    setAcknowledged(acknowledged);
    setConsented(consented);
  };

  const setTheme = useCallback((theme: Themes) => {
    updateSettings({ updates: { theme } });

    document.documentElement.dataset.theme = theme;

    setUserSettings(prev => ({ ...prev, theme }));
  }, []);

  useEffect(
    () => setAcknowledged(Boolean(userSettings?.acknowledged)),
    [userSettings?.acknowledged],
  );

  useEffect(() => {
    i18n.changeLanguage(userSettings?.language || 'pt_BR');
  }, [i18n, userSettings?.language]);

  useEffect(() => {
    document.documentElement.dataset.theme = userSettings?.theme || 'light';
  }, [setTheme, userSettings?.theme]);

  const values: ConfigContextProps = {
    acknowledged,
    setUserAcknowledged,
    setTheme,
    userSettings,
  };

  return (
    <ConfigContext.Provider value={values}>
      {children}
      <ConsentModal
        acknowledged={acknowledged}
        setAcknowledged={setUserAcknowledged}
      />
    </ConfigContext.Provider>
  );
}

export default ConfigContext;
