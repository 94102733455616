import { ReactElement, useState } from 'react';
import getKlassStudents from '@/functions/klassActiveStudents';
import { useQuery } from '@tanstack/react-query';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import Text from './Text';
import AvatarName from './AvatarName';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './Tooltip';
import ConditionalRenderer from '../ConditionalRenderer';
import { LoadingIcon } from '../../icons';
import User from '@/models/User';
import { enrollmentsQueryKeys } from '@/data/services/querykeys';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { createSearchParams, Link } from 'react-router-dom';
import { handleUserFullName } from '@/functions/handleUserFullName';

export default function StudentListTooltip({
  klassId,
  children,
  className,
}: {
  klassId: number;
  children: ReactElement;
  className?: string;
}) {
  const [isVisible, setIsVisible] = useState(false);
  const status = [EnrollmentStatusEnum.ACTIVE, EnrollmentStatusEnum.CONCLUDED];
  const { data: enrollments, isFetching } = useQuery({
    enabled: !!klassId && isVisible,
    ...enrollmentsQueryKeys.list({ klassId, status }),
  });
  const activeStudents = getKlassStudents(enrollments?.results);

  return (
    <Tooltip
      color="white"
      className={`text-primary ${className ?? ''}`}
      options={{
        interactive: true,
        delayHide: 500,
        onVisibleChange: state => setIsVisible(state),
      }}
      text={
        <RenderTooltipContent
          isLoading={isFetching}
          activeStudents={activeStudents}
        />
      }
    >
      {children}
    </Tooltip>
  );
}

const RenderTooltipContent = ({
  isLoading,
  activeStudents,
}: {
  isLoading: boolean;
  activeStudents: User[];
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherKlasses',
  });
  if (isLoading) {
    return <LoadingIcon className="py-4 m-auto w-14 h-14 text-primary" />;
  } else
    return (
      <ConditionalRenderer
        fallback={
          <div className="flex bg-base-100 p-3 rounded-xl">
            <Text text={t('noStudents')} format="rubik-400" />
          </div>
        }
        condition={activeStudents.length}
      >
        {activeStudents.map(student => {
          const search = handleUserFullName(student);
          return (
            <span
              data-testid="item"
              key={student.id}
              className="flex transition ease-in-out duration-500 py-1.5 bg-base-100 gap-2 justify-between"
            >
              <AvatarName
                className="underline text-primary font-500"
                href={`/admin/students/info/?${createSearchParams({
                  search,
                })}`}
                user={student}
              />

              <Tooltip text={t('studentSheet')}>
                <Link
                  to={`/admin/students/sheet/?${createSearchParams({
                    search,
                  })}`}
                >
                  <DocumentTextIcon className="text-primary w-6 h-6" />
                </Link>
              </Tooltip>
            </span>
          );
        })}
      </ConditionalRenderer>
    );
};
