import React from 'react';

interface ButtonTextProps {
  text: string;
  className?: string;
  id?: string;
}

export default function ButtonText(props: ButtonTextProps) {
  return (
    <p
      id={props.id}
      className={`font-poppins font-600 text-16 ${props.className}`}
    >
      {props.text}
    </p>
  );
}
