import { createContext, useEffect, useRef, useState } from 'react';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import useAuth from '../hook/useAuth';
import { isStudent } from '@/functions/auth';
import { Course } from '@/models/Course';
import Rewards from '@/models/Rewards';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiError } from '@/models/Errors';
import { REQUEST_STALE_TIME_IN_MS, REWARDS_TRIGGER_EVENTS } from '@/constants';
import useListService from '../hook/useListService';
import { RequestEvent } from '@/utils/observers/RequestObserver';
import { getRequestObserver } from '@/axios';
import {
  klassesQueryKeys,
  nextScheduledLessonsQueryKeys,
  nextStepsQueryKeys,
  rewardsQueryKeys,
  simplifiedCourseProgressesQueryKeys,
} from '../services/querykeys';

export interface StudentContextProps {
  rewardsData: undefined | Rewards;
  isLoadingRewards: boolean;
  shouldAnimateReward: boolean;
  updateCourseProgress(): Promise<void>;
  nextSteps?: Course[];
  klass?: Klass;
  klasses?: Klass[];
  nextLesson?: ScheduledLesson;
  isLoadingKlass: boolean;
  isLoadingNextLesson: boolean;
  courseProgressError: string | null;
  nextLessonError: string | null;
  klassListError: string | null;
  changeKlassByCourseSlug(slug: string): Promise<void>;
  courseProgress?: SimplifiedCourseProgress;
  courseProgressList?: SimplifiedCourseProgress[];
  loadingCourseProgress: boolean;
}

export const StudentContext = createContext<StudentContextProps>(
  {} as StudentContextProps,
);

export function StudentProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();

  const studentView = !!user && isStudent(user.userType);

  const [shouldAnimateReward, setShouldAnimateReward] =
    useState<boolean>(false);

  const [klass, setKlass] = useState<Klass>();

  const nextLessonLog = useRef<string | null>(null);

  const { current: nextLessonError } = nextLessonLog;

  const klassListLog = useRef<string | null>(null);

  const { current: klassListError } = klassListLog;

  const courseProgressLog = useRef<string | null>(null);

  const { current: courseProgressError } = courseProgressLog;

  const previousRewardsData = useRef<Rewards>();

  const queryClient = useQueryClient();
  const { queryFn: rewardsQueryFn, queryKey: rewardsQueryKey } =
    rewardsQueryKeys.get(user?.id ?? 0);

  const { data: rewardsData, isInitialLoading: isLoadingRewards } = useQuery({
    queryKey: rewardsQueryKey,
    queryFn: rewardsQueryFn,
    enabled: !!user?.id && !isNaN(Number(user.id)) && studentView,
  });

  const eventMatches = (
    currentEvent: RequestEvent,
    expectedEvent: RequestEvent,
  ) => {
    const isExpectedUrl = currentEvent.url.includes(expectedEvent.url);
    const isExpectedMethod = currentEvent.method === expectedEvent.method;
    const isExpectedStatus =
      currentEvent.statusCode === expectedEvent.statusCode;

    return isExpectedUrl && isExpectedMethod && isExpectedStatus;
  };

  useEffect(() => {
    const invalidateRewards = async () =>
      await queryClient.invalidateQueries(rewardsQueryKey);
    const observerId = 'rewards';
    const requestObserver = getRequestObserver();

    if (studentView) {
      const onTriggerRewards = async (event: RequestEvent) => {
        for (const expectedEvent of REWARDS_TRIGGER_EVENTS) {
          if (eventMatches(event, expectedEvent)) {
            await invalidateRewards();
            break;
          }
        }
      };

      requestObserver.subscribe({
        id: observerId,
        action: onTriggerRewards,
      });
    }

    return () => {
      requestObserver.unsubscribe(observerId);
    };
  }, [queryClient, rewardsQueryKey, studentView]);

  useEffect(() => {
    let mounted = true;
    let timeOut: NodeJS.Timeout;
    if (mounted && rewardsData) {
      const previousRewards = previousRewardsData.current;
      const rewardsHasChange = previousRewards?.coins !== rewardsData.coins;
      if (previousRewards && rewardsHasChange) {
        setShouldAnimateReward(true);
        timeOut = setTimeout(() => {
          setShouldAnimateReward(false);
        }, 2000);
      }
      previousRewardsData.current = rewardsData;
    }
    return () => {
      mounted = false;
      clearTimeout(timeOut);
    };
  }, [rewardsData]);

  const {
    results: klasses,
    isInitialLoading: loadingKlasses,
    error: klassesError,
  } = useListService({
    ...klassesQueryKeys.list({ ordering: '-klass_start_date' }),
    refetchOnWindowFocus: false,
    enabled: studentView,
  });

  useEffect(() => {
    if (klassesError) {
      const apiError = new ApiError(klassesError as any);
      klassListLog.current = apiError.getErrorMessage();
    }
  }, [klassesError]);

  const { queryKey: courseProgressQueryKey, queryFn: listCourseProgress } =
    simplifiedCourseProgressesQueryKeys.list(user?.id ?? 0, {
      isActive: true,
      pageSize: 50,
    });

  const { results: courseProgressList, isFetching: loadingCourseProgress } =
    useListService({
      queryKey: courseProgressQueryKey,
      queryFn: listCourseProgress,
      enabled: !!klass && studentView && !!user.id,
      keepPreviousData: true,
      staleTime: REQUEST_STALE_TIME_IN_MS,
    });

  const courseProgress =
    courseProgressList?.find(progress => progress.klassId === klass?.id) ??
    undefined;

  const updateCourseProgress = async () => {
    if (!courseProgress || !klass) throw new Error('progress not found');

    await queryClient.invalidateQueries(courseProgressQueryKey);
  };

  const changeKlassByCourseSlug = async (slug: string) => {
    if (klasses?.length) {
      const klass = klasses.find(
        ({ coursePathSlug }) => coursePathSlug === slug,
      );
      if (klass) {
        setKlass(klass);
      }
    }
  };

  const {
    data: nextLesson,
    isInitialLoading: isLoadingNextLesson,
    error: nextLessonRequestError,
  } = useQuery({
    refetchOnWindowFocus: false,
    enabled: studentView && !!klass,
    ...nextScheduledLessonsQueryKeys.get(klass?.id ?? 0),
  });

  useEffect(() => {
    if (nextLessonRequestError) {
      const apiError = new ApiError(nextLessonRequestError as any);
      nextLessonLog.current = apiError.getErrorMessage();
    }
  }, [nextLessonRequestError]);

  const { results: nextSteps } = useListService({
    enabled: studentView && !!courseProgress && !!klass,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...nextStepsQueryKeys.list(courseProgress?.id ?? 0),
  });

  useEffect(() => {
    if (klasses?.length && !klass) {
      const [recentKlass] = klasses;
      setKlass(recentKlass);
    }
  }, [klass, klasses]);

  useEffect(() => {
    if (!user) {
      setKlass(undefined);
      previousRewardsData.current = undefined;
    }
  }, [user]);

  return (
    <StudentContext.Provider
      value={{
        courseProgress,
        rewardsData,
        isLoadingRewards,
        shouldAnimateReward,
        nextSteps,
        klass,
        klasses,
        nextLesson,
        isLoadingKlass: loadingKlasses,
        isLoadingNextLesson,
        courseProgressError,
        nextLessonError,
        klassListError,
        changeKlassByCourseSlug,
        updateCourseProgress,
        loadingCourseProgress,
        courseProgressList,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
}

export default StudentContext;
