import { ImageFolderEnum } from '../../models/CkEditor';
import { HtmlPreview } from '../common/dataDisplay/HtmlPreview';
import MyCkeditor from '../editor/MyCkeditor';
import Text from '../common/dataDisplay/Text';
import { Control, Controller } from 'react-hook-form';

interface BioProps {
  bio?: string;
  isEdit: boolean;
  setHasChanges: (value: boolean) => void;
  control: Control<any>;
  name: string;
}

export default function ProfileBio({
  bio,
  isEdit,
  setHasChanges,
  control,
  name,
}: BioProps) {
  return (
    <div
      data-testid="bio"
      className="flex flex-col w-full gap-1 text-justify justify-self-start grow-0"
    >
      <Text
        text="Bio"
        format="rubik-500"
        className="text-accent text-18 self-start"
      />
      {!isEdit ? (
        <HtmlPreview className="w-full" html={bio || `¯\\_(ツ)_/¯`} />
      ) : (
        <div
          className="w-full lg:w-[95%] lg:max-w-[60vw] xl:max-w-[68vw] "
          data-testid="bioEditor"
        >
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange } }) => (
              <MyCkeditor
                content={bio || ''}
                testId="ckBioEditor"
                disabled={!isEdit}
                folder={ImageFolderEnum.BIO}
                handleContentChange={value => {
                  onChange(value);
                  setHasChanges(true);
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
