import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LoadingIcon } from '../../icons';
import { twMerge } from 'tailwind-merge';

interface MainButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  className?: string;
  icon: JSX.Element | ReactNode;
  loading?: boolean;
  disabled?: boolean;
  href?: string;
  isExternalLink?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  testId?: string;
  loadingSize?: string;
}

export default function IconButton(props: MainButtonProps) {
  const {
    id,
    testId,
    className,
    icon,
    loading,
    disabled,
    href,
    isExternalLink,
    loadingSize,
    onClick,
    ...rest
  } = props;
  function renderButton() {
    return (
      <button
        {...rest}
        id={id}
        data-testid={!href && testId}
        onClick={onClick}
        disabled={disabled || loading || false}
        className={twMerge(
          'leading-none max-w-content h-fit flex rounded-md enabled:active:scale-95 items-center font-rubik duration-150 transition disabled:opacity-50 disabled:cursor-default focus-visible:ring focus:outline-none',
          className,
        )}
      >
        {loading ? <LoadingIcon className={`${loadingSize || ''}`} /> : icon}
      </button>
    );
  }

  return href ? (
    <React.Fragment>
      {isExternalLink ? (
        <a href={href} target="_blank" rel="noreferrer">
          {renderButton()}
        </a>
      ) : (
        <Link to={href} data-testid={testId}>
          {renderButton()}
        </Link>
      )}
    </React.Fragment>
  ) : (
    renderButton()
  );
}
