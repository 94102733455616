import { Fragment } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  VideoActivityElement,
  VideoPlayerEnum,
} from '@/models/ActivityElement';
import { VideoActivityProgress } from '@/models/ActivityProgress';
import LoadingView from '@/pages/courses/LoadingView';
import { ElementPreviewFactoryProps } from './ElementPreviewFactory';
import VimeoEmbed from './VimeoEmbed';
import ActivityHeading from './ActivityHeading';
import { updateVideoActivityProgress } from '@/data/services/activityElement/videoActivityProgressServices';
import ErrorComponent from '@/components/common/ErrorComponent';
import YoutubePlayer from '@/components/common/YoutubePlayer';
import useActivityView from '@/data/hook/useActivityView';
import alert from '@/utils/UseAlert';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import { UpdateQuestionAttempt } from '@/models/QuestionAttempt';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  videoActivitiesQueryKeys,
  videoActivityProgressQueryKeys,
} from '@/data/services/querykeys';

export default function VideoElement(props: ElementPreviewFactoryProps) {
  const { user } = useAuth();

  const queryClient = useQueryClient();

  const { blockStepNavigate, invalidate, status } = useActivityView();

  const studentView = isStudent(user?.userType);

  const { questionId, progressId, activityMode } = props;

  const { queryFn: videoActivityProgressFn, queryKey: progressKey } =
    videoActivityProgressQueryKeys.get({
      videActivityId: questionId,
      videoActivityProgressId: progressId,
    });

  const {
    data: questionProgress,
    isInitialLoading: loadingQuestionProgress,
    isError: questionProgressError,
  } = useQuery({
    queryFn: videoActivityProgressFn,
    queryKey: progressKey,
    enabled:
      studentView && !!progressId && !!questionId && status === 'inProgress',
  });

  const questionProgressErrorDetail = getErrorMessage(questionProgressError);

  const {
    data: question,
    isInitialLoading: loadingQuestion,
    error: questionError,
  } = useQuery({
    ...videoActivitiesQueryKeys.get(questionId),
    enabled: !studentView && !!questionId,
  });

  const questionErrorDetail = getErrorMessage(questionError);

  const updateVideoProgress = async ({
    questionId,
    questionProgressId,
  }: UpdateQuestionAttempt) => {
    const questionProgress = await updateVideoActivityProgress(
      {
        videActivityId: questionId,
        videoActivityProgressId: questionProgressId,
      },
      { isDone: true },
    );

    return questionProgress;
  };

  const { mutateAsync: update } = useMutation(updateVideoProgress, {
    async onSuccess() {
      await queryClient.invalidateQueries(progressKey);

      await invalidate();
    },

    onError(error: any) {
      alert.error(getErrorMessage(error));
    },

    onSettled() {
      blockStepNavigate(false);
    },
  });

  const loading = loadingQuestion || loadingQuestionProgress;

  const errorDetail = questionErrorDetail || questionProgressErrorDetail;

  if (loading) {
    return <LoadingView />;
  }

  if (errorDetail) {
    return <ErrorComponent errorTextTitle={errorDetail} />;
  }

  if (questionProgress) {
    return (
      <Element
        questionProgress={questionProgress}
        activityMode={activityMode}
        update={update}
      />
    );
  }

  if (question) {
    return <Element question={question} activityMode={activityMode} />;
  }

  return <Fragment />;
}

type ElementProps = Pick<ElementPreviewFactoryProps, 'activityMode'> & {
  question?: VideoActivityElement;
  questionProgress?: VideoActivityProgress;
  update?: ({
    questionId,
    questionProgressId,
  }: UpdateQuestionAttempt) => Promise<VideoActivityProgress>;
};

function Element({
  questionProgress,
  question,
  activityMode,
  update,
}: ElementProps) {
  if (questionProgress) {
    const { videoActivity: question, id } = questionProgress;
    const shouldWatchProgress = !questionProgress.isDone;

    const onVideoProgress = (currentProgress: number) => {
      if (currentProgress >= 0.8) {
        update?.({ questionId: question.id, questionProgressId: id });
      }
    };

    return (
      <Content
        question={question}
        shouldWatchProgress={shouldWatchProgress}
        activityMode={activityMode}
        onVideoProgress={onVideoProgress}
      />
    );
  }

  if (question) {
    return <Content question={question} activityMode={activityMode} />;
  }

  return <Fragment />;
}

type ContentProps = Pick<ElementPreviewFactoryProps, 'activityMode'> & {
  question: VideoActivityElement;
  shouldWatchProgress?: boolean;
  onVideoProgress?: (currentProgress: number) => void;
};

function Content({
  question,
  shouldWatchProgress,
  onVideoProgress,
}: ContentProps) {
  return (
    <Fragment>
      <ActivityHeading
        heading={question.title}
        subheading={question.subtitle!}
      />
      <div className="w-full flex justify-center mt-3">
        <SwitchVideoPlayer
          question={question}
          onVideoProgress={onVideoProgress}
          shouldWatchProgress={shouldWatchProgress}
        />
      </div>
    </Fragment>
  );
}

type SwitchVideoPlayerProps = {
  question: VideoActivityElement;
  onVideoProgress?: (currentProgress: number) => void;
  shouldWatchProgress?: boolean;
};

function SwitchVideoPlayer({
  question,
  onVideoProgress,
  shouldWatchProgress,
}: SwitchVideoPlayerProps) {
  switch (question.videoPlayer) {
    case VideoPlayerEnum.YOUTUBE:
      return (
        <YoutubePlayer
          videoUrl={question.url}
          onVideoProgress={onVideoProgress}
          shouldWatchProgress={shouldWatchProgress}
        />
      );
    case VideoPlayerEnum.VIMEO:
      return <VimeoEmbed url={question.url} title={question.title} />;

    default:
      return <Fragment />;
  }
}
